import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Finish } from 'degineo-common';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-print-settings-finish',
  templateUrl: './print-settings-finish.component.html',
  styleUrls: ['./print-settings-finish.component.scss'],
})
export class PrintSettingsFinishComponent implements OnInit {
  @Input()
  availableFinish: Finish[];
  @Input()
  selectedFinish: Finish;
  @Output()
  finishChanged: EventEmitter<any> = new EventEmitter();

  rpcHost: string = environment.rpcHost;
  constructor() {}

  ngOnInit(): void {}
}
