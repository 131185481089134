import { Component, OnInit, Inject } from '@angular/core';
import { Filter, PrinterTechnology } from 'degineo-common';
import { GenericFilter, ProducerPrinterListItemBODTO } from 'degineo-common';
import { ProducerBOService } from 'degineo-common';
import { ProducerData } from '../../providers/producer.provider';
import { L } from 'ic2-lib';
import { BrandConsumableService } from 'degineo-common';
import { ArticleService } from 'degineo-common';

@Component({
  selector: 'app-producer-printer-consumable-tab',
  templateUrl: './producer-printer-consumable-tab.component.html',
  styleUrls: ['./producer-printer-consumable-tab.component.scss'],
})
export class ProducerPrinterConsumableTabComponent implements OnInit {
  ProducerPrinterConsumableTabComponent: typeof ProducerPrinterConsumableTabComponent = ProducerPrinterConsumableTabComponent;

  printers: ProducerPrinterListItemBODTO[] = [];
  loading: boolean = true;

  static filtersCache = {};
  filtersDefault = {};

  builtFilters: Filter[] = [];

  brandFilter: Array<string> = [];
  technologyFilter: PrinterTechnology[] = PrinterTechnology.values;
  typeFilter: Array<String> = [];

  presenterName = (obj) => obj.name;

  presenterTitle = (obj) => obj.title;

  constructor(
    public data: ProducerData,
    @Inject(ProducerBOService) public producerBOService: ProducerBOService,
    @Inject(ArticleService) public articleService: ArticleService,
    @Inject(BrandConsumableService) private brandConsumableService: BrandConsumableService
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    if (!this.data.articleTypes)
      this.articleService.getAllActiveArticleTypes().subscribe(
        (data) => {
          console.log('getAllActiveArticleTypes', data);
          this.data.articleTypes = data;
        },
        (err) => {
          L.e(err);
        }
      );

    this.brandConsumableService.getAllConsumableBrand().subscribe(
      (data) => {
        this.brandFilter = data;
      },
      (error) => {
        L.e(error);
      }
    );

    this.reload();
  }

  reload() {
    let genericFilter = new GenericFilter();
    genericFilter.filters = this.builtFilters;
    console.log('genericFilter', genericFilter);
    this.loading = true;
    this.producerBOService.getProducerPrinterConsumable(this.data.dto.producer.id, genericFilter).subscribe(
      (data) => {
        console.log('data', data);
        this.printers = data;
        this.loading = false;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  filterChanged(filters: Filter[]) {
    console.log('filterChanged event', filters);
    this.reload();
  }
}
