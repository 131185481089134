import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from 'degineo-common';
import { Intercom } from 'ng-intercom';

@Component({
  selector: 'app-signup-confirm',
  templateUrl: './signup-confirm.component.html',
  styleUrls: ['./signup-confirm.component.scss'],
})
export class SignupConfirmComponent implements OnInit {
  email: string;
  constructor(@Inject(AuthService) private authService: AuthService, private intercom: Intercom) {}

  ngOnInit() {
    this.email = this.authService.userBundle.user.email;
  }

  openChat() {
    this.intercom.show();
  }
}
