<h6 class="text-center mb-4">Options disponibles</h6>

<table class="w-100 table-responsive-md" *ngIf="!edit">
  <tr style="height: 40px" *ngFor="let option of data.dto.options">
    <td style="width: 100px" class="text-secondary">
      {{ option.co.id }}
    </td>
    <td>
      <div [ngStyle]="{ 'background-color': '#' + option.hexCode }" class="view"></div>
    </td>

    <td>
      {{ option.colorName }}
    </td>

    <td>
      <div class="text-uppercase">#{{ option.hexCode }}</div>
    </td>

    <td>
      <div class="text-right mr-2">{{ option.co.capacity }}</div>
      <div class="text-right mr-2">{{ option.co.unit }}</div>
    </td>

    <td>
      <div *ngIf="option.co.supplierReference">{{ option.co.supplierReference }}</div>
    </td>

    <td class="text-right">
      <div class="price text-nowrap">
        {{ option.co.price | number: '.2':'fr-FR' }}
        &nbsp;€
      </div>
    </td>

    <td class="text-right">
      <i class="fas fa-coins text-danger" *ngIf="!option.co.stillSold"></i>
    </td>
  </tr>
</table>

<table class="w-100 editTable table-responsive-md" *ngIf="edit">
  <thead>
    <tr>
      <th class="text-secondary text-center">#ID</th>

      <th colspan="2">
        <ic2-textassist
          class="bold-deep d-block"
          style="width: 125px"
          placeholder="Nom couleur"
          [values]="data.colorList"
          [presenter]="presenter"
          [(ngModel)]="name"
          (objectChange)="nomChanged($event)"
          required
        >
        </ic2-textassist>
      </th>

      <th class="text-nowrap">
        <span>#</span>
        <input
          class="form-control fw-500 d-inline-block"
          style="width: 125px"
          [(ngModel)]="hexa"
          placeholder="HEX000"
          type="text"
          (change)="hexChanged()"
          required
        />
      </th>

      <th>
        <input class="form-control fw-500 d-inline-block" style="width: 125px" [(ngModel)]="capacity" placeholder="Valeur" type="number" />
        <input class="form-control fw-500 d-inline-block" style="width: 125px" [(ngModel)]="unit" placeholder="Unité" type="text" />
      </th>

      <th>
        <input class="form-control fw-500" style="width: 125px" type="text" [(ngModel)]="supplierReference" placeholder="Ref" />
      </th>

      <th class="text-nowrap">
        <input class="price form-control fw-500 d-inline" style="width: 125px" type="number" placeholder="Prix" [(ngModel)]="price" />
        <span class="d-inline">€</span>
      </th>

      <th>
        <button
          placement="left"
          ngbTooltip="Toujours en vente"
          [ngClass]="{ 'text-success': stillSold, 'text-danger': !stillSold }"
          (click)="stillSold = !stillSold"
        >
          <i class="fas fa-coins"></i>
        </button>
      </th>

      <th class="text-right">
        <button (click)="add()"><i class="far fa-plus-square text-success"></i></button>
      </th>
    </tr>
  </thead>
  <tbody *ngIf="data.dto.options.length > 0">
    <tr style="height: 25px" class="border-bottom border-secondary">
      <td colspan="8"></td>
    </tr>

    <tr style="height: 25px"></tr>

    <tr style="height: 50px" *ngFor="let option of data.dto.options; let i = index">
      <td class="text-secondary text-right pr-2">
        <span *ngIf="option.co.id != 0">{{ option.co.id }}</span>
      </td>

      <td class="text-center">
        <div [ngStyle]="{ 'background-color': '#' + option.hexCode }" class="view"></div>
      </td>

      <td>
        {{ option.colorName }}
      </td>

      <td>
        <div class="text-uppercase">#{{ option.hexCode }}</div>
      </td>

      <td>
        <input class="form-control d-inline-block" style="width: 125px" placeholder="Valeur" type="number" [(ngModel)]="option.co.capacity" #value />
        <input class="form-control d-inline-block" style="width: 125px" placeholder="Unité" type="text" [(ngModel)]="option.co.unit" #value />
      </td>

      <td>
        <input class="form-control" style="width: 125px" type="text" placeholder="Ref" [(ngModel)]="option.co.supplierReference" />
      </td>

      <td class="text-nowrap">
        <input class="price form-control d-inline-block" style="width: 125px" type="number" placeholder="Prix" [(ngModel)]="option.co.price" />
        &nbsp;€
      </td>

      <td>
        <button
          placement="left"
          ngbTooltip="Toujours en vente"
          [ngClass]="{ 'text-success': option.co.stillSold, 'text-danger': !option.co.stillSold }"
          (click)="option.co.stillSold = !option.co.stillSold"
        >
          <i class="fas fa-coins"></i>
        </button>
      </td>

      <td class="text-right">
        <button *ngIf="!option.linked" (click)="remove(option)"><i class="far fa-times-circle text-danger"></i></button>
        <div
          *ngIf="option.linked"
          placement="left"
          [ngbTooltip]="
            'Option utilisé par :&#013;' +
            option.nbAssocPrinter +
            ' Imprimante' +
            (option.nbAssocPrinter > 1 ? 's' : '') +
            '&#013;' +
            option.nbAssocProducer +
            ' Producteur' +
            (option.nbAssocProducer > 1 ? 's' : '')
          "
        >
          <button type="button" disabled><i class="far fa-times-circle text-secondary"></i></button>
        </div>
      </td>
    </tr>
  </tbody>
</table>

<div *ngIf="data.dto.options.length == 0" class="text-center text-info" style="margin-top: 25px">Aucune option ajoutée</div>
