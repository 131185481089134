import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthService, AdminAuthGuard, DegineoCommonModule } from 'degineo-common';
import { NotFoundComponent } from 'degineo-common';
import { AdminLayoutComponent } from './AdminLayout/admin-layout.component';
import { InvoiceAdminComponent } from './Admins/Financial/invoice-admin.component';
import { DashboardComponent } from './Admins/Dashboard/dashboard.component';
import { ProducerListComponent } from './Admins/Producer/List/producer-list.component';
import { ProducerEditComponent } from './Admins/Producer/Edit/producer-edit.component';
import { ConsumableListComponent } from './Admins/Consumable/consumable-list/consumable-list.component';
import { ConsumableViewEditComponent } from './Admins/Consumable/consumable-view-edit/consumable-view-edit.component';
import { PrinterListComponent } from './Admins/Printer/printer-list/printer-list.component';
import { PrinterViewEditComponent } from './Admins/Printer/printer-view-edit/printer-view-edit.component';
import { ClientListComponent } from './Admins/Client/client-list/client-list.component';
import { ClientViewComponent } from './Admins/Client/client-view/client-view.component';
import { MaterialTypeCharacteristicViewEditComponent } from './Admins/MaterialTypeCharacteristic/material-type-characteristic-view-edit/material-type-characteristic-view-edit.component';
import { OrderListComponent } from './Admins/Order/order-list/order-list.component';
import { OrderViewComponent } from './Admins/Order/order-view/order-view.component';
import { ProducerPageListComponent } from './Admins/ProducerPage/producer-page-list/producer-page-list.component';
import { PaymentToDegineoListComponent } from './Admins/PaymentToDegieno/payment-to-degineo-list/payment-to-degineo-list.component';
import { PaymentToProducerListComponent } from './Admins/PaymentToProducer/payment-to-producer-list/payment-to-producer-list.component';
import { SubscriptionListComponent } from './Admins/Subscription/subscription-list/subscription-list.component';
import { PaymentToDegineoEditComponent } from './Admins/PaymentToDegieno/payment-to-degineo-edit/payment-to-degineo-edit.component';
import { ProducerNewComponent } from './Admins/Producer/New/producer-new/producer-new.component';
import { ArticleTypeViewEditComponent } from './Admins/ArticleType/article-type-view-edit/article-type-view-edit.component';
import { CodenafListEditComponent } from './Admins/CodeNaf/codenaf-list-edit/codenaf-list-edit.component';
import { FixturesComponent } from './Admins/Fixtures/fixtures.component';
import { FinishListComponent } from './Admins/Finish/finish-list/finish-list.component';
import { FinishViewEditComponent } from './Admins/Finish/finish-view-edit/finish-view-edit.component';

const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AdminAuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent, canActivate: [AdminAuthGuard] },
      //
      { path: 'clients', component: ClientListComponent, canActivate: [AdminAuthGuard] },
      { path: 'clients/:name/:id', component: ClientViewComponent, canActivate: [AdminAuthGuard] },
      //
      { path: 'orders', component: OrderListComponent, canActivate: [AdminAuthGuard] },
      { path: 'orders/:id', component: OrderViewComponent, canActivate: [AdminAuthGuard] },
      //
      { path: 'producers', component: ProducerListComponent, canActivate: [AdminAuthGuard] },
      { path: 'producers/edit/:name/:id', redirectTo: 'producers/edit/:name/:id/infos' },
      { path: 'producers/edit/:name/:id/:page', component: ProducerEditComponent, canActivate: [AdminAuthGuard] },
      { path: 'producers/new', component: ProducerNewComponent, canActivate: [AdminAuthGuard] },
      //
      { path: 'producersPage', component: ProducerPageListComponent, canActivate: [AdminAuthGuard] },
      //
      { path: 'paymentToD', component: PaymentToDegineoListComponent, canActivate: [AdminAuthGuard] },
      { path: 'paymentToD/edit/:id', component: PaymentToDegineoEditComponent, canActivate: [AdminAuthGuard] },
      //
      { path: 'paymentToP', component: PaymentToProducerListComponent, canActivate: [AdminAuthGuard] },
      //
      { path: 'invoices', component: InvoiceAdminComponent, canActivate: [AdminAuthGuard] },
      { path: 'invoices/search/:search', component: InvoiceAdminComponent, canActivate: [AdminAuthGuard] },
      { path: 'invoices/company/:company', component: InvoiceAdminComponent, canActivate: [AdminAuthGuard] },
      //
      { path: 'printers', component: PrinterListComponent, canActivate: [AdminAuthGuard] },
      { path: 'printers/:view/:name/:id', component: PrinterViewEditComponent, canActivate: [AdminAuthGuard] },
      { path: 'printers/:view', component: PrinterViewEditComponent, canActivate: [AdminAuthGuard] },
      //
      { path: 'consumables', component: ConsumableListComponent, canActivate: [AdminAuthGuard] },
      { path: 'consumables/:view/:id/:name', component: ConsumableViewEditComponent, canActivate: [AdminAuthGuard] },
      { path: 'consumables/:view', component: ConsumableViewEditComponent, canActivate: [AdminAuthGuard] },
      //
      { path: 'materialTypeCharacteristics', component: MaterialTypeCharacteristicViewEditComponent, canActivate: [AdminAuthGuard] },
      //
      { path: 'articleType', component: ArticleTypeViewEditComponent, canActivate: [AdminAuthGuard] },
      //
      { path: 'subscription', component: SubscriptionListComponent, canActivate: [AdminAuthGuard] },
      //
      { path: 'codenaf', component: CodenafListEditComponent, canActivate: [AdminAuthGuard] },
      //
      { path: 'fixtures', component: FixturesComponent, canActivate: [AdminAuthGuard] },
      //
      { path: 'finitions', component: FinishListComponent, canActivate: [AdminAuthGuard] },
      { path: 'finition/:view/:id', component: FinishViewEditComponent, canActivate: [AdminAuthGuard] },
      { path: 'finition/:view', component: FinishViewEditComponent, canActivate: [AdminAuthGuard] },
    ],
  },
  { path: 'test', component: AdminLayoutComponent, canActivate: [AdminAuthGuard] },
  { path: 'connexion', component: LoginComponent },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }), DegineoCommonModule],
  providers: [AuthService],
})
export class AppRoutingModule {}
