import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { OrderBy } from 'degineo-common';
import { DevisListItemBODTO, Filter, FilterOrder, GenericFilter, DevisStatus } from 'degineo-common';
import { DevisBOService } from 'degineo-common';
import { Router } from '@angular/router';
import { ProducerData } from '../../providers/producer.provider';
import { L } from 'ic2-lib';
import { SlugService } from 'degineo-common';

@Component({
  selector: 'app-producer-devis-tab',
  templateUrl: './producer-devis-tab.component.html',
  styleUrls: ['./producer-devis-tab.component.scss'],
})
export class ProducerDevisTabComponent implements OnInit, AfterViewInit {
  ProducerDevisTabComponent: typeof ProducerDevisTabComponent = ProducerDevisTabComponent;
  OrderBy: typeof OrderBy = OrderBy;

  devis: DevisListItemBODTO[] = [];

  static filtersCache = {};
  filtersDefault = {};

  static OrderCaches = {};
  static page: number = 1;

  nbPerPage: number = 10;
  count = 0;

  builtFilters: Filter[] = [];
  builtFiltersOrder: FilterOrder[] = [];

  status = DevisStatus.values;

  constructor(
    @Inject(DevisBOService) public devisBOService: DevisBOService,
    private router: Router,
    public data: ProducerData,
    @Inject(SlugService) public slugService: SlugService
  ) {}

  ngOnInit() {}
  ngAfterViewInit() {
    this.reload();
  }

  reload() {
    let genericFilter = new GenericFilter();
    genericFilter.filters = this.builtFilters;

    let f: Filter = new Filter();
    f.name = 'producer';
    f.intValues = [this.data.dto.producer.id];
    genericFilter.filters.push(f);

    genericFilter.orderFilters = this.builtFiltersOrder;
    genericFilter.page = ProducerDevisTabComponent.page;
    genericFilter.nbPerPage = this.nbPerPage;
    console.log('genericFilter', genericFilter);
    this.devisBOService.getProducerDevis(this.data.dto.producer.id, genericFilter).subscribe(
      (data) => {
        console.log('getProducerDevis', data);
        this.devis = data.data;
        this.count = data.count;
      },
      (error) => {
        L.e(error);
      }
    );
  }

  restoreDevis(id) {
    this.devisBOService.restoreDevis(id).subscribe(
      (data) => {
        this.reload();
      },
      (error) => {
        L.e(error);
      }
    );
  }

  getRatio(num1, num2) {
    if (num2 == 0) return 0;
    return num1 / num2;
  }

  filterChanged(filters: Filter[]) {
    console.log('filterChanged event', filters);
    this.reload();
  }
  orderChange(order) {
    console.log('orderChange', order);
    this.reload();
  }
  tableRowClick(row: any) {
    console.log('tableRowClick');
  }
}
