<div class="container pt-4 pb-4">
  <h1 class="mx-auto text-center mb-5">Clients</h1>

  <degineo-filter
    class="d-inline-block w-100 mt-4 mb-4"
    #fliter
    [builtFilters]="builtFilters"
    (filterChanged)="filterChanged($event)"
    [cache]="ClientListComponent.filtersCache"
    [default]="filtersDefault"
  >
    <div class="form-inline">
      <degineo-select-filter
        disabled
        #f
        class="input-group mr-2 mt-1"
        style="width: 13rem"
        name="Tous les comptes"
        filterName="compte"
        [values]="compteFilter"
        [presenter]="titlePresenter"
      >
      </degineo-select-filter>

      <degineo-select-filter
        #f
        class="input-group mr-2 mt-1"
        style="width: 13rem"
        name="Tous les secteurs d'activité"
        filterName="target"
        [presenter]="targetPresenter"
        [values]="targets"
      >
      </degineo-select-filter>

      <degineo-date-filter #f class="input-group mr-2 mt-1" style="width: 13rem" name="Création du compte" filterName="dateCreate"> </degineo-date-filter>

      <degineo-date-filter #f class="input-group mr-2 mt-1" style="width: 13rem" name="Dernière connexion" filterName="dateLogin"> </degineo-date-filter>

      <degineo-text-filter #f class="input-group mr-2 mt-1" style="width: 13rem" name="Entreprise ou Client" filterName="recherche"> </degineo-text-filter>

      <i class="fas fa-undo text-primary resetToDefault ml-2" (click)="fliter.resetToDefault(); ClientListComponent.page = 1"></i>
    </div>

    <degineo-checkbox-group-filter #f class="form-inline float-left mt-4" filterName="checkBox">
      <degineo-round-checkbox-filter #cb class="mr-4" name="Paiements OK" filterName="ok" color="success"> </degineo-round-checkbox-filter>
      <degineo-round-checkbox-filter #cb class="mr-4" name="Paiements en fin de mois en cours" filterName="end" color="warning">
      </degineo-round-checkbox-filter>
      <degineo-round-checkbox-filter #cb class="mr-4" name="Paiements en retard" filterName="late" color="danger"> </degineo-round-checkbox-filter>
      <degineo-round-checkbox-filter #cb name="Jamais de commandes passées" filterName="aucun" color="secondary"> </degineo-round-checkbox-filter>
    </degineo-checkbox-group-filter>
  </degineo-filter>

  <degineo-my-table
    [rows]="clients"
    [orderCache]="ClientListComponent.OrderCaches"
    [builtFiltersOrder]="builtFiltersOrder"
    (orderChange)="orderChange($event)"
    (rowClick)="tableRowClick($event)"
  >
    <degineo-my-column orderName="name" name="Entreprise">
      <td *cv="let r" class="text-nowrap text-left">
        <div
          class="round d-inline-block mr-2"
          [ngClass]="{
            'bg-success': r.nbPay > 0 && r.nbEndPay == 0 && r.nbLatePay == 0,
            'bg-warning': r.nbEndPay > 0 && r.nbLatePay == 0,
            'bg-danger': r.nbLatePay > 0,
            'bg-secondary': r.nbOrder == 0
          }"
        ></div>
        <div class="d-inline-block text-wrap pl-1" [class.text-warning]="r.invited" [ngbTooltip]="r.invited ? 'Client invité mais compte non créé' : null">
          {{ r.companyName }}
        </div>
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="lastName" name="Client">
      <td *cv="let r" class="text-center">{{ r.firstName }} {{ r.lastName }}</td>
    </degineo-my-column>

    <degineo-my-column orderName="target" name="Secteur d'activité">
      <td *cv="let r" class="text-center">
        {{ r.target?.lib }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="origin" name="Origine">
      <td *cv="let r" class="text-center">
        {{ r.origin }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="dateCreated" name="Création du compte">
      <td *cv="let r" class="text-center">
        {{ r.dateCreated | date: 'dd/MM/yyyy' }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="dateLastConnexion" name="Dernière connexion" [defaultOrder]="OrderBy.DESC">
      <td *cv="let r" class="text-center">
        <ng-container *ngIf="r.dateLastConnexion">
          {{ r.dateLastConnexion | date: 'dd/MM/yyyy' }}
        </ng-container>
        <ng-container *ngIf="!r.dateLastConnexion"> - </ng-container>
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="caProd" name="CA Prod. 3D">
      <td *cv="let r" class="text-center">
        {{ r.caProd | currency: 'EUR':'symbol':'1.2-2':'fr' }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="caDegineo" name="CA Degineo">
      <td *cv="let r" class="text-center">
        {{ r.caDegineo | currency: 'EUR':'symbol':'1.2-2':'fr' }}
      </td>
    </degineo-my-column>
  </degineo-my-table>

  <div class="row justify-content-center" *ngIf="!loading">
    <ngb-pagination
      [collectionSize]="count"
      [(page)]="ClientListComponent.page"
      [pageSize]="nbPerPage"
      [maxSize]="6"
      [rotate]="true"
      (pageChange)="reload()"
      [disabled]="loading"
    ></ngb-pagination>
  </div>
</div>
