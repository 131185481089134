import { Component, OnInit, Inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService, ClientService, UserEditValidators, ForgotPwdPopupComponent } from 'degineo-common';
import { L } from 'ic2-lib';
import { AvatarChangeService } from '../../front-layout/avatar-change.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
})
export class MyProfileComponent implements OnInit {
  errorFile: string = null;
  public file: File = null;
  progressPercent = null;
  oldImgURL: any = null;
  loading: boolean = false;
  showConfirm: boolean = false;
  error: string = null;

  form: FormGroup = this.fb.group({
    firstname: [null, [Validators.required]],
    lastname: [null, [Validators.required]],
    companyName: [null, [Validators.required]],
    email: [null, [Validators.required, Validators.email]],
    phone: [null, [Validators.required, UserEditValidators.phoneNumber]],
  });

  constructor(
    private modalService: NgbModal,
    @Inject(FormBuilder) private fb: FormBuilder,
    @Inject(AuthService) private authService: AuthService,
    @Inject(ClientService) private clientService: ClientService,
    @Inject(AvatarChangeService) public avatarChangeService: AvatarChangeService,
    @Inject(Router) public router: Router
  ) {}

  ngOnInit() {
    this.form.disable();
    this.oldImgURL = this.avatarChangeService.avatar;
    this.form.patchValue({
      firstname: this.authService.userBundle.user.firstName,
      lastname: this.authService.userBundle.user.lastName,
      companyName: this.authService.userBundle.client.name,
      email: this.authService.userBundle.user.email,
      phone: this.authService.userBundle.du.phoneNumber,
    });
  }

  handleFileInput(event) {
    let files: FileList = event.target.files;
    var fr = new FileReader();

    fr.onload = (e) => {
      // file is loaded
      var img = new Image();
      img.onload = () => {
        console.log(img.width + ' x ' + img.height);
        let dimensionsMin = { w: 150, h: 150 };
        if (img.width < dimensionsMin.w || img.height < dimensionsMin.h) {
          //dimensions minis
          this.errorFile = 'Votre fichier doit au minimum faire ' + dimensionsMin.w + 'x' + dimensionsMin.h;
          this.avatarChangeService.avatar = this.oldImgURL;
        } else {
          this.file = files[0];
          console.log(this.file, this.file.size / 1024 / 1024 + 'MB', this.file.name.split('.').pop());
          this.uploadModel();
        }
      };
      this.oldImgURL = this.avatarChangeService.avatar;
      this.avatarChangeService.avatar = (e.target as any).result;
      img.src = (e.target as any).result; // is the data URL because called with readAsDataURL
    };
    fr.readAsDataURL(files[0]);
  }

  enable() {
    this.showConfirm = true;
    this.form.enable();
  }

  cancel() {
    this.showConfirm = false;
    this.error = null;
    this.form.patchValue({
      firstname: this.authService.userBundle.user.firstName,
      lastname: this.authService.userBundle.user.lastName,
      email: this.authService.userBundle.user.email,
      phone: this.authService.userBundle.du.phoneNumber,
      companyName: this.authService.userBundle.client.name,
    });
    this.form.disable();
  }

  validate() {
    if (!this.form.valid) {
      console.log(this.form);
      return;
    }
    let value = this.form.value;
    this.clientService.updateUserInfos(value.firstname, value.lastname, value.companyName, value.phone, value.email).subscribe(
      (data) => {
        this.authService.userBundle.user.firstName = value.firstname;
        this.authService.userBundle.user.lastName = value.lastname;
        this.authService.userBundle.client.name = value.companyName;
        this.authService.userBundle.user.email = value.email;
        this.authService.userBundle.user.login = value.email;
        this.authService.userBundle.du.phoneNumber = value.phone;
        this.showConfirm = false;
        this.form.disable();
      },
      (error) => {
        if (error.isBusinessError()) this.error = 'Cet e-mail est déjà utilisé';
        else L.e(error);
      }
    );
  }

  uploadModel() {
    this.errorFile = null;
    this.clientService
      .changeLogo()
      .withFile(this.file)
      .subscribeWithProgress(
        (data) => {
          this.progressPercent = null;
          this.file = null;
        },
        (loaded, total) => {
          this.progressPercent = ((loaded / total) * 100).toFixed(0);
        },
        (err) => {
          L.e(err);
          this.progressPercent = null;
          this.errorFile = err.message;
        }
      );
  }

  openResetPasswordPopup() {
    const modalRef = this.modalService.open(ForgotPwdPopupComponent, {
      centered: true,
      //@ts-ignore
      size: 'xl',
      beforeDismiss: () => {
        return false;
      },
    });
  }
}
