import { Component, OnInit, Inject } from '@angular/core';
import { ProducerData } from '../../../../providers/producer.provider';
import { ProducerBOService } from 'degineo-common';
import { L } from 'ic2-lib';
import { environment } from 'environments/environment';
import { SlugService } from 'degineo-common';

@Component({
  selector: 'app-producer-infos-right',
  templateUrl: './producer-infos-right.component.html',
  styleUrls: ['./producer-infos-right.component.scss'],
})
export class ProducerInfosRightComponent implements OnInit {
  producerUrl = environment.producerUrl;
  constructor(
    public data: ProducerData,
    @Inject(ProducerBOService) public producerBOService: ProducerBOService,
    @Inject(SlugService) public slugService: SlugService
  ) {}

  ngOnInit() {}

  marqueBlanchePrintChange() {
    this.producerBOService.updateMarqueBlanchePrint(this.data.dto.producer.id, !this.data.dto.producer.marqueBlanchePrint).subscribe(
      (data) => {
        this.data.dto.producer.marqueBlanchePrint = !this.data.dto.producer.marqueBlanchePrint;
      },
      (error) => {
        L.e(error);
      }
    );
  }

  marqueBlancheDevisChange() {
    this.producerBOService.updateMarqueBlancheDevis(this.data.dto.producer.id, !this.data.dto.producer.marqueBlancheDevis).subscribe(
      (data) => {
        this.data.dto.producer.marqueBlancheDevis = !this.data.dto.producer.marqueBlancheDevis;
      },
      (error) => {
        L.e(error);
      }
    );
  }

  subscribedChange() {
    this.producerBOService.updateSubscribeStatus(this.data.dto.producer.id, !this.data.dto.producer.subscribed).subscribe(
      (data) => {
        this.data.dto.producer.subscribed = !this.data.dto.producer.subscribed;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  removeClicked() {
    console.log('removeClicked');
  }
  vewInvoiceClicked() {
    console.log('vewInvoiceClicked');
  }

  copy(val) {
    let selBox = document.createElement('textarea');

    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = environment.clientUrl + this.slugService.string_to_slug(this.data.dto.producer.name) + '/' + this.data.dto.producer.id + '/' + val;

    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();

    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  /*
  saveMarqueBlanche() {
    console.log(this.marqueBlancheForm);
    this.loading = true;
    const value = this.marqueBlancheForm.value;
    this.producerBOService.updateMarqueBlanche(this.id, value.modeDevis, value.modeCommande).subscribe(
      (data) => {
        this.loading = false;
        this.successMarqueBlanche = 'Bien mis à jour';
        setTimeout(() => {
          this.successMarqueBlanche = null;
        }, 2000);
      },
      (error) => {
        this.errorMarqueBlanche = error.message;
      }
    );
  }
  */
}
