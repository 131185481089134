<div class="container-fluid">
  <div class="row">
    <div class="col text-right fs-4">
      <button type="button" class="close" aria-label="Close" (click)="paymentWorkflow.close('Cross click')">
        <i class="fas fa-times modal-cross"></i>
      </button>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col text-center">
      <h3>Adresse de livraison</h3>
    </div>
  </div>
  <div class="row mt-4 justify-content-center">
    <div class="col-5">
      <ic2-textassist
        class="flex-fill"
        inputClass="form-control user-input"
        placeholder="Nom ou Société à livrer"
        [values]="shippingAddresses"
        [presenter]="presenter"
        (textChanges)="shippingNameChanged($event)"
        (objectChange)="shippingAddressSelected($event)"
        [(ngModel)]="shippingPersonOrCompany"
        [listPaddingLeft]="40"
        required
        autocomplete="off"
      >
      </ic2-textassist>
    </div>
  </div>
  <div class="row mt-3 justify-content-center">
    <div class="col-8">
      <input
        class="form-control map-marker-input"
        placeholder="Adresse de livraison"
        ngx-google-places-autocomplete
        [options]="options"
        (onAddressChange)="handleShippingAddressChange($event)"
        autocomplete="off"
        [(ngModel)]="shippingFormattedAddress"
        required
      />
    </div>
  </div>

  <div class="row mt-3">
    <div class="col text-center">
      <hr class="w-75" />
    </div>
  </div>
  <div class="row mt-2">
    <div class="col text-center">
      <h3>Adresse de facturation</h3>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col text-center">
      <label
        ><input type="checkbox" class="" [(ngModel)]="facturationSame" (ngModelChange)="facturationSameChanged()" />
        <span class="no-select ml-2">Adresse de facturation identique à l'adresse de livraison</span></label
      >
    </div>
  </div>
  <div class="row mt-4 justify-content-center" *ngIf="!facturationSame">
    <div class="col-5">
      <ic2-textassist
        class="flex-fill"
        inputClass="form-control user-input"
        placeholder="Nom ou Société à facturer"
        [values]="billingAddresses"
        [presenter]="presenter"
        (textChanges)="billingNameChanged($event)"
        (objectChange)="billingAddressSelected($event)"
        [(ngModel)]="billingPersonOrCompany"
        [listPaddingLeft]="40"
        required
        autocomplete="off"
      >
      </ic2-textassist>
    </div>
  </div>
  <div class="row mt-3 justify-content-center" *ngIf="!facturationSame">
    <div class="col-8">
      <input
        class="form-control map-marker-input"
        placeholder="Adresse de facturation"
        ngx-google-places-autocomplete
        [options]="options"
        (onAddressChange)="handleBillingAddressChange($event)"
        autocomplete="off"
        [(ngModel)]="billingFormattedAddress"
        required
      />
    </div>
  </div>
  <div class="row mt-3">
    <div class="col text-center">
      <hr class="w-75" />
    </div>
  </div>
  <div class="row mt-3 mb-5">
    <div class="col text-center">
      <button type="submit" class="btn btn-success text-uppercase" (click)="valider()" [disabled]="!canValid()">Valider</button>
    </div>
  </div>
</div>
