import { Injectable, Inject } from '@angular/core';
import { CommonBOService } from 'degineo-common';
import { L } from 'ic2-lib';

const interval: number = 60000;

@Injectable()
export class BOData {
  public nbOrderRed: number;
  public nbOrderOrange: number;
  public nbProducerWarning: number;
  public nbBillToProd: number;
  public nbBillToDegineo: number;

  public constructor(@Inject(CommonBOService) public commonBOService: CommonBOService) {}

  fetchNbProducerWarn() {
    this.getNbPrinterAndConsomableWarn();
    setInterval(() => {
      this.getNbPrinterAndConsomableWarn();
    }, interval);
  }

  fetchNbBillToProd() {
    this.getNbBillToProd();
    setInterval(() => {
      this.getNbBillToProd();
    }, interval);
  }
  fetchNbBillToDegineo() {
    this.getNbBillToDegineo();
    setInterval(() => {
      this.getNbBillToDegineo();
    }, interval);
  }

  fetchNbOrderRed() {
    this.getNbOrderRed();
    setInterval(() => {
      this.getNbOrderRed();
    }, interval);
  }
  fetchNbOrderOrange() {
    this.getNbOrderOrange();
    setInterval(() => {
      this.getNbOrderOrange();
    }, interval);
  }

  getNbPrinterAndConsomableWarn() {
    this.commonBOService.getNbPrinterAndConsomableWarn().subscribe(
      (data) => {
        console.log('fetchNbProducerWarn res', data);
        this.nbProducerWarning = data;
      },
      (error) => {
        L.e(error);
      }
    );
  }

  getNbBillToProd() {
    this.commonBOService.getNbBillToProd().subscribe(
      (data) => {
        console.log('fetchNbBillToProd res', data);
        this.nbBillToProd = data;
      },
      (error) => {
        L.e(error);
      }
    );
  }
  getNbBillToDegineo() {
    this.commonBOService.getNbBillToDegineo().subscribe(
      (data) => {
        console.log('fetchNbBillToDegineo res', data);
        this.nbBillToDegineo = data;
      },
      (error) => {
        L.e(error);
      }
    );
  }

  getNbOrderRed() {
    this.commonBOService.getNbRedOrder().subscribe(
      (data) => {
        console.log('getNbOrderRed res', data);
        this.nbOrderRed = data;
      },
      (error) => {
        L.e(error);
      }
    );
  }
  getNbOrderOrange() {
    this.commonBOService.getNbOrangeOrder().subscribe(
      (data) => {
        console.log('getNbOrderOrange res', data);
        this.nbOrderOrange = data;
      },
      (error) => {
        L.e(error);
      }
    );
  }
}
