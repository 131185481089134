import { Component, OnInit, Inject, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OrderBy, PaymentType, ClientBOService, ClientListItemBODTO } from 'degineo-common';
import { Filter } from 'degineo-common';
import { FilterOrder, GenericFilter, InvoiceToDegineoBODTO, InvoiceRowPaimentBODTO, Client } from 'degineo-common';
import { L } from 'ic2-lib';
import { InvoiceBOService } from 'degineo-common';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BOData } from '../../../provider/bo.provider';
import { SlugService } from 'degineo-common';

@Component({
  selector: 'app-payment-to-degineo-edit',
  templateUrl: './payment-to-degineo-edit.component.html',
  styleUrls: ['./payment-to-degineo-edit.component.scss'],
})
export class PaymentToDegineoEditComponent implements OnInit, AfterViewInit {
  PaymentToDegineoEditComponent: typeof PaymentToDegineoEditComponent = PaymentToDegineoEditComponent;
  private sub: Subscription;
  invoice: InvoiceToDegineoBODTO;

  OrderBy: typeof OrderBy = OrderBy;

  static filtersCache = {};
  filtersDefault = {};

  static OrderCaches = {};
  static page: number = 1;
  loading: boolean = true;

  builtFilters: Filter[] = [];
  builtFiltersOrder: FilterOrder[] = [];

  paymentTypes: PaymentType[] = PaymentType.values;
  paymentType: PaymentType = null;
  clients: ClientListItemBODTO[] = [];

  id: number;

  valueMap = new Map<number, number>();
  summTotal: number = 0;
  valueTotal: number;

  presenterName = (obj: ClientListItemBODTO) => obj.companyName;

  constructor(
    private router: Router,
    public BOData: BOData,
    private modalService: NgbModal,
    @Inject(ActivatedRoute) public route: ActivatedRoute,
    @Inject(InvoiceBOService) public invoiceService: InvoiceBOService,
    @Inject(ClientBOService) public clientBOService: ClientBOService,
    @Inject(SlugService) public slugService: SlugService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {}
  ngAfterViewInit() {
    setTimeout(() => {
      this.valueTotal = null;
      this.summTotal = 0;
      this.valueMap = new Map<number, number>();
      this.paymentType = null;

      this.sub = this.route.params.subscribe((params) => {
        if (params['id']) {
          this.id = parseInt(params['id']);
        } else {
          this.router.navigate(['/paymentToD']);
        }

        this.getClientName('');

        this.loading = true;
        let genericFilter = new GenericFilter();
        genericFilter.filters = this.builtFilters;
        genericFilter.orderFilters = this.builtFiltersOrder;
        this.invoiceService.getBillInfoToDegineo(genericFilter, this.id).subscribe(
          (data) => {
            if (data.paid) this.router.navigate(['/paymentToD']);

            this.loading = false;

            data.rows.map((row) => {
              this.valueMap.set(row.id, null);
              this.summTotal += row.toPaid;
            });

            this.invoice = data;
            console.log('data', data);
          },
          (err) => {
            this.loading = false;
            L.e(err);
          }
        );
      });
    });
  }

  reload() {
    let genericFilter = new GenericFilter();
    genericFilter.filters = this.builtFilters;
    genericFilter.orderFilters = this.builtFiltersOrder;
    console.log('genericFilter', genericFilter);
    this.invoiceService.getBillInfoToDegineo(genericFilter, this.id).subscribe(
      (data) => {
        this.invoice.rows = data.rows;
        this.summTotal = 0;
        data.rows.map((row) => {
          this.summTotal += row.toPaid;
        });
        console.log('data', data);
      },
      (err) => {
        L.e(err);
      }
    );
  }
  open(content) {
    console.log(this);
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  cancelModalClicked() {
    console.log('cancelModalClicked');
  }

  saveModalClicked() {
    console.log('saveModalClicked', this.id, this.valueTotal, this.valueMap);
    let payments: InvoiceRowPaimentBODTO[] = [];
    this.valueMap.forEach((value, key) => {
      if (value != null && value > 0) {
        let irp = new InvoiceRowPaimentBODTO();
        irp.invoiceRowId = key;
        irp.paiment = value;
        payments.push(irp);
      }
    });

    this.loading = true;
    this.invoiceService.payInvoiceToDegineo(this.id, this.valueTotal, this.paymentType, payments).subscribe(
      (data) => {
        console.log('data', data);
        if (data) {
          this.BOData.fetchNbBillToDegineo();
          this.ngAfterViewInit();
        }
      },
      (err) => {
        L.e(err);
      }
    );
  }

  rowValueChange(id, number) {
    this.valueMap.set(id, number);

    this.valueTotal = 0;

    this.valueMap.forEach((value) => {
      this.valueTotal += value;
    });
  }

  totalValueChange(number) {
    if (number != null) number = Number(number.toFixed(2));
    if (number < 0) {
      this.valueTotal = 0;
      return;
    }
    if (number > this.summTotal) {
      number = this.summTotal;
    }
    let number2 = number;
    this.invoice.rows.map((row) => {
      if (number2 > row.toPaid) {
        this.valueMap.set(row.id, row.toPaid);
        number2 = Number((number2 - row.toPaid).toFixed(2));
      } else {
        this.valueMap.set(row.id, number2);
        number2 = 0;
      }
    });
    this.changeDetectorRef.detectChanges();
    this.valueTotal = number;
    this.changeDetectorRef.markForCheck();
  }

  payRowClicked(id) {
    this.rowValueChange(id, this.invoice.rows.find((obj) => obj.id == id).toPaid);
  }
  cancelRowClicked(id) {
    this.rowValueChange(id, 0);
  }
  payTotalClicked() {
    this.totalValueChange(this.summTotal);
  }
  cancelTotalClicked() {
    this.paymentType = null;
    this.totalValueChange(0);
  }

  textChanges(text) {
    this.getClientName(text);
  }

  getClientName(text) {
    let filter = new GenericFilter();
    filter.nbPerPage = 10;
    filter.page = 1;
    let search = new Filter();
    search.name = 'recherche';
    search.stringValues = [];
    search.stringValues.push(text);
    filter.filters = [];
    filter.filters.push(search);
    this.clientBOService.getClients(filter).subscribe(
      (data) => {
        this.clients = data.data;
      },
      (error) => {
        L.e(error);
      }
    );
  }

  backClicked() {
    this.router.navigate(['/paymentToD']);
  }

  filterChanged(filters: Filter[]) {
    console.log('filterChanged event', filters);
    this.reload();
  }
  orderChange(order) {
    console.log('orderChange', order);
    this.reload();
  }
}
