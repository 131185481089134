<div class="container-fluid d-flex flex-column h-100">
  <div class="row">
    <div class="col text-center fw-500">
      Une fois la création de votre compte Degineo terminée, vous pourrez nous passer commande<br />
      en ligne directement depuis votre Espace Degineo de façon rapide, simple et sécurisée.
    </div>
  </div>
  <div class="row mt-5 bg-primary justify-content-center flex-grow-1" style="box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1)">
    <div class="col mt-5 px-4">
      <div class="container-small border bg-white rounded shadow-2 pb-4">
        <form #f="ngForm" [formGroup]="registerForm" [class.show-errors]="f.submitted && !registerForm.valid">
          <div class="row" style="padding-top: 2rem">
            <div class="col text-center fw-500 fs-4">
              Afin de finir la configuration de votre compte,<br />
              nous avons besoin des informations suivantes :
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-6">
              <label>Prénom</label>
            </div>
            <div class="col-6">
              <label>Nom</label>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <input formControlName="firstname" type="text" class="form-control" />
            </div>
            <div class="col-6">
              <input formControlName="lastname" type="text" class="form-control" />
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <label>Nom de votre entreprise</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <input formControlName="companyName" type="text" class="form-control" />
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <label>Téléphone portable ou fixe</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <input formControlName="phone" type="text" class="form-control" />
              <div class="help-button" (click)="showInfo = !showInfo"></div>
            </div>
          </div>
          <div class="row mt-2" *ngIf="showInfo">
            <div class="col">
              <div class="alert alert-primary">
                Nous demandons votre numéro de téléphone uniquement dans le cas où Degineo aurait besoin de vous contacter directement dans le cadre de vos
                commandes. Il est donc important que vous mettiez le numéro (fixe ou portable) où vous êtes le plus facilement joignable. Il ne sera jamais
                utilisé à des fins commerciales ni divulgué à quelconques entités ou personnes tierces. Sa consultation sera strictement restreinte au personnel
                de Degineo concerné.
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <label>Quel est votre siret ?</label>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <input formControlName="siret" type="text" class="form-control" mask="000 000 000 00000" />
            </div>
          </div>

          <div class="row mt-3" *ngIf="error || (f.submitted && registerForm.invalid)">
            <div class="col">
              <div class="alert alert-danger">
                <ul>
                  <li *ngIf="registerForm.hasError('required', 'lastname')">Le champ prénom est requis</li>
                  <li class="row" *ngIf="registerForm.hasError('required', 'lastname')">Le champ nom est requis</li>
                  <li class="row" *ngIf="registerForm.hasError('required', 'phone')">Le champ téléphone doit être valide</li>
                  <li class="row" *ngIf="registerForm.hasError('required', 'companyName')">Le champ nom de votre entreprise est requis</li>
                  <li class="row" *ngIf="registerForm.hasError('required', 'siret')">Le siret est requis</li>
                  <li *ngIf="registerForm.hasError('invalidSiret', 'siret')">Le siret est invalide</li>
                  <li>
                    {{ error }}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="row mt-4 mb-5">
            <div class="col-12 col-md-6 mt-3 mt-sm-0 text-md-right order-2 order-md-1">
              <button type="button" class="btn btn-secondary text-uppercase btn-allonge w-100 pt-2 pb-2" (click)="previous.emit()" [disabled]="disabled">
                Précédent
              </button>
            </div>
            <div class="col-12 col-md-6 order-1 order-md-2">
              <button
                type="submit"
                class="btn btn-success btn-allonge text-uppercase w-100 pt-2 pb-2"
                (click)="next.emit(); disabled = true"
                [disabled]="disabled"
              >
                Valider <i class="fas fa-spinner spinning" *ngIf="disabled"></i>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="col-12 text-center mt-3 mb-3">
      <img src="assets/header/logo_degineo_blanc_vert.svg" style="height: 2rem" />
    </div>
  </div>
</div>
