<div class="container pt-4 pb-4" *ngIf="!loading">
  <div class="row">
    <div class="col-12 col-md-8 mt-4">
      <app-producer-infos-left class="h-100"></app-producer-infos-left>
    </div>
    <div class="col-12 col-md-4 mt-4 align-self-end">
      <app-producer-infos-right class="h-100"></app-producer-infos-right>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-6 mt-4">
      <app-producer-bank-account class="p-4 d-block alert border-secondary border m-0 h-100"> </app-producer-bank-account>
    </div>
    <div class="col-12 col-md-6 mt-4">
      <app-producer-billing class="p-4 d-block alert border-secondary border m-0 h-100"> </app-producer-billing>
    </div>
  </div>

  <div class="mt-4 row">
    <div class="col-12">
      <textarea class="form-control" style="resize:none" placeholder="Notes" rows="5" [(ngModel)]="data.dto.producer.noteDegineo"></textarea>
    </div>
  </div>
  <div class="row justify-content-end mt-2">
    <div class="col-md-auto col-12 pr-md-1 mt-2 mt-md-1 order-1 order-md-0">
      <button class="btn btn-secondary w-100" style="min-width: 200px;" type="button" (click)="cancelNoteClicked()" *ngIf="displayBtNote()">
        Annuler les modifications
      </button>
    </div>
    <div class="col-md-auto col-12 pl-md-1 mt-1 order-0 order-md-1">
      <button class="btn btn-success w-100" style="min-width: 200px;" type="button" (click)="saveNoteClicked()" *ngIf="displayBtNote()">
        Enregistrer les modifications
      </button>
    </div>
  </div>
</div>
