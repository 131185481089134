<div class="container pt-4 pb-4" *ngIf="!loading">
  <!-- Boutton top - View -->
  <div class="overflow-auto" *ngIf="!edit!; new">
    <button type="button" class="btn btn-secondary m-1 float-left" (click)="backClicked()"><i class="fas fa-chevron-left"></i> Retour</button>
    <button type="button" class="btn btn-primary m-1 float-right" style="min-width: 200px;" (click)="editClicked()">Modifier</button>
  </div>

  <!-- Boutton top - Edit -->
  <div class="overflow-auto" *ngIf="edit && !new">
    <button
      class="btn btn-success  m-1 float-right"
      style="min-width: 200px;"
      type="button"
      (click)="saveEditClicked()"
      [disabled]="!data.dto.printer.name || !data.dto.printer.brand || !data.dto.printer.printerTechnology || data.dto.printer.printerVerticalSpeed <= 0"
    >
      Enregistrer les modifications
    </button>
    <button class="btn btn-secondary  m-1 float-right" style="min-width: 200px;" type="button" (click)="cancelClicked()">
      Annuler les modifications
    </button>
    <button
      class="btn btn-danger m-1 float-right"
      style="min-width: 200px;"
      *ngIf="data.dto.assocCompany.length == 0 && data.dto.assocConsumable.length == 0"
      type="button"
      (click)="deleteClicked()"
    >
      Supprimer cette imprimante
    </button>
    <div
      class="m-1 float-right"
      style="min-width: 200px;"
      *ngIf="data.dto.assocCompany.length > 0 || data.dto.assocConsumable.length > 0"
      placement="bottom"
      [ngbTooltip]="
        'Imprimante utilisé par :&#013;' +
        data.dto.assocConsumable.length +
        ' Consommable' +
        (data.dto.assocConsumable.length > 1 ? 's' : '') +
        '&#013;' +
        data.dto.assocCompany.length +
        ' Producteur' +
        (data.dto.assocCompany.length > 1 ? 's' : '')
      "
    >
      <button class="btn btn-danger float-right" style="min-width: 200px;" type="button" disabled>
        Supprimer cette imprimante
      </button>
    </div>
  </div>

  <!-- Boutton top - New -->
  <div class="overflow-auto" *ngIf="new">
    <button
      class="btn btn-success m-1 float-right"
      style="min-width: 200px;"
      type="button"
      (click)="newClicked()"
      [disabled]="!data.dto.printer.name || !data.dto.printer.brand || !data.dto.printer.printerTechnology || data.dto.printer.printerVerticalSpeed <= 0"
    >
      Ajouter
    </button>
    <button class="btn btn-secondary m-1 float-right" style="min-width: 200px;" type="button" (click)="cancelClicked()">
      Annuler
    </button>
  </div>

  <div class="row mt-5">
    <div class="col-12">
      <app-printer-info [edit]="edit"> </app-printer-info>
    </div>
    <div class="col-auto"></div>
  </div>

  <div class="row">
    <div class="col-12 col-md-6 mt-4">
      <app-printer-size class="h-100 p-4 d-block alert border-secondary border" [edit]="edit"> </app-printer-size>
    </div>
    <div class="col-12 col-md-6 mt-4">
      <app-printer-metier class="h-100 p-4 d-block alert border-secondary border" [edit]="edit"> </app-printer-metier>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-6 mt-4">
      <app-printer-consumables [edit]="edit" [new]="new" class="h-100 p-4 d-block alert border-secondary border"> </app-printer-consumables>
    </div>
    <div class="col-12 col-md-6 mt-4">
      <app-printer-company class="h-100 p-4 d-block alert border-secondary border"> </app-printer-company>
    </div>
  </div>
</div>
