import { Component, Input, OnInit } from '@angular/core';
import { FinishBODTO } from 'degineo-common';
import { environment } from 'environments/environment';
import { FinishData } from '../../finish.provider';

@Component({
  selector: 'app-finish-info',
  templateUrl: './finish-info.component.html',
  styleUrls: ['./finish-info.component.scss'],
})
export class FinishInfoComponent implements OnInit {
  @Input()
  edit: boolean = false;
  imgURL: any;
  errorFile: string;
  rpcHost: string = environment.rpcHost;
  constructor(public data: FinishData) {}

  ngOnInit(): void {}

  handleFileInput(event) {
    let files: FileList = event.target.files;
    var fr = new FileReader();
    fr.onload = (e) => {
      // file is loaded
      var img = new Image();
      img.onload = () => {
        console.log(img.width + ' x ' + img.height);
        let dimensionsMin = { w: 64, h: 64 };
        if (img.width < dimensionsMin.w || img.height < dimensionsMin.h) {
          //dimensions minis
          this.errorFile = 'Votre fichier doit au minimum faire ' + dimensionsMin.w + 'x' + dimensionsMin.h;
        } else {
          this.data.file = files[0];
          console.log(this.data.file, this.data.file.size / 1024 / 1024 + 'MB', this.data.file.name.split('.').pop());
        }
      };
      this.imgURL = (e.target as any).result;
      img.src = (e.target as any).result; // is the data URL because called with readAsDataURL
    };
    fr.readAsDataURL(files[0]);
  }
}
