<div class="container" *ngIf="loading">
  <div class="row">
    <div class="col"><i class="fas fa-spinner spinning"></i></div>
  </div>
</div>
<ng-container *ngIf="!loading">
  <ng-container *ngIf="invoice">
    <div class="container">
      <div class="row mb-4 align-items-center">
        <div class="col text-center">
          <h4>Paiement à 30 jours à régler à {{ delayedOrders.producerName }}.</h4>
        </div>
      </div>
      <div class="row mb-5 align-items-center" *ngFor="let article of invoice.articles">
        <div class="col">
          <img style="width: 3.75rem; height: 3.75rem" src="{{ rpcHost }}orderModele3Dpng/{{ article.tokenModelPng }}/modele.png" />
        </div>
        <div class="col-4 text-center fs-2">
          {{ article.name }} <br />
          {{ article.articleTypeName }} <br />
          <ng-container *ngIf="!article.dentaire">
            {{ article.sizeInMm.x | number: '.2':'fr-FR' }}&nbsp;mm x {{ article.sizeInMm.y | number: '.2':'fr-FR' }}&nbsp;mm x
            {{ article.sizeInMm.z | number: '.2':'fr-FR' }}&nbsp;mm<br />
            {{ article.materialName }} - {{ article.colorName }}
          </ng-container>
        </div>
        <div class="col text-center fw-700">
          <span class="fw-700 ml-2 mr-2">{{ article.quantity }}</span>
        </div>
        <div class="col text-right fs-2 fw-700">{{ article.unitPriceTTC * article.quantity | number: '.2':'fr-FR' }}&nbsp;€<!-- prix en ttc--></div>
        <div class="col fs-2 fw-700">Commandé le {{ article.orderDate | date: 'dd/MM/yyyy' }}</div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row bg-light-30p pt-2 pb-2">
        <div class="col-12 col-md-4 order-3 order-md-1 pt-2 pt-md-0 text-center align-self-center">
          <button routerLink="/" class="btn btn-secondary w-100 mt-2 mb-2 text-uppercase">Retour</button>
        </div>
        <div class="col-12 col-md-4 text-right order-1 order-md-2 align-self-center">
          <span class="fs-2 fw-300">Total TTC</span><span class="fs-4 fw-700"> {{ invoice.totalTTC | number: '.2':'fr-FR' }}&nbsp;€</span><br />
          <span class="fs-2 fw-400">Dont TVA : {{ invoice.VATAmount | number: '.2':'fr-FR' }}&nbsp;€ - frais de port inclus</span>
        </div>
        <div class="col-12 col-md-4 order-2 order-md-3 pt-2 pt-md-0 text-center align-self-center">
          <button (click)="openPayment()" class="btn btn-success w-100 mt-2 mb-2 text-uppercase" [disabled]="invoice.paidByClient">
            {{ invoice.paidByClient ? 'Paiement en attente de vérif' : 'Payer' }}
          </button>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="delayedOrders.articles.length > 0">
    <div class="row mb-4 align-items-center" [class.mt-5]="invoice">
      <div class="col text-center">
        <h4>Prochain paiement à 30 jours à régler à {{ delayedOrders.producerName }}.</h4>
      </div>
    </div>
    <div class="container">
      <div class="row mb-5 align-items-center" *ngFor="let article of delayedOrders.articles">
        <div class="col">
          <img style="width: 3.75rem; height: 3.75rem" src="{{ rpcHost }}orderModele3Dpng/{{ article.tokenModelPng }}/modele.png" />
        </div>
        <div class="col-4 text-center fs-2">
          {{ article.name }} <br />
          {{ article.articleTypeName }} <br />
          <ng-container *ngIf="!article.dentaire">
            {{ article.sizeInMm.x | number: '.2':'fr-FR' }}&nbsp;mm x {{ article.sizeInMm.y | number: '.2':'fr-FR' }}&nbsp;mm x
            {{ article.sizeInMm.z | number: '.2':'fr-FR' }}&nbsp;mm<br />
            {{ article.materialName }} - {{ article.colorName }}
          </ng-container>
        </div>
        <div class="col text-center fw-700">
          <span class="fw-700 ml-2 mr-2">Qté&nbsp;:&nbsp;{{ article.quantity }}</span>
        </div>
        <div class="col text-right fs-2 fw-700">{{ article.unitPriceTTC * article.quantity | number: '.2':'fr-FR' }}&nbsp;€<!-- prix en ttc--></div>
        <div class="col fs-2 fw-700">Commandé le {{ article.orderDate | date: 'dd/MM/yyyy' }}</div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row bg-light-30p pt-2 pb-2">
        <div class="col-12 offset-md-4 col-md-4 text-right order-1 order-md-2">
          <span class="fs-2 fw-300">Total TTC</span><span class="fs-4 fw-700"> {{ delayedOrders.totalTTC | number: '.2':'fr-FR' }}&nbsp;€</span><br />
          <span class="fs-2 fw-400">Dont TVA : {{ delayedOrders.VATAmount | number: '.2':'fr-FR' }}&nbsp;€ - frais de port inclus</span>
        </div>
        <div class="col-12 col-md-4 order-2 order-md-3 pt-2 pt-md-0 text-center align-self-center">
          <h3>Sera à payer le {{ delayedOrders.dueDate | date: 'dd/MM/yyyy' }}</h3>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
