<div class="container-fluid pt-4 pb-4">
  <degineo-filter
    class="d-inline-block w-100 mt-4 mb-4"
    #fliter
    [builtFilters]="builtFilters"
    (filterChanged)="filterChanged($event)"
    [cache]="ProducerArticleListComponent.filtersCache"
    [default]="filtersDefault"
  >
    <div class="form-inline">
      <degineo-select-filter
        #f
        class="input-group mr-2 mt-1"
        style="width :13rem"
        name="Tous les choix"
        filterName="choice"
        [presenter]="presenterName"
        [values]="choix"
      >
      </degineo-select-filter>

      <degineo-select-filter
        #f
        class="input-group mr-2 mt-1"
        style="width :13rem"
        name="TODO - Tous les fichiers 3D"
        filterName="files"
        [presenter]=""
        [values]="[]"
      >
      </degineo-select-filter>

      <degineo-select-filter #f class="input-group mr-2 mt-1" style="width :13rem" name="Tous les statuts" filterName="status" [values]="status">
      </degineo-select-filter>

      <degineo-text-filter #f class="input-group mr-2 mt-1" style="width :13rem" name="N° commande ou Fichier 3D" filterName="recherche"> </degineo-text-filter>

      <i class="fas fa-undo text-primary resetToDefault ml-2" (click)="fliter.resetToDefault(); ProducerArticleListComponent.page = 1"></i>
    </div>
  </degineo-filter>

  <degineo-my-table
    [rows]="articles"
    [orderCache]="ProducerArticleListComponent.OrderCaches"
    [builtFiltersOrder]="builtFiltersOrder"
    (orderChange)="orderChange($event)"
    (rowClick)="tableRowClick($event)"
  >
    <degineo-my-column orderName="reference" name="N° commande">
      <td *cv="let r" class="text-left align-middle text-nowrap">
        <a [routerLink]="'/orders/' + r.id">
          {{ r.id }} <i class="far fa-comment-dots text-primary ml-1" *ngIf="r.note" placement="top" [ngbTooltip]="r.note"></i>
        </a>
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="client" name="Client">
      <td *cv="let r" class="text-center align-middle">
        {{ r.clientName }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="choice" name="Choix">
      <td *cv="let r" class="text-center align-middle">
        <span *ngIf="r.producerSelected">Producteur</span>
        <span *ngIf="!r.producerSelected">Degineo</span>
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="price" name="Prix&nbsp;TTC">
      <td *cv="let r" class="text-right align-middle">{{ r.priceTTC | number: '.2':'fr-FR' }}&nbsp;€</td>
    </degineo-my-column>

    <degineo-my-column orderName="checked" name="Validation fichier">
      <td *cv="let r" class="text-center align-middle">
        <div class="pointer d-inline-block text-success m-1" style="font-size: 25px;" *ngIf="r.checked">
          <i class="fas fa-check-circle"></i>
        </div>
        <div class="pointer d-inline-block text-danger m-1" style="font-size: 25px;" *ngIf="!r.checked">
          <i class="fas fa-times-circle"></i>
        </div>
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="status" name="Statut">
      <td *cv="let r" class="text-center align-middle">
        {{ r.status.lib }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="dateLastStatusChange" name="Durée du statut" [defaultOrder]="OrderBy.DESC">
      <td *cv="let r" class="text-center align-middle">
        {{ moment(r.dateLastStatusChange).fromNow() }}
      </td>
    </degineo-my-column>
  </degineo-my-table>
  <div class="row justify-content-center mt-3">
    <ngb-pagination
      [collectionSize]="count"
      [(page)]="ProducerArticleListComponent.page"
      [pageSize]="nbPerPage"
      [maxSize]="6"
      [rotate]="true"
      (pageChange)="reload()"
    ></ngb-pagination>
  </div>
</div>
