import { Component, OnInit } from '@angular/core';
import { OrderData } from '../../providers/order.provider';

@Component({
  selector: 'app-order-view-shipping-address',
  templateUrl: './order-view-shipping-address.component.html',
  styleUrls: ['./order-view-shipping-address.component.scss'],
})
export class OrderViewShippingAddressComponent implements OnInit {
  constructor(public data: OrderData) {}

  ngOnInit() {}
}
