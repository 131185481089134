<div class="card edit">
  <div class="card-header">
    <input style="width: 250px" class="form-control d-inline-block" type="text" [(ngModel)]="articleType.name" placeholder="Nom" required />
  </div>
  <div class="card-body">
    <div class="row mt-3">
      <div class="col-auto">
        <label>Active : </label>
        <ui-switch style="vertical-align: sub" class="ml-2" size="small" color="#00c462" defaultBgColor="#d82929" [(ngModel)]="articleType.active"> </ui-switch>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-auto m-0">
        <label>Ordre : </label>
      </div>
      <div class="col">
        <input class="form-control d-inline-block" style="width: 100px" type="number" [(ngModel)]="articleType.order" />
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-auto m-0">
        <label>Coefficient de matière moyenne au cm³ : </label>
      </div>
      <div class="col">
        <input class="form-control d-inline-block" style="width: 100px" type="number" [(ngModel)]="articleType.averageMatterQuantity" />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-auto">
        <label>Dentaire : </label>
        <ui-switch style="vertical-align: sub" class="ml-2" size="small" color="#00c462" defaultBgColor="#d82929" [(ngModel)]="articleType.dental"> </ui-switch>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-auto">
        <label>Prix unitaire : (si dentaire)</label>
        <input class="form-control d-inline-block" style="width: 100px" type="number" [(ngModel)]="articleType.defaultPrice" />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-auto">
        <label>Cible métier&nbsp;:</label>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-auto">
        <ng-container *ngFor="let t of targets">
          <div class="row">
            <div class="col align-items-center d-flex">
              <i
                class="fas pr-2 pointer"
                style="width: 1.5rem"
                (click)="toggleBT(t)"
                [class.fa-chevron-down]="isOpen(t)"
                [class.fa-chevron-right]="!isOpen(t)"
              ></i>
              <label class="fw-400 pointer">
                <input type="checkbox" class="mr-2" [checked]="hasAll(t)" [indeterminate]="hasAny(t) && !hasAll(t)" (change)="changeParent(t)" />
                {{ t.level1.lib }}
              </label>
            </div>
          </div>
          <ng-container *ngIf="isOpen(t)">
            <div class="row" *ngFor="let child of t.children">
              <div class="col align-items-center ml-5">
                <label class="fw-400 pointer">
                  <input type="checkbox" class="mr-2" [checked]="has(child)" (change)="changeChild(child)" />
                  {{ child.lib }}
                </label>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <label>Image : </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <input type="file" accept="image/png" id="file" style="display: none" #fileInput (change)="handleFileInput($event)" />
        <button class="btn p-0 text-primary" style="font-size: 0.9rem; letter-spacing: normal" (click)="fileInput.click()">
          <i class="fas fa-upload text-primary pointer" style="font-size: 18px"></i> Choisir une image
        </button>
        <div class="progress" *ngIf="progressPercent">
          <div class="progress-bar" role="progressbar" [style.width]="progressPercent + '%'">{{ progressPercent }}%</div>
        </div>
        <span class="text-danger d-block" *ngIf="errorFile">{{ errorFile }}</span>
      </div>
    </div>
  </div>
  <div class="card-footer text-right">
    <button class="btn btn-secondary ml-auto" style="min-width: 100px" type="button" (click)="cancelClicked()">Annuler</button>
    <button class="btn btn-success ml-2" style="min-width: 100px" type="button" (click)="saveClicked()">Valider</button>
  </div>
</div>
