import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { OrderListItemBODTO, OrderBy, Filter, FilterOrder, OrderNEWStatus, GenericFilter, PaymentType } from 'degineo-common';
import { Router } from '@angular/router';
import { OrderBOService } from 'degineo-common';
import { L } from 'ic2-lib';
import { ClientData } from '../../providers/client.provider';

@Component({
  selector: 'app-client-order-list',
  templateUrl: './client-order-list.component.html',
  styleUrls: ['./client-order-list.component.scss'],
})
export class ClientOrderListComponent implements OnInit, AfterViewInit {
  ClientOrderListComponent: typeof ClientOrderListComponent = ClientOrderListComponent;
  orders: OrderListItemBODTO[] = [];

  OrderBy: typeof OrderBy = OrderBy;

  static filtersCache = {};
  filtersDefault = {};

  static OrderCaches = {};
  static page: number = 1;

  nbPerPage: number = 10;
  count = 0;
  loading: boolean = true;

  builtFilters: Filter[] = [];
  builtFiltersOrder: FilterOrder[] = [];

  paymentType: PaymentType[] = PaymentType.values;
  status = OrderNEWStatus.values;

  presenterName = (obj) => obj.name;
  paymentTypePresenter = (obj: PaymentType) => obj.lib;

  constructor(@Inject(OrderBOService) public orderBOService: OrderBOService, private router: Router, public data: ClientData) {}

  ngOnInit() {}
  ngAfterViewInit() {
    this.reload();
  }

  reload() {
    let genericFilter = new GenericFilter();
    genericFilter.filters = this.builtFilters;

    let fCompany: Filter = new Filter();
    fCompany.name = 'client';
    fCompany.intValues = [this.data.dto.id];
    genericFilter.filters.push(fCompany);

    genericFilter.orderFilters = this.builtFiltersOrder;
    genericFilter.page = ClientOrderListComponent.page;
    genericFilter.nbPerPage = this.nbPerPage;
    console.log('genericFilter', genericFilter);
    this.loading = true;
    this.orderBOService.getOrders(genericFilter).subscribe(
      (data) => {
        this.loading = false;
        this.orders = data.data;
        this.count = data.count;
        console.log('data', data);
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }

  getNowDif(date) {
    var delta = Math.abs(new Date(date).getTime() - new Date().getTime()) / 1000;

    var days = Math.floor(delta / 86400);
    delta -= days * 86400;

    var hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    var minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    let result = '';
    if (days > 0) {
      result += days + 'j et ';
    }
    result += hours + 'h' + minutes;
    return result;
  }

  filterChanged(filters: Filter[]) {
    console.log('filterChanged event', filters);
    this.reload();
  }
  orderChange(order) {
    console.log('orderChange', order);
    this.reload();
  }
  tableRowClick(row: any) {
    console.log('tableRowClick', row);
    this.router.navigate(['/orders', row.id]);
  }
}
