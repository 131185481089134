import { Injectable } from '@angular/core';
import { MaterialTypeNEW, MaterialTypeCharacteristicBODTO, CharacteristicNEW } from 'degineo-common';

@Injectable()
export class MaterialTypeCharacteristicData {
  public dto: MaterialTypeCharacteristicBODTO[] = null;

  public materialParentList: Array<MaterialTypeNEW> = null;
  public characteristicList: Array<CharacteristicNEW> = null;

  public constructor() {}

  clear() {
    this.dto = null;
    this.materialParentList = null;
    this.characteristicList = null;
  }
}
