<div class="d-flex flex-column" style="height: 100vh !important">
  <div class="container-fluid bg-white" *ngIf="infos !== null">
    <div class="row mt-5">
      <div class="col text-center">
        <img src="{{ rpcHost }}companylogo/{{ infos.producerLogoToken }}/logo.png?size=medium" alt="{{ infos.producerName }}" />
      </div>
    </div>
    <div class="row my-5">
      <div class="col text-center fw-500">
        Nous utilisons le service Degineo pour sécuriser nos prises de commande en ligne.<br />
        Si vous ne possédez pas encore de compte Degineo, vous pouvez le créer gratuitement ci&#8209;dessous. Cela prend 2 min.<br />
        Si vous avez déjà un compte Degineo, connectez-vous à votre Espace et retrouvez cette invitation dans vos imprimeurs partenaires.
      </div>
    </div>
  </div>

  <div class="container-fluid bg-primary flex-grow-1 bg-logo pb-5" style="box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1)">
    <div class="row mt-5" *ngIf="infos === null">
      <div class="col text-center">
        <a [href]="svClientUrl">
          <img src="assets/header/logo-degineo.svg" width="310px" />
        </a>
      </div>
    </div>

    <div class="row mt-3" *ngIf="infos === null">
      <div class="col-12 text-center fw-300 fs-5 text-white">Espace Client</div>
    </div>

    <div class="row mt-5">
      <div class="col-12">
        <div class="container-small bg-white rounded shadow-2 py-4">
          <div class="row">
            <div class="col text-center">
              <h3>J'ai déjà un compte Degineo</h3>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col text-center text-uppercase">
              <a class="fw-500" style="letter-spacing: 0.025em" routerLink="/connexion">Se connecter</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col">
        <div class="container-small bg-white rounded shadow-2 pb-4">
          <form autocomplete="off" #f="ngForm" [formGroup]="form" [class.show-errors]="f.submitted && !form.valid" (submit)="submit()" *ngIf="step === 1">
            <div class="row" style="padding-top: 2rem !important">
              <div class="col text-center">
                <h3 *ngIf="infos === null">Nouveau sur Degineo ?</h3>
                <h3 *ngIf="infos !== null">Créer un compte Degineo<br />afin d'accepter l'invitation de {{ infos.producerName }}.</h3>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <div class="form-group m-0">
                  <i class="fas fa-envelope input-icon"></i>
                  <input
                    type="email"
                    class="form-control input-with-icon"
                    id="email"
                    aria-describedby="emailHelp"
                    autocomplete="username"
                    placeholder="E-mail"
                    formControlName="email"
                  />
                </div>
              </div>
            </div>
            <div class="row" style="margin-top: 2rem">
              <div class="col">
                <div class="form-group m-0">
                  <i class="fas fa-lock input-icon"></i>
                  <input
                    type="password"
                    class="form-control input-with-icon"
                    id="password"
                    autocomplete="new-password"
                    placeholder="Mot de passe"
                    formControlName="password"
                  />
                </div>
              </div>
            </div>
            <div class="row" style="margin-top: 2rem">
              <div class="col">
                <div class="form-group m-0">
                  <i class="fas fa-lock input-icon"></i>
                  <input
                    type="password"
                    class="form-control input-with-icon"
                    id="password2"
                    autocomplete="new-password"
                    placeholder="Confirmez votre mot de passe"
                    formControlName="password2"
                  />
                </div>
              </div>
            </div>
            <div class="row mt-4" *ngIf="f.submitted && !form.valid">
              <div class="col">
                <div class="alert alert-danger pr-3 pl-3 pr-md-5 pl-md-5 pt-3 pb-3">
                  <ul>
                    <li *ngIf="form.hasError('required', 'email')">Tous les champs sont requis.</li>
                    <li *ngIf="form.hasError('email', 'email')">L'e-mail est incorrect.</li>
                    <li *ngIf="form.hasError('alreadyExists', 'email')">Cet e-mail est déjà utilisé.</li>
                    <li *ngIf="form.hasError('required', 'password') || form.hasError('pattern', 'password')">
                      Le mot de passe doit comporter au moins 6 caractères.
                    </li>
                    <li *ngIf="form.hasError('notSame')">La confirmation du mot de passe et le mot de passe doivent être identiques.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col text-center">
                <button type="submit" class="btn btn-success text-uppercase w-100">Créer un compte</button>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col text-center fs-1">
                En créant un compte, vous acceptez nos <a [href]="svMentionsLegalesUrl" target="_blank">conditions générales de vente</a
                ><br class="d-none d-xl-inline" />
                et notre <a [href]="svConfidentialitesUrl" target="_blank">politique de confidentialité</a>.
              </div>
            </div>
            <div class="row my-1 justify-content-center" *ngIf="infos === null">
              <div class="col-9">
                <hr />
              </div>
            </div>
            <div class="row" *ngIf="infos === null">
              <div class="col text-center fs-2">
                Si vous êtes un professionnel de l'impression 3D<br />
                <a class="fw-500" href="{{ producerUrl }}inscription">Inscrivez-vous ici</a>
              </div>
            </div>
          </form>
          <form autocomplete="off" #f2="ngForm" [formGroup]="form2" [class.show-errors]="f2.submitted && !form2.valid" (submit)="submit2()" *ngIf="step === 2">
            <div class="row" style="padding-top: 2rem !important">
              <div class="col text-center fs-4 fw-700">Afin de finir la configuration de votre compte, nous avons besoin des informations suivantes&nbsp;:</div>
            </div>
            <div class="row mt-4">
              <div class="col-md-6 order-md-0 order-0">
                <label>Prénom</label>
              </div>
              <div class="col-md-6 order-md-1 order-2">
                <label>Nom</label>
              </div>
              <div class="col-md-6 order-md-2 order-1 mt-1">
                <input type="text" class="form-control" formControlName="prenom" />
              </div>
              <div class="col-md-6 order-md-3 order-3 mt-1">
                <input type="text" class="form-control" formControlName="nom" />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <label>Nom de votre entreprise</label>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col">
                <input type="text" class="form-control" formControlName="companyName" />
              </div>
            </div>

            <div class="row mt-4">
              <div class="col">
                <label>N° téléphone</label>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col">
                <input type="text" class="form-control" formControlName="phoneNumber" mask="00 00 00 00 00" />
              </div>
            </div>
            <div class="row mt-4">
              <div class="col">
                <label>N° SIRET de votre entreprise</label>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col">
                <input type="text" class="form-control" formControlName="siret" mask="000 000 000 00000" />
              </div>
            </div>
            <div class="row mb-4" style="margin-top: 2rem">
              <div class="col">
                <button type="submit" [disabled]="loading" class="btn btn-success text-uppercase w-100">
                  Valider <i class="fas fa-spinner spinning" *ngIf="loading"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="col-12 text-center mt-3 mb-3" *ngIf="infos !== null">
        <img src="assets/header/logo_degineo_blanc_vert.svg" style="height: 2rem" />
      </div>
    </div>
  </div>
</div>
