<div>
  <degineo-filter
    class="d-inline-block w-100 mt-4 mb-4"
    #fliter
    [builtFilters]="builtFilters"
    (filterChanged)="filterChanged($event)"
    [cache]="ProducerUserListComponent.filtersCache"
    [default]="filtersDefault"
  >
    <div class="form-inline">
      <degineo-select-filter
        #f
        [values]="rights"
        class="input-group mr-2 mt-1"
        style="width: 18rem"
        name="Tous les droits"
        filterName="role"
        [presenter]="presenterLib"
      ></degineo-select-filter>

      <degineo-text-filter #f class="input-group mr-2 mt-1" style="width: 13rem" name="Recherche" filterName="recherche"> </degineo-text-filter>

      <i class="fas fa-undo text-primary resetToDefault ml-2" (click)="fliter.resetToDefault(); ProducerUserListComponent.page = 1"></i>
    </div>
  </degineo-filter>

  <degineo-my-table
    [rows]="users"
    [hoverable]="false"
    [pointer]="false"
    [orderCache]="ProducerUserListComponent.OrderCaches"
    [builtFiltersOrder]="builtFiltersOrder"
    (orderChange)="orderChange($event)"
  >
    <degineo-my-column name="#ID" [disableOrder]="true">
      <td *cv="let r" class="text-right text-secondary" [class.text-line-through]="r.user.disabled" [ngbTooltip]="r.user.disabled ? 'Compte désactivé' : null">
        {{ r.user.idUser }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="firstName" name="Prénom NOM" [defaultOrder]="OrderBy.ASC">
      <td *cv="let r" class="text-left">{{ r.user.firstName }} {{ r.user.lastName | uppercase }}</td>
    </degineo-my-column>

    <degineo-my-column name="Droit" [disableOrder]="true">
      <td *cv="let r" class="text-center">
        {{ r.role.lib }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="manage" name="Géré par">
      <td *cv="let r" class="text-center">{{ r.managerFirstName }} {{ r.managerLastName | uppercase }}</td>
    </degineo-my-column>

    <degineo-my-column orderName="phoneNumber" name="N° Téléphone">
      <td *cv="let r" class="text-center">
        {{ r.phoneNumber | mask: '00.00.00.00.00' }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="email" name="E-Mail">
      <td *cv="let r" class="text-right">
        {{ r.user.email }}
      </td>
    </degineo-my-column>
  </degineo-my-table>

  <div class="row justify-content-center mt-3" *ngIf="!loading">
    <ngb-pagination
      [collectionSize]="count"
      [(page)]="ProducerUserListComponent.page"
      [pageSize]="nbPerPage"
      [maxSize]="6"
      [rotate]="true"
      (pageChange)="reload()"
      [disabled]="loading"
    ></ngb-pagination>
  </div>
</div>
