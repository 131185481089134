import { Injectable, Inject } from '@angular/core';
import { BasketService, BasketDTO } from 'degineo-common';
import { L } from 'ic2-lib';

@Injectable({
  providedIn: 'root',
})
export class PanierDataService {
  articles: BasketDTO = null;
  loading: boolean = false;
  constructor(@Inject(BasketService) public basketService: BasketService) {}

  load(force: boolean = true) {
    if ((!force && this.articles !== null) || this.loading) return;
    this.loading = true;
    this.basketService.getBasket().subscribe(
      (data) => {
        this.articles = data;
        this.loading = false;
      },
      (error) => {
        L.e(error);
        this.loading = false;
      }
    );
  }
}
