import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { OrderBy, OrderNEWStatus } from 'degineo-common';
import { Filter } from 'degineo-common';
import { FilterOrder } from 'degineo-common';
import { OrderBOService } from 'degineo-common';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { L } from 'ic2-lib';
import { OrderData } from '../providers/order.provider';
import { environment } from 'environments/environment';
import { AllowedModelExtensionsService } from 'degineo-common';
import { SlugService } from 'degineo-common';
import { OrderListComponent } from '../order-list/order-list.component';
import * as moment_ from 'moment';

@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.scss'],
})
export class OrderViewComponent implements OnInit, AfterViewInit {
  OrderViewComponent: typeof OrderViewComponent = OrderViewComponent;
  private sub: Subscription;
  OrderBy: typeof OrderBy = OrderBy;
  OrderStatus: typeof OrderNEWStatus = OrderNEWStatus;
  moment = moment_;

  id: number;

  static filtersCache = {};
  filtersDefault = {};

  static OrderCaches = {};

  loading: boolean = true;

  builtFilters: Filter[] = [];
  builtFiltersOrder: FilterOrder[] = [];

  choix: { id: number; name: String }[] = [
    { id: 1, name: 'Client' },
    { id: 0, name: 'Degineo' },
  ];

  rpcHost: string;

  file: File = null;
  progressPercent = null;
  errorFile: string;

  presenterName = (obj) => obj.name;

  constructor(
    @Inject(ActivatedRoute) public route: ActivatedRoute,
    @Inject(OrderBOService) public orderBOService: OrderBOService,
    @Inject(AllowedModelExtensionsService) public allowedModelExtensionsService: AllowedModelExtensionsService,
    @Inject(SlugService) public slugService: SlugService,
    private router: Router,
    public data: OrderData
  ) {
    this.rpcHost = environment.rpcHost;
    this.moment.locale('fr');
  }

  ngOnInit() {}
  ngAfterViewInit() {
    this.sub = this.route.params.subscribe((params) => {
      if (params['id']) {
        this.id = parseInt(params['id']);
      } else {
        this.router.navigate(['/clients']);
      }

      this.reload();
    });
  }

  reload() {
    this.loading = true;
    this.orderBOService.getOrder(this.id).subscribe(
      (data) => {
        console.log('getOrder', data);
        this.data.dto = data;
        this.loading = false;
      },
      (error) => {
        L.e(error);
      }
    );
  }

  handleFileInput(event) {
    let files: FileList = event.target.files;
    var fr = new FileReader();
    fr.onload = (e) => {
      // file is loaded
      this.file = files[0];
      this.uploadModel();
    };

    fr.readAsDataURL(files[0]);
  }

  uploadModel() {
    this.orderBOService
      .updateOrderModel(this.data.dto.order.id)
      .withFile(this.file)
      .subscribeWithProgress(
        (data) => {
          this.progressPercent = null;
          this.file = null;
          this.reload();
        },
        (loaded, total) => {
          this.progressPercent = ((loaded / total) * 100).toFixed(0);
        },
        (err) => {
          L.e(err);
          this.progressPercent = null;
          this.errorFile = err.message;
        }
      );
  }
  removeClicked() {
    console.log('deleteClicked');
  }

  payOrderClicked() {
    this.orderBOService.payOrder(this.id).subscribe(
      (data) => {
        this.reload();
        console.log('PayOrder', data);
      },
      (error) => {
        L.e(error);
      }
    );
  }
  unPayOrderClicked() {
    console.log('unPayOrderClicked');
  }

  checkClicked() {
    this.orderBOService.checkOrder(this.data.dto.order.id, true).subscribe(
      (data) => {
        this.reload();
      },
      (error) => {
        L.e(error);
      }
    );
  }
  unCheckClicked() {
    console.log('unCheckClicked');
  }

  filterChanged(filters: Filter[]) {
    console.log('filterChanged event', filters);
    this.reload();
  }
  orderChange(order) {
    console.log('orderChange', order);
    this.reload();
  }
  tableRowClick(row: any) {
    console.log('tableRowClick', row);
  }
}
