import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-print-settings-comment',
  templateUrl: './print-settings-comment.component.html',
  styleUrls: ['./print-settings-comment.component.scss'],
})
export class PrintSettingsCommentComponent implements OnInit {
  @Output()
  commentChanged: EventEmitter<string> = new EventEmitter<string>();
  @Input()
  comment: string;

  constructor() {}

  ngOnInit() {}
}
