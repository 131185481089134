<h3 class="text-center m-0 p-2 rounded bg-light text-dark">Commandes en cours</h3>

<degineo-filter
  class="d-inline-block w-100 mt-4 mb-4"
  #fliter
  [builtFilters]="builtFilters"
  (filterChanged)="filterChanged($event)"
  [cache]="ClientOrderListComponent.filtersCache"
  [default]="filtersDefault"
>
  <div class="form-inline">
    <degineo-select-filter
      #f
      class="input-group mr-2 mt-1"
      style="width :13rem"
      name="Tous les types de paiement"
      filterName="paymentType"
      [values]="paymentType"
      [presenter]="paymentTypePresenter"
    >
    </degineo-select-filter>

    <degineo-select-filter #f class="input-group mr-2 mt-1" style="width :13rem" name="Tous les statuts" filterName="status" [values]="status">
    </degineo-select-filter>

    <degineo-date-filter #f class="input-group mr-2 mt-1" style="width :13rem" name="Commandé le" filterName="date"> </degineo-date-filter>

    <degineo-text-filter #f class="input-group mr-2 mt-1" style="width :13rem" name="N° de commande" filterName="recherche"> </degineo-text-filter>

    <i class="fas fa-undo text-primary resetToDefault ml-2" (click)="fliter.resetToDefault(); ClientOrderListComponent.page = 1"></i>
  </div>
</degineo-filter>

<degineo-my-table
  [rows]="orders"
  [orderCache]="ClientOrderListComponent.OrderCaches"
  [builtFiltersOrder]="builtFiltersOrder"
  (orderChange)="orderChange($event)"
  (rowClick)="tableRowClick($event)"
>
  <degineo-my-column orderName="reference" name="N° de commande">
    <td *cv="let r" class="text-left text-nowrap">
      {{ r.id }} <i class="far fa-comment-dots text-primary ml-1" *ngIf="r.noteDegineo" placement="top" [ngbTooltip]="r.noteDegineo"></i>
    </td>
  </degineo-my-column>

  <degineo-my-column orderName="dateCreated" name="Commandé le" [defaultOrder]="OrderBy.DESC">
    <td *cv="let r" class="text-center">
      {{ r.dateCreated | date: 'dd/MM/yyyy' }}
    </td>
  </degineo-my-column>

  <degineo-my-column orderName="paymentType" name="Type de paiement">
    <td *cv="let r" class="text-center">
      {{ r.paymentType == null ? 'Inconnu?' : r.paymentType.lib }}
    </td>
  </degineo-my-column>

  <degineo-my-column orderName="status" name="Statut">
    <td *cv="let r" class="text-center">
      {{ r.status.lib }}
    </td>
  </degineo-my-column>

  <degineo-my-column orderName="dateLastStatusChange" name="Durée du statut">
    <td *cv="let r" class="text-center">
      {{ getNowDif(r.dateLastStatusChange) }}
    </td>
  </degineo-my-column>

  <degineo-my-column orderName="price" name="Chiffre d'affaires">
    <td *cv="let r" class="text-right">{{ r.priceTTC.toFixed(2) | number: '.2':'fr-FR' }}&nbsp;€ TTC</td>
  </degineo-my-column>
</degineo-my-table>

<div class="row justify-content-center mt-3" *ngIf="!loading">
  <ngb-pagination
    [collectionSize]="count"
    [(page)]="ClientOrderListComponent.page"
    [pageSize]="nbPerPage"
    [maxSize]="6"
    [rotate]="true"
    (pageChange)="reload()"
    [disabled]="loading"
  ></ngb-pagination>
</div>
