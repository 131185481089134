import { Component, OnInit, Inject } from '@angular/core';
import { ProducerBOService, DateStringFr, BusinessType, Plan, SubscriptionPlanBOService } from 'degineo-common';
import { ProducerData } from '../../../../providers/producer.provider';
import { L } from 'ic2-lib';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-producer-infos-left',
  templateUrl: './producer-infos-left.component.html',
  styleUrls: ['./producer-infos-left.component.scss'],
})
export class ProducerInfosLeftComponent implements OnInit {
  file: File = null;
  progressPercent = null;
  loadingLogo: boolean;
  loadingSubscribe: boolean;
  errorFile: string;
  companyUniqueCode: string;

  editPlan: boolean = false;
  idPlan: number = null;
  plans: Plan[] = [];

  editRemise: boolean = false;
  editRemiseActive: boolean = false;
  percent: number = null;
  dateDiscountEnd: any = null;

  tva: number = environment.tva;

  public INgxMyDpOptions: IAngularMyDpOptions = DateStringFr.INgxMyDpOptions;

  editBusinessType: boolean = false;
  businessType: BusinessType = null;
  loadingEditBusinessType: boolean = false;
  businessTypes: BusinessType[] = BusinessType.values;

  constructor(
    public data: ProducerData,
    @Inject(ProducerBOService) public producerBOService: ProducerBOService,
    @Inject(SubscriptionPlanBOService) public subscriptionPlanBOService: SubscriptionPlanBOService
  ) {}

  ngOnInit() {}

  handleFileInput(event) {
    this.errorFile = null;
    let files: FileList = event.target.files;
    var fr = new FileReader();
    fr.onload = (e) => {
      // file is loaded
      var img = new Image();
      img.onload = () => {
        console.log(img.width + ' x ' + img.height);
        let dimensionsMin = { w: 94, h: 140 };
        if (img.width < dimensionsMin.w || img.height < dimensionsMin.h) {
          //dimensions minis
          this.errorFile = 'Votre fichier doit au minimum faire ' + dimensionsMin.w + 'x' + dimensionsMin.h + ' au minimum';
        } else {
          this.file = files[0];
          console.log(files, this.file.size / 1024 / 1024 + 'MB', this.file.name.split('.').pop());
          this.uploadLogo();
        }
      };
      img.src = (e.target as any).result; // is the data URL because called with readAsDataURL
    };
    fr.readAsDataURL(files[0]);
  }

  uploadLogo() {
    if (this.file == null) {
      return;
    }
    console.log('uploadLogo', this.file);
    this.loadingLogo = true;
    this.producerBOService
      .uploadLogo(this.data.dto.producer.id)
      .withFile(this.file)
      .subscribeWithProgress(
        (data) => {
          //console.log('SAVE LOGO', data);
          this.progressPercent = null;
          this.file = null;
          this.loadingLogo = false;
          this.data.numberToForceReload++;
        },
        (loaded, total) => {
          //console.log('SAVE LOGO progress', loaded, total);
          this.progressPercent = ((loaded / total) * 100).toFixed(0);
        },
        (error) => {
          this.progressPercent = null;
          this.loadingLogo = false;
          L.e('SAVE LOGO err', error);
        }
      );
  }

  toggleEditPlan() {
    this.subscriptionPlanBOService.getActivePlansSimple().subscribe(
      (data) => {
        this.plans = data;
        this.editPlan = true;
        if (this.data.dto.plan) this.idPlan = this.data.dto.plan.id;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  savePlan() {
    this.loadingSubscribe = true;
    this.producerBOService.updatePlan(this.data.dto.producer.id, this.idPlan).subscribe(
      (data) => {
        this.data.dto.plan = data;
        this.editPlan = false;
        this.loadingSubscribe = false;
      },
      (err) => {
        this.loadingSubscribe = false;
        L.e(err);
      }
    );
  }

  toggleEditBusinessType() {
    this.businessType = this.data.dto.producer.businessType;
    this.editBusinessType = true;
  }

  businessTypeChanged() {
    this.loadingEditBusinessType = true;
    this.producerBOService.updateBusinessType(this.data.dto.producer.id, this.businessType).subscribe(
      (data) => {
        this.editBusinessType = false;
        this.loadingEditBusinessType = false;
        this.data.dto.producer.businessType = this.businessType;
      },
      (err) => {
        this.loadingEditBusinessType = false;
        L.e(err);
      }
    );
  }

  toggleEditRemise() {
    this.editRemise = true;
    this.editRemiseActive = this.data.dto.producer.dateDiscountEnd !== null;
    this.percent = this.data.dto.producer.discountPercent;
    this.dateDiscountEnd =
      this.data.dto.producer.dateDiscountEnd !== null
        ? { isRange: false, singleDate: { jsDate: this.data.dto.producer.dateDiscountEnd } }
        : { isRange: false, singleDate: { jsDate: new Date() } };
  }

  saveRemise() {
    this.loadingSubscribe = true;
    this.producerBOService.updateRemise(this.data.dto.producer.id, this.percent, this.dateDiscountEnd.singleDate.jsDate).subscribe(
      (data) => {
        this.data.dto.producer.discountPercent = this.percent;
        this.data.dto.producer.dateDiscountEnd = this.dateDiscountEnd.singleDate.jsDate;
        this.editRemise = false;
        this.loadingSubscribe = false;
      },
      (err) => {
        L.e(err);
        this.loadingSubscribe = false;
      }
    );
  }

  hasRemise() {
    return this.data.dto.producer.dateDiscountEnd !== null && !ProducerInfosLeftComponent.isOutdatedStatic(this.data.dto.producer.dateDiscountEnd);
  }

  static isOutdatedStatic(date: Date) {
    if (date === null) return false;
    const copiedDate = new Date(date.getTime());
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    copiedDate.setHours(0, 0, 0, 0);
    return copiedDate < now;
  }
}
