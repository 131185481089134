<div class="container-fluid pt-4 pb-4">
  <degineo-filter
    class="d-inline-block w-100 mt-4 mb-4"
    #fliter
    [builtFilters]="builtFilters"
    (filterChanged)="filterChanged($event)"
    [cache]="ProducerDevisTabComponent.filtersCache"
    [default]="filtersDefault"
  >
    <div class="form-inline">
      <degineo-date-filter #f class="input-group mr-2 mt-1" style="width: 13rem" name="Date création" filterName="creationDate"> </degineo-date-filter>

      <degineo-select-filter #f class="input-group mr-2 mt-1" style="width: 13rem" name="Statut" filterName="statut" [values]="status"> </degineo-select-filter>

      <degineo-text-filter #f type="number" class="input-group mr-2 mt-1" style="width: 13rem" name="Numéro devis" filterName="number"> </degineo-text-filter>

      <degineo-text-filter #f class="input-group mr-2 mt-1" style="width: 13rem" name="Nom client, Entreprise client" filterName="client">
      </degineo-text-filter>

      <i class="fas fa-undo text-primary resetToDefault ml-2" (click)="fliter.resetToDefault(); ProducerDevisTabComponent.page = 1"></i>
    </div>

    <degineo-checkbox-group-filter #f class="form-inline float-left mt-4" filterName="checkBox">
      <degineo-checkbox-filter #cb class="mr-4" name="Non Supprimé" filterName="noDeleted" color="success"> </degineo-checkbox-filter>
      <degineo-checkbox-filter #cb class="mr-4" name="Supprimé" filterName="deleted" color="danger"> </degineo-checkbox-filter>
    </degineo-checkbox-group-filter>
  </degineo-filter>

  <degineo-my-table
    [rows]="devis"
    [orderCache]="ProducerDevisTabComponent.OrderCaches"
    [builtFiltersOrder]="builtFiltersOrder"
    (orderChange)="orderChange($event)"
    (rowClick)="tableRowClick($event)"
  >
    <degineo-my-column name="#ID" [disableOrder]="true">
      <td *cv="let r" class="text-right align-middle text-secondary">
        {{ r.id }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="number" name="N° Devis">
      <td *cv="let r" class="text-center align-middle">
        {{ r.numDevisProducteur }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="creationDate" name="Date création" [defaultOrder]="OrderBy.DESC">
      <td *cv="let r" class="text-center align-middle">
        {{ r.creationDate | date: 'dd/MM/yyyy' }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="clientName" name="Client">
      <td *cv="let r" class="text-center align-middle">
        <a
          [routerLink]="'/clients/' + slugService.string_to_slug(r.clientCompanyName) + '/' + r.idClientCompany"
          class="d-block"
          ngbTooltip="{{ r.userFirstName }} {{ r.userLastName | uppercase }}"
          placement="top"
        >
          {{ r.clientCompanyName }}
        </a>
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="statut" name="Statut">
      <td *cv="let r" class="text-center align-middle">
        {{ r.status }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="PrintTimeRatio" name="Ratio Temps">
      <td
        *cv="let r"
        class="text-center align-middle"
        [ngClass]="{ 'text-warning': r.printTimeInMin / r.advisedPrintTimeInMin > 1, 'text-success': r.printTimeInMin / r.advisedPrintTimeInMin < 1 }"
      >
        <ng-template #PrintTimeInMin>
          Temps réel : {{ 0 + r.printTimeInMin | number: '.2':'fr-FR' }}&nbsp;min
          <br />
          Temps estimé : {{ 0 + r.advisedPrintTimeInMin | number: '.2':'fr-FR' }}&nbsp;min
        </ng-template>
        <span placement="top" [ngbTooltip]="PrintTimeInMin">
          {{ getRatio(r.printTimeInMin, r.advisedPrintTimeInMin).toFixed(3) }}
        </span>
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="MaterialQuantityRatio" name="Ratio Matière">
      <td
        *cv="let r"
        class="text-center align-middle"
        [ngClass]="{
          'text-warning': r.materialQuantityInCm3 / r.advisedMaterialQuantityInCm3 > 1,
          'text-success': r.materialQuantityInCm3 / r.advisedMaterialQuantityInCm3 < 1
        }"
      >
        <ng-template #MaterialQuantityInCm3>
          Matière réel : {{ 0 + r.materialQuantityInCm3 | number: '.2':'fr-FR' }}&nbsp;Cm³
          <br />
          Matière estimé : {{ 0 + r.advisedMaterialQuantityInCm3 | number: '.2':'fr-FR' }}&nbsp;Cm³
        </ng-template>
        <span placement="top" [ngbTooltip]="MaterialQuantityInCm3">
          {{ getRatio(r.materialQuantityInCm3, r.advisedMaterialQuantityInCm3).toFixed(3) }}
        </span>
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="unitPriceRatio" name="Ratio Prix">
      <td
        *cv="let r"
        class="text-center align-middle"
        [ngClass]="{ 'text-warning': r.unitPriceTTC / r.advisedUnitPriceTTC > 1, 'text-success': r.unitPriceTTC / r.advisedUnitPriceTTC < 1 }"
      >
        <ng-template #unitPriceTTC>
          Prix réel : {{ r.unitPriceTTC.toFixed(2) | number: '.2':'fr-FR' }}&nbsp;€
          <br />
          Prix estimé : {{ r.advisedUnitPriceTTC.toFixed(2) | number: '.2':'fr-FR' }}&nbsp;€
        </ng-template>
        <span placement="top" [ngbTooltip]="unitPriceTTC">
          {{ getRatio(r.unitPriceTTC, r.advisedUnitPriceTTC).toFixed(3) }}
        </span>
      </td>
    </degineo-my-column>

    <degineo-my-column [disableOrder]="true" name="Restauration du devis">
      <td *cv="let r" class="text-center align-middle">
        <button [disabled]="!r.producerDeleted && !r.clientDeleted" class="btn btn-primary" (click)="restoreDevis(r.id)">Restaurer</button>
      </td>
    </degineo-my-column>
  </degineo-my-table>

  <div class="row justify-content-center mt-3">
    <ngb-pagination
      [collectionSize]="count"
      [(page)]="ProducerDevisTabComponent.page"
      [pageSize]="nbPerPage"
      [maxSize]="6"
      [rotate]="true"
      (pageChange)="reload()"
    ></ngb-pagination>
  </div>

  <div class="row mt-3">
    <div class="col">
      <div class="row">
        <div class="col text-success">Ratio &lt; 1</div>
      </div>
      <div class="row">
        <div class="col text-warning">Ratio > 1</div>
      </div>
    </div>
  </div>
</div>
