import { Component, OnInit, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { L } from 'ic2-lib';
import { ClientDevisService, ClientDevisDTO } from 'degineo-common';

@Component({
  selector: 'app-client-devis-modification',
  templateUrl: './client-devis-modification.component.html',
  styleUrls: ['./client-devis-modification.component.scss'],
})
export class ClientDevisModificationComponent implements OnInit {
  private sub: Subscription;
  success: boolean;
  dto: ClientDevisDTO;
  reason: string;

  constructor(
    @Inject(ClientDevisService) public clientDevisService: ClientDevisService,
    @Inject(ActivatedRoute) public route: ActivatedRoute,
    public router: Router
  ) {}

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      this.clientDevisService.getDevis(+params['id']).subscribe(
        (data) => {
          this.dto = data;
        },
        (error) => {
          L.e(error);
        }
      );
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  sendDemand() {
    if (this.reason === null || this.reason.length === 0) return;
    this.clientDevisService.modificationDevis(this.dto.idDevis, this.reason).subscribe(
      (data) => {
        this.success = true;
        setTimeout(() => {
          this.router.navigate(['/devis']);
        }, 2000);
      },
      (error) => {
        L.e(error);
      }
    );
  }
}
