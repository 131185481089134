<div class="container-fluid">
  <div class="row">
    <div class="col">
      <h6 class="text-center">Vous pouvez modifier le nom de votre impression&nbsp;3D ici</h6>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <form>
        <input
          type="text"
          name="name"
          class="form-control"
          placeholder="Nom"
          maxlength="200"
          [(ngModel)]="name"
          (ngModelChange)="nameChanged.emit($event)"
          minlength="3"
        />
      </form>
    </div>
  </div>
  <div class="row mt-1" *ngIf="name !== null && name.length < 3">
    <div class="col text-danger">Le nom doit contenir au minimum 3 caractères.</div>
  </div>
  <div class="row mt-1" *ngIf="name !== null && name.length > 200">
    <div class="col text-danger">Le nom ne doit pas dépasser 200 caractères.</div>
  </div>
</div>
