<div class="container-fluid">
  <div class="row">
    <div class="col-12 offset-sm-0 col-sm-6 offset-xl-2 col-xl-4">
      <app-print-settings-name
        [name]="orderService.name"
        (nameChanged)="orderService.nameChanged($event)"
        class="d-block alert border-secondary border p-4"
      ></app-print-settings-name>
      <app-print-settings-comment
        (commentChanged)="orderService.commentChanged($event)"
        class="d-block alert border-secondary border mt-4 p-4"
      ></app-print-settings-comment>
    </div>
    <div class="col-12 col-sm-6 col-xl-4">
      <degineo-preview-modele [modelUrl]="modelUrl"></degineo-preview-modele>
    </div>
  </div>
  <div class="row mt-4 mb-5">
    <div class="col-12 col-md-6 mt-3 mt-sm-0 text-md-right order-2 order-md-1">
      <button type="button" class="btn btn-secondary btn-allonge w-100 w-sm-200px" (click)="previous.emit()">
        <i class="fas fa-chevron-left"></i> Précédent
      </button>
    </div>
    <div class="col-12 col-md-6 order-1 order-md-2">
      <button type="button" class="btn btn-success btn-allonge w-100 w-sm-200px" [disabled]="!nextAvailable" (click)="next.emit()">
        Suivant <i class="fas fa-chevron-right"></i>
      </button>
    </div>
  </div>
</div>
