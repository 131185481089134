<h6 class="text-center mb-4">RIB</h6>

<div class="mb-1">
  <div class="mb-1">
    <span class="fw-500">
      Pays de domiciliation bancaire
    </span>
    &nbsp;:&nbsp;
    <span>
      {{ data.dto.address.country }}
    </span>
  </div>

  <div class="mb-1">
    <span class="fw-500">
      Titulaire du compte
    </span>
    &nbsp;:&nbsp;
    <span>
      {{ data.dto.producer.name }}
    </span>
  </div>

  <div class="mb-1">
    <span class="fw-500">
      IBAN (lettres incluses)
    </span>
    &nbsp;:&nbsp;
    <span>
      {{ data.dto.producer.iban }}
    </span>
  </div>

  <div>
    <span class="fw-500">
      BIC (ou SWIFT)
    </span>
    &nbsp;:&nbsp;
    <span>
      {{ data.dto.producer.bic }}
    </span>
  </div>
</div>
