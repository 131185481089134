import { Component, OnInit } from '@angular/core';
import { ProducerData } from '../../providers/producer.provider';

@Component({
  selector: 'app-producer-users-tab',
  templateUrl: './producer-users-tab.component.html',
  styleUrls: ['./producer-users-tab.component.scss'],
})
export class ProducerUsersTabComponent implements OnInit {
  newUser = false;

  constructor(public data: ProducerData) {}

  ngOnInit() {}

  newClicked() {
    this.newUser = true;
  }

  cancelClicked() {
    this.newUser = false;
  }
}
