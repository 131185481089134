<div class="container pt-4 pb-4">
  <h1 class="mx-auto text-center mb-5">Paiements vers producteurs</h1>

  <degineo-filter
    class="d-inline-block w-100 mt-4 mb-4"
    #fliter
    [builtFilters]="builtFilters"
    (filterChanged)="filterChanged($event)"
    [cache]="PaymentToProducerListComponent.filtersCache"
    [default]="filtersDefault"
  >
    <div class="form-inline">
      <degineo-textassist-filter
        #f
        class="input-group mr-2 mt-1"
        style="width: 13rem;"
        name="Producteur à payer"
        filterName="producer"
        [values]="producers"
        [presenter]="presenterName"
      >
      </degineo-textassist-filter>

      <degineo-date-filter #f class="input-group mr-2 mt-1" style="width: 13rem;" name="Facturé le" filterName="date"> </degineo-date-filter>

      <degineo-text-filter #f class="input-group mr-2 mt-1" style="width: 13rem;" name="N° de facture" filterName="recherche"> </degineo-text-filter>

      <i class="fas fa-undo text-primary resetToDefault ml-2" (click)="fliter.resetToDefault(); PaymentToProducerListComponent.page = 1"></i>
    </div>
  </degineo-filter>

  <degineo-my-table
    [rows]="payments"
    [orderCache]="PaymentToProducerListComponent.OrderCaches"
    [builtFiltersOrder]="builtFiltersOrder"
    (orderChange)="orderChange($event)"
    [pointer]="false"
  >
    <degineo-my-column name="#ID" [disableOrder]="true">
      <td *cv="let r" class="text-right align-middle text-secondary">
        {{ r.invoiceId }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="reference" name="N° de facture">
      <td *cv="let r" class="text-left align-middle" [ngClass]="{ 'text-danger': olderThan1Month(r.date) && r.closed, 'text-warning': r.closed }">
        {{ r.reference }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="date" name="Facturé le" [defaultOrder]="OrderBy.DESC">
      <td *cv="let r" class="text-center align-middle" [ngClass]="{ 'text-danger': olderThan1Month(r.date) && r.closed, 'text-warning': r.closed }">
        {{ r.date | date: 'dd/MM/yyyy' }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="producer" name="Producteur à payer">
      <td *cv="let r" class="text-center align-middle" [ngClass]="{ 'text-danger': olderThan1Month(r.date) && r.closed, 'text-warning': r.closed }">
        <a [routerLink]="'/producers/edit/' + slugService.string_to_slug(r.producerName) + '/' + r.idProducer + '/infos'" *ngIf="r.producerName">
          {{ r.producerName }}
        </a>
      </td>
    </degineo-my-column>

    <degineo-my-column name="Voir" [disableOrder]="true">
      <td *cv="let r" class="text-center align-middle">
        <div
          class="rib pointer bg-primary fw-500"
          (click)="open(modalRib)"
          placement="top"
          [ngbTooltip]="
            'Pays&nbsp;:&nbsp;' +
            r.bankAccountCountry +
            '&#013;Titulaire&nbsp;:&nbsp;' +
            r.producerName +
            '&#013;IBAN&nbsp;:&nbsp;' +
            r.iban +
            '&#013;BIC&nbsp;:&nbsp;' +
            r.bic
          "
        >
          RIB
        </div>

        <ng-template #modalRib let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">RIB - {{ r.producerName }}</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="mb-1">
              <span class="fw-500"> Pays de domiciliation bancaire</span>
              &nbsp;:&nbsp;
              <span>
                {{ r.bankAccountCountry }}
              </span>
            </div>

            <div class="mb-1">
              <span class="fw-500">
                Titulaire du compte
              </span>
              &nbsp;:&nbsp;
              <span>
                {{ r.producerName }}
              </span>
            </div>

            <div class="mb-1">
              <span class="fw-500">
                IBAN (lettres incluses)
              </span>
              &nbsp;:&nbsp;
              <span>
                {{ r.iban | mask: 'AAAA AAAA AAAA AAAA AAAA AAAA AAA' }}
              </span>
            </div>

            <div>
              <span class="fw-500">
                BIC (ou SWIFT)
              </span>
              &nbsp;:&nbsp;
              <span>
                {{ r.bic }}
              </span>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark" (click)="modal.close('Close click')">Fermer</button>
          </div>
        </ng-template>
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="price" name="Nous devons au producteur">
      <td *cv="let r" class="text-right align-middle" [ngClass]="{ 'text-danger': olderThan1Month(r.date) && r.closed, 'text-warning': r.closed }">
        {{ r.price | number: '.2':'fr-FR' }}&nbsp;€ TTC
      </td>
    </degineo-my-column>

    <degineo-my-column [disableOrder]="true">
      <td *cv="let r" class="text-center align-middle">
        <button class="btn btn-primary" type="button" (click)="open(modalPay)">
          Payé
        </button>

        <ng-template #modalPay let-modal>
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Confirmation de paiement envoyé</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Vous confirmé avoir envoyé un paiement de <span class="fw-500">{{ r.price | number: '.2':'fr-FR' }}&nbsp;€</span>
            <br />
            <br />
            Au producteur <span class="fw-500">{{ r.producerName }}</span>
          </div>
          <div class="modal-footer">
            <button ngbAutofocus type="button" class="btn btn-secondary" (click)="modal.dismiss('cancel click')">Annuler</button>
            <button type="button" class="btn btn-success" (click)="modal.close('Ok click'); paidClick(r.invoiceId)">Valider</button>
          </div>
        </ng-template>
      </td>
    </degineo-my-column>
  </degineo-my-table>

  <div class="row justify-content-center mt-3" *ngIf="!loading">
    <ngb-pagination
      [collectionSize]="count"
      [(page)]="PaymentToProducerListComponent.page"
      [pageSize]="nbPerPage"
      [maxSize]="6"
      [rotate]="true"
      (pageChange)="reload()"
      [disabled]="loading"
    ></ngb-pagination>
  </div>

  <div class="row mt-3">
    <div class="col">
      <div class="row">
        <div class="col text-warning">Commande reçu</div>
      </div>
      <div class="row">
        <div class="col text-danger">Commande reçu et facturé depuis plus de 1 mois</div>
      </div>
    </div>
  </div>
</div>
