<div class="card edit">
  <div class="card-header">
    <ic2-textassist
      class="d-inline-block mr-1"
      style="width: 250px"
      placeholder="Type"
      [(ngModel)]="mtc.parentName"
      [values]="data.materialParentList"
      [presenter]="materialPresenter"
    >
    </ic2-textassist>
    <input style="width: 250px" class="form-control d-inline-block" type="text" [(ngModel)]="mtc.materialName" placeholder="Matière niv. 2" required />
    <select style="width: 250px" class="form-control d-inline-block ml-1 mr-1" [(ngModel)]="mtc.printerTechnology" required>
      <option [ngValue]="null">-- Technologie --</option>
      <option *ngFor="let tech of PrinterTechnology.values" [ngValue]="tech">{{ tech.lib }}</option>
    </select>
    <span class="badge badge-pill badge-primary float-right">{{ mtc.characts.length }}</span>
  </div>
  <div class="card-body">
    <div class="row">
      <div *ngIf="mtc.characts.length == 0" class="ml-3 text-secondary">Aucune caractéristique</div>
      <div class="col-12 col-md-6" *ngFor="let charac of mtc.characts; let i = index">
        <span *ngIf="charac.prefix">{{ charac.prefix }} - </span>
        {{ charac.title }}
        <span *ngIf="charac.suffix"> - {{ charac.suffix }}</span>
        <div class="ml-1 h-100 pointer d-inline-block" style="font-size: 20px" (click)="removeRowClicked(i)">
          <i class="far fa-times-circle text-danger align-middle"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div class="row">
      <div class="col-md-auto col-12 p-0 mr-1">
        <ic2-textassist
          #Tprefix
          class="d-inline-block"
          style="width: 125px"
          placeholder="Prefix"
          [values]="data.characteristicList"
          [presenter]="prefixPresenter"
          [(ngModel)]="charaPrefix"
        >
        </ic2-textassist>
      </div>
      <div class="col-md-4 col-12 p-0 mr-1">
        <ic2-textassist
          #Tcharacteristic
          class="d-inline-block"
          style="width: 100%"
          placeholder="Ajouter caractéristique"
          [values]="data.characteristicList"
          [presenter]="charaPresenter"
          [(ngModel)]="charaTitle"
          (objectChange)="charaChanged($event)"
          required
        >
        </ic2-textassist>
      </div>
      <div class="col-md-auto col-12 p-0 mr-1">
        <ic2-textassist
          #Tunit
          class="d-inline-block"
          style="width: 125px"
          placeholder="Unité"
          [values]="data.characteristicList"
          [presenter]="unitPresenter"
          [(ngModel)]="charaUnit"
        >
        </ic2-textassist>
      </div>
      <div class="col-md-auto col-12 p-0 ml-2 mr-1">
        <div class="d-inline-block mt-2">
          <div *ngFor="let type of Types" class="form-check form-check-inline">
            <input type="radio" name="inlineRadioOptions" [id]="type.name" [value]="type" [(ngModel)]="charaType" />
            <label class="form-check-label" style="line-height: 1.5" [for]="type.name">{{ type.lib }}</label>
          </div>
        </div>
      </div>
      <div class="col-md-auto col-12 p-0 mr-1">
        <select style="width: 150px" class="form-control d-inline-block" [(ngModel)]="charaDisplay">
          <option *ngFor="let display of Displays" [ngValue]="display">{{ display.lib }}</option>
        </select>
      </div>
      <div class="col-md-auto col-12 p-0">
        <div
          class="ml-2 h-100 pointer d-inline-block align-middle"
          style="font-size: 30px"
          (click)="addClicked(); Tcharacteristic.clear(); Tunit.clear(); Tprefix.clear()"
        >
          <i class="far fa-plus-square text-success"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="card-footer text-right">
    <button class="btn btn-secondary ml-auto" style="min-width: 100px" type="button" (click)="cancelClicked()">Annuler</button>
    <button class="btn btn-success ml-2 mr-3" style="min-width: 100px" type="button" (click)="saveClicked()">Valider</button>
  </div>
</div>
