import { Component, Input, OnInit } from '@angular/core';
import { MaterialTypeNEW, PrinterTechnology } from 'degineo-common';

@Component({
  selector: 'app-print-settings-printer-technology',
  templateUrl: './print-settings-printer-technology.component.html',
  styleUrls: ['./print-settings-printer-technology.component.scss'],
})
export class PrintSettingsPrinterTechnologyComponent implements OnInit {
  @Input()
  selectedMaterial: MaterialTypeNEW = null;

  printerTechnoBlabla = {
    [PrinterTechnology.FDM.id]: 'Visualisation rapide des concepts',
    [PrinterTechnology.SLS.id]: 'Pièce de travail en plastique',
    [PrinterTechnology.SLA.id]: "Copie extrêmement détaillée d'objet réel",
    [PrinterTechnology.FTI.id]: 'Pièce de précision en plastique',
    [PrinterTechnology.MJP.id]: 'Pièce de précision rigide en élastomère',
    [PrinterTechnology.CJP.id]: 'Visualisation multicolore des concepts',
    [PrinterTechnology.DMLS_SLM.id]: 'Pièce de travail en métal',
    [PrinterTechnology.DMP.id]: 'Pièce de travail en métal à Haute performance et de grande qualité',
    [PrinterTechnology.EBAM.id]: 'Pièce de travail en métal à Haute performance',
    [PrinterTechnology.CNC.id]: 'Pièce de qualité supérieure et constante',
  };

  constructor() {}

  ngOnInit(): void {}
}
