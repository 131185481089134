import { Component, OnInit, ElementRef, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FileDTO, MyFilesService } from 'degineo-common';
import { environment } from 'environments/environment';
import { L } from 'ic2-lib';
import { PrintDataService } from '../print/print-data.service';

@Component({
  selector: 'app-my-files',
  templateUrl: './my-files.component.html',
  styleUrls: ['./my-files.component.scss'],
})
export class MyFilesComponent implements OnInit {
  files: FileDTO[] = [];
  over: boolean[];
  modalFile: FileDTO = null;
  editName: boolean = false;
  editId: number = null;

  editedFileName: string;
  editedFileExtention: string;
  rpcHost: string = environment.rpcHost;
  modal: NgbModalRef;
  modalRemove: NgbModalRef;
  modalPrint: NgbModalRef;

  constructor(private modalService: NgbModal, private myFilesService: MyFilesService, private router: Router, private printDataService: PrintDataService) {}

  ngOnInit() {
    this.reload();
  }

  reload() {
    this.myFilesService.getMyFiles().subscribe(
      (data) => {
        this.files = data;
        this.over = new Array(this.files.length);
        this.over.fill(false);
      },
      (error) => {
        L.e(error);
      }
    );
  }

  open(content, file: FileDTO) {
    console.log('onpen');
    this.modalFile = file;
    this.editName = false;
    this.editedFileName = this.getFileName(file.fileName);
    this.editedFileExtention = this.getFileExtention(file.fileName);
    this.modal = this.modalService.open(content, { centered: true });
  }

  getFileExtention(text) {
    return text.split('.').pop();
  }

  getFileName(text) {
    return text.split('.').slice(0, -1).join('.');
  }

  remove() {
    if (!confirm('Êtes-vous sûr de vouloir supprimer le fichier 3D: ' + this.modalFile.fileName + '?')) return;

    this.myFilesService.removeFile(this.modalFile.id).subscribe(
      (data) => {
        this.modal.close();
        this.reload();
      },
      (err) => {
        L.e(err);
      }
    );
  }

  edit() {
    this.editName = !this.editName;
    this.editedFileName = this.getFileName(this.modalFile.fileName);
    this.editedFileExtention = this.getFileExtention(this.modalFile.fileName);
    //console.log(this.modal);
    //this.modal._contentRef.nodes[0][0].querySelector('input').select()
    /*
    this.fileinput[0].select();
    this.fileinput[0].focus();
    */
  }

  saveName() {
    let newOriginalFileName = this.editedFileName + '.' + this.editedFileExtention;

    //todo update
    this.myFilesService.updateName(this.modalFile.id, this.editedFileName).subscribe(
      (data) => {
        this.modalFile.fileName = newOriginalFileName;
        this.editName = !this.editName;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  print() {
    this.myFilesService.getLastPrintConfig(this.modalFile.id).subscribe(
      (data) => {
        this.modal.close();
        this.printDataService.printAgain(data);
      },
      (err) => {
        L.e(err);
      }
    );
  }
}
