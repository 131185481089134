import { Component, OnInit } from '@angular/core';
import { ProducerData } from '../../../../providers/producer.provider';

@Component({
  selector: 'app-producer-bank-account',
  templateUrl: './producer-bank-account.component.html',
  styleUrls: ['./producer-bank-account.component.scss'],
})
export class ProducerBankAccountComponent implements OnInit {
  constructor(public data: ProducerData) {}

  ngOnInit() {}
}
