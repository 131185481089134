<div class="container-fluid">
  <div class="row">
    <div class="col">
      <h6 class="text-center">Caractéristiques techniques de la matière choisie&nbsp;:</h6>
    </div>
  </div>
  <div class="row mt-3" *ngIf="selectedMaterial === null">
    <div class="col text-center">Veuillez choisir une matière avant.</div>
  </div>
  <ng-container *ngIf="selectedMaterial">
    <div class="row mt-3 mb-3 justify-content-center">
      <div class="col text-center">
        <ng-container *ngIf="parentMaterial !== null && parentMaterial !== undefined">{{ parentMaterial.name }} - </ng-container>{{ selectedMaterial.name }}
      </div>
    </div>

    <div class="row" *ngFor="let c of characs">
      <div class="col">
        <b>Caractéristique {{ c.title }}</b> : {{ c.value }}
      </div>
    </div>
  </ng-container>
</div>
