import { Component, Inject, HostListener } from '@angular/core';
import { BOData } from '../provider/bo.provider';
import { AuthService } from 'degineo-common';
import { Router } from '@angular/router';

@Component({
  selector: 'admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
})
export class AdminLayoutComponent {
  show = true;
  mobile = false;

  public innerWidth: any;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (window.innerWidth >= 768) {
      this.mobile = false;
      this.show = true;
    } else {
      this.mobile = true;
      this.show = false;
    }
  }

  constructor(@Inject(AuthService) private authService: AuthService, public BOData: BOData, private router: Router) {}

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    if (window.innerWidth >= 768) {
      this.mobile = false;
      this.show = true;
    } else {
      this.mobile = true;
      this.show = false;
    }

    this.BOData.fetchNbProducerWarn();
    this.BOData.fetchNbBillToProd();
    this.BOData.fetchNbBillToDegineo();
    this.BOData.fetchNbOrderOrange();
    this.BOData.fetchNbOrderRed();
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['connexion']);
  }

  clicked() {
    if (this.mobile) this.show = false;
  }
}
