import { Component, OnInit, Inject } from '@angular/core';
import { PrintDataService } from '../print-data.service';
import { PrintService, PrintStep1DTO, ArticleTypePrintStep1DTO, AssociatedProducerDTO } from 'degineo-common';
import { L } from 'ic2-lib';
import { Router } from '@angular/router';
import { environment } from '../../../../../../../../environments/environment';

@Component({
  selector: 'app-print-step1',
  templateUrl: './print-step1.component.html',
  styleUrls: ['./print-step1.component.scss'],
})
export class PrintStep1Component implements OnInit {
  data: PrintStep1DTO = null;
  rpcHost: string = environment.rpcHost;
  constructor(
    @Inject(PrintDataService) public printDataService: PrintDataService,
    @Inject(PrintService) public printService: PrintService,
    @Inject(Router) public router: Router
  ) {}

  ngOnInit() {
    this.printService.getProducers().subscribe(
      (data) => {
        this.data = data;
      },
      (error) => {
        L.e(error);
      }
    );
  }

  compareProducer(p1: AssociatedProducerDTO, p2: AssociatedProducerDTO) {
    //console.log(p1, p2);
    if (p1 === p2) return true;
    if (p1 === null || p2 === null) return false;
    return p1.idProducer === p2.idProducer;
  }

  producerChanged(producer: AssociatedProducerDTO) {
    console.log('producer changed', producer);
    this.printDataService.idProducer = producer.idProducer;
  }

  articleTypeSelected(at: ArticleTypePrintStep1DTO) {
    if (!this.isAvailableForCurrentProducer(at)) return;
    console.log('clicked', at);
    this.printDataService.articleTypeSelected = at;

    if (at.dental) this.printDataService.dentaire = true;
    else this.printDataService.dentaire = false;

    this.printService.getPrinterSizes(this.printDataService.idProducer, null, this.printDataService.articleTypeSelected.id).subscribe(
      (data) => {
        this.printDataService.printerSizes = data;
        this.printDataService.step = PrintDataService.STEP_UPLOAD_MODELS;
        this.router.navigate(['/impression', 'etape-2']);
      },
      (err) => {
        L.e(err);
      }
    );
  }

  isAvailableForCurrentProducer(at: ArticleTypePrintStep1DTO) {
    let producer = this.data.producers.find((p) => p.idProducer === this.printDataService.idProducer);
    return producer.idArticlesAvailable.includes(at.id);
  }

  aDesImprimeursPartenaires() {
    return this.data.producers.length > 1;
  }
}
