import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MaterialTypeCharacteristicBOService, PrinterTechnology } from 'degineo-common';
import { MaterialTypeCharacteristicBODTO, CharacteristicNEW, CharacteristicValueType, CharacteristicDisplayType } from 'degineo-common';
import { MaterialTypeCharacteristicData } from '../../providers/materialTypeCharacteristic.provider';
import { L } from 'ic2-lib';
import { ConsumableData } from '../../../Consumable/providers/consumable.provider';

@Component({
  selector: 'app-material-type-caracteristic-new',
  templateUrl: './material-type-caracteristic-new.component.html',
  styleUrls: ['./material-type-caracteristic-new.component.scss'],
})
export class MaterialTypeCaracteristicNewComponent implements OnInit {
  mtc: MaterialTypeCharacteristicBODTO = new MaterialTypeCharacteristicBODTO();
  PrinterTechnology: typeof PrinterTechnology = PrinterTechnology;

  chara: CharacteristicNEW;
  charaTitle: string;
  charaUnit: string;
  charaPrefix: string;
  charaType: CharacteristicValueType = CharacteristicValueType.values[0];
  charaDisplay: CharacteristicDisplayType = CharacteristicDisplayType.values[0];

  Types: CharacteristicValueType[] = CharacteristicValueType.values;
  Displays: CharacteristicDisplayType[] = CharacteristicDisplayType.values;

  charaPresenter = (obj) => obj.title;
  unitPresenter = (obj) => obj.suffix;
  materialPresenter = (obj) => obj.name;
  prefixPresenter = (obj) => obj.prefix;

  @Output()
  cancel: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  reload: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    @Inject(MaterialTypeCharacteristicBOService) private materialTypeCharacteristicBOService: MaterialTypeCharacteristicBOService,
    public data: MaterialTypeCharacteristicData,
    private consumableData: ConsumableData
  ) {}

  ngOnInit() {
    this.mtc.characts = [];
  }

  removeRowClicked(i) {
    this.mtc.characts.splice(i, 1);
  }

  charaChanged(value: CharacteristicNEW) {
    if (value) {
      this.chara = value;
      this.charaUnit = value.suffix;
      this.charaPrefix = value.prefix;
      this.charaDisplay = this.Displays.find((obj) => obj.id == value.displayType.id);
      this.charaType = this.Types.find((obj) => obj.id == value.characType.id);
    }
  }

  parentChanged(value) {
    if (value) {
      this.mtc.idParent = value.id;
    }
  }

  addClicked() {
    if (this.charaTitle) {
      let selected = this.data.characteristicList.find((obj) => obj.title == this.charaTitle);
      if (selected) this.chara = selected;
      else {
        this.chara = new CharacteristicNEW();
        this.chara.prefix = this.charaPrefix;
        this.chara.title = this.charaTitle;
        this.chara.suffix = this.charaUnit;
        this.chara.displayType = this.charaDisplay;
        this.chara.characType = this.charaType;
      }
      if (this.mtc.characts.length > 0) if (this.mtc.characts.find((obj) => obj.title == this.charaTitle)) return;
      this.mtc.characts.push(this.chara);
    }
  }

  saveClicked() {
    if (this.mtc.materialName) {
      if (this.mtc.parentName) {
        let parent = this.data.materialParentList.find((obj) => obj.name == this.mtc.parentName);
        if (parent) this.mtc.idParent = parent.id;
      }
      console.log('add', this.mtc);
      this.materialTypeCharacteristicBOService.add(this.mtc).subscribe(
        (data) => {
          console.log('added', data);
          this.data.dto.push(data);
          this.consumableData.materialList = null; //reset to reload
          this.cancel.emit();
          this.reload.emit();
        },
        (error) => {
          L.e(error);
        }
      );
    }
  }

  cancelClicked() {
    this.cancel.emit();
  }
}
