import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { L } from 'ic2-lib';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientDevisService, DevisStatus, ClientDevisDTO } from 'degineo-common';

@Component({
  selector: 'app-client-devis-page',
  templateUrl: './client-devis-page.component.html',
  styleUrls: ['./client-devis-page.component.scss'],
})
export class ClientDevisPageComponent implements OnInit, OnDestroy {
  private sub: Subscription;
  loading: boolean = true;
  showPreview: boolean = false;
  rpcHost: string = environment.rpcHost;
  dto: ClientDevisDTO;
  DevisStatus: typeof DevisStatus = DevisStatus;
  sending: boolean = false;

  constructor(
    @Inject(ClientDevisService) public clientDevisService: ClientDevisService,
    @Inject(ActivatedRoute) public route: ActivatedRoute,
    public router: Router
  ) {}

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      this.clientDevisService.getDevis(+params['id']).subscribe(
        (data) => {
          this.dto = data;
          this.loading = false;
        },
        (error) => {
          L.e(error);
          this.loading = false;
        }
      );
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  hideDevis(idDevis: number) {
    this.sending = true;
    this.clientDevisService.hideDevis(idDevis).subscribe(
      (data) => {
        this.router.navigate(['/devis']);
        this.sending = false;
      },
      (error) => {
        this.sending = false;
        L.e(error);
      }
    );
  }
}
