<div class="row">
  <div class="col">
    <div class="fw-500" style="font-size: 1.3rem">
      {{ data.dto.firstName }} <span class="text-uppercase">{{ data.dto.lastName }}</span>
    </div>
  </div>
</div>

<div class="row">
  <div class="col">
    <div class="fw-300 text-warning" style="font-size: 1.3rem" *ngIf="data.dto.invited">Client en attente d'acceptation</div>
    <div class="fw-300" style="font-size: 1.3rem" *ngIf="!data.dto.invited && !editTarget">
      {{ getLevel1(data.dto.target) !== null ? getLevel1(data.dto.target).lib : 'Non défini' }} -
      {{ data.dto.target === null ? 'Non défini' : data.dto.target.lib }} <i class="fas fa-pen-square pointer text-primary" (click)="editTarget = true"></i>
    </div>
    <div *ngIf="editTarget" class="form-inline">
      <select class="form-control" [(ngModel)]="data.dto.target">
        <option [ngValue]="null" disabled="disabled">-- Choisir --</option>
        <optgroup [label]="target1.level1.lib" *ngFor="let target1 of targets">
          <option *ngFor="let target2 of target1.children" [ngValue]="target2">{{ target2.lib }}</option>
        </optgroup>
      </select>
      <i class="fas fa-check-circle fs-4 text-success pointer no-select ml-2" *ngIf="!loadingTarget" (click)="saveTarget()"></i>
      <i class="fas fa-times-circle fs-4 text-danger pointer no-select ml-2" *ngIf="!loadingTarget" (click)="editTarget = false"></i>
      <i class="fas fa-spinner spinning" *ngIf="loadingTarget"></i>
    </div>
  </div>
</div>
<div class="row">
  <div class="col">
    <div class="fw-300 text-secondary">#ID&nbsp;: {{ data.dto.id }}</div>
  </div>
</div>
<div class="row mt-3">
  <div class="col-auto">
    <div class="alert border-secondary border p-4">
      <div class="row">
        <div class="col">
          <div *ngIf="data.dto.adress">
            {{ data.dto.adress.line1 }}
            <ng-container *ngIf="data.dto.adress.line2"> <br />{{ data.dto.adress.line2 }} </ng-container>
            <ng-container *ngIf="data.dto.adress.line3"> <br />{{ data.dto.adress.line3 }} </ng-container>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <div *ngIf="data.dto.adress">{{ data.dto.adress.zipCode }} {{ data.dto.adress.city }}</div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <div>
            <span class="fw-500">Tél : </span>
            {{ data.dto.phoneNumber }}
            <span *ngIf="!data.dto.phoneNumber">-- -- -- -- --</span>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <div>
            <span class="fw-500">E-mail : </span>
            {{ data.dto.email }}
            <span *ngIf="!data.dto.email">---@---.--</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
