import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-checkout-devis-popup',
  templateUrl: './checkout-devis-popup.component.html',
  styleUrls: ['./checkout-devis-popup.component.scss'],
})
export class CheckoutDevisPopupComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
}
