import { Component, OnInit, Inject, OnDestroy, ChangeDetectorRef, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'environments/environment';
import { OrderService } from '../order.service';
import { Location } from '@angular/common';
import { UploadedFile } from '../UploadedFile';
import { PrintSettingsComponent } from '../steps/print-settings/print-settings.component';
import { PrintSettingsDentaireComponent } from '../steps/print-settings-dentaire/print-settings-dentaire.component';

@Component({
  selector: 'app-tunnel-marque-blanche',
  templateUrl: './tunnel-marque-blanche.component.html',
  styleUrls: ['./tunnel-marque-blanche.component.scss'],
})
export class TunnelMarqueBlancheComponent implements OnInit, OnDestroy {
  sub: Subscription;
  sub1: Subscription;
  sub2: Subscription;
  slugProducteur: string;
  rpcHost: string;
  companyUniqueCode: string;
  os = OrderService;

  @ViewChild(PrintSettingsComponent)
  printSettingsComponent: PrintSettingsComponent;
  @ViewChild(PrintSettingsDentaireComponent)
  printSettingsDentaireComponent: PrintSettingsDentaireComponent;

  constructor(
    @Inject(ActivatedRoute) private activatedRoute: ActivatedRoute,
    @Inject(Location) private location: Location,
    @Inject(OrderService) public orderService: OrderService
  ) {
    this.rpcHost = environment.rpcHost;
    this.sub2 = orderService.setModelColorEvent.subscribe((data) => {
      console.log('setModelColorEvent', data);
      if (this.printSettingsComponent) {
        if (data.multicolor) this.printSettingsComponent.preview.setMultiColor();
        else this.printSettingsComponent.preview.setColor(data.color);
      }
      if (this.printSettingsDentaireComponent) {
        if (data.multicolor) this.printSettingsDentaireComponent.preview.setMultiColor();
        else this.printSettingsDentaireComponent.preview.setColor(data.color);
      }
    });
  }

  //Pour empêcher le closing
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: BeforeUnloadEvent) {
    if (environment.name !== 'DEV' && !this.orderService.redirecting) $event.returnValue = true;
  }

  ngOnInit() {
    this.sub = this.activatedRoute.params.subscribe((data) => {
      this.orderService.idProducer = +data['idProducer'];
      this.orderService.slugProducer = data['slug_producteur'];
      if (this.location.path().includes('impression-en-ligne')) this.orderService.typeTunnel = OrderService.TUNNEL_PRINT;
      else this.orderService.typeTunnel = OrderService.TUNNEL_DEVIS;
      this.orderService.load();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    if (this.sub1) this.sub1.unsubscribe();
    if (this.sub2) this.sub2.unsubscribe();
  }

  fileAdded(file: File) {
    this.orderService.fileAdded(file);
  }

  fileDeleted(file: UploadedFile) {
    this.orderService.fileDeleted(file);
  }

  back() {
    history.back();
  }
}
