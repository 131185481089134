import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericFilter, Filter, Finish, FinishBODTO, FinishBOService, MaterialTypeNEW, MaterialTypeService } from 'degineo-common';
import { L } from 'ic2-lib';
import { Subscription } from 'rxjs';
import { FinishData } from '../finish.provider';

@Component({
  selector: 'app-finish-view-edit',
  templateUrl: './finish-view-edit.component.html',
  styleUrls: ['./finish-view-edit.component.scss'],
})
export class FinishViewEditComponent implements OnInit, OnDestroy {
  private sub: Subscription;
  loading: boolean = true;
  edit: boolean;
  new: boolean = false;

  id: number;
  constructor(
    private materialTypeService: MaterialTypeService,
    public route: ActivatedRoute,
    private router: Router,
    private finishBOService: FinishBOService,
    public data: FinishData
  ) {}

  ngOnInit(): void {
    this.materialTypeService.getAllMaterialTypes().subscribe(
      (data) => {
        this.data.materials = data;
      },
      (error) => {
        L.e(error);
      }
    );

    this.sub = this.route.params.subscribe((params) => {
      if (params['view'] == 'new') {
        this.new = true;
        this.edit = true;
        this.loading = false;
        const f = new FinishBODTO();
        f.finish = new Finish();
        f.materialTypes = [];
        this.data.dto = f;
      } else {
        this.new = false;

        if ((params['view'] !== 'view' && params['view'] !== 'edit' && params['view'] !== 'new') || params['id'] === undefined)
          this.router.navigate(['/consumables']);

        if (params['view'] == 'edit') this.edit = true;
        else this.edit = false;

        if (this.id !== parseInt(params['id'])) {
          this.id = parseInt(params['id']);
          this.finishBOService.getFinishDetail(this.id).subscribe(
            (data) => {
              console.log(data);
              this.data.dto = data;
              this.loading = false;
            },
            (err) => {
              L.e(err);
            }
          );
        }
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  backClicked() {
    this.router.navigate(['/finitions']);
  }

  editClicked() {
    this.router.navigate(['/finition', 'edit', this.id]);
  }

  cancelClicked() {
    if (this.new) {
      this.router.navigate(['/finitions']);
    } else {
      this.router.navigate(['/finition', 'view', this.id]);
    }
  }

  deleteClicked() {
    this.finishBOService.deleteFinish(this.id).subscribe(
      (data) => {
        this.router.navigate(['/finitions']);
      },
      (error) => {
        L.e(error);
      }
    );
  }

  saveEditClicked() {
    if (this.data.dto.finish.name) {
      const rpcReq = this.finishBOService.updateFinish(this.data.dto);
      if (this.data.file) {
        rpcReq.withFile(this.data.file);
        console.log('with file', this.data.file);
      }
      rpcReq.subscribeWithProgress(
        (data) => {
          console.log('updatePrinter', data);
          if (data) this.router.navigate(['/finition', 'view', this.data.dto.finish.id]);
        },
        (loaded, total) => {},
        (error) => {
          L.e(error);
        }
      );
    }
  }

  newClicked() {
    if (this.data.dto.finish.name) {
      const rpcReq = this.finishBOService.newFinish(this.data.dto);
      if (this.data.file) {
        rpcReq.withFile(this.data.file);
        console.log('with file', this.data.file);
      }
      rpcReq.subscribeWithProgress(
        (data) => {
          console.log('new', data);
          if (data) this.router.navigate(['/finition', 'view', data.finish.id]);
        },
        (loaded, total) => {},
        (error) => {
          L.e(error);
        }
      );
    }
  }
}
