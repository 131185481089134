<div class="container pt-4 pb-4">
  <div class="row">
    <div class="col">
      <h1 class="mx-auto text-center">Codes Naf</h1>
    </div>
  </div>

  <ng-container *ngFor="let section of getSections()">
    <div class="row" style="font-weight: bold">
      <div class="col-9 align-items-center d-flex">
        <div class="pointer" (click)="toggle(section)">
          <i class="fas" style="width: 1.5rem" [class.fa-chevron-down]="isOpen(section)" [class.fa-chevron-right]="!isOpen(section)"></i>
          {{ section.section }} -
          {{ section.libelle }}
        </div>
      </div>
      <div class="col-3 py-1">
        <select class="form-control" [(ngModel)]="section.typeLie" (ngModelChange)="onChange(section, $event)">
          <option [ngValue]="null" disabled="disabled">-- Choisir --</option>
          <optgroup [label]="target1.level1.lib" *ngFor="let target1 of types">
            <option *ngFor="let target2 of target1.children" [ngValue]="target2">{{ target2.lib }}</option>
          </optgroup>
        </select>
      </div>
    </div>
    <!-- Divisions-->
    <ng-container *ngIf="isOpen(section)">
      <ng-container *ngFor="let division of getDivisions(section.section)">
        <div class="row" style="font-weight: 600">
          <div class="col-9 align-items-center d-flex">
            <div class="pointer" (click)="toggle(division)">
              <i class="fas" style="width: 1.5rem" [class.fa-chevron-down]="isOpen(division)" [class.fa-chevron-right]="!isOpen(division)"></i>
              {{ division.division }} -
              {{ division.libelle }}
            </div>
          </div>
          <div class="col-3 py-1">
            <select class="form-control" [(ngModel)]="division.typeLie" (ngModelChange)="onChange(division, $event)">
              <option [ngValue]="null" disabled="disabled">-- Choisir --</option>
              <optgroup [label]="target1.level1.lib" *ngFor="let target1 of types">
                <option *ngFor="let target2 of target1.children" [ngValue]="target2">{{ target2.lib }}</option>
              </optgroup>
            </select>
          </div>
        </div>
        <!-- Groupes-->

        <ng-container *ngIf="isOpen(division)">
          <ng-container *ngFor="let groupe of getGroupes(division.division)">
            <div class="row" style="font-weight: 600; font-style: italic">
              <div class="col-9 align-items-center d-flex">
                <div class="pointer" (click)="toggle(groupe)">
                  <i class="fas" style="width: 1.5rem" [class.fa-chevron-down]="isOpen(groupe)" [class.fa-chevron-right]="!isOpen(groupe)"></i>
                  {{ groupe.groupe }} -
                  {{ groupe.libelle }}
                </div>
              </div>
              <div class="col-3 py-1">
                <select class="form-control" [(ngModel)]="groupe.typeLie" (ngModelChange)="onChange(groupe, $event)">
                  <option [ngValue]="null" disabled="disabled">-- Choisir --</option>
                  <optgroup [label]="target1.level1.lib" *ngFor="let target1 of types">
                    <option *ngFor="let target2 of target1.children" [ngValue]="target2">{{ target2.lib }}</option>
                  </optgroup>
                </select>
              </div>
            </div>
            <!-- Classes-->

            <ng-container *ngIf="isOpen(groupe)">
              <ng-container *ngFor="let classe of getClasse(groupe.groupe)">
                <div class="row" style="font-style: italic">
                  <div class="col-9 align-items-center d-flex">
                    <div class="pointer" (click)="toggle(classe)">
                      <i class="fas" style="width: 1.5rem" [class.fa-chevron-down]="isOpen(classe)" [class.fa-chevron-right]="!isOpen(classe)"></i>
                      {{ classe.classe }} -
                      {{ classe.libelle }}
                    </div>
                  </div>
                  <div class="col-3 py-1">
                    <select class="form-control" [(ngModel)]="classe.typeLie" (ngModelChange)="onChange(classe, $event)">
                      <option [ngValue]="null" disabled="disabled">-- Choisir --</option>
                      <optgroup [label]="target1.level1.lib" *ngFor="let target1 of types">
                        <option *ngFor="let target2 of target1.children" [ngValue]="target2">{{ target2.lib }}</option>
                      </optgroup>
                    </select>
                  </div>
                </div>
                <!-- Sous Classes-->

                <ng-container *ngIf="isOpen(classe)">
                  <ng-container *ngFor="let sousclasse of getSousClasses(classe.classe)">
                    <div class="row">
                      <div class="col-9 align-items-center d-flex">
                        {{ sousclasse.sousClasse }} -
                        {{ sousclasse.libelle }}
                      </div>
                      <div class="col-3 py-1">
                        <select class="form-control" [(ngModel)]="sousclasse.typeLie" (ngModelChange)="onChange(sousclasse, $event)">
                          <option [ngValue]="null" disabled="disabled">-- Choisir --</option>
                          <optgroup [label]="target1.level1.lib" *ngFor="let target1 of types">
                            <option *ngFor="let target2 of target1.children" [ngValue]="target2">{{ target2.lib }}</option>
                          </optgroup>
                        </select>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
