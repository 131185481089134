import { Component, OnInit, Input, Inject, EventEmitter, Output } from '@angular/core';
import { MaterialTypeCharacteristicData } from '../../providers/materialTypeCharacteristic.provider';
import { MaterialTypeCharacteristicBODTO, CharacteristicNEW, CharacteristicValueType, CharacteristicDisplayType, PrinterTechnology } from 'degineo-common';
import { MaterialTypeCharacteristicBOService } from 'degineo-common';
import { L } from 'ic2-lib';
import { ConsumableData } from '../../../Consumable/providers/consumable.provider';

@Component({
  selector: 'app-material-type-characteristics-info',
  templateUrl: './material-type-characteristics-info.component.html',
  styleUrls: ['./material-type-characteristics-info.component.scss'],
})
export class MaterialTypeCharacteristicsInfoComponent implements OnInit {
  show: boolean = false;

  edit: boolean = false;
  PrinterTechnology: typeof PrinterTechnology = PrinterTechnology;

  @Input()
  item: MaterialTypeCharacteristicBODTO;
  backupItem: MaterialTypeCharacteristicBODTO;

  chara: CharacteristicNEW;
  charaTitle: string;
  charaUnit: string;
  charaPrefix: string;
  charaType: CharacteristicValueType = CharacteristicValueType.values[0];
  charaDisplay: CharacteristicDisplayType = CharacteristicDisplayType.values[0];

  Types: CharacteristicValueType[] = CharacteristicValueType.values;
  Displays: CharacteristicDisplayType[] = CharacteristicDisplayType.values;

  charaPresenter = (obj) => obj.title;
  unitPresenter = (obj) => obj.suffix;
  materialPresenter = (obj) => obj.name;
  prefixPresenter = (obj) => obj.prefix;

  @Output()
  reload: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  update: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    @Inject(MaterialTypeCharacteristicBOService) private materialTypeCharacteristicBOService: MaterialTypeCharacteristicBOService,
    public data: MaterialTypeCharacteristicData,
    private consumableData: ConsumableData
  ) {}

  ngOnInit() {}

  editClicked() {
    this.edit = true;
    this.backupItem = Object.assign(new MaterialTypeCharacteristicBODTO(), this.item);
  }

  removeRowClicked(i) {
    this.item.characts.splice(i, 1);
  }

  removeClicked() {
    if (!this.item.used) {
      this.materialTypeCharacteristicBOService.remove(this.item.idMaterial).subscribe(
        (data) => {
          this.reload.emit();
        },
        (error) => {
          L.e(error);
        }
      );
    }
  }

  charaChanged(value) {
    if (value) {
      this.chara = value;
      this.charaUnit = value.suffix;
      this.charaPrefix = value.prefix;
      this.charaDisplay = this.Displays.find((obj) => obj.id == value.displayType.id);
      this.charaType = this.Types.find((obj) => obj.id == value.characType.id);
    }
  }

  addClicked() {
    if (this.charaTitle) {
      let selected = this.data.characteristicList.find((obj) => obj.title == this.charaTitle);
      if (selected) this.chara = selected;
      else {
        this.chara = new CharacteristicNEW();
        this.chara.prefix = this.charaPrefix;
        this.chara.title = this.charaTitle;
        this.chara.suffix = this.charaUnit;
        this.chara.displayType = this.charaDisplay;
        this.chara.characType = this.charaType;
      }
      if (this.item.characts.length > 0) if (this.item.characts.find((obj) => obj.title == this.charaTitle)) return;
      this.item.characts.push(this.chara);
    }
  }

  saveClicked() {
    this.materialTypeCharacteristicBOService.update(this.item).subscribe(
      (data) => {
        console.log('update', data);
        this.materialTypeCharacteristicBOService.getMaterialTypeCharacteristic(data.idMaterial).subscribe(
          (data) => {
            this.data.dto[this.data.dto.findIndex((obj) => obj.idMaterial == data.idMaterial)].characts = data.characts;
          },
          (error) => {
            L.e(error);
          }
        );
        this.update.emit();
        this.consumableData.materialList = null; //reset to reload
        this.show = true;
        this.edit = false;
      },
      (error) => {
        L.e(error);
      }
    );
    console.log('saveClicked');
  }

  cancelClicked() {
    this.item = this.backupItem;
    this.show = true;
    this.edit = false;
  }

  toggle() {
    this.show = !this.show;
  }
}
