<div class="card">
  <div (click)="toggle()" class="card-header pointer">
    <i *ngIf="!show" class="fas fa-chevron-right mr-2" style="width: 15px; font-size: 12px"></i>
    <i *ngIf="show" class="fas fa-chevron-down mr-2" style="width: 15px; font-size: 12px"></i>
    <span class="fw-500">{{ consumable.brand }}</span> - {{ consumable.name }} - {{ consumable.colorName == null ? 'Pas de couleur' : consumable.colorName }} -
    {{ consumable.capacity | number: '.2':'fr-FR' }} {{ consumable.unit == null ? '' : consumable.unit }}
    <a class="fs-2" [routerLink]="'/consumables/view/' + consumable.id + '/' + slugService.string_to_slug(consumable.name)"
      ><i class="fas fa-external-link-alt"></i
    ></a>
    <div
      *ngIf="consumable.checked"
      class="float-right mt-2"
      [ngClass]="consumable.active ? 'bg-success' : 'bg-danger'"
      style="height: 10px; width: 10px; border-radius: 10px; margin-right: 5px"
    ></div>
    <span class="fw-500 text-warning float-right" *ngIf="!consumable.checked">En attente</span>
  </div>
  <div *ngIf="show" class="card-body">
    <div class="row no-gutters">
      <div class="col-md-auto col-12">
        <div class="row">
          <div class="col">
            <span class="fw-500">Coût matière par cm³</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            Producteur :&nbsp;
            <span *ngIf="consumable.currentProductionCost != 0">{{ consumable.currentProductionCost | number: '.6':'fr-FR' }}</span>
            <span *ngIf="consumable.currentProductionCost == 0">-</span>
            &nbsp;€
          </div>
        </div>
        <div class="row">
          <div *ngIf="!edit" class="col">
            Conseillé :&nbsp;
            <span class="pointer text-primary" (click)="edit = true">
              <span *ngIf="consumable.defaultProductionCost != 0">{{ consumable.defaultProductionCost | number: '.6':'fr-FR' }}</span>
              <span *ngIf="consumable.defaultProductionCost == 0">-</span>
              &nbsp;€
            </span>
          </div>
          <div *ngIf="edit" class="col">
            Conseillé :
            <input class="form-control d-inline-block" style="width: 100px" type="number" [(ngModel)]="newPrice" />
            <button class="btn btn-primary-outline text-danger p-0" (click)="cancelClicked()">
              <i class="fas fa-times-circle" style="font-size: 25px"></i>
            </button>
            <button class="btn btn-primary-outline text-success p-0" (click)="saveClicked()">
              <i class="fas fa-check-circle" style="font-size: 25px"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 col-md pl-3">
        <div class="row">
          <ng-container *ngIf="data.articleTypes.length === 0"> Aucun type d'article existant </ng-container>
          <ng-container *ngFor="let type of data.articleTypes">
            <div class="col-12 col-md-4" *ngIf="!isDental(type)">
              <label class="pointer pl-0" [class.text-secondary]="!isAdvised(type.id)">
                <input type="checkbox" class="" #cb (click)="onClick(type.id, !isChecked(type.id)); $event.preventDefault()" [checked]="isChecked(type.id)" />
                {{ type.name }}
              </label>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-md-auto col-12 pl-3">
        <div class="row">
          <div class="d-inline-block position-relative">
            <span class="fw-500 text-success mr-2" *ngIf="consumable.checked">Validée</span>
            <span class="fw-500 text-warning mr-2" *ngIf="!consumable.checked">En attente</span>
            <ui-switch
              style="vertical-align: sub"
              size="small"
              color="#00c462"
              defaultBgColor="#ffc107"
              [checked]="consumable.checked"
              (change)="checked($event)"
            >
            </ui-switch>
          </div>
        </div>
        <div class="row mt-1">
          <span
            class="text-secondary"
            ngbTooltip="ProducerPrinterConsumableOption: {{ consumable.idProducerPrinterConsumableOption }}, Consumable : {{ consumable.id }}"
            >#ID : {{ consumable.idProducerPrinterConsumableOption }}</span
          >
        </div>
      </div>
    </div>
    <div class="row">
      <hr class="w-100 ml-2 mr-2" />
      <ng-container *ngFor="let type of data.articleTypes">
        <div class="col-12 col-md-4" *ngIf="isDental(type)">
          <label
            class="pointer pl-0"
            [class.text-secondary]="!isAdvised(type.id)"
            [for]="idCompanyPrinter + type.name + consumable.idProducerPrinterConsumableOption"
            *ngIf="!isChecked(type.id)"
          >
            <input
              type="checkbox"
              [id]="idCompanyPrinter + type.name + consumable.idProducerPrinterConsumableOption"
              #cb
              (click)="onClick(type.id, !isChecked(type.id)); $event.preventDefault()"
              [checked]="isChecked(type.id)"
            />
            {{ type.name }}
          </label>
          <label
            class="pointer pl-0"
            [class.text-secondary]="!isAdvised(type.id)"
            [for]="idCompanyPrinter + type.name + consumable.idProducerPrinterConsumableOption"
            *ngIf="isChecked(type.id)"
          >
            <input
              type="checkbox"
              [id]="idCompanyPrinter + type.name + consumable.idProducerPrinterConsumableOption"
              #cb
              (click)="onClick(type.id, !isChecked(type.id)); $event.preventDefault()"
              [checked]="isChecked(type.id)"
            />
            {{ type.name }}
          </label>
        </div>
      </ng-container>
    </div>
  </div>
</div>
