import { Injectable, Inject, ChangeDetectorRef, ApplicationRef, EventEmitter } from '@angular/core';
import { ClientMarqueBlancheService, AuthService, PrintService, DegineoRight, Finish } from 'degineo-common';
import { L, IRPC } from 'ic2-lib';
import {
  ProducerMarqueBlancheInfosDTO,
  ArticleTypeNEW,
  SizeDTO,
  MaterialTypeNEW,
  ColorNEW,
  ColorListDTO,
  CharacteristicsDisplayDTO,
  PrinterSizeDTO,
} from 'degineo-common';
import { UploadedFile } from './UploadedFile';
import { environment } from 'environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserEditValidators } from 'degineo-common';
import { PriceService } from 'degineo-common';
import { HttpClient } from '@angular/common/http';
import { RegisterService } from 'degineo-common';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  public static readonly STEP_CHOOSE_ARTICLE_TYPE = 1;
  public static readonly STEP_UPLOAD_MODELS = 2;
  public static readonly STEP_PRINT_SETTINGS = 3;
  public static readonly STEP_LOGIN_REGISTER = 4;
  public static readonly STEP_REGISTER = 5;
  public static readonly STEP_LOGIN = 6;
  public static readonly STEP_QUANTITY = 7;
  public static readonly TUNNEL_PRINT = 'print';
  public static readonly TUNNEL_DEVIS = 'devis';
  public static readonly MULTICOLOR_STR = 'multicolor';
  public static readonly PRINTER_MARGIN = 0.95; //95% of real size

  public static readonly STEP_TITLES = {
    [OrderService.STEP_CHOOSE_ARTICLE_TYPE]: 'Etape 1',
    [OrderService.STEP_UPLOAD_MODELS]: 'Etape 2',
    [OrderService.STEP_PRINT_SETTINGS]: 'Etape 3',
    [OrderService.STEP_LOGIN_REGISTER]: 'Inscription',
    [OrderService.STEP_REGISTER]: 'Inscription',
    [OrderService.STEP_LOGIN]: 'Connexion',
    [OrderService.STEP_QUANTITY]: 'Etape finale',
  };

  idProducer: number;
  slugProducer: string;
  modeDevis: boolean = false;
  noMarginProducerSet: boolean = false;
  title: string;
  step: number = 0;
  infos: ProducerMarqueBlancheInfosDTO;
  typeTunnel: string;
  error: string;
  characs: CharacteristicsDisplayDTO[] = [];
  articleTypes: ArticleTypeNEW[] = [];
  articleTypeSelected: ArticleTypeNEW;
  dentaire: boolean = false;
  files: UploadedFile[] = [];
  size: SizeDTO = null;
  availableMaterials: MaterialTypeNEW[] = null;
  selectedMaterial: MaterialTypeNEW = null;
  parentMaterial: MaterialTypeNEW = null;
  tempMaterial: MaterialTypeNEW = null;
  availableColors: ColorListDTO = null;
  selectedColor: ColorNEW | string = null;
  tempColor: ColorNEW | string = null;
  availableFinish: Finish[] = null;
  selectedFinish: Finish = null;
  tempFinish: Finish = null;
  defaultSizeDTO: SizeDTO = null;
  printerSizes: PrinterSizeDTO[] = [];
  comment: string;
  name: string = null;
  loginError: string = null;
  registerEmailUnavailable: boolean = false;
  redirecting: boolean = false;
  wishedDeliveryDate: Date = null;

  quantity: number = 1;
  price: number;

  loading: boolean = false;

  loginForm: FormGroup = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
    password: [null, [Validators.required]],
  });
  preRegisterForm: FormGroup = this.fb.group(
    {
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, UserEditValidators.passwordDifficulty]],
      password2: [null, [Validators.required, UserEditValidators.passwordDifficulty]],
    },
    {
      validator: UserEditValidators.checkPasswords('password', 'password2'), // your validation method
    }
  );

  registerForm: FormGroup = this.fb.group({
    lastname: [null, [Validators.required]],
    firstname: [null, [Validators.required]],
    phone: [null, [Validators.required, Validators.pattern(/^(?:\+33\s|0)[1-9](?:\d{2}){4}$/)]],
    companyName: [null, [Validators.required]],
    siret: [null, [Validators.required, UserEditValidators.siretValide]],
  });

  modelUrl: string = null;
  setModelColorEvent = new EventEmitter();
  printOff: boolean = false;
  devisOff: boolean = false;

  constructor(
    @Inject(ClientMarqueBlancheService) private clientMarqueBlancheService: ClientMarqueBlancheService,
    @Inject(PrintService) private printService: PrintService,
    @Inject(FormBuilder) private fb: FormBuilder,
    @Inject(AuthService) private authService: AuthService,
    @Inject(PriceService) private priceService: PriceService,
    @Inject(RegisterService) private registerService: RegisterService,
    private http: HttpClient,
    private irpc: IRPC,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    if (router.url.indexOf('demande-devis') !== -1) this.modeDevis = true;
    this.authService.logout(); //force logout
    this.goToStep(OrderService.STEP_CHOOSE_ARTICLE_TYPE, false);
    //this.goToStep(OrderService.STEP_LOGIN_REGISTER, false); //TODO REMOVE THIS

    window.onpopstate = (ev) => {
      //console.log(ev, window.history);
      if (ev.state && ev.state.step) {
        if (ev.state.step > this.step) {
          //console.log('cant go forward');
          ev.preventDefault();
        } else {
          //console.log('we are at ' + this.step + ' and want to go back to ' + ev.state.step);
          let currentStep = this.step;
          while (currentStep !== ev.state.step) {
            this.previous(); // for the first page
            currentStep = this.step;
          }
        }
      } else {
        //console.log('we are at ' + this.step + ' and want to go back to the minimal step');
        //if state only contains navigationId we want to go back to the begining
        let currentStep = this.step;
        while (currentStep !== OrderService.STEP_CHOOSE_ARTICLE_TYPE) {
          this.previous(); // for the first page
          currentStep = this.step;
        }
      }
    };
  }

  load() {
    this.clientMarqueBlancheService.getInfos(this.idProducer).subscribe(
      (data) => {
        this.infos = data;
        if (this.typeTunnel === 'devis' && !data.marqueBlancheDevis) {
          this.devisOff = true;
        }
        if (this.typeTunnel === 'print' && !data.marqueBlanchePrint) {
          this.printOff = true;
        }
        this.setTitle();
      },
      (err) => {
        L.e(err);
        this.error = err.message;
      }
    );
    this.clientMarqueBlancheService.getArticles(this.idProducer).subscribe(
      (data) => {
        this.articleTypes = data;
        if (this.modeDevis)
          this.articleTypes = this.articleTypes.filter((at) => {
            return !at.dental;
          });
      },
      (err) => {
        L.e(err);
        this.error = err.message;
      }
    );
  }

  setArticleType(at: ArticleTypeNEW) {
    this.articleTypeSelected = at;
    if (this.articleTypeSelected.dental) this.dentaire = true;
    else this.dentaire = false;

    this.printService.getPrinterSizes(this.idProducer, null, this.articleTypeSelected.id).subscribe(
      (data) => {
        this.printerSizes = data;
        this.goToStep(OrderService.STEP_UPLOAD_MODELS);
      },
      (err) => {
        L.e(err);
        this.error = err.message;
      }
    );
  }

  mtlFileAdded(file: File) {
    console.log('mtl file added', file);
    let fileHash = 'test';
    if (this.files.length > 0) fileHash = this.files[0].fileHash;
    /*this.printService
      .uploadMtlFile(fileHash)
      .withFile(file)
      .subscribeWithProgress(
        (data) => {
          console.log('mtl uploaded');
        },
        (loaded, total) => {
          //uf.progress = ~~(loaded / total * 100);
        },
        (err) => {
          L.e(err);
          this.error = err.message;
        }
      );
      */
  }

  fileAdded(file: File) {
    if (!this.dentaire && this.files.length === 1) {
      this.fileDeleted(this.files[0]);
    }
    console.log(file);
    let uf = new UploadedFile();
    uf.file = file;
    uf.name = file.name;
    this.files.push(uf);
    this.printService
      .uploadFile()
      .withFile(uf.file)
      .subscribeWithProgress(
        (data) => {
          uf.fileHash = data;
          this.printService.getFileDimensions(uf.fileHash).subscribe(
            (data) => {
              uf.modelData = data;
              uf.good = true;
              uf.progress = 100;
              if (this.dentaire) this.quantityChanged(this.files.length);
            },
            (err) => {
              L.e(err);
              this.error = err.message;
            }
          );
        },
        (loaded, total) => {
          uf.progress = ~~(((loaded / total) * 100) / 2);
        },
        (err) => {
          L.e(err);
          this.error = err.message;
        }
      );
  }

  fileDeleted(file: UploadedFile) {
    this.files.splice(this.files.indexOf(file), 1);
    this.printService.deleteFile(file.fileHash).subscribe(
      (data) => {
        console.log(file.file.name, 'deleted');
        if (this.dentaire) this.quantityChanged(this.files.length);
      },
      (err) => {
        L.e(err);
        this.error = err.message;
      }
    );
  }

  nameChanged(name: string) {
    this.name = name;
  }

  commentChanged(comment: string) {
    this.comment = comment;
  }

  quantityChanged(quantity: number) {
    this.quantity = quantity;
  }

  deliveryDateChanged(deliveryDate: Date) {
    console.log(deliveryDate);
    this.wishedDeliveryDate = deliveryDate;
  }

  sizeChanged(size: SizeDTO) {
    console.log('size changed', size);
    this.size = size;
    this.tempMaterial = this.selectedMaterial;
    this.selectedMaterial = null;
    this.tempColor = this.selectedColor;
    this.selectedColor = null;
    this.availableMaterials = null;
    this.availableColors = null;
    this.availableFinish = null;
    if (this.size.x === null || this.size.y === null || this.size.z === null) {
      //to avoid safari error when input has text in it
      console.error('there is letters in number inputs');
      return;
    }
    this.printService.getPossibleMaterials(this.idProducer, null, this.articleTypeSelected.id, this.size).subscribe(
      (data) => {
        this.availableMaterials = data;
        if (this.tempMaterial !== null) {
          let material = this.availableMaterials.find((mt) => mt.id === this.tempMaterial.id);
          this.tempMaterial = null;
          if (material !== undefined) {
            this.materialChanged(material);
          }
        }
      },
      (err) => {
        L.e(err);
        this.error = err.message;
      }
    );
  }

  materialChanged(material: MaterialTypeNEW) {
    //console.log('material changed', material);
    this.selectedMaterial = material;
    this.parentMaterial = this.availableMaterials.find((mt) => mt.id === this.selectedMaterial.idParent);
    if (this.tempColor === null)
      //car peut déjà être set via sizeChanged
      this.tempColor = this.selectedColor;
    this.selectedColor = null;
    this.availableColors = null;
    this.availableFinish = null;
    this.printService.getPossibleColors(this.idProducer, null, this.articleTypeSelected.id, this.selectedMaterial.id, this.size).subscribe(
      (data) => {
        this.availableColors = data;

        if (this.tempColor !== null) {
          let color = null;
          if (this.tempColor instanceof String) {
            if (this.availableColors.canPrintInMulticolor) color = OrderService.MULTICOLOR_STR;
          } else {
            color = this.availableColors.colors.find((c) => c.id === (<ColorNEW>this.tempColor).id);
          }
          this.tempColor = null;
          if (color !== null && color !== undefined) this.colorChanged(color);
        }
      },
      (err) => {
        L.e(err);
        this.error = err.message;
      }
    );
    if (this.tempColor === null) this.loadCharacs();
  }

  colorChanged(color: ColorNEW | string) {
    //string can be 'multicolor'
    //console.log('color changed', color);
    this.selectedColor = color;

    if (color === OrderService.MULTICOLOR_STR) this.setModelColorEvent.emit({ multicolor: true });
    else this.setModelColorEvent.emit({ multicolor: false, color: '#' + (<ColorNEW>color).hexCode });

    let idColor = 0;
    if (this.selectedColor !== OrderService.MULTICOLOR_STR && this.selectedColor !== null) {
      idColor = (<ColorNEW>this.selectedColor).id;
    }

    this.selectedFinish = null;
    this.availableFinish = null;
    this.printService.getPossibleFinish(this.idProducer, null, this.articleTypeSelected.id, this.selectedMaterial.id, idColor, this.size).subscribe(
      (data) => {
        this.availableFinish = data.sort((a, b) => a.name.localeCompare(b.name));
        if (this.tempFinish !== null) {
          const finish = this.availableFinish.find((f) => f.id === this.tempFinish.id);
          this.tempFinish = null;
          if (finish !== undefined) this.finishChanged(finish);
        }
      },
      (err) => {
        L.e(err);
      }
    );

    if (this.tempFinish === null) this.loadCharacs();
  }

  finishChanged(finish: Finish) {
    this.selectedFinish = finish;
    this.loadCharacs();
  }

  isMulticolor() {
    return this.selectedColor === OrderService.MULTICOLOR_STR;
  }

  canNextUploadStep(): boolean {
    //console.log('CAN NEXT', (this.files.length > 0), (this.files.find((file) => !file.good) === undefined));
    return this.files.length > 0 && this.files.find((file) => !file.good) === undefined; //all uploaded
  }

  canNextPrintSettingStep(): boolean {
    if (this.dentaire) {
      return this.name !== null && this.name.length > 2;
    }
    /*console.log(
      'next print settings',
      this.size !== null,
      this.selectedColor !== null,
      this.selectedMaterial !== null,
      this.name !== null && this.name.length > 2
    );*/
    return this.size !== null && this.selectedColor !== null && this.selectedMaterial !== null && this.name !== null && this.name.length > 2;
  }

  canNextQuantityStep() {
    return this.wishedDeliveryDate !== undefined && this.wishedDeliveryDate !== null;
  }

  goToStep(step: number, pushState: boolean = true) {
    if (pushState) {
      window.history.pushState({ step: this.step }, OrderService.STEP_TITLES[this.step]);
      //console.log('pushing', { step: this.step }, OrderService.STEP_TITLES[this.step], window.history);
    } else window.history.replaceState({ step: this.step }, OrderService.STEP_TITLES[this.step]);
    this.step = step;
    this.setTitle();
  }

  setTitle() {
    //console.log('SETTING TITLE', this.buildTitle());
    setTimeout(() => {
      this.titleService.setTitle(this.buildTitle());
    }, 100);
  }

  buildTitle() {
    return (
      (this.infos ? this.infos.name : 'Chargement...') +
      ' • ' +
      (this.modeDevis ? 'Demande de devis' : 'Imprimer un fichier 3D') +
      ' - ' +
      OrderService.STEP_TITLES[this.step]
    );
  }

  goBackStep(step: number) {
    this.step = step;
    this.setTitle();
    //window.history.pushState({ step: this.step }, OrderService.STEP_TITLES[this.step]);
    //console.log(window.history);
  }

  next() {
    if (this.step === OrderService.STEP_UPLOAD_MODELS) {
      window.history.pushState({ step: this.step }, OrderService.STEP_TITLES[this.step]);
      this.goToStep(OrderService.STEP_PRINT_SETTINGS);
      this.defaultSizeDTO = new SizeDTO();
      let file = this.files[0];
      this.defaultSizeDTO.x = Math.round(file.modelData.x * 100) / 100;
      this.defaultSizeDTO.y = Math.round(file.modelData.y * 100) / 100;
      this.defaultSizeDTO.z = Math.round(file.modelData.z * 100) / 100;
      //this.sizeChanged(this.defaultSizeDTO); removed because the print-setting-size will trigger it
      if (this.dentaire) this.name = this.articleTypeSelected.name + ' ' + moment().format('DD/MM/YYYY');
      else {
        this.name = file.file.name.split('.').slice(0, -1).join('.');
        //this.sizeChanged(this.defaultSizeDTO);
      }

      let uf = this.files[0];
      this.modelUrl = environment.rpcHost + 'tempTunnelModel/' + uf.fileHash + '/' + uf.name;
      console.log('orderService.load', this.modelUrl);
      //this.loadModelEvent.emit({ url: url, modelData: uf.modelData });
    } else if (this.step === OrderService.STEP_PRINT_SETTINGS) {
      this.goToStep(OrderService.STEP_LOGIN_REGISTER);
    } else if (this.step === OrderService.STEP_LOGIN_REGISTER) {
      if (this.preRegisterForm.valid) {
        this.verifyEmail();
      }
    } else if (this.step === OrderService.STEP_REGISTER) {
      if (this.registerForm.valid) {
        this.createAccount();
      } else {
        console.log(this.registerForm, this);
      }
    } else if (this.step === OrderService.STEP_QUANTITY) {
      if (this.modeDevis) this.createDevis();
      else this.createOrder();
    }
  }

  goLogin() {
    this.step = OrderService.STEP_LOGIN;
  }
  goRegister() {
    this.step = OrderService.STEP_LOGIN_REGISTER;
  }

  previous() {
    if (this.step === OrderService.STEP_UPLOAD_MODELS) {
      this.goBackStep(OrderService.STEP_CHOOSE_ARTICLE_TYPE);
    } else if (this.step === OrderService.STEP_PRINT_SETTINGS) {
      this.goBackStep(OrderService.STEP_UPLOAD_MODELS);
      this.characs = [];
      this.size = null;
      this.availableMaterials = null;
      this.selectedMaterial = null;
      this.parentMaterial = null;
      this.tempMaterial = null;
      this.availableColors = null;
      this.selectedColor = null;
      this.tempColor = null;
      this.availableFinish = null;
      this.selectedFinish = null;
      this.tempFinish = null;
      this.defaultSizeDTO = null;
      this.comment = undefined;
    } else if (this.step === OrderService.STEP_LOGIN_REGISTER) {
      this.goBackStep(OrderService.STEP_PRINT_SETTINGS);
    } else if (this.step === OrderService.STEP_REGISTER) {
      this.goBackStep(OrderService.STEP_LOGIN_REGISTER);
    } else if (this.step === OrderService.STEP_QUANTITY) {
      this.authService.logout();
      this.router.navigate([this.slugProducer, this.idProducer, this.typeTunnel === OrderService.TUNNEL_PRINT ? 'impression-en-ligne' : 'demande-devis']);
      this.goBackStep(OrderService.STEP_LOGIN_REGISTER);
    } else if (this.step === OrderService.STEP_LOGIN) {
      this.authService.logout();
      this.goBackStep(OrderService.STEP_LOGIN_REGISTER);
    }
  }

  loadCharacs() {
    let colorId = 0;
    if (this.selectedColor !== null && this.selectedColor instanceof ColorNEW) colorId = this.selectedColor.id;
    let finishId = 0;
    if (this.selectedFinish !== null) finishId = this.selectedFinish.id;
    this.printService.getCharacteristics(this.idProducer, null, this.articleTypeSelected.id, this.selectedMaterial.id, colorId, finishId, this.size).subscribe(
      (data) => {
        this.characs = data;
      },
      (err) => {
        L.e(err);
        this.error = err.message;
      }
    );
  }

  login() {
    if (!this.loginForm.valid) return;
    this.loading = true;
    this.authService.redirectUrl = null;
    this.authService.login(this.loginForm.value.email, this.loginForm.value.password).subscribe(
      (data) => {
        this.loading = false;
        if (!AuthService.has(data.userBundle, DegineoRight.CLIENT)) {
          this.authService.logout();
          this.loginError = "Ce compte n'est pas un compte producteur";
          console.error(this.loginError);
          return;
        }

        data.onLoginAction = () => {
          this.loadPriceAndGoQuantityStep();
        };
      },
      (error) => {
        this.loading = false;
        L.e(error);
        this.loginError = "Votre identifiant ou votre mot de passe n'est pas valide, veuillez réessayer.";
        console.error(this.loginError);
      }
    );
  }

  verifyEmail() {
    this.registerService.emailAvailable(this.preRegisterForm.value.email).subscribe(
      (data) => {
        this.registerEmailUnavailable = !data;
        if (!this.registerEmailUnavailable) this.goToStep(OrderService.STEP_REGISTER);
      },
      (error) => {
        L.e(error);
        this.error = error.message;
      }
    );
  }

  createAccount() {
    let step1Val = this.preRegisterForm.value;
    let step2Val = this.registerForm.value;
    //console.log('create account', this.preRegisterForm.value, this.registerForm.value);
    this.loading = true;
    this.registerService
      .createClientMarqueBlanche(
        this.idProducer,
        step1Val.email,
        step1Val.password,
        step2Val.lastname,
        step2Val.firstname,
        step2Val.phone,
        step2Val.companyName,
        step2Val.siret
      )
      .subscribe(
        (data) => {
          this.loading = false;
          this.authService.loginWith(data);
          if (this.modeDevis) {
            this.createDevis();
          } else {
            this.loadPriceAndGoQuantityStep();
          }
        },
        (error) => {
          this.loading = false;
          L.e(error);
          this.error = error.message;
        }
      );
  }

  loadPriceAndGoQuantityStep() {
    let idColor = 0;
    let multicolor = false;
    if (this.selectedColor === OrderService.MULTICOLOR_STR) {
      multicolor = true;
    } else if (this.selectedColor !== null) {
      idColor = (<ColorNEW>this.selectedColor).id;
    }
    let idMaterial = 0;
    if (this.selectedMaterial !== null) {
      idMaterial = this.selectedMaterial.id;
    }
    let idFinish = 0;
    if (this.selectedFinish !== null) idFinish = this.selectedFinish.id;

    this.loading = true;
    this.priceService.getPriceClientMarqueBlanche(this.idProducer, this.articleTypeSelected.id, idMaterial, idColor, multicolor, idFinish, this.size).subscribe(
      (data) => {
        this.price = data;
        this.loadNextDeliveryDate();
      },
      (error) => {
        this.loading = false;
        if (error.isBusinessError()) {
          this.modeDevis = true;
          this.noMarginProducerSet = true;
          this.loadNextDeliveryDate();
        } else {
          L.e(error);
          this.error = error.message;
        }
      }
    );
  }

  loadNextDeliveryDate() {
    this.printService.getNextAvailableDeliveryDate(this.idProducer).subscribe(
      (data) => {
        this.loading = false;
        this.wishedDeliveryDate = data.wishedDate;
        //this.goToStep(OrderService.STEP_QUANTITY);
        this.step = OrderService.STEP_QUANTITY;
        this.router.navigate(
          [
            this.slugProducer,
            this.idProducer,
            this.typeTunnel === OrderService.TUNNEL_PRINT ? 'impression-en-ligne' : 'demande-devis',
            'finalisation-commande',
          ],
          {
            state: { step: this.step },
          }
        );
      },
      (error) => {
        this.loading = false;
        L.e(error);
        this.error = error.message;
      }
    );
  }

  createDevis() {
    let idColor = 0;
    let multicolor = false;
    if (this.selectedColor === OrderService.MULTICOLOR_STR) {
      multicolor = true;
    } else if (this.selectedColor !== null) {
      idColor = (<ColorNEW>this.selectedColor).id;
    }
    let idMaterial = 0;
    if (this.selectedMaterial !== null) {
      idMaterial = this.selectedMaterial.id;
    }
    let idFinish = 0;
    if (this.selectedFinish !== null) idFinish = this.selectedFinish.id;

    this.loading = true;
    this.redirecting = true;

    this.clientMarqueBlancheService
      .createDevis(
        this.files[0].fileHash,
        this.name,
        this.comment,
        this.idProducer,
        this.articleTypeSelected.id,
        idMaterial,
        idColor,
        multicolor,
        idFinish,
        this.size,
        this.quantity,
        this.wishedDeliveryDate
      )
      .subscribe(
        (data) => {
          this.clear();
          this.router.navigate(['/devis']);
          this.loading = false;
        },
        (error) => {
          L.e(error);
          this.error = error.message;
          this.loading = false;
        }
      );
  }

  createOrder() {
    let idColor = 0;
    let multicolor = false;
    if (this.selectedColor === OrderService.MULTICOLOR_STR) {
      multicolor = true;
    } else if (this.selectedColor !== null) {
      idColor = (<ColorNEW>this.selectedColor).id;
    }
    let idMaterial = 0;
    if (this.selectedMaterial !== null) {
      idMaterial = this.selectedMaterial.id;
    }
    let idFinish = 0;
    if (this.selectedFinish !== null) idFinish = this.selectedFinish.id;

    this.redirecting = true;
    this.loading = true;

    this.clientMarqueBlancheService
      .createOrder(
        this.files.map((uf) => uf.fileHash),
        this.name,
        this.comment,
        this.idProducer,
        this.articleTypeSelected.id,
        idMaterial,
        idColor,
        multicolor,
        idFinish,
        this.size,
        this.quantity,
        this.wishedDeliveryDate
      )
      .subscribe(
        (data) => {
          this.clear();
          this.router.navigate(['/panier']);
          this.loading = false;
        },
        (error) => {
          L.e(error);
          this.error = error.message;
          this.loading = false;
        }
      );
  }

  clear() {
    this.loginForm.reset();
    this.registerForm.reset();

    this.idProducer = undefined;
    this.slugProducer = undefined;
    this.modeDevis = false;
    this.title = undefined;
    this.step = 0;
    this.infos = undefined;
    this.typeTunnel = undefined;
    this.error = undefined;
    this.characs = [];
    this.articleTypes = [];
    this.articleTypeSelected = undefined;
    this.dentaire = false;
    this.files = [];
    this.size = null;
    this.availableMaterials = null;
    this.selectedMaterial = null;
    this.parentMaterial = null;
    this.tempMaterial = null;
    this.availableColors = null;
    this.selectedColor = null;
    this.tempColor = null;
    this.availableFinish = null;
    this.selectedFinish = null;
    this.tempFinish = null;
    this.defaultSizeDTO = null;
    this.printerSizes = [];
    this.comment = undefined;
    this.name = null;
    this.loginError = null;
    this.registerEmailUnavailable = false;
    this.redirecting = false;
    this.wishedDeliveryDate = null;
    this.quantity = 1;
    this.price = undefined;
  }

  /*crossLoginAndRedirect(urlPath: string = '/Client#open-basket') {
    let formData = new FormData();
    formData.append('jwtToken', this.irpc.authToken);

    this.http
      .post(environment.webappUrl + '/Client/Account/LoginFromToken', formData, {
        withCredentials: true,
      })
      .subscribe(
        (data) => {
          let url = environment.webappUrl + urlPath;
          console.log('REDIRECT', data);
          window.location.href = url;
        },
        (error) => {
          L.e(error);
          this.error = error.message;
        }
      );
  }*/
}
