<div class="container-fluid mb-3">
  <div class="row">
    <div class="col text-center">
      <h3 class="fw-400">Quel type d'impression souhaitez vous faire ?</h3>
    </div>
  </div>
</div>
<div class="container">
  <a [routerLink]="" (click)="select.next(at)" *ngFor="let at of articleTypes">
    <div class="articleType">
      <div class="picture">
        <img [src]="rpcHost + 'articleType/' + at.id" alt="{{ at.name }}" />
      </div>
      <div class="core text-center">
        <h4>{{ at.name }}</h4>
      </div>
    </div>
  </a>
</div>
