<div class="container pt-4 pb-4">
  <h1 class="mx-auto text-center mb-5">Pages Degineo</h1>

  <degineo-filter
    class="d-inline-block w-100 mt-4 mb-4"
    #fliter
    [builtFilters]="builtFilters"
    (filterChanged)="filterChanged($event)"
    [cache]="ProducerPageListComponent.filtersCache"
    [default]="filtersDefault"
  >
    <div class="form-inline">
      <degineo-checkbox-group-filter #f class="form-inline float-left" filterName="checkBox">
        <degineo-checkbox-filter #cb class="mr-2" name="Abonné" filterName="abonne" color="success"> </degineo-checkbox-filter>
        <degineo-checkbox-filter #cb class="mr-2" name="Inscrit" filterName="inscrit" color="primary"> </degineo-checkbox-filter>
        <degineo-checkbox-filter #cb class="mr-2" name="Désabonné" filterName="desabonne" color="warning"> </degineo-checkbox-filter>
        <degineo-checkbox-filter disabled #cb class="mr-2" name="Désinscrit" filterName="desinscrit" color="danger"> </degineo-checkbox-filter>
        <degineo-checkbox-filter #cb name="Non inscrit" filterName="nonInscrit" color="secondary"> </degineo-checkbox-filter>
        <div class="border-right ml-2 mr-2" style="width: 1px; height: 30px"></div>
        <degineo-checkbox-filter #cb class="mr-2" name="Activé - TODO" filterName="active" color="dark"> </degineo-checkbox-filter>
        <degineo-checkbox-filter #cb class="mr-2" name="Desactivé - TODO" filterName="unactive" color="danger"> </degineo-checkbox-filter>
      </degineo-checkbox-group-filter>

      <degineo-text-filter #f class="input-group mr-2" style="width: 13rem" name="Producer, CP ou Ville" filterName="recherche"> </degineo-text-filter>

      <i class="fas fa-undo text-primary resetToDefault ml-2" (click)="fliter.resetToDefault(); ProducerPageListComponent.page = 1"></i>
    </div>
  </degineo-filter>

  <degineo-my-table
    [rows]="producers"
    [orderCache]="ProducerPageListComponent.OrderCaches"
    [builtFiltersOrder]="builtFiltersOrder"
    (orderChange)="orderChange($event)"
    (rowClick)="tableRowClick($event)"
  >
    <degineo-my-column orderName="name" name="Producteur" [defaultOrder]="OrderBy.ASC">
      <td *cv="let r" class="align-middle text-center">
        <img class="logo rounded mb-2" style="width: 80px" src="{{ rpcHost }}companylogo/{{ r.logoToken }}/logo.png?size=small" />
        <div style="line-height: 1">{{ r.name }}</div>
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="city" name="CP - Ville">
      <td *cv="let r" class="align-middle text-center">{{ r.address.zipCode }} - {{ r.address.city }}</td>
    </degineo-my-column>

    <degineo-my-column name="Status" [disableOrder]="true">
      <td *cv="let r" class="align-middle text-center">
        <div class="text-success fw-500" *ngIf="r.dateSub">Abonné</div>
        <div class="text-warning fw-500" *ngIf="r.unsub">Désabonné</div>
        <div class="text-primary fw-500" *ngIf="r.register">Inscrit</div>
        <div class="text-secondary fw-500" *ngIf="!r.register">Non inscrit</div>
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="etat" name="Etat">
      <td *cv="let r" class="align-middle text-center">
        <ng-container *ngIf="r.etat"> Activée </ng-container>
        <ng-container *ngIf="!r.etat">
          <div class="text-danger">Desactivée</div>
        </ng-container>
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="nbVisitTotal" name="Nbr. de visites total">
      <td *cv="let r" class="align-middle text-right">
        {{ r.nbVisitTotal }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="nbVisitMonth" name="Nbr. de visites ce mois-ci">
      <td *cv="let r" class="align-middle text-right">
        {{ r.nbVisitMonth }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="nbClickTotal" name="Nbr. de clics total">
      <td *cv="let r" class="align-middle text-right">
        {{ r.nbClickTotal }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="nbClickMonth" name="Nbr. de clics ce mois-ci">
      <td *cv="let r" class="align-middle text-right">
        {{ r.nbClickMonth }}
      </td>
    </degineo-my-column>
  </degineo-my-table>

  <div class="row justify-content-center mt-3" *ngIf="!loading">
    <ngb-pagination
      [collectionSize]="count"
      [(page)]="ProducerPageListComponent.page"
      [pageSize]="nbPerPage"
      [maxSize]="6"
      [rotate]="true"
      (pageChange)="reload()"
      [disabled]="loading"
    ></ngb-pagination>
  </div>
</div>
