<div class="container pt-4 pb-4" *ngIf="!loading">
  <!-- Boutton top - View -->
  <div class="overflow-auto" *ngIf="!edit!; new">
    <button type="button" class="btn btn-secondary m-1 float-left" (click)="backClicked()"><i class="fas fa-chevron-left"></i> Retour</button>
    <button type="button" class="btn btn-primary m-1 float-right" style="min-width: 200px" (click)="editClicked()">Modifier</button>
  </div>

  <!-- Boutton top - Edit -->
  <div class="overflow-auto" *ngIf="edit && !new">
    <button class="btn btn-success m-1 float-right" style="min-width: 200px" type="button" (click)="saveEditClicked()" [disabled]="cantSave()">
      Enregistrer les modifications
    </button>
    <button class="btn btn-secondary m-1 float-right" style="min-width: 200px" type="button" (click)="cancelClicked()">Annuler les modifications</button>
    <button
      class="btn btn-danger m-1 float-right"
      style="min-width: 200px"
      *ngIf="data.dto.assocPrinter.length == 0 && data.dto.assocProducer.length == 0"
      type="button"
      (click)="deleteClicked()"
    >
      Supprimer ce consommable
    </button>
    <div
      class="m-1 float-right"
      style="min-width: 200px"
      *ngIf="data.dto.assocPrinter.length > 0 || data.dto.assocProducer.length > 0"
      placement="bottom"
      [ngbTooltip]="
        'Consommable utilisé par :&#013;' +
        data.dto.assocPrinter.length +
        ' Imprimante' +
        (data.dto.assocPrinter.length > 1 ? 's' : '') +
        '&#013;' +
        data.dto.assocProducer.length +
        ' Producteur' +
        (data.dto.assocProducer.length > 1 ? 's' : '')
      "
    >
      <button class="btn btn-danger float-right" style="min-width: 200px" type="button" disabled>Supprimer ce consommable</button>
    </div>
  </div>

  <div class="alert alert-danger mt-3" *ngIf="error">
    <ul>
      <li>{{ error }}</li>
    </ul>
  </div>

  <!-- Boutton top - New -->
  <div class="overflow-auto" *ngIf="new">
    <button class="btn btn-success m-1 float-right" style="min-width: 200px" type="button" (click)="newClicked()" [disabled]="cantSave()">Ajouter</button>
    <button class="btn btn-secondary m-1 float-right" style="min-width: 200px" type="button" (click)="cancelClicked()">Annuler</button>
  </div>

  <div class="row">
    <div class="col">
      <app-consumable-info class="mt-5 d-block" [edit]="edit"></app-consumable-info>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col">
      <textarea
        class="form-control"
        style="resize: none"
        [disabled]="!edit"
        placeholder="Description"
        rows="3"
        [(ngModel)]="data.dto.consumable.description"
      ></textarea>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col">
      <app-consumable-caracteristiques class="p-4 d-block alert border-secondary border m-0" [edit]="edit"> </app-consumable-caracteristiques>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col">
      <app-consumable-options class="p-4 d-block alert border-secondary border m-0" [edit]="edit"> </app-consumable-options>
    </div>
  </div>

  <div class="row" *ngIf="!new">
    <div class="col-12 col-md-6 mt-4">
      <app-consumable-printers class="h-100 p-4 d-block alert border-secondary border"> </app-consumable-printers>
    </div>
    <div class="col-12 col-md-6 mt-4">
      <app-consumable-producers class="h-100 p-4 d-block alert border-secondary border"> </app-consumable-producers>
    </div>
  </div>
</div>
