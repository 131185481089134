import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { ArticleTypeBODTO, ArticleTypeBusinessTarget, ArticleTypeNEW, BusinessTarget, BusinessTargetLevel2, BusinessTargets } from 'degineo-common';
import { ArticleTypeData } from '../../providers/articleType.provider';
import { L } from 'ic2-lib';
import { ArticleTypeBOService } from 'degineo-common';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-article-type-info',
  templateUrl: './article-type-info.component.html',
  styleUrls: ['./article-type-info.component.scss'],
})
export class ArticleTypeInfoComponent implements OnInit {
  show: boolean = false;
  edit: boolean = false;

  rpcHost: string = environment.rpcHost;

  @Input()
  articleTypeDTO: ArticleTypeBODTO;

  @Output()
  reload: EventEmitter<any> = new EventEmitter<any>();

  tempArticleType: ArticleTypeNEW;
  file: File = null;
  progressPercent: number = null;
  errorFile: string;

  imgUrl: string = null;

  targets: BusinessTarget[] = BusinessTargets;
  opened: BusinessTarget[] = [];

  constructor(@Inject(ArticleTypeBOService) private articleTypeBOService: ArticleTypeBOService, public data: ArticleTypeData) {}

  ngOnInit() {
    this.tempArticleType = Object.assign(new ArticleTypeNEW(), this.articleTypeDTO.at);
    this.imgUrl = this.rpcHost + 'articleType/' + this.articleTypeDTO.at.id + '/at.png?nocache';
  }

  toggle() {
    this.show = !this.show;
  }

  editClicked() {
    this.edit = true;
  }

  cancelClicked() {
    this.tempArticleType = Object.assign(new ArticleTypeNEW(), this.articleTypeDTO.at);
    this.show = true;
    this.edit = false;
  }

  saveClicked() {
    this.articleTypeDTO.at = this.tempArticleType;
    let builder = this.articleTypeBOService.updateAT(this.articleTypeDTO);
    if (this.file !== null) builder.withFile(this.file);
    builder.subscribeWithProgress(
      (data) => {
        this.progressPercent = null;
        this.file = null;
        console.log('update', data);

        this.articleTypeDTO = data;
        this.tempArticleType = Object.assign(new ArticleTypeNEW(), this.articleTypeDTO.at);
        this.show = true;
        this.edit = false;
      },
      (loaded, total) => {
        this.progressPercent = +((loaded / total) * 100).toFixed(0);
      },
      (err) => {
        L.e(err);
        this.progressPercent = null;
        this.errorFile = err.message;
      }
    );
    console.log('saveClicked');
  }

  activated(bool) {
    this.tempArticleType.active = bool;
  }

  handleFileInput(event) {
    let files: FileList = event.target.files;
    var fr = new FileReader();

    fr.onload = (e) => {
      // file is loaded
      var img = new Image();
      img.onload = () => {
        console.log(img.width + ' x ' + img.height);
        let dimensionsExact = { w: 420, h: 330 };
        if (img.width != dimensionsExact.w || img.height != dimensionsExact.h) {
          //dimensions minis
          this.errorFile = 'Votre fichier doit faire ' + dimensionsExact.w + 'x' + dimensionsExact.h;
          this.imgUrl = this.rpcHost + 'articleType/' + this.articleTypeDTO.at.id + '?nocache';
        } else {
          this.file = files[0];
          console.log(this.file, this.file.size / 1024 / 1024 + 'MB', this.file.name.split('.').pop());
        }
      };
      this.imgUrl = (e.target as any).result;
      img.src = (e.target as any).result; // is the data URL because called with readAsDataURL
    };
    fr.readAsDataURL(files[0]);
  }

  changeParent(target: BusinessTarget) {
    if (this.hasAll(target)) {
      //deselect every child
      for (let t of target.children) {
        this.changeChild(t);
      }
    } else {
      //select any missing child
      for (let t of target.children) {
        if (!this.has(t)) this.changeChild(t);
      }
    }
  }

  changeChild(target: BusinessTargetLevel2) {
    if (this.has(target))
      this.articleTypeDTO.targets.splice(
        this.articleTypeDTO.targets.findIndex((b) => b.target.id === target.id),
        1
      );
    else {
      let pbt = new ArticleTypeBusinessTarget();
      pbt.target = target;
      this.articleTypeDTO.targets.push(pbt);
    }
  }

  toggleBT(code: BusinessTarget) {
    if (this.isOpen(code)) this.opened.splice(this.opened.indexOf(this.opened.find((c) => c.level1.id === code.level1.id)), 1);
    else this.opened.push(code);
  }

  isOpen(code: BusinessTarget) {
    return this.opened.find((c) => c.level1.id === code.level1.id) !== undefined;
  }

  hasAny(target: BusinessTarget) {
    return this.articleTypeDTO.targets.find((bt) => target.children.find((b) => b.id === bt.target.id) !== undefined) !== undefined;
  }
  hasAll(target: BusinessTarget) {
    for (const child of target.children) {
      if (this.articleTypeDTO.targets.find((bt) => bt.target.id === child.id) === undefined) return false;
    }
    return true;
  }
  has(target: BusinessTargetLevel2) {
    return this.articleTypeDTO.targets.find((bt) => bt.target.id === target.id) !== undefined;
  }
}
