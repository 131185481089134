import { Component, OnInit, Input, Inject } from '@angular/core';
import { ConsumableData } from '../../providers/consumable.provider';
import { ConsumableBOService } from 'degineo-common';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-consumable-info',
  templateUrl: './consumable-info.component.html',
  styleUrls: ['./consumable-info.component.scss'],
})
export class ConsumableInfoComponent implements OnInit {
  @Input()
  edit: boolean;

  presenter = (obj) => obj;

  constructor(public data: ConsumableData, @Inject(ConsumableBOService) public consumableBOService: ConsumableBOService) {}

  ngOnInit() {}

  materialChange(value) {
    console.log('change', value);
    if (value != 0)
      this.consumableBOService.getMaterialTypeCharacteristic(parseInt(value)).subscribe(
        (data) => {
          this.data.dto.characs = data;
        },
        (error) => {
          L.e(error);
        }
      );
  }

  getAvgPrice() {
    let avg = 0;
    let length = 0;
    this.data.dto.options.forEach((c) => {
      if (c.co.price != null) {
        avg += c.co.price;
        length++;
      }
    });
    let res = (avg / length).toFixed(2).toString().replace('.', ',');
    if (res != 'NaN') return res;
    else return '~';
  }

  getCurrentPrinterTechnology() {
    const currentMaterial = this.data.materialList.find((m) => m.id === this.data.dto.consumable.idMaterialType);
    if (!currentMaterial) return '';
    return currentMaterial.printerTechnology;
  }
}
