import { Injectable, Inject } from '@angular/core';
import { ClientProducerAssociationService } from 'degineo-common';
import { L } from 'ic2-lib';

@Injectable({
  providedIn: 'root',
})
export class CpaService {
  hasPendingClientProducerAssociations: boolean = true;

  constructor(@Inject(ClientProducerAssociationService) public clientProducerAssociationService: ClientProducerAssociationService) {
    this.reload();
  }

  reload() {
    this.clientProducerAssociationService.hasPendingClientProducerAssociations().subscribe(
      (data) => {
        this.hasPendingClientProducerAssociations = data;
      },
      (err) => {
        L.e(err);
      }
    );
  }
}
