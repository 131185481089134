<div class="container">
  <div class="row">
    <div *ngFor="let file of files; let i = index" class="col-md-3 mb-4">
      <div class="position-relative w-100 pointer" (click)="open(modal, file)">
        <img
          class="w-100 rounded"
          alt="img"
          [src]="over[i] ? rpcHost + 'orderModele3Dgif/' + file.tokenGIF + '/modele.gif' : rpcHost + 'orderModele3Dpng/' + file.tokenPNG + '/modele.png'"
          (mouseover)="over[i] = true"
          (mouseout)="over[i] = false"
        />
        <div
          class="position-absolute text-white text-center py-1 px-2 overflow-hidden text-shadow-1"
          style="bottom: 0; right: 0; left: 0; background-color: rgba(0, 0, 0, 0.2); text-overflow: ellipsis; border-radius: 0 0 0.25rem 0.25rem"
        >
          {{ file.fileName }}
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #modal let-modal>
  <div class="bg-light-30p text-white rounded overflow-hidden shadow-5">
    <div class="row align-middle p-1 text-primary">
      <div class="col-2 align-self-center text-center pr-0">
        <div class="d-flex justify-content-around">
          <div
            *ngIf="!editName"
            class="text-center p-1 pointer text-dark hover-danger rounded"
            ngbTooltip="Supprimer ce fichier 3D"
            placement="bottom"
            (click)="remove()"
          >
            <i class="far fa-trash-alt"></i>
          </div>
        </div>
      </div>
      <div class="col-8 text-center align-self-center fs-4">
        <span
          *ngIf="!editName"
          style="word-break: break-all; word-wrap: break-word"
          class="hover-underline pointer"
          ngbTooltip="Modifier le nom du fichier"
          placement="bottom"
          (click)="edit()"
          >{{ modalFile.fileName }}</span
        >
        <div *ngIf="editName">
          <input
            ngbAutofocus
            type="text"
            style="max-width: 225px"
            class="text-center form-control d-inline-block pt-1 pb-1"
            [(ngModel)]="editedFileName"
          /><span>.{{ getFileExtention(modalFile.fileName) }}</span>
          <i class="fas fa-check-circle fs-4 text-success pointer no-select mr-2 ml-3" (click)="saveName()"></i>
          <i class="fas fa-times-circle fs-4 text-danger pointer no-select" (click)="edit()"></i>
        </div>
      </div>
      <div class="col-2 align-self-center pl-0 text-right">
        <div class="text-white mr-2">
          <i class="fas fa-times p-1 pointer text-secondary hover-medium" (click)="modal.dismiss('Cross click')" style="font-size: 30px"></i>
        </div>
      </div>
    </div>
    <div class="position-relative">
      <img class="w-100" style="min-height: 5rem" alt="img" [src]="rpcHost + 'orderModele3Dgif/' + modalFile.tokenGIF + '/modele.gif'" />
      <div *ngIf="!editName" class="position-absolute text-white text-center p-3" style="bottom: 0; right: 0; left: 0; background-color: rgba(0, 0, 0, 0.2)">
        <div class="row">
          <div class="col-md-6 order-1 order-md-0 mt-md-0 mt-2">
            <a class="btn btn-primary w-100" type="button" href="{{ rpcHost }}orderModele3D/{{ modalFile.tokenModele }}/{{ modalFile.fileName }}?dl">
              TÉLÉCHARGER
            </a>
          </div>
          <div class="col-md-6 order-0 order-md-1">
            <button class="btn btn-success w-100" type="button" (click)="print()">IMPRIMER</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
