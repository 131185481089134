import { Component, OnInit, Inject } from '@angular/core';
import { OrderBy } from 'degineo-common';
import { Filter } from 'degineo-common';
import { FilterOrder, GenericFilter, ProducerLiteBODTO } from 'degineo-common';
import { Router } from '@angular/router';
import { InvoiceBOService } from 'degineo-common';
import { L } from 'ic2-lib';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BOData } from '../../../provider/bo.provider';
import { SlugService } from 'degineo-common';

@Component({
  selector: 'app-payment-to-producer-list',
  templateUrl: './payment-to-producer-list.component.html',
  styleUrls: ['./payment-to-producer-list.component.scss'],
})
export class PaymentToProducerListComponent implements OnInit {
  PaymentToProducerListComponent: typeof PaymentToProducerListComponent = PaymentToProducerListComponent;
  payments = [];
  OrderBy: typeof OrderBy = OrderBy;

  static filtersCache = {};
  filtersDefault = {};

  static OrderCaches = {};
  static page: number = 1;

  nbPerPage: number = 10;
  count = 0;
  loading: boolean = true;

  builtFilters: Filter[] = [];
  builtFiltersOrder: FilterOrder[] = [];

  producers: ProducerLiteBODTO[] = [];

  presenterName = (obj) => obj.name;

  constructor(
    private router: Router,
    public BOData: BOData,
    private modalService: NgbModal,
    @Inject(InvoiceBOService) public invoiceService: InvoiceBOService,
    @Inject(SlugService) public slugService: SlugService
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.invoiceService.getProducersWaitingForPaymentFromDegineo().subscribe(
        (data) => {
          this.producers = data;
        },
        (err) => {
          L.e(err);
        }
      );
      this.reload();
    });
  }

  reload() {
    let genericFilter = new GenericFilter();
    genericFilter.filters = this.builtFilters;
    genericFilter.orderFilters = this.builtFiltersOrder;
    genericFilter.page = PaymentToProducerListComponent.page;
    genericFilter.nbPerPage = this.nbPerPage;
    console.log('genericFilter', genericFilter);
    this.loading = true;
    this.invoiceService.getBillToProducer(genericFilter).subscribe(
      (data) => {
        console.log(data);
        this.payments = data.data;
        this.count = data.count;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true });
  }

  paidClick(id) {
    console.log('paidClick', id);
    this.invoiceService.payInvoiceToProducer(id).subscribe(
      (data) => {
        this.BOData.fetchNbBillToProd();
        console.log('data', data);
        if (data) this.reload();
      },
      (err) => {
        L.e(err);
      }
    );
  }

  oneMonthsAgo = new Date().setMonth(new Date().getMonth() - 1);
  olderThan1Month(date) {
    const d = new Date(date);
    return +this.oneMonthsAgo > +date;
  }

  filterChanged(filters: Filter[]) {
    console.log('filterChanged event', filters);
    this.reload();
  }
  orderChange(order) {
    console.log('orderChange', order);
    this.reload();
  }
  tableRowClick(row: any) {
    console.log('tableRowClick', row);
    //this.router.navigate(['/producers', 'edit', row.id]);
  }
}
