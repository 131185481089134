import { Component, Input, OnInit } from '@angular/core';
import { FinishMaterialType, MaterialTypeNEW } from 'degineo-common';
import { FinishData } from '../../finish.provider';

@Component({
  selector: 'app-finish-material-assoc',
  templateUrl: './finish-material-assoc.component.html',
  styleUrls: ['./finish-material-assoc.component.scss'],
})
export class FinishMaterialAssocComponent implements OnInit {
  @Input()
  edit: boolean = false;
  constructor(public data: FinishData) {}

  ngOnInit(): void {
    console.log(this.data);
  }

  getParentMaterial() {
    return this.data.materials.filter((mt) => mt.idParent === 0);
  }

  getChildren(idParent: number) {
    return this.data.materials.filter((mt) => mt.idParent === idParent);
  }

  getParentMaterialChecked() {
    return this.data.materials.filter(
      (mt) =>
        mt.idParent === 0 &&
        (this.data.dto.materialTypes.find((fmt) => fmt.idMaterialType === mt.id) !== undefined || //checked lui même
          this.data.materials.find(
            (m) =>
              m.idParent === mt.id && //parmis les enfants
              this.data.dto.materialTypes.find((fmt) => fmt.idMaterialType === m.id) !== undefined //est-ce qu'un enfant est coché
          ) !== undefined)
      //this.data.dto.materialTypes.find((fmt) => fmt.idMaterialType === mt.id) !== undefined
    );
  }

  getChildrenChecked(idParent: number) {
    return this.data.materials.filter(
      (mt) => mt.idParent === idParent && this.data.dto.materialTypes.find((fmt) => fmt.idMaterialType === mt.id) !== undefined
    );
  }

  has(material: MaterialTypeNEW) {
    return this.data.dto.materialTypes.find((fmt) => fmt.idMaterialType === material.id) !== undefined;
  }

  checkChange(event, t: MaterialTypeNEW) {
    console.log(event, t);
    if (this.has(t)) {
      this.data.dto.materialTypes = this.data.dto.materialTypes.filter((fmt) => fmt.idMaterialType !== t.id);
    } else {
      const fmt = new FinishMaterialType();
      fmt.idMaterialType = t.id;
      this.data.dto.materialTypes.push(fmt);
    }
  }
}
