<div class="container pt-4 pb-4">
  <h1 class="mx-auto text-center mb-5">Consommables</h1>

  <button class="btn btn-success fw-500" type="button" (click)="newClicked()"><i class="fas fa-plus mr-1"></i> Nouveau consommable</button>

  <degineo-filter
    class="d-inline-block w-100 mt-4 mb-4"
    #fliter
    [builtFilters]="builtFilters"
    (filterChanged)="filterChanged($event)"
    [cache]="ConsumableListComponent.filtersCache"
    [default]="filtersDefault"
  >
    <div class="form-inline">
      <degineo-textassist-filter #f class="input-group mr-2 mt-1" style="width: 13rem" name="Toutes les marques" filterName="marques" [values]="brandFilter">
      </degineo-textassist-filter>
      <degineo-textassist-filter
        #f
        class="input-group mr-2 mt-1"
        style="width: 13rem"
        name="Toutes les matières"
        filterName="matieres"
        [values]="materialFilter"
        [presenter]="presenterSelect"
      >
      </degineo-textassist-filter>
      <degineo-textassist-filter
        #f
        class="input-group mr-2 mt-1"
        style="width: 13rem"
        name="Toutes les couleurs"
        filterName="couleurs"
        [values]="colorFilter"
        [presenter]="presenterSelect"
      >
      </degineo-textassist-filter>
      <degineo-select-filter
        #f
        class="input-group mr-2 mt-1"
        style="width: 13rem"
        name="Toutes les technologies"
        filterName="techno"
        [values]="technologyFilter"
        [presenter]="presenterSelect"
      >
      </degineo-select-filter>
      <degineo-text-filter #f class="input-group mr-2 mt-1" style="width: 13rem" name="Recherche" filterName="recherche"> </degineo-text-filter>

      <i class="fas fa-undo text-primary resetToDefault ml-2" (click)="fliter.resetToDefault(); ConsumableListComponent.page = 1"></i>
    </div>

    <degineo-checkbox-group-filter #f class="form-inline float-left mt-4" filterName="checkBox">
      <degineo-round-checkbox-filter #cb class="mr-4" name="Disponible" filterName="disponible" color="success"> </degineo-round-checkbox-filter>
      <degineo-round-checkbox-filter #cb class="mr-4" name="Indisponible" filterName="indisponible" color="danger"> </degineo-round-checkbox-filter>
      <degineo-round-checkbox-filter #cb name="Aucun producteur associé" filterName="aucun" color="secondary"> </degineo-round-checkbox-filter>
    </degineo-checkbox-group-filter>
  </degineo-filter>

  <degineo-my-table
    [rows]="consumables"
    [orderCache]="ConsumableListComponent.OrderCaches"
    [builtFiltersOrder]="builtFiltersOrder"
    (orderChange)="orderChange($event)"
    (rowClick)="tableRowClick($event)"
  >
    <degineo-my-column orderName="brand" name="Marque" [defaultOrder]="OrderBy.ASC">
      <td *cv="let r" class="text-nowrap text-left">
        <div
          class="round mr-2"
          [ngClass]="{
            'bg-success': r.hasAnyProducerPrinterConsumableEnabled,
            'bg-danger': !r.hasAnyProducerPrinterConsumableEnabled && (r.nbPrinterLinked > 0 || r.nbProducerLinked > 0),
            'bg-secondary': r.nbPrinterLinked == 0 && r.nbProducerLinked == 0
          }"
        ></div>
        <div class="d-inline-block">
          <div class="fw-500 text-nowrap pl-1">{{ r.brand }}</div>
          <div class="text-secondary text-nowrap pl-1">{{ r.name }}</div>
        </div>
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="printerTechnology" name="Technologie">
      <td *cv="let r" class="text-center">
        {{ r.printerTechnology }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="materialTypeName" name="Matière">
      <td *cv="let r" class="text-center">
        {{ r.materialTypeName }}
      </td>
    </degineo-my-column>

    <degineo-my-column name="Couleur" [disableOrder]="true">
      <td *cv="let r" class="text-center">
        {{ r.color }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="nbPrinterLinked" name="Nbr. d'imprimantes associés">
      <td *cv="let r" class="text-center">
        {{ r.nbPrinterLinked }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="nbProducerLinked" name="Nbr. de producteurs associés">
      <td *cv="let r" class="text-center">
        {{ r.nbProducerLinked }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="avg" name="Prix de vente moyen">
      <td *cv="let r" class="text-center">{{ r.avgPrice | number: '.2':'fr-FR' }}&nbsp;€</td>
    </degineo-my-column>
  </degineo-my-table>

  <div class="row justify-content-center mt-3" *ngIf="!loading">
    <ngb-pagination
      [collectionSize]="count"
      [(page)]="ConsumableListComponent.page"
      [pageSize]="nbPerPage"
      [maxSize]="6"
      [rotate]="true"
      (pageChange)="reload()"
      [disabled]="loading"
    ></ngb-pagination>
  </div>
</div>
