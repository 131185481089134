import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { AuthService } from 'degineo-common';

@Injectable({
  providedIn: 'root',
})
export class AvatarChangeService {
  avatar: string = 'assets/header/image-profil-default.svg';
  defaultAvatarUrl: string = 'assets/header/image-profil-default.svg';

  constructor(public authService: AuthService) {
    this.reload();
  }

  reload() {
    this.avatar = this.defaultAvatarUrl;
    if (this.authService.userBundle !== null)
      this.avatar = environment.rpcHost + 'userLogo/' + this.authService.userBundle.logoToken + '/logo.' + this.authService.userBundle.du.logoExtension;
    //else console.log('no avatar because userBundle is null');
  }

  errorLoading() {
    this.avatar = this.defaultAvatarUrl;
  }
}
