import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-print-settings-name',
  templateUrl: './print-settings-name.component.html',
  styleUrls: ['./print-settings-name.component.scss'],
})
export class PrintSettingsNameComponent implements OnInit {
  @Output()
  nameChanged: EventEmitter<string> = new EventEmitter<string>();
  @Input()
  name: string = null;
  constructor() {}

  ngOnInit() {}
}
