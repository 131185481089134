<div class="container pt-4 pb-4">
  <h1 class="mx-auto text-center mb-5">Finitions</h1>

  <button class="btn btn-success fw-500" type="button" (click)="newClicked()"><i class="fas fa-plus mr-1"></i> Nouvelle finition</button>

  <degineo-filter
    class="d-inline-block w-100 mt-4 mb-4"
    #fliter
    [builtFilters]="builtFilters"
    (filterChanged)="filterChanged($event)"
    [cache]="FinishListComponent.filtersCache"
    [default]="filtersDefault"
  >
    <div class="form-inline">
      <degineo-text-filter #f class="input-group mr-2 mt-1" style="width: 13rem" name="Nom" filterName="recherche"> </degineo-text-filter>

      <i class="fas fa-undo text-primary resetToDefault ml-2" (click)="fliter.resetToDefault(); FinishListComponent.page = 1"></i>
    </div>
  </degineo-filter>

  <degineo-my-table
    [rows]="finish"
    [orderCache]="FinishListComponent.OrderCaches"
    [builtFiltersOrder]="builtFiltersOrder"
    (orderChange)="orderChange($event)"
    (rowClick)="tableRowClick($event)"
  >
    <degineo-my-column orderName="name" name="Nom" [defaultOrder]="OrderBy.ASC">
      <td *cv="let r" class="text-nowrap">
        <img class="logo rounded mb-2" style="width: 80px" src="{{ rpcHost }}finish/{{ r.id }}/{{ r.imageFileName }}" />
        <div style="line-height: 1">
          {{ r.name }}
        </div>
      </td>
    </degineo-my-column>
  </degineo-my-table>

  <div class="row justify-content-center mt-3" *ngIf="!loading">
    <ngb-pagination
      [collectionSize]="count"
      [(page)]="FinishListComponent.page"
      [pageSize]="nbPerPage"
      [maxSize]="6"
      [rotate]="true"
      (pageChange)="reload()"
      [disabled]="loading"
    ></ngb-pagination>
  </div>
</div>
