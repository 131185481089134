<div class="container-fluid">
  <ng-container *ngIf="dto">
    <div class="row">
      <div class="col text-center">
        <h3>Demande de révision du devis n°{{ dto.numDevisProducteur }}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <h5>à {{ dto.producerName }}</h5>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="dto && !success">
    <div class="row mt-4">
      <div class="col">
        <label>Expliquez à {{ dto.producerName }} ce que vous souhaitez voir modifié dans ce devis :</label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <textarea [(ngModel)]="reason" class="form-control w-100"></textarea>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col text-right">
        <button class="btn btn-allonge btn-secondary" routerLink="/devis/{{ dto.idDevis }}/preview">Retour</button>
      </div>
      <div class="col">
        <button class="btn btn-allonge btn-success" (click)="sendDemand()">Envoyer la demande</button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="success">
    <div class="row mt-3 align-items-center mt-4 mb-4">
      <div class="col text-center text-success fs-5 fw-500"><i class="fas fa-check"></i> Envoyé</div>
    </div>
  </ng-container>
</div>
