import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConsumableBOService, ConsumableNEW, CharacteristicValueType } from 'degineo-common';
import { ConsumableCharacteristicValueNEW, ConsumableBODTO } from 'degineo-common';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { L } from 'ic2-lib';
import { MaterialTypeService } from 'degineo-common';
import { BrandConsumableService } from 'degineo-common';
import { ConsumableData } from '../providers/consumable.provider';
import { ColorService } from 'degineo-common';
import { ArticleService } from 'degineo-common';

@Component({
  selector: 'app-consumable-view-edit',
  templateUrl: './consumable-view-edit.component.html',
  styleUrls: ['./consumable-view-edit.component.scss'],
})
export class ConsumableViewEditComponent implements OnInit {
  private sub: Subscription;
  loading: boolean = true;
  edit: boolean;
  new: boolean = false;

  name: string;
  id: number;
  error: string = null;

  constructor(
    @Inject(ActivatedRoute) public route: ActivatedRoute,
    @Inject(ConsumableBOService) public consumableBOService: ConsumableBOService,
    @Inject(MaterialTypeService) private materialTypeService: MaterialTypeService,
    @Inject(BrandConsumableService) private brandConsumableService: BrandConsumableService,
    @Inject(ArticleService) private articleService: ArticleService,
    @Inject(ColorService) private colorService: ColorService,
    private router: Router,
    public data: ConsumableData
  ) {}

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      if (params['view'] == 'new') {
        this.new = true;
        this.edit = true;

        this.data.clear();
        let c = new ConsumableBODTO();
        c.MaterialTypeName = null;
        c.assocPrinter = [];
        c.assocProducer = [];
        c.consumable = new ConsumableNEW();
        c.characs = null;
        c.options = [];
        c.consuChara = [];
        this.data.dto = c;
      } else {
        this.new = false;

        if ((params['view'] !== 'view' && params['view'] !== 'edit' && params['view'] !== 'new') || params['name'] === undefined || params['id'] === undefined)
          this.router.navigate(['/consumables']);

        if (params['view'] == 'edit') this.edit = true;
        else this.edit = false;

        if (this.name !== params['name'] || this.id != parseInt(params['id'])) {
          this.name = params['name'];
          this.id = parseInt(params['id']);
          this.data.clear();
        }
      }
      this.reload();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  reload() {
    this.loading = true;

    if (!this.data.materialList)
      this.materialTypeService.getAllChildsMaterialTypes().subscribe(
        (data) => {
          this.data.materialList = data;
        },
        (error) => {
          L.e(error);
        }
      );

    if (this.edit) {
      if (!this.data.brandList)
        this.brandConsumableService.getAllConsumableBrand().subscribe(
          (data) => {
            this.data.brandList = data;
          },
          (error) => {
            L.e(error);
          }
        );

      if (!this.data.articleTypeList)
        this.articleService.getAllArticleTypes().subscribe(
          (data) => {
            this.data.articleTypeList = data;
          },
          (error) => {
            L.e(error);
          }
        );

      if (!this.data.colorList)
        this.colorService.getAllColors().subscribe(
          (data) => {
            this.data.colorList = data;
          },
          (error) => {
            L.e(error);
          }
        );
    }

    if (!this.data.dto)
      this.consumableBOService.getConsumableDetail(this.id).subscribe(
        (data) => {
          this.data.dto = data;
          this.loading = false;
        },
        (error) => {
          L.e(error);
        }
      );
    else {
      this.data.dto = this.data.dto;
      this.loading = false;
    }

    console.log('data', this.data);
  }

  backClicked() {
    this.router.navigate(['/consumables']);
  }

  editClicked() {
    this.router.navigate(['/consumables', 'edit', this.id, this.name]);
  }

  cancelClicked() {
    if (this.new) {
      this.router.navigate(['/consumables']);
    } else {
      this.data.dto = null;
      this.router.navigate(['/consumables', 'view', this.id, this.name]);
    }
  }

  deleteClicked() {
    this.consumableBOService.deleteConsomable(this.id).subscribe(
      (data) => {
        if (data) this.router.navigate(['/consumables']);
      },
      (error) => {
        L.e(error);
      }
    );
  }

  saveEditClicked() {
    this.error = null;
    console.log('id', this.data.dto.consumable.idMaterialType);
    console.log('name', this.data.dto.MaterialTypeName);
    this.buildChara();

    if (this.data.dto.consumable.name && this.data.dto.consumable.brand && this.data.dto.consumable.idMaterialType && this.data.dto.options.length > 0)
      this.consumableBOService.updateConsomable(this.data.dto).subscribe(
        (data) => {
          console.log('updateConsomable', data);
          this.data.clear();
          if (data) this.router.navigate(['/consumables', 'view', data.consumable.id, data.consumable.name]);
        },
        (error) => {
          L.e(error);
          if (error.isBusinessError()) this.error = error.message;
        }
      );
  }

  newClicked() {
    this.buildChara();

    if (this.data.dto.consumable.name && this.data.dto.consumable.brand && this.data.dto.consumable.idMaterialType && this.data.dto.options.length > 0)
      this.consumableBOService.newConsomable(this.data.dto).subscribe(
        (data) => {
          console.log('new', data);
          this.data.clear();
          if (data) this.router.navigate(['/consumables', 'view', data.consumable.id, data.consumable.name]);
        },
        (error) => {
          L.e(error);
        }
      );
  }

  buildChara() {
    this.data.dto.consuChara = [];
    if (this.data.dto.characs)
      this.data.dto.characs.map((charac) => {
        if (this.data.consumableCaracteristique[charac.id]) {
          const value = new ConsumableCharacteristicValueNEW();
          value.idCharacteristic = charac.id;
          if (charac.characType.id === CharacteristicValueType.NUMBER.id) value.valueDouble = parseFloat(this.data.consumableCaracteristique[charac.id]);
          else value.valueStr = this.data.consumableCaracteristique[charac.id];
          this.data.dto.consuChara.push(value);
        }
      });
  }

  cantSave() {
    return (
      this.data.dto.consumable.name == null ||
      this.data.dto.consumable.brand == null ||
      this.data.dto.consumable.idMaterialType === 0 ||
      this.data.dto.consumable.idMaterialType === null ||
      this.data.dto.options.length === 0
    );
  }
}
