<div class="container-fluid">
  <div class="row">
    <div class="col">
      <h6 class="text-center">Choisissez la finition de surface</h6>
    </div>
  </div>
  <div class="row mt-3" *ngIf="availableFinish === null">
    <div class="col text-center">Veuillez choisir une couleur avant.</div>
  </div>
  <div class="row mt-3" *ngIf="availableFinish !== null && availableFinish.length === 0">
    <div class="col text-center">Aucune finition n'est disponible pour ces critères</div>
  </div>
  <div class="row align-items-center pointer mt-3" *ngFor="let f of availableFinish" (click)="finishChanged.emit(f)">
    <div class="col-auto">
      <img src="{{ rpcHost }}finish/{{ f.id }}/{{ f.imageFileName }}" [class.active]="f === selectedFinish" />
    </div>
    <div class="col-auto" [class.text-primary]="f === selectedFinish">
      {{ f.name }} <ng-container *ngIf="f.description">({{ f.description }})</ng-container>
    </div>
  </div>
</div>
