<i class="fas fa-spinner spinning" *ngIf="data === null"></i>
<div class="container" *ngIf="data !== null">
  <div class="row mt-4 mb-4" *ngIf="aDesImprimeursPartenaires()">
    <div class="col text-center">
      <h3>Avec quel imprimeur souhaitez-vous travailler ?</h3>
    </div>
  </div>
  <div class="row justify-content-center mt-2 mb-5" *ngIf="aDesImprimeursPartenaires()">
    <div class="col-6">
      <select class="form-control" [compareWith]="compareProducer" [(ngModel)]="printDataService.producerSelected" (ngModelChange)="producerChanged($event)">
        <option [ngValue]="null" disabled="disabled">-- Choisir un imprimeur 3D --</option>
        <option [ngValue]="producer" *ngFor="let producer of data.producers">{{ producer.name }}</option>
      </select>
    </div>
  </div>

  <div class="row mt-4 mb-4">
    <div class="col text-center">
      <h3>Quel type d'impression 3D souhaitez-vous faire ?</h3>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col text-center">
      <div class="container">
        <a [routerLink]="" (click)="articleTypeSelected(at)" *ngFor="let at of data.articles">
          <div class="articleType" [class.disabled]="!isAvailableForCurrentProducer(at)">
            <div class="picture">
              <img [src]="rpcHost + 'articleType/' + at.id" alt="{{ at.name }}" />
            </div>
            <div class="core text-center">
              <h4>{{ at.name }}</h4>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>

  <div class="row mt-2 mb-5" *ngIf="aDesImprimeursPartenaires()">
    <div class="col fs-2 fw-400 text-center">* Le choix des types d'impression peuvent varier selon le producteur choisi.</div>
  </div>
</div>
