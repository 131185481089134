import { Component, OnInit, Inject } from '@angular/core';
import { OrderService } from '../../MarqueBlanche/order.service';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { AuthService } from 'degineo-common';

@Component({
  selector: 'app-finish-marque-blanche-order',
  templateUrl: './finish-marque-blanche-order.component.html',
  styleUrls: ['./finish-marque-blanche-order.component.scss'],
})
export class FinishMarqueBlancheOrderComponent implements OnInit {
  rpcHost = environment.rpcHost;
  showMessage: boolean = false;

  constructor(@Inject(OrderService) public orderService: OrderService, public authService: AuthService, public router: Router) {}

  ngOnInit() {
    if (this.orderService.step != OrderService.STEP_QUANTITY) {
      this.router.navigate(['/impression']);
    }
  }

  previous() {
    this.showMessage = false;
  }
  next() {
    if (!this.orderService.noMarginProducerSet) {
      this.orderService.next();
      return;
    }
    if (this.showMessage) {
      this.orderService.next();
      return;
    }
    this.showMessage = true;
  }
}
