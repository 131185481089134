import { Injectable, Inject } from '@angular/core';
import { ClientNotifService, NotifDTO } from 'degineo-common';
import { L } from 'ic2-lib';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  notifications: NotifDTO[] = [];
  constructor(@Inject(ClientNotifService) public clientNotifService: ClientNotifService) {
    this.reload();
  }

  reload() {
    this.clientNotifService.getNotifs().subscribe(
      (data) => {
        this.notifications = data;
        //console.log(data);
      },
      (error) => {
        L.e(error);
      }
    );
  }
}
