<div class="container pt-4 pb-4">
  <h1 class="mx-auto text-center mb-5">Facturation</h1>

  <a class="btn btn-primary mt-4" *ngIf="idCompany != null" style="width: 13rem" [routerLink]="'/invoices'"> Voir tout </a>

  <div class="row">
    <div class="col">
      <button class="btn btn-primary" (click)="generateDelayedInvoices()" [disabled]="generationLoading" *ngIf="notProd">
        Générer les factures en attente <i class="fas fa-spinner spinning" *ngIf="generationLoading"></i>
      </button>
      <span class="ml-2 fw-500" *ngIf="nbInvoicesInserted !== null">{{ nbInvoicesInserted }} factures crées</span>
    </div>
  </div>

  <degineo-filter
    class="d-inline-block w-100 mt-2 mb-4"
    #filter
    [builtFilters]="builtFilters"
    (filterChanged)="filterChanged($event)"
    [cache]="InvoiceAdminComponent.filtersCache"
    [default]="filtersDefault"
  >
    <div class="form-inline">
      <degineo-textassist-filter
        *ngIf="idCompany == null"
        #f
        class="input-group mr-2 mt-1"
        style="width: 13rem"
        name="Emetteur"
        filterName="biller"
        [values]="company1"
        (textChanges)="company1NameChange($event)"
      >
      </degineo-textassist-filter>

      <degineo-textassist-filter
        *ngIf="idCompany == null"
        #f
        class="input-group mr-2 mt-1"
        style="width: 13rem"
        name="Récepteur"
        filterName="billed"
        [values]="company2"
        (textChanges)="company2NameChange($event)"
      >
      </degineo-textassist-filter>

      <degineo-date-filter #f class="input-group mr-2 mt-1" style="width: 13rem" name="Date d'émission" filterName="date"> </degineo-date-filter>

      <degineo-select-filter #f class="input-group mr-2 mt-1" style="width: 13rem" name="Tous les types" filterName="type" [values]="type">
      </degineo-select-filter>

      <degineo-text-filter #f class="input-group mr-2 mt-1" style="width: 13rem" name="N° de facture ou de commande" filterName="recherche">
      </degineo-text-filter>

      <i class="fas fa-undo text-primary resetToDefault ml-2" (click)="filter.resetToDefault(); InvoiceAdminComponent.page = 1"></i>
    </div>
  </degineo-filter>

  <degineo-my-table
    [rows]="invoices"
    [orderCache]="InvoiceAdminComponent.OrderCaches"
    [builtFiltersOrder]="builtFiltersOrder"
    (orderChange)="orderChange($event)"
    (rowClick)="tableRowClick($event)"
    [pointer]="false"
  >
    <degineo-my-column name="#ID" [disableOrder]="true">
      <td *cv="let r" class="text-right align-middle text-secondary">
        {{ r.invoiceId }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="reference" name="N° de facture">
      <td *cv="let r" class="text-left">
        {{ r.reference }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="DateCreated" name="Date d'émission" [defaultOrder]="OrderBy.DESC">
      <td *cv="let r" class="text-center">
        {{ r.date | date: 'dd/MM/yyyy' }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="type" name="Type">
      <td *cv="let r" class="text-center" [class.text-line-through]="r.cancelled">
        {{ getInvoiceTypeLib(r.idType) }} {{ r.idCancelledInvoice !== 0 ? '(Annule #' + r.idCancelledInvoice + ')' : '' }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="transmitter" name="Emetteur">
      <td *cv="let r" class="text-center">
        {{ r.transmitter }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="receiver" name="Récepteur">
      <td *cv="let r" class="text-center">
        {{ r.receiver }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="totalHT" name="Montant HT">
      <td *cv="let r" class="text-right">{{ r.totalHT | number: '.2':'fr-FR' }}&nbsp;€</td>
    </degineo-my-column>

    <degineo-my-column orderName="totalTTC" name="Montant TTC">
      <td *cv="let r" class="text-right">{{ r.totalTTC | number: '.2':'fr-FR' }}&nbsp;€</td>
    </degineo-my-column>

    <degineo-my-column [disableOrder]="true">
      <td *cv="let r" class="text-center">
        <a href="{{ rpcHost }}invoice/{{ r.fileToken }}">
          <i style="vertical-align: middle" class="fa fa-download"></i>
        </a>
      </td>
    </degineo-my-column>
  </degineo-my-table>

  <div class="row justify-content-center mt-3" *ngIf="!loading">
    <ngb-pagination
      [collectionSize]="count"
      [(page)]="InvoiceAdminComponent.page"
      [pageSize]="nbPerPage"
      [maxSize]="6"
      [rotate]="true"
      (pageChange)="reload()"
      [disabled]="loading"
    ></ngb-pagination>
  </div>
</div>
