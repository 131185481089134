import { Component, OnInit, Input } from '@angular/core';
import { ConsumableData } from '../../providers/consumable.provider';
import { L } from 'ic2-lib';
import { CharacteristicValueType } from 'degineo-common';

@Component({
  selector: 'app-consumable-caracteristiques',
  templateUrl: './consumable-caracteristiques.component.html',
  styleUrls: ['./consumable-caracteristiques.component.scss'],
})
export class ConsumableCaracteristiquesComponent implements OnInit {
  @Input()
  edit: boolean;

  constructor(public data: ConsumableData) {}

  ngOnInit() {
    if (this.data.dto.characs)
      this.data.dto.characs.map((charac) => {
        let data = this.data.dto.consuChara.find((obj) => obj.idCharacteristic == charac.id);
        if (data) {
          if (charac.characType.id === CharacteristicValueType.NUMBER.id) this.data.consumableCaracteristique[charac.id] = data.valueDouble;
          else this.data.consumableCaracteristique[charac.id] = data.valueStr;
        } else this.data.consumableCaracteristique[charac.id] = null;
      });
  }
}
