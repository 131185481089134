import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { BusinessTargetLevel2, ClientBOService } from 'degineo-common';
import { Router } from '@angular/router';
import { ClientListItemBODTO } from 'degineo-common';
import { OrderBy } from 'degineo-common';
import { Filter } from 'degineo-common';
import { FilterOrder, GenericFilter } from 'degineo-common';
import { L } from 'ic2-lib';
import { SlugService } from 'degineo-common';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss'],
})
export class ClientListComponent implements OnInit, AfterViewInit {
  constructor(@Inject(ClientBOService) public clientBOService: ClientBOService, @Inject(SlugService) public slugService: SlugService, private router: Router) {}
  ClientListComponent: typeof ClientListComponent = ClientListComponent;
  clients: ClientListItemBODTO[] = [];
  OrderBy: typeof OrderBy = OrderBy;

  static filtersCache = {};
  filtersDefault = {
    ok: true,
    end: true,
    late: true,
    aucun: true,
  };

  static OrderCaches = {};
  static page: number = 1;

  nbPerPage: number = 10;
  count = 0;
  loading: boolean = true;

  builtFilters: Filter[] = [];
  builtFiltersOrder: FilterOrder[] = [];

  targets: BusinessTargetLevel2[] = BusinessTargetLevel2.values;
  compteFilter: Object[] = [
    { id: 0, title: 'Comptes désactivés' },
    { id: 1, title: 'Comptes activés' },
  ];

  titlePresenter = (obj) => obj.title;
  targetPresenter = (obj: BusinessTargetLevel2) => obj.lib;

  ngOnInit() {}

  ngAfterViewInit() {
    this.reload();
  }

  reload() {
    let genericFilter = new GenericFilter();
    genericFilter.filters = this.builtFilters;
    genericFilter.orderFilters = this.builtFiltersOrder;
    genericFilter.page = ClientListComponent.page;
    genericFilter.nbPerPage = this.nbPerPage;
    console.log('genericFilter', genericFilter);
    this.loading = true;
    this.clientBOService.getClients(genericFilter).subscribe(
      (data) => {
        this.loading = false;
        this.clients = data.data;
        this.count = data.count;
        console.log('data', data);
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }

  newClicked() {
    console.log('newClicked');
    //this.router.navigate(['/printers', 'new']);
  }

  filterChanged(filters: Filter[]) {
    console.log('filterChanged event', filters);
    this.reload();
  }
  orderChange(order) {
    console.log('orderChange', order);
    this.reload();
  }
  tableRowClick(row: ClientListItemBODTO) {
    console.log('tableRowClick', row);
    this.router.navigate(['/clients', this.slugService.string_to_slug(row.companyName), row.id]);
  }
}
