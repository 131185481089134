<div class="container pt-4 pb-4" *ngIf="!loading">
  <div class="row">
    <div class="col">
      <button type="button" class="btn btn-secondary m-1 float-left" (click)="backClicked()"><i class="fas fa-chevron-left"></i> Retour</button>
    </div>
  </div>

  <div class="row">
    <h1 class="mx-auto text-center">{{ invoice.producerName }}</h1>
  </div>
  <div class="row">
    <h6 class="mx-auto text-center mb-5">{{ invoice.reference }}</h6>
  </div>

  <degineo-filter
    class="d-inline-block w-100 mt-4 mb-4"
    #fliter
    [builtFilters]="builtFilters"
    (filterChanged)="filterChanged($event)"
    [cache]="PaymentToDegineoEditComponent.filtersCache"
    [default]="filtersDefault"
  >
    <div class="form-inline">
      <degineo-textassist-filter
        #f
        class="input-group mr-2 mt-1"
        style="width :13rem"
        name="Client"
        filterName="client"
        [values]="clients"
        [presenter]="presenterName"
        (textChanges)="textChanges($event)"
      >
      </degineo-textassist-filter>

      <degineo-date-filter #f class="input-group mr-2 mt-1" style="width :13rem" name="Commandé le" filterName="date"> </degineo-date-filter>

      <degineo-text-filter #f class="input-group mr-2 mt-1" style="width :13rem" name="N° d'article" filterName="recherche"> </degineo-text-filter>

      <i class="fas fa-undo text-primary resetToDefault ml-2" (click)="fliter.resetToDefault(); PaymentToDegineoEditComponent.page = 1"></i>
    </div>
  </degineo-filter>

  <degineo-my-table
    [rows]="invoice.rows"
    [orderCache]="PaymentToDegineoEditComponent.OrderCaches"
    [builtFiltersOrder]="builtFiltersOrder"
    (orderChange)="orderChange($event)"
    [pointer]="false"
  >
    <degineo-my-column name="#ID" [disableOrder]="true">
      <td *cv="let r" class="text-right align-middle text-secondary">
        <a [routerLink]="'/orders/' + r.idOrder">
          {{ r.id }}
        </a>
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="date" name="Commandé le" [defaultOrder]="OrderBy.DESC">
      <td *cv="let r" class="text-center align-middle">
        {{ r.date | date: 'dd/MM/yyyy' }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="client" name="Client de la commande">
      <td *cv="let r" class="text-center align-middle">
        <a [routerLink]="'/clients/' + slugService.string_to_slug(r.companyName) + '/' + r.idClient">
          {{ r.companyName }}
        </a>
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="price" name="Le producteur nous doit TTC">
      <td *cv="let r" class="text-center align-middle">{{ r.price | number: '.2':'fr-FR' }}&nbsp;€</td>
    </degineo-my-column>

    <degineo-my-column orderName="toPaid" name="Ce qui reste à payer TTC">
      <td *cv="let r" class="text-center align-middle">{{ r.toPaid | number: '.2':'fr-FR' }}&nbsp;€</td>
    </degineo-my-column>

    <degineo-my-column name="Montant reçu TTC" [disableOrder]="true">
      <td *cv="let r" class="text-center align-middle text-nowrap">
        <input
          [ngModel]="valueMap.get(r.id)"
          (ngModelChange)="rowValueChange(r.id, $event)"
          class="price form-control d-inline-block"
          style="width: 100px;"
          type="number"
          placeholder="Montant"
          [disabled]="r.paid"
        />&nbsp;€
      </td>
    </degineo-my-column>

    <degineo-my-column name=" " [disableOrder]="true">
      <td *cv="let r" class="text-center align-middle">
        <div *ngIf="!r.paid">
          <button class="btn btn-primary" type="button" (click)="payRowClicked(r.id)" *ngIf="valueMap.get(r.id) == 0 || valueMap.get(r.id) == null">
            Payé
          </button>

          <div class="text-warning d-inline-flex" *ngIf="valueMap.get(r.id) > 0">
            <div class="d-inline-block">En attente de validation</div>
            <div class="ml-2 pointer d-inline-block align-self-center" style="font-size: 16px;" (click)="cancelRowClicked(r.id)">
              <i class="far fa-times-circle text-danger "></i>
            </div>
          </div>
        </div>
        <div class="text-success text-center" *ngIf="r.paid">Payé le {{ r.datePaid | date: 'dd/MM/yyyy' }}</div>
      </td>
    </degineo-my-column>

    <degineo-my-footer>
      <degineo-my-column name="Client de la commande">
        <td *cv="let rows" class="text-center align-middle bg-primary text-light">
          TOTAL
        </td>
      </degineo-my-column>

      <degineo-my-column name="Le producteur nous doit TTC">
        <td *cv="let rows" class="text-center align-middle" style="background: #CCE6FF;">{{ invoice.price | number: '.2':'fr-FR' }}&nbsp;€</td>
      </degineo-my-column>

      <degineo-my-column name="Ce qui reste à payer TTC">
        <td *cv="let rows" class="text-center align-middle" style="background: #CCE6FF;">{{ invoice.toPaid | number: '.2':'fr-FR' }}&nbsp;€</td>
      </degineo-my-column>

      <degineo-my-column name="Montant reçu TTC">
        <td *cv="let rows" class="text-center align-middle  text-nowrap" style="background: #CCE6FF;">
          <input
            [(ngModel)]="valueTotal"
            class="price form-control d-inline-block"
            style="width: 100px;"
            type="number"
            placeholder="Montant"
            (ngModelChange)="totalValueChange($event)"
            [disabled]="summTotal == 0"
          />
          &nbsp;€
        </td>
      </degineo-my-column>

      <degineo-my-column name=" ">
        <td *cv="let rows" class="text-center align-middle" style="min-width: 175px; background: #CCE6FF;">
          <button class="btn btn-primary" type="button" (click)="payTotalClicked()" *ngIf="valueTotal != summTotal" [disabled]="summTotal == 0">
            Tout est payé
          </button>
          <div class="text-warning d-inline-flex" *ngIf="valueTotal == summTotal">
            <div class="d-inline-block">En attente de validation</div>
            <div class="ml-2 pointer d-inline-block align-self-center" style="font-size: 16px;" (click)="cancelTotalClicked()">
              <i class="far fa-times-circle text-danger "></i>
            </div>
          </div>
        </td>
      </degineo-my-column>
    </degineo-my-footer>
  </degineo-my-table>

  <div class="row mt-4">
    <div class="col d-flex justify-content-end">
      <select style="width: 240px;" class="form-control d-inline-block" [(ngModel)]="paymentType">
        <option [ngValue]="null">
          -- Type de paiement reçu --
        </option>
        <option *ngFor="let o of paymentTypes" [ngValue]="o">
          {{ o.lib }}
        </option>
      </select>
    </div>
  </div>
  <div class="row justify-content-end mt-3">
    <div class="col-auto pr-md-1 mt-1">
      <button class="btn btn-secondary" style="min-width: 240px;" type="button" (click)="cancelTotalClicked()">
        Annuler le paiement
      </button>
    </div>
    <div class="col-auto pl-md-1 mt-1">
      <button
        class="btn btn-success"
        style="min-width: 240px;"
        type="button"
        (click)="open(modalConfirm)"
        [disabled]="!valueTotal || valueTotal == 0 || paymentType == null"
      >
        Valider le paiement
      </button>
    </div>
  </div>
</div>

<ng-template #modalConfirm let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation de paiement reçu</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Vous confirmé avoir reçu un paiement de <span class="fw-500">{{ valueTotal | number: '.2':'fr-FR' }}&nbsp;€</span>
    <br />
    Via <span class="fw-500">{{ paymentType.lib }}</span>
    <br />
    De la part du producteur <span class="fw-500">{{ invoice.producerName }}</span>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="modal.dismiss('cancel click'); cancelModalClicked()">Annuler</button>
    <button ngbAutofocus type="button" class="btn btn-outline-success" (click)="modal.close('Ok click'); saveModalClicked()">Valider</button>
  </div>
</ng-template>
