<form class="h-100" #f="ngForm" [formGroup]="form" [class.show-errors]="f.submitted && !form.valid" (ngSubmit)="validerPaiementCB()">
  <div class="container-fluid pb-4 d-flex h-100 flex-column">
    <div class="row">
      <div class="col text-right fs-4">
        <button type="button" class="close" aria-label="Close" (click)="paymentStep2.closePopup('Cross click')">
          <i class="fas fa-times modal-cross"></i>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="offset-1 col-10">
        <div class="container-small newcc rounded pt-4 pb-4" [class.active]="idCardSelected === 0">
          <div class="row">
            <div class="col">
              <label>Numéro de carte</label>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col">
              <div class="fakeinput" #cardNumber></div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col">
              <label>Nom sur la carte</label>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col">
              <input type="text" class="form-control" formControlName="name" />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <label>Date expiration</label>
            </div>
            <div class="col-6">
              <label>Code de sécurité</label>
            </div>
            <div class="col-6 mt-1">
              <div class="fakeinput" #cardExpiry></div>
            </div>
            <div class="col-6 mt-1">
              <div class="fakeinput" style="width: 95px;" #cardCvc></div>
              <img src="assets/payment/crypto.png" class="ml-2 align-top" style="height: 40px;" />
            </div>
          </div>

          <div class="row mt-3">
            <div class="col text-center">
              <label><input type="checkbox" formControlName="saveNewCard" /> <span class="no-select ml-2">Mémoriser cette carte</span></label>
            </div>
          </div>
          <div class="row mt-3" *ngIf="error && idCardSelected === 0">
            <div class="col">
              <div id="card-errors" class="alert alert-danger mt-2" role="alert">{{ error }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4 align-items-center ml-2 mr-2 fs-2" [class.pr-4]="cards.length > 3">
      <div class="col-5 offset-1 fw-700">
        Vos cartes de paiement
      </div>
      <div class="col-3 fw-700">
        Nom sur la carte
      </div>
      <div class="col-3 fw-700">
        Date d'expiration
      </div>
    </div>
    <div [class.overflow-auto]="cards.length > 3" class="flex-fill">
      <div class="row mt-2 mb-2 p-2" *ngIf="cards.length === 0">
        <div class="col text-center text-muted card-row py-2">
          Disponible si vous sauvegardez une de vos cartes.
        </div>
      </div>
      <div
        class="row align-items-center pointer pt-2 pb-2 ml-2 mr-2 fs-2 rounded card-row mt-2"
        [class.active]="idCardSelected === card.id"
        *ngFor="let card of cards"
        (click)="select(card); check($event, card)"
      >
        <div class="col-1">
          <input type="radio" name="cb" id="cb-{{ card.id }}" [checked]="idCardSelected === card.id" />
          <label>&nbsp;</label>
        </div>
        <div class="col-5">
          <img src="assets/payment/cb.svg" style="width: 2rem" class="mr-2" />
          <span class="fw-500">{{ card.brand }}</span>
          <span class="text-secondary ml-2">***-{{ card.cardLast4Digits }}</span>
        </div>
        <div class="col-3">
          {{ card.cardOwner }}
        </div>
        <div class="col-2">{{ card.expMonth | number: '2.0' }}/{{ card.expYear }}</div>
        <div class="col-1">
          <i class="far fa-times-circle text-secondary hover-danger pointer" ngbTooltip="Retirer cette carte"></i>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col text-center">
        <button type="submit" [disabled]="loading" class="btn btn-success w-75" [disabled]="disabled()">
          <img src="assets/payment/lock-solid.svg" class="mr-1" style="height: 1.5rem" /> Payer maintenant
          <i class="fas fa-circle-notch spinning" *ngIf="loading"></i>
        </button>
      </div>
    </div>
  </div>
</form>
