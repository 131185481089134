<h6 class="text-center mb-4">Producteurs associées</h6>

<ng-container *ngFor="let producer of data.dto.assocCompany">
  <a [routerLink]="'/producers/edit/'+ slugService.string_to_slug(producer.name) + '/' + producer.id + '/infos'"
    class="d-block text-left">
    {{ producer.name }} - <span class="fw-300">{{ producer.zipCode }} {{producer.city}}</span>
  </a>
</ng-container>

<div *ngIf="data.dto.assocCompany">
  <div class="text-center" *ngIf="data.dto.assocCompany.length === 0">Aucun producteur associé</div>
</div>
