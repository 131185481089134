import { Injectable } from '@angular/core';
import { PrinterBODTO, PrinterTechnology } from 'degineo-common';
import { Subject } from 'rxjs';

@Injectable()
export class PrinterData {
  public dto: PrinterBODTO = null;

  public brandList: string[] = null;
  public technologyList: PrinterTechnology[] = PrinterTechnology.values;

  public file: File = null;
  public numberToForceReload = 0;
  public reloadConsumableBrands: Subject<void> = new Subject<void>();

  public constructor() {}

  clear() {
    this.dto = null;
    this.brandList = null;
    this.file = null;
  }
}
