<div class="text-center fw-500">Adresse de livraison</div>
<div class="row mt-4">
  <div class="col" *ngIf="data.dto.shippingAddress">
    {{ data.dto.shippingAddress.line1 }}
    <ng-container *ngIf="data.dto.shippingAddress.line2"> <br />{{ data.dto.shippingAddress.line2 }} </ng-container>
    <ng-container *ngIf="data.dto.shippingAddress.line3"> <br />{{ data.dto.shippingAddress.line3 }} </ng-container>
  </div>
</div>
<div class="row">
  <div class="col" *ngIf="data.dto.shippingAddress">{{ data.dto.shippingAddress.zipCode }} {{ data.dto.shippingAddress.city }}</div>
</div>
<div class="row">
  <div class="col" *ngIf="data.dto.shippingAddress">
    {{ data.dto.shippingAddress.country | uppercase }}
  </div>
</div>
