<div id="content" [ngStyle]="{ 'padding-left': show ? '260px' : '0px', 'padding-top': show ? '0px' : '50px' }">
  <div *ngIf="mobile" id="nav-icon3" [ngClass]="{ open: show }" (click)="show = !show">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>

  <nav [ngStyle]="{ height: show ? '0px' : '50px' }" id="navBar" class="overflow-hidden topnav w-100 fixed-top">
    <div class="row p-1 align-items-baseline">
      <div class="col ml-5 mt-1">
        <h3 class="pointer" (click)="show = !show">Degineo Backoffice</h3>
      </div>
    </div>
  </nav>

  <nav [ngStyle]="{ left: show ? '0px' : '-260px' }" class="sidenav fixed-top">
    <div class="sidebar-header mt-5 mt-md-2 p-2 text-center">
      <img src="assets/images/Logo_Degineo_Back_Office.svg" width="210px" alt="logo-degineo-back-office" />
    </div>

    <ul class="list-unstyled components mt-3" (click)="clicked()">
      <li routerLinkActive="active">
        <a routerLink="/dashboard">
          <i class="fas fa-tachometer-alt"></i>
          Tableau de bord
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/clients">
          <i class="fa fa-user"></i>
          Clients
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/orders">
          <i class="fas fa-shopping-cart"></i>
          Commandes
          <span class="float-right">
            <degineo-my-badge bgColor="danger" class="d-inline-block" *ngIf="BOData.nbOrderRed > 0"> {{ BOData.nbOrderRed }}</degineo-my-badge>
            <degineo-my-badge bgColor="warning" class="d-inline-block ml-2" *ngIf="BOData.nbOrderOrange > 0"> {{ BOData.nbOrderOrange }}</degineo-my-badge>
          </span>
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/producers">
          <i class="fas fa-user-tie"></i>
          Producteurs 3D
          <span class="float-right">
            <degineo-my-badge bgColor="warning" class="d-inline-block" *ngIf="BOData.nbProducerWarning > 0"> {{ BOData.nbProducerWarning }}</degineo-my-badge>
          </span>
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/producersPage">
          <i class="fas fa-address-card"></i>
          Pages Degineo
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/paymentToP">
          <i class="far fa-credit-card"></i>
          Paiements vers Prod. 3D
          <span class="float-right">
            <degineo-my-badge bgColor="warning" class="d-inline-block" *ngIf="BOData.nbBillToProd > 0"> {{ BOData.nbBillToProd }}</degineo-my-badge>
          </span>
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/paymentToD">
          <i class="fas fa-credit-card"></i>
          Paiements vers Degineo
          <span class="float-right">
            <degineo-my-badge bgColor="warning" class="d-inline-block" *ngIf="BOData.nbBillToDegineo > 0"> {{ BOData.nbBillToDegineo }}</degineo-my-badge>
          </span>
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/invoices">
          <i class="fa fa-briefcase"></i>
          Facturation
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/printers">
          <i class="fas fa-print"></i>
          Imprimantes 3D
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/consumables">
          <i class="fas fa-tint"></i>
          Consommables
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/materialTypeCharacteristics">
          <i class="fas fa-sliders-h"></i>
          Caractéristiques matières
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/finitions">
          <i class="fas fa-blender"></i>
          Finitions
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/articleType">
          <i class="fas fa-cubes"></i>
          Types articles
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/subscription">
          <i class="fas fa-star"></i>
          Réglages des abonnements
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/codenaf">
          <i class="fas fa-theater-masks"></i>
          Réglages des codes naf
        </a>
      </li>
      <li routerLinkActive="active">
        <a routerLink="/fixtures">
          <i class="far fa-clone"></i>
          Fixtures
        </a>
      </li>
      <li (click)="logout()" class="mt-4">
        <a class="pointer text-warning">
          <i class="fas fa-power-off"></i>
          Déconnexion
        </a>
      </li>
    </ul>
  </nav>
  <div (click)="clicked()">
    <router-outlet></router-outlet>
  </div>
</div>
