<div class="container-fluid">
  <div class="row" *ngIf="orderService.idProducer !== 0">
    <div class="col text-center">
      <img src="{{ rpcHost }}companylogo/{{ orderService.infos.logoToken }}/logo.png?size=large" alt="{{ orderService.infos.name }}" />
    </div>
  </div>
  <div class="row" [class.mt-5]="orderService.idProducer !== 0" *ngIf="!showMessage">
    <div class="col">
      <app-quantity-choice-step
        [dentaire]="orderService.dentaire"
        [quantity]="orderService.quantity"
        [modeDevis]="orderService.modeDevis"
        [shouldShowSuivant]="orderService.noMarginProducerSet"
        (quantityChanged)="orderService.quantityChanged($event)"
        [unitPriceHT]="orderService.price"
        [nextAvailable]="orderService.canNextQuantityStep()"
        [date]="orderService.wishedDeliveryDate"
        (dateChanged)="orderService.deliveryDateChanged($event)"
        [loading]="orderService.loading"
        (next)="next()"
        (previous)="orderService.previous()"
      >
      </app-quantity-choice-step>
    </div>
  </div>
  <div class="row" [class.mt-5]="orderService.idProducer !== 0" *ngIf="showMessage">
    <div class="col text-center">
      <h6 style="line-height: 1.5">
        Votre demande d'impression 3D doit faire l'objet d'une étude plus approfondie.<br />
        {{ orderService.infos.name }} va créer un devis que vous retrouverez d’ici quelques jours dans votre espace Devis.<br />
        Si {{ orderService.infos.name }} a un besoin de complément d'information, il vous contactera au
        {{ authService.userBundle.du.phoneNumber | mask: '00.00.00.00.00' }}.
      </h6>
    </div>
  </div>
  <div class="row mt-5" *ngIf="showMessage">
    <div class="col-12 col-md-6 mt-3 mt-sm-0 text-md-right order-2 order-md-1">
      <button type="button" class="btn btn-secondary btn-allonge w-100 w-sm-200px" (click)="previous()"><i class="fas fa-chevron-left"></i> Précédent</button>
    </div>
    <div class="col-12 col-md-6 order-1 order-md-2">
      <button class="btn btn-success w-100 w-sm-200px pl-1 pr-1" [disabled]="orderService.loading" (click)="next()">
        <i class="fas fa-paper-plane"></i> Envoyer la demande <i class="fas fa-spinner spinning ml-2" *ngIf="orderService.loading"></i>
      </button>
    </div>
  </div>
  <div class="row" *ngIf="orderService.error">
    <div class="col">
      <div class="alert alert-danger">
        <ul>
          <li>Une erreur est survenue : {{ orderService.error }}</li>
        </ul>
      </div>
    </div>
  </div>
</div>
