import { Component, OnInit, Input, Inject } from '@angular/core';
import { ProducerPrinterListItemBODTO, SlugService } from 'degineo-common';
import { ProducerBOService } from 'degineo-common';
import { L } from 'ic2-lib';
import { ProducerData } from '../../../../providers/producer.provider';
import { BOData } from '../../../../../../provider/bo.provider';

@Component({
  selector: 'app-producer-printer-consumable-info',
  templateUrl: './producer-printer-consumable-info.component.html',
  styleUrls: ['./producer-printer-consumable-info.component.scss'],
})
export class ProducerPrinterConsumableInfoComponent implements OnInit {
  show: boolean = false;
  edit: boolean = false;
  newPrice: number;

  disabled: boolean = true;

  @Input()
  ppc: ProducerPrinterListItemBODTO;

  constructor(
    @Inject(ProducerBOService) public producerBOService: ProducerBOService,
    public data: ProducerData,
    public BOData: BOData,
    public slugService: SlugService
  ) {}

  ngOnInit() {
    this.newPrice = this.ppc.defaultProductionCost;

    this.checkDisabled();
  }

  checkDisabled() {
    this.disabled = true;
    if (this.ppc.checked) {
      this.disabled = false;
    } else {
      //Check si au moin 1 consommable dispo et un prix
      this.ppc.consumables.forEach((consumable) => {
        if (consumable.checked) this.disabled = false;
      });
      if (this.ppc.currentProductionCost == 0) this.disabled = true;
    }
  }

  checked(value) {
    //if (!this.disabled)
    this.producerBOService.updateProducerPrinterChecked(this.ppc.idProducerPrinter, value).subscribe(
      (data) => {
        this.ppc.checked = data;
        this.data.fetchNbWarn(this.data.dto.producer.id);
        this.BOData.fetchNbProducerWarn();
        this.checkDisabled();
      },
      (err) => {
        L.e(err);
      }
    );
  }

  cancelClicked() {
    this.newPrice = this.ppc.defaultProductionCost;
    this.edit = false;
  }

  saveClicked() {
    this.producerBOService.updateProducerPrinterPrice(this.ppc.idProducerPrinter, this.newPrice).subscribe(
      (data) => {
        console.log('data', data);
        this.ppc.defaultProductionCost = this.newPrice;
        if (this.ppc.currentProductionCost == 0) this.ppc.currentProductionCost = this.newPrice;
        this.edit = false;
        this.checkDisabled();
      },
      (err) => {
        L.e(err);
      }
    );
  }

  toggle() {
    this.show = !this.show;
  }
}
