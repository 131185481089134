import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Filter, FilterOrder, Finish, FinishBODTO, FinishBOService, GenericFilter, MaterialTypeService, OrderBy, PrinterTechnology } from 'degineo-common';
import { environment } from 'environments/environment';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-finish-list',
  templateUrl: './finish-list.component.html',
  styleUrls: ['./finish-list.component.scss'],
})
export class FinishListComponent implements OnInit {
  FinishListComponent: typeof FinishListComponent = FinishListComponent;
  finish: Finish[] = [];
  OrderBy: typeof OrderBy = OrderBy;

  static filtersCache = {};
  filtersDefault = {};
  static OrderCaches = {};
  static page: number = 1;

  nbPerPage: number = 10;
  count = 0;
  loading: boolean = true;

  builtFilters: Filter[] = [];
  builtFiltersOrder: FilterOrder[] = [];
  rpcHost: string = environment.rpcHost;

  presenterName = (obj) => obj;

  constructor(public finishBOService: FinishBOService, private router: Router) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.reload();
  }

  reload() {
    let genericFilter = new GenericFilter();
    genericFilter.filters = this.builtFilters;
    genericFilter.orderFilters = this.builtFiltersOrder;
    genericFilter.page = FinishListComponent.page;
    genericFilter.nbPerPage = this.nbPerPage;
    console.log('genericFilter', genericFilter);
    this.loading = true;
    this.finishBOService.getFinish(genericFilter).subscribe(
      (data) => {
        this.finish = data.data;
        this.count = data.count;
        this.loading = false;
        console.log('data', data);
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }

  newClicked() {
    this.router.navigate(['/finition', 'new']);
  }

  filterChanged(filters: Filter[]) {
    console.log('filterChanged event', filters);
    this.reload();
  }
  orderChange(order) {
    console.log('orderChange', order);
    this.reload();
  }

  tableRowClick(row: Finish) {
    console.log('tableRowClick', row);
    this.router.navigate(['/finition', 'view', row.id]);
  }
}
