import { Component, OnInit, Inject } from '@angular/core';
import { OrderData } from '../../providers/order.provider';
import { SlugService } from 'degineo-common';

@Component({
  selector: 'app-order-view-client',
  templateUrl: './order-view-client.component.html',
  styleUrls: ['./order-view-client.component.scss'],
})
export class OrderViewClientComponent implements OnInit {
  constructor(public data: OrderData, @Inject(SlugService) public slugService: SlugService) {}

  ngOnInit() {}
}
