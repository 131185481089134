import { Component, OnInit, Inject } from '@angular/core';
import { environment } from 'environments/environment';
import { ClientProducerAssociationService, CPADTO } from 'degineo-common';
import { L } from 'ic2-lib';
import { CpaService } from '../../front-layout/cpa.service';
import { NotificationService } from '../../front-layout/notification.service';

@Component({
  selector: 'app-my-producers',
  templateUrl: './my-producers.component.html',
  styleUrls: ['./my-producers.component.scss'],
})
export class MyProducersComponent implements OnInit {
  rpcHost: string = environment.rpcHost;
  producers: CPADTO[] = null;
  loading: boolean = false;

  constructor(
    @Inject(ClientProducerAssociationService) private clientProducerAssociationService: ClientProducerAssociationService,
    private cpaService: CpaService,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.load();
  }

  load() {
    this.clientProducerAssociationService.getClientProducerAssociations().subscribe(
      (data) => {
        this.producers = data;
        this.loading = false;
      },
      (error) => {
        L.e(error);
      }
    );
  }

  accept(producer: CPADTO) {
    if (this.loading) return;
    this.loading = true;
    this.clientProducerAssociationService.acceptCPA(producer.id).subscribe(
      (data) => {
        this.load();
        this.cpaService.reload();
        this.notificationService.reload();
      },
      (error) => {
        L.e(error);
        this.loading = false;
      }
    );
  }

  refuse(producer: CPADTO) {
    if (this.loading) return;
    this.loading = true;
    this.clientProducerAssociationService.refuseCPA(producer.id).subscribe(
      (data) => {
        this.load();
        this.cpaService.reload();
        this.notificationService.reload();
      },
      (error) => {
        L.e(error);
        this.loading = false;
      }
    );
  }

  remove(producer: CPADTO) {
    if (this.loading) return;
    this.loading = true;
    this.clientProducerAssociationService.removeCPA(producer.id).subscribe(
      (data) => {
        this.load();
        this.cpaService.reload();
      },
      (error) => {
        L.e(error);
        this.loading = false;
      }
    );
  }
}
