<div class="row" *ngIf="!edit && data.dto">
  <div class="col-12 col-md-auto text-center">
    <img
      class="rounded"
      alt="Logo de la finition"
      src="{{ rpcHost }}finish/{{ data.dto.finish.id }}/{{ data.dto.finish.imageFileName }}"
      style="width: 140px"
    />
  </div>
  <div class="col-12 col-md-auto">
    <div class="row">
      <div class="col">
        <div class="fw-500" style="font-size: 1.4rem">
          {{ data.dto.finish.name }}
          <span class="fw-300">({{ data.dto.finish.description }})</span>
        </div>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col text-secondary">#ID&nbsp;: {{ data.dto.finish.id }}</div>
    </div>
  </div>
</div>

<div class="row" *ngIf="edit">
  <div class="col-12 col-md-auto">
    <div class="row">
      <div class="col text-center">
        <img
          class="rounded"
          style="width: 140px"
          *ngIf="!data.file"
          alt="Logo de la finition"
          src="{{ rpcHost }}finish/{{ data.dto.finish.id }}/{{ data.dto.finish.imageFileName }}"
        />
        <img class="rounded" *ngIf="data.file" alt="Logo de la finition" [src]="imgURL" style="width: 140px" />
        <span class="text-danger d-block" *ngIf="errorFile">{{ errorFile }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <input type="file" id="file" style="display: none" accept="image/png" #fileInput (change)="handleFileInput($event)" />
        <button class="btn d-block ml-auto mr-auto p-0 mt-1 text-primary" style="font-size: 0.9rem; letter-spacing: normal" (click)="fileInput.click()">
          Changer l'image
        </button>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-auto mt-3">
    <div class="row">
      <div class="col-6">
        <input style="width: 282px" [(ngModel)]="data.dto.finish.name" class="form-control" type="text" placeholder="Nom de la finition" required />
      </div>
      <div class="col-6">
        <div class="fw-500 d-inline-block">
          <input
            style="width: 282px"
            [(ngModel)]="data.dto.finish.description"
            class="form-control"
            type="text"
            placeholder="Description de la finition"
            required
          />
        </div>
      </div>
    </div>
  </div>
</div>
