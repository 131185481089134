import { Component, OnInit, Inject, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { OrderService } from '../../order.service';
import { UploadedFile } from '../../UploadedFile';
import { AllowedModelExtensionsService } from 'degineo-common';

@Component({
  selector: 'app-upload-step',
  templateUrl: './upload-step.component.html',
  styleUrls: ['./upload-step.component.scss'],
})
export class UploadStepComponent implements OnInit {
  static readonly maxFileSizeMB: number = 120;
  @Input()
  multi: boolean = true;
  @Input()
  files: UploadedFile[] = [];
  @Input()
  nextAvailable: boolean = false;
  @Output()
  next: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  previous: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  fileAdded: EventEmitter<File> = new EventEmitter<File>();
  @Output()
  fileDeleted: EventEmitter<UploadedFile> = new EventEmitter<UploadedFile>();
  dragging: boolean = false;
  @ViewChild('fileinput', { static: true })
  fileinput: ElementRef;

  constructor(@Inject(AllowedModelExtensionsService) public allowedModelExtensionsService: AllowedModelExtensionsService) {}

  ngOnInit() {}

  deleteFile(file: UploadedFile) {
    this.fileDeleted.emit(file);
  }

  emitUpload(file: File) {
    let mb = file.size / 1024 / 1024;
    if (mb > UploadStepComponent.maxFileSizeMB) return;
    this.fileAdded.emit(file);
  }

  fileUpload($event) {
    //console.log($event.target.files);
    for (let f of $event.target.files) {
      this.emitUpload(f);
    }
    this.fileinput.nativeElement.value = '';
  }

  drop(ev: DragEvent) {
    //console.log(ev);
    ev.preventDefault();
    this.dragging = false;

    if (ev.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (var i = 0; i < ev.dataTransfer.items.length; i++) {
        // If dropped items aren't files, reject them
        if (ev.dataTransfer.items[i].kind === 'file') {
          var file = ev.dataTransfer.items[i].getAsFile();
          this.emitUpload(file);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (var i = 0; i < ev.dataTransfer.files.length; i++) {
        this.emitUpload(ev.dataTransfer.files[i]);
      }
    }
  }
  dragover(ev: DragEvent) {
    //console.log('File(s) in drop zone', ev);
    ev.preventDefault();
  }
  dragexit(ev: Event) {
    //console.log('exit', ev);
    this.dragging = false;
  }
  dragenter(ev: DragEvent) {
    //console.log('dragenter', ev);
    this.dragging = true;
  }
}
