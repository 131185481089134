<div class="container-fluid">
  <div *ngIf="!loading" class="row">
    <div class="col-12 offset-sm-0 col-sm-6 offset-xl-2 col-xl-4 pr-md-4">
      <app-print-settings-name [name]="printDataService.name" (nameChanged)="nameChanged($event)" class="d-block alert shadow-2 p-4"></app-print-settings-name>
      <app-print-settings-size
        *ngIf="!printDataService.dentaire"
        [defaultSizeDTO]="printDataService.defaultSizeDTO"
        [printerSizes]="printDataService.printerSizes"
        [alreadySelectedSizeDTO]="printDataService.size"
        (sizeChanged)="sizeChanged($event)"
        class="d-block alert shadow-2 mt-4 p-4"
      >
      </app-print-settings-size>
      <app-print-settings-material
        *ngIf="!printDataService.dentaire"
        [availableMaterials]="printDataService.availableMaterials"
        (materialSelected)="materialChanged($event)"
        [selectedMaterial]="printDataService.selectedMaterial"
        class="d-block alert shadow-2 mt-4 p-4"
      ></app-print-settings-material>
      <app-print-settings-color
        *ngIf="!printDataService.dentaire"
        [availableColors]="printDataService.availableColors"
        [selectedColor]="printDataService.selectedColor"
        [isMulticolor]="isMulticolor()"
        (colorChanged)="colorChanged($event)"
        (fileAdded)="mtlFileAdded($event)"
        class="d-block alert shadow-2 mt-4 p-4"
      ></app-print-settings-color>
      <app-print-settings-finish
        *ngIf="!printDataService.dentaire"
        [availableFinish]="printDataService.availableFinish"
        [selectedFinish]="printDataService.selectedFinish"
        (finishChanged)="finishChanged($event)"
        class="d-block alert shadow-2 mt-4 p-4"
      ></app-print-settings-finish>
      <app-print-settings-comment
        (commentChanged)="commentChanged($event)"
        [comment]="printDataService.comment"
        class="d-block alert shadow-2 mt-4 p-4"
      ></app-print-settings-comment>
    </div>
    <div class="col-12 col-sm-6 col-xl-4 pl-md-4 mt-5 mt-sm-0">
      <degineo-preview-modele></degineo-preview-modele>
      <app-print-settings-printer-technology
        [selectedMaterial]="printDataService.selectedMaterial"
        class="d-block alert alert-dark mt-5 p-4"
      ></app-print-settings-printer-technology>
      <app-print-settings-characteristics
        *ngIf="!printDataService.dentaire"
        [selectedMaterial]="printDataService.selectedMaterial"
        [parentMaterial]="printDataService.parentMaterial"
        [characs]="printDataService.characs"
        class="d-block alert alert-dark mt-4 p-4"
      ></app-print-settings-characteristics>
    </div>
  </div>
  <div *ngIf="!loading" class="row mt-5 mb-5">
    <div class="col-12 col-md-6 mt-3 mt-sm-0 text-md-right order-2 order-md-1">
      <button type="button" class="btn btn-secondary btn-allonge w-100 w-sm-200px" (click)="previous()"><i class="fas fa-chevron-left"></i> Précédent</button>
    </div>
    <div class="col-12 col-md-6 order-1 order-md-2">
      <button type="button" class="btn btn-success btn-allonge w-100 w-sm-200px" [disabled]="!canNextPrintSettingStep()" (click)="next()">
        Suivant <i class="fas fa-chevron-right"></i>
      </button>
    </div>
  </div>
  <div *ngIf="loading" class="row">Chargement...<i class="fas fa-spinner spinning"></i></div>
</div>
