import { Injectable } from '@angular/core';
import { ConsumableBODTO, MaterialTypeNEW, ArticleTypeNEW, ColorNEW, PrinterTechnology } from 'degineo-common';

@Injectable()
export class ConsumableData {
  public dto: ConsumableBODTO = null;

  public brandList: Array<string> = null;
  public materialList: MaterialTypeNEW[] = null;
  public technologyList: PrinterTechnology[] = PrinterTechnology.values;
  public articleTypeList: Array<ArticleTypeNEW> = null;
  public colorList: Array<ColorNEW> = null;

  public consumableCaracteristique: any = {};

  public constructor() {}

  clear() {
    this.dto = null;
    this.brandList = null;
    this.materialList = null;
    this.articleTypeList = null;
    this.colorList = null;
    this.consumableCaracteristique = {};
  }
}
