import { Component, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { ArticleTypeBODTO, ArticleTypeBusinessTarget, ArticleTypeNEW, BusinessTarget, BusinessTargetLevel2, BusinessTargets } from 'degineo-common';
import { ArticleTypeBOService } from 'degineo-common';
import { ArticleTypeData } from '../../providers/articleType.provider';
import { L } from 'ic2-lib';
import { SlugService } from 'degineo-common';

@Component({
  selector: 'app-article-type-new',
  templateUrl: './article-type-new.component.html',
  styleUrls: ['./article-type-new.component.scss'],
})
export class ArticleTypeNewComponent implements OnInit {
  articleType: ArticleTypeNEW = new ArticleTypeNEW();
  dto: ArticleTypeBODTO = new ArticleTypeBODTO();

  presenter = (obj) => obj.title;

  @Output()
  cancel: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  reload: EventEmitter<any> = new EventEmitter<any>();

  file: File = null;
  progressPercent: number = null;
  errorFile: string;

  targets: BusinessTarget[] = BusinessTargets;
  opened: BusinessTarget[] = [];

  constructor(
    @Inject(ArticleTypeBOService) private articleTypeBOService: ArticleTypeBOService,
    @Inject(SlugService) private slugService: SlugService,
    public data: ArticleTypeData
  ) {
    this.dto.targets = [];
    this.dto.at = this.articleType;
  }

  ngOnInit() {}

  saveClicked() {
    if (this.articleType.averageMatterQuantity < 0) this.articleType.averageMatterQuantity = 0;

    let builder = this.articleTypeBOService.add(this.dto);
    if (this.file !== null) builder.withFile(this.file);
    builder.subscribeWithProgress(
      (data) => {
        this.progressPercent = null;
        this.file = null;
        this.reload.emit();
        this.cancel.emit();
      },
      (loaded, total) => {
        this.progressPercent = +((loaded / total) * 100).toFixed(0);
      },
      (err) => {
        L.e(err);
        this.progressPercent = null;
        this.errorFile = err.message;
      }
    );
  }

  cancelClicked() {
    this.cancel.emit();
  }

  handleFileInput(event) {
    let files: FileList = event.target.files;
    var fr = new FileReader();
    this.errorFile = null;
    fr.onload = (e) => {
      // file is loaded
      var img = new Image();
      img.onload = () => {
        console.log(img.width + ' x ' + img.height);
        let dimensionsExact = { w: 420, h: 330 };
        if (img.width != dimensionsExact.w || img.height != dimensionsExact.h) {
          //dimensions minis
          this.errorFile = 'Votre fichier doit faire ' + dimensionsExact.w + 'x' + dimensionsExact.h;
        } else {
          this.file = files[0];
          console.log(this.file, this.file.size / 1024 / 1024 + 'MB', this.file.name.split('.').pop());
        }
      };
      img.src = (e.target as any).result; // is the data URL because called with readAsDataURL
    };
    fr.readAsDataURL(files[0]);
  }
  changeParent(target: BusinessTarget) {
    if (this.hasAll(target)) {
      //deselect every child
      for (let t of target.children) {
        this.changeChild(t);
      }
    } else {
      //select any missing child
      for (let t of target.children) {
        if (!this.has(t)) this.changeChild(t);
      }
    }
  }

  changeChild(target: BusinessTargetLevel2) {
    if (this.has(target))
      this.dto.targets.splice(
        this.dto.targets.findIndex((b) => b.target.id === target.id),
        1
      );
    else {
      let pbt = new ArticleTypeBusinessTarget();
      pbt.target = target;
      this.dto.targets.push(pbt);
    }
  }

  toggleBT(code: BusinessTarget) {
    if (this.isOpen(code)) this.opened.splice(this.opened.indexOf(this.opened.find((c) => c.level1.id === code.level1.id)), 1);
    else this.opened.push(code);
  }

  isOpen(code: BusinessTarget) {
    return this.opened.find((c) => c.level1.id === code.level1.id) !== undefined;
  }

  hasAny(target: BusinessTarget) {
    return this.dto.targets.find((bt) => target.children.find((b) => b.id === bt.target.id) !== undefined) !== undefined;
  }
  hasAll(target: BusinessTarget) {
    for (const child of target.children) {
      if (this.dto.targets.find((bt) => bt.target.id === child.id) === undefined) return false;
    }
    return true;
  }
  has(target: BusinessTargetLevel2) {
    return this.dto.targets.find((bt) => bt.target.id === target.id) !== undefined;
  }
}
