<h6 class="text-center mb-4">Taille réelle du bac d'impression</h6>

<div *ngIf="!edit">
  <div class="row text-center">
    <div class="col">X : {{ data.dto.printer.sizeXmm }} mm</div>
    <div class="col">Y : {{ data.dto.printer.sizeYmm }} mm</div>
    <div class="col">Z : {{ data.dto.printer.sizeZmm }} mm</div>
  </div>
  <div class="mt-4">Taille minimum d'impression&nbsp;: {{ data.dto.printer.minimalPrintingSize }} mm</div>
  <div class="mt-2">Vitesse moyenne d'impression&nbsp;: {{ data.dto.printer.printerVerticalSpeed }} mm/h</div>
  <div class="mt-2">
    Supporte l'impression multicolore&nbsp;:
    <ui-switch
      style="vertical-align: sub"
      size="small"
      color="#00c462"
      defaultBgColor="#d82929"
      [disabled]="true"
      [(ngModel)]="data.dto.printer.allowMulticolor"
    >
    </ui-switch>
  </div>
</div>

<div *ngIf="edit">
  <div class="row text-center">
    <div class="col mb-2">
      X : <input class="form-control d-inline-block" style="width: 80px" type="number" [(ngModel)]="data.dto.printer.sizeXmm" />
      mm
    </div>
    <div class="col mb-2">
      Y : <input class="form-control d-inline-block" style="width: 80px" type="number" [(ngModel)]="data.dto.printer.sizeYmm" />
      mm
    </div>
    <div class="col mb-2">
      Z : <input class="form-control d-inline-block" style="width: 80px" type="number" [(ngModel)]="data.dto.printer.sizeZmm" />
      mm
    </div>
  </div>
  <div class="mt-4">
    Taille minimum d'impression&nbsp;:
    <input class="form-control d-inline-block" style="width: 80px" type="number" [(ngModel)]="data.dto.printer.minimalPrintingSize" />
    mm
  </div>
  <div class="mt-2">
    Vitesse moyenne d'impression&nbsp;:
    <input class="form-control d-inline-block" style="width: 80px" type="number" [(ngModel)]="data.dto.printer.printerVerticalSpeed" required />
    mm/h
  </div>
  <div class="mt-2">
    Supporte l'impression multicolore&nbsp;:
    <div class="ml-2 form-check form-check-inline">
      <ui-switch style="vertical-align: sub" size="small" color="#00c462" defaultBgColor="#d82929" [(ngModel)]="data.dto.printer.allowMulticolor"> </ui-switch>
    </div>
  </div>
</div>
