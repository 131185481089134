<div class="card light-opacity">
  <div (click)="toggle()" class="card-header pointer" style="font-size: 18px">
    <i *ngIf="!show" class="fas fa-chevron-right mr-3" style="width: 25px"></i>
    <i *ngIf="show" class="fas fa-chevron-down mr-3" style="width: 25px"></i>
    <span class="fw-500">{{ ppc.brand }}</span> - {{ ppc.name }}
    <a class="fs-2" [routerLink]="'/printers/view/' + slugService.string_to_slug(ppc.name) + '/' + ppc.id"><i class="fas fa-external-link-alt"></i></a>
    <div
      *ngIf="ppc.checked"
      class="float-right"
      [ngClass]="ppc.active ? 'bg-success' : 'bg-danger'"
      style="height: 20px; width: 20px; border-radius: 20px; margin-top: 1px"
    ></div>
    <span class="fw-500 text-warning float-right" *ngIf="!ppc.checked">En attente</span>
  </div>
  <div *ngIf="show" class="card-body">
    <div class="row justify-content-between">
      <div class="col-auto">
        <div class="row">
          <div class="col">
            <span class="fw-500">Coût production par cm³</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            Producteur :&nbsp;
            <span *ngIf="ppc.currentProductionCost != 0">{{ ppc.currentProductionCost | number: '.6':'fr-FR' }}</span>
            <span *ngIf="ppc.currentProductionCost == 0">-</span>
            &nbsp;€
          </div>
        </div>
        <div class="row">
          <div *ngIf="!edit" class="col">
            Conseillé :&nbsp;
            <span class="pointer text-primary" (click)="edit = true">
              <span *ngIf="ppc.defaultProductionCost != 0">{{ ppc.defaultProductionCost | number: '.6':'fr-FR' }}</span>
              <span *ngIf="ppc.defaultProductionCost == 0">-</span>
              &nbsp;€
            </span>
          </div>
          <div *ngIf="edit" class="col">
            Conseillé :
            <input class="form-control d-inline-block" style="width: 100px" type="number" [(ngModel)]="newPrice" />
            <button class="btn btn-primary-outline text-danger p-0" (click)="cancelClicked()">
              <i class="fas fa-times-circle" style="font-size: 25px"></i>
            </button>
            <button class="btn btn-primary-outline text-success p-0" (click)="saveClicked()">
              <i class="fas fa-check-circle" style="font-size: 25px"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="col-auto">
        <div class="row">
          <div class="d-inline-block position-relative">
            <span class="fw-500 text-success mr-2" *ngIf="ppc.checked">Validée</span>
            <span class="fw-500 text-warning mr-2" *ngIf="!ppc.checked">En attente</span>
            <ui-switch style="vertical-align: sub" size="small" color="#00c462" defaultBgColor="#ffc107" [checked]="ppc.checked" (change)="checked($event)">
            </ui-switch>
          </div>
        </div>
        <div class="row mt-1">
          <span class="text-secondary" ngbTooltip="Printer id : {{ ppc.id }} Producer Printer id : {{ ppc.idProducerPrinter }}"
            >#ID : {{ ppc.idProducerPrinter }}</span
          >
        </div>
      </div>
    </div>
  </div>
</div>

<app-producer-printer-consumable-option-info
  (checkPrinterDisabled)="checkDisabled()"
  *ngFor="let consumable of ppc.consumables"
  class="d-block"
  [consumable]="consumable"
  [idCompanyPrinter]="ppc.idProducerPrinter"
>
</app-producer-printer-consumable-option-info>

<!--
<div *ngIf="show">
  <app-producer-printer-consumable-option-info *ngFor="let consumable of ppc.consumables" class="d-block"
    [consumable]="consumable">
  </app-producer-printer-consumable-option-info>
</div>
-->
