import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PrintDataService } from './print-data.service';

@Injectable()
export class PrintGuard implements CanActivate {
  constructor(private printDataService: PrintDataService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const url: string = state.url;

    return this.checkLogin(route, url);
  }

  checkLogin(route: ActivatedRouteSnapshot, url: string): Observable<boolean> {
    return Observable.create((observer) => {
      console.log('PrintGuard', route, url, this.printDataService.step);
      if (this.printDataService.step === PrintDataService.STEP_UPLOAD_MODELS && url !== '/impression/etape-2') {
        this.router.navigate(['/impression', 'etape-2']);
      } else if (this.printDataService.step === PrintDataService.STEP_PRINT_SETTINGS && url !== '/impression/etape-3') {
        this.router.navigate(['/impression', 'etape-3']);
      } else if (this.printDataService.step === PrintDataService.STEP_QUANTITY && url !== '/impression/etape-4') {
        this.router.navigate(['/impression', 'etape-4']);
      } else if (this.printDataService.step === PrintDataService.STEP_CHOOSE_ARTICLE_TYPE && url !== '/impression') {
        this.router.navigate(['/impression']);
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }
}
