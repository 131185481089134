import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CodeNafBOService, CodeNaf, BusinessTargetLevel2, BusinessTargets, BusinessTarget } from 'degineo-common';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-codenaf-list-edit',
  templateUrl: './codenaf-list-edit.component.html',
  styleUrls: ['./codenaf-list-edit.component.scss'],
})
export class CodenafListEditComponent implements OnInit {
  codes: CodeNaf[] = [];
  opened: CodeNaf[] = [];
  types: BusinessTarget[] = BusinessTargets;
  constructor(public codeNafBOService: CodeNafBOService, public cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.reload();
  }

  reload() {
    this.codeNafBOService.getCodesNaf().subscribe(
      (data) => {
        this.codes = data;
      },
      (error) => {
        L.e(error);
      }
    );
  }

  toggle(code: CodeNaf) {
    if (this.isOpen(code)) this.opened.splice(this.opened.indexOf(this.opened.find((c) => c.id === code.id)), 1);
    else this.opened.push(code);
  }

  isOpen(code: CodeNaf) {
    return this.opened.find((c) => c.id === code.id) !== undefined;
  }

  getSections() {
    return this.codes.filter((val) => val.division === null);
  }
  getDivisions(section: string) {
    return this.codes.filter((val) => val.division !== null && val.section === section && val.groupe === null);
  }
  getGroupes(division: string) {
    return this.codes.filter((val) => val.groupe !== null && val.division === division && val.classe === null);
  }
  getClasse(groupe: string) {
    return this.codes.filter((val) => val.classe !== null && val.groupe === groupe && val.sousClasse === null);
  }
  getSousClasses(classe: string) {
    return this.codes.filter((val) => val.sousClasse !== null && val.classe === classe && val.sousClasse !== null);
  }

  onChange(codeNaf: CodeNaf, type: BusinessTargetLevel2) {
    this.codeNafBOService.updateClientBusinessTarget(codeNaf.section, codeNaf.division, codeNaf.groupe, codeNaf.classe, codeNaf.sousClasse, type).subscribe(
      (data) => {
        this.reload();
      },
      (error) => {
        L.e(error);
      }
    );
  }
}
