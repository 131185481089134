<div class="container-fluid">
  <div class="row">
    <div class="col-12 offset-sm-0 col-sm-6 offset-xl-2 col-xl-4 pr-md-4">
      <app-print-settings-name
        [name]="orderService.name"
        (nameChanged)="orderService.nameChanged($event)"
        class="d-block alert shadow-2 p-4"
      ></app-print-settings-name>
      <app-print-settings-size
        [defaultSizeDTO]="orderService.defaultSizeDTO"
        [printerSizes]="orderService.printerSizes"
        [alreadySelectedSizeDTO]="orderService.size"
        (sizeChanged)="orderService.sizeChanged($event)"
        class="d-block alert shadow-2 mt-4 p-4"
      ></app-print-settings-size>
      <app-print-settings-material
        [availableMaterials]="orderService.availableMaterials"
        [selectedMaterial]="orderService.selectedMaterial"
        (materialSelected)="orderService.materialChanged($event)"
        class="d-block alert shadow-2 mt-4 p-4"
      ></app-print-settings-material>
      <app-print-settings-color
        [availableColors]="orderService.availableColors"
        [selectedColor]="orderService.selectedColor"
        [isMulticolor]="orderService.isMulticolor()"
        (colorChanged)="orderService.colorChanged($event)"
        (fileAdded)="orderService.mtlFileAdded($event)"
        class="d-block alert shadow-2 mt-4 p-4"
      ></app-print-settings-color>
      <app-print-settings-finish
        *ngIf="!orderService.dentaire"
        [availableFinish]="orderService.availableFinish"
        [selectedFinish]="orderService.selectedFinish"
        (finishChanged)="orderService.finishChanged($event)"
        class="d-block alert shadow-2 mt-4 p-4"
      ></app-print-settings-finish>
      <app-print-settings-comment (commentChanged)="orderService.commentChanged($event)" class="d-block alert shadow-2 mt-4 p-4"></app-print-settings-comment>
    </div>
    <div class="col-12 col-sm-6 col-xl-4 pl-md-4 mt-5 mt-sm-0">
      <degineo-preview-modele [modelUrl]="modelUrl"></degineo-preview-modele>
      <app-print-settings-printer-technology
        [selectedMaterial]="orderService.selectedMaterial"
        class="d-block alert alert-dark mt-5 p-4"
      ></app-print-settings-printer-technology>
      <app-print-settings-characteristics
        [selectedMaterial]="orderService.selectedMaterial"
        [parentMaterial]="orderService.parentMaterial"
        [characs]="orderService.characs"
        class="d-block alert alert-dark mt-4 p-4"
      ></app-print-settings-characteristics>
    </div>
  </div>
  <div class="row mt-5 mb-5">
    <div class="col-12 col-md-6 mt-3 mt-sm-0 text-md-right order-2 order-md-1">
      <button type="button" class="btn btn-secondary btn-allonge w-100 w-sm-200px" (click)="previous.emit()">
        <i class="fas fa-chevron-left"></i> Précédent
      </button>
    </div>
    <div class="col-12 col-md-6 order-1 order-md-2">
      <button type="button" class="btn btn-success btn-allonge w-100 w-sm-200px" [disabled]="!nextAvailable" (click)="next.emit()">
        Suivant <i class="fas fa-chevron-right"></i>
      </button>
    </div>
  </div>
</div>
