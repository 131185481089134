import { Injectable } from '@angular/core';
import { FinishBODTO, MaterialTypeNEW } from 'degineo-common';

@Injectable({
  providedIn: 'root',
})
export class FinishData {
  public file: File = null;
  materials: MaterialTypeNEW[] = [];
  dto: FinishBODTO = null;
  constructor() {}
}
