<div class="container">
  <div class="row">
    <div class="col text-center" *ngIf="devis.length === 0">Vous n'avez aucun devis.</div>
    <div class="col-12 col-md-3 text-center mb-4" *ngFor="let d of devis">
      <div class="position-relative w-100">
        <img class="w-100 rounded" img-preloader="{{ rpcHost }}modele3Dpng/{{ d.modelePNGToken }}/modele.png" />

        <img class="logo-producteur" img-preloader="{{ rpcHost }}companylogo/{{ d.producerLogoToken }}/logo.png?size=small" />

        <div class="position-absolute w-100 text-center p-2 bottom pr-4 pl-4" style="border-radius: 0 0 0.25rem 0.25rem">
          <div class="fw-500 text-white txt-shadow ellipsis" style="line-height: 1.2">{{ d.name }}</div>
          <ng-container [ngSwitch]="d.status.id">
            <ng-container *ngSwitchCase="DevisStatus.DEMANDE_ENVOYEE.id">
              <div class="text-white fs-2 mb-2 txt-shadow" style="line-height: 1.2">Demande envoyée le {{ d.creationDate | date: 'dd/MM/yyyy' }}</div>
              <button class="btn w-100 btn-medium" routerLink="/devis/{{ d.idDevis }}">Devis en création</button>
            </ng-container>

            <ng-container *ngSwitchCase="DevisStatus.NOUVEAU_DEVIS.id">
              <div class="text-white fs-2 mb-2 txt-shadow" style="line-height: 1.2">Reçu le {{ d.sentDate | date: 'dd/MM/yyyy' }}</div>
              <button class="btn w-100 btn-success" routerLink="/devis/{{ d.idDevis }}/preview">Nouveau devis</button>
            </ng-container>

            <ng-container *ngSwitchCase="DevisStatus.DEVIS_EN_COURS_REVISION.id">
              <div class="text-white fs-2 mb-2 txt-shadow" style="line-height: 1.2">Reçu le {{ d.clientModificationCommentDate | date: 'dd/MM/yyyy' }}</div>
              <button class="btn w-100 btn-medium" routerLink="/devis/{{ d.idDevis }}">Devis en révision</button>
            </ng-container>

            <ng-container *ngSwitchCase="DevisStatus.DEVIS_REVISE.id">
              <div class="text-white fs-2 mb-2 txt-shadow" style="line-height: 1.2">Reçu le {{ d.sentDate | date: 'dd/MM/yyyy' }}</div>
              <button class="btn w-100 btn-success" routerLink="/devis/{{ d.idDevis }}">Devis révisé</button>
            </ng-container>

            <ng-container *ngSwitchCase="DevisStatus.DEMANDE_REVISION_REFUSEE.id">
              <div class="text-white fs-2 mb-2 txt-shadow" style="line-height: 1.2">Reçu le {{ d.producerRefusalCommentDate | date: 'dd/MM/yyyy' }}</div>
              <button class="btn w-100 btn-warning" routerLink="/devis/{{ d.idDevis }}">Révision refusée</button>
            </ng-container>

            <ng-container *ngSwitchCase="DevisStatus.DEMANDE_DEVIS_REFUSEE.id">
              <div class="text-white fs-2 mb-2 txt-shadow" style="line-height: 1.2">Reçu le {{ d.producerRefusalCommentDate | date: 'dd/MM/yyyy' }}</div>
              <button class="btn w-100 btn-danger" routerLink="/devis/{{ d.idDevis }}">Demande de devis refusée</button>
            </ng-container>

            <ng-container *ngSwitchCase="DevisStatus.DEVIS_ACCEPTE.id">
              <div class="text-white fs-2 mb-2 txt-shadow" style="line-height: 1.2">Accepté le {{ d.acceptDate | date: 'dd/MM/yyyy' }}</div>
              <button class="btn w-100 btn-success" routerLink="/devis/{{ d.idDevis }}/preview" *ngIf="!d.alreadyInBasket">Ajouter au panier</button>
              <button class="btn w-100 btn-success" routerLink="/panier" *ngIf="d.alreadyInBasket">À payer</button>
            </ng-container>

            <!--
            <ng-container *ngSwitchCase="DevisStatus.DEVIS_PAYE.id">
              <div class="text-white fs-2 mb-2 txt-shadow" style="line-height: 1.2">Payé le {{ d.paymentDate | date: 'dd/MM/yyyy' }}</div>
              <button class="btn w-100 btn-primary" routerLink="/devis/{{ d.idDevis }}">
                Devis payé
              </button>
            </ng-container>
            -->

            <!--<ng-container *ngSwitchCase="DevisStatus.DEVIS_REFUSE_PAR_CLIENT">
              <div class="text-white fs-2 mb-2 txt-shadow">Refusé le {{ d.clientRefusalCommentDate | date: 'dd/MM/yyyy' }}</div
              ><br />
              <button class="btn w-100 btn-danger" routerLink="/devis/{{ d.idDevis }}">
                Devis refusé
              </button>
            </ng-container>-->
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
