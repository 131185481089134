<div class="container-fluid">
  <div class="row mt-3" *ngIf="error">
    <div class="col text-center">
      <div class="alert alert-danger">
        {{ error }}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <app-upload-step
        [files]="printDataService.files"
        [multi]="printDataService.dentaire"
        (fileDeleted)="fileDeleted($event)"
        (fileAdded)="fileAdded($event)"
        [nextAvailable]="canNextUploadStep()"
        (next)="next()"
        (previous)="previous()"
      ></app-upload-step>
    </div>
  </div>
</div>
