<div class="container py-5">
  <div class="row">
    <div class="col-md-12">
      <h2 class="text-center text-white mb-4">Degineo backoffice</h2>
      <div class="row">
        <div class="col-md-6 mx-auto">
          <div class="card rounded-0">
            <div class="card-header">
              <h3 class="mb-0">Connexion</h3>
            </div>
            <div class="card-body">
              <form #f="ngForm" [class.show-errors]="f.submitted">
                <div class="form-group">
                  <input
                    email
                    [(ngModel)]="email"
                    type="email"
                    class="form-control form-control-lg rounded-0"
                    name="email"
                    required
                    placeholder="Email Address"
                    #em="ngModel"
                    autocomplete="username"
                  />
                  <div *ngIf="em.invalid && (em.dirty || em.touched)" class="alert alert-danger">
                    <div *ngIf="em.errors.required">Email requis.</div>
                    <div *ngIf="em.errors.email">Email non valide.</div>
                  </div>
                </div>
                <div class="form-group">
                  <input
                    [(ngModel)]="password"
                    type="password"
                    class="form-control form-control-lg rounded-0"
                    name="password"
                    required
                    placeholder="Password"
                    #pwd="ngModel"
                    autocomplete="current-password"
                  />
                  <div *ngIf="pwd.invalid && (pwd.dirty || pwd.touched)" class="alert alert-danger">
                    <div *ngIf="pwd.errors.required">Password requis.</div>
                  </div>
                </div>
                <div class="alert alert-danger" *ngIf="loginError">{{ loginError }}</div>
                <button type="submit" class="btn btn-success btn-lg float-right" (click)="login()" [disabled]="!f.form.pristine && !f.form.valid">
                  Login <i class="fas fa-spinner spinning" *ngIf="loading"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--
<div class="container h-100 login bg-light p-4">
  <div class="row justify-content-center align-items-center">
    <h2>Admin Login</h2>
  </div>
  <div class="row justify-content-center align-items-center">
    <form>
      <div class="form-group">
        <input [(ngModel)]="email" name="email" type="email" class="form-control" placeholder="Email Address">
      </div>

      <div class="form-group">
        <input [(ngModel)]="password" name="password" type="password" class="form-control" placeholder="Password">
      </div>
      <p>{{message}}</p>
      <button type="submit" class="btn btn-primary" (click)="login()">Login</button>
    </form>
  </div>
</div>
-->
