<div class="row">
  <div class="col text-md-right">
    <div class="position-relative">
      <div class="d-inline-block" placement="top">
        <span class="fw-500" [ngClass]="data.dto.inactived ? 'text-danger' : 'text-success'">Compte activé </span>
        <ui-switch
          class="d-inline-block ml-1"
          style="vertical-align: sub"
          size="small"
          color="#00c462"
          defaultBgColor="#D82929"
          [checked]="!data.dto.inactived"
        ></ui-switch>
        <div (click)="activeChange.emit(); $event.stopPropagation()" class="pointer position-absolute h-100" style="right: 0; top: 0; width: 150px"></div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="countCommand == 0" class="row mt-1">
  <div class="col text-md-right">
    <div class="d-inline-block" placement="top">
      <button class="btn btn-primary-outline text-danger p-0" style="letter-spacing: normal" (click)="deleteClient.emit(); $event.stopPropagation()">
        <i class="fas fa-user-times mr-1"></i> Supprimer ce client
      </button>
    </div>
  </div>
</div>

<div class="row mt-3">
  <div class="col text-md-right">
    <div>Création du compte : {{ data.dto.dateCreacted | date: 'dd/MM/yyyy' }}</div>
  </div>
</div>
<div class="row mt-1">
  <div class="col text-md-right">
    <div>Dernière connexion : {{ data.dto.dateLastConnexion | date: 'dd/MM/yyyy' }}</div>
  </div>
</div>

<div class="row mt-3">
  <div class="col text-md-right">
    <div class="d-inline-block" [ngbTooltip]="data.dto.autologToken === null ? 'Pas d\'utilisateur trouvé' : null">
      <a
        class="btn btn-success"
        style="min-width: 235px"
        [attr.href]="data.dto.autologToken !== null ? clientUrl + 'autolog/' + data.dto.autologToken : null"
        target="_blank"
        [class.disabled]="data.dto.autologToken === null"
      >
        Se connecter au compte
      </a>
    </div>
  </div>
</div>
<div class="row mt-1">
  <div class="col text-md-right">
    <div>
      <a class="btn btn-primary" style="min-width: 235px" [routerLink]="'/invoices/company/' + data.dto.id"> Voir factures </a>
    </div>
  </div>
</div>
