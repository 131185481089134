<div class="container-fluid h-100 d-flex flex-column">
  <div class="row flex-fill">
    <div class="col align-self-center">
      <div class="container-fluid">
        <ng-container *ngIf="!modeFacturePaiementA30j">
          <i class="fas fa-spinner spinning ml-2" *ngIf="!paiementA30jShowConfirm && degineoBankInfos === null"></i>
          <ng-container *ngIf="!paiementA30jShowConfirm && degineoBankInfos !== null">
            <div class="row mt-4 text-center">
              <div class="col">
                <h2>Payez par virement SEPA</h2>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col pr-5 pl-5">
                <div class="alert alert-info p-4">
                  <div><span class="fw-700">Destinataire&nbsp;:&nbsp;</span>Degineo<br /></div>
                  <div class="mt-2"><span class="fw-700">IBAN&nbsp;:&nbsp;</span>{{ degineoBankInfos.iban }}<br /></div>
                  <div class="mt-2"><span class="fw-700">BIC&nbsp;:&nbsp;</span>{{ degineoBankInfos.bic }}</div>
                </div>
              </div>
            </div>
            <div class="row px-5 mt-4 text-center">
              <div class="col">
                Afin de payer votre commande par virement SEPA, vous devez effectuer un virement exact de
                <span class="fw-700">{{ paymentDataService.totalPriceTTC | number: '.2':'fr-FR' }}&nbsp;€</span> sur le RIB ci-dessus.<br />
                Une fois votre virement effectué, veuillez cliquer sur le bouton ci&#8209;dessous afin de valider celui-ci :
              </div>
            </div>

            <div class="row mt-4 text-center">
              <div class="col">
                <button class="btn btn-success text-uppercase" [disabled]="loading" (click)="wireTransfertDone()">
                  J'ai fait le virement<i class="fas fa-spinner spinning ml-2" *ngIf="loading"></i>
                </button>
              </div>
            </div>
            <div class="row mt-5 text-center" *ngIf="paiementA30j">
              <div class="col">
                <span class="fw-700">Ou choisissez le paiement à 30 jours : </span><br />
                Ce mode de paiement vous permet de régler toutes vos commandes du mois au début du mois suivant par virement SEPA.
              </div>
            </div>

            <div class="row mt-4 text-center" *ngIf="paiementA30j">
              <div class="col">
                <button class="btn btn-primary text-uppercase" (click)="paiementA30jShowConfirm = true">Choisir le paiement à 30 jours</button>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="paiementA30jShowConfirm">
            <div class="row text-center">
              <div class="col">
                <h2>Paiement à 30 jours</h2>
              </div>
            </div>
            <div class="row mt-5 text-center" *ngIf="paiementA30j">
              <div class="col">
                <p>
                  Vous ne souhaitez pas payer cette commande à <span class="fw-500">{{ paymentDataService.selectedArticles[0].producerName }}</span> maintenant
                  mais le 1er {{ nextMonth | date: 'MMMM yyyy' }} ?
                </p>
              </div>
            </div>
            <div class="row mt-3 text-center" *ngIf="paiementA30j">
              <div class="col">
                <button class="btn btn-danger text-uppercase" (click)="paiementA30jShowConfirm = false">non</button>
                <button class="btn btn-success text-uppercase ml-4" [disabled]="loading" (click)="delayPayment()">
                  oui<i class="fas fa-spinner spinning ml-2" *ngIf="loading"></i>
                </button>
              </div>
            </div>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="modeFacturePaiementA30j">
          <div class="row mt-4 text-center">
            <div class="col">
              <h2>{{ paymentStep2.producerName }}</h2>
            </div>
          </div>
          <div class="row">
            <div class="col py-5">
              <div class="alert alert-info p-4">
                <div><span class="fw-700">Destinataire&nbsp;:&nbsp;</span>{{ paymentStep2.producerName }}<br /></div>
                <div class="mt-2">
                  <span class="fw-700">IBAN&nbsp;:&nbsp;</span> <span>{{ formatIban(paymentStep2.invoice.ibanProducer) }}</span
                  ><br />
                </div>
                <div class="mt-2"><span class="fw-700">BIC&nbsp;:&nbsp;</span> {{ paymentStep2.invoice.bicProducer }}</div>
              </div>
            </div>
          </div>
          <div class="row px-5 mt text-center">
            <div class="col">
              Afin de payer vos commandes du mois {{ moisApostrophes.includes(paymentStep2.invoice.dateMoisFacture.getMonth()) ? "d'" : 'de '
              }}<span class="fw-700">{{ paymentStep2.invoice.dateMoisFacture | date: 'MMMM' }}</span> à
              <span class="fw-700">{{ paymentStep2.producerName }}</span> par virement SEPA, vous devez effectuer un virement exact de
              <span class="fw-700 text-success">{{ paymentStep2.invoice.totalTTC | number: '.2':'fr-FR' }}&nbsp;€</span> sur le RIB ci-dessus.
            </div>
          </div>
          <div class="row mt-4 text-center">
            <div class="col">
              Lors de votre virement, merci de mentionner
              <span class="fw-700"
                >la référence de facture N°&nbsp;<span class="text-success fw-700">{{ paymentStep2.invoice.reference }}</span></span
              >
              en titre de votre virement afin de lier votre paiement à votre commande.
            </div>
          </div>
          <div class="row mt-4 text-center">
            <div class="col">
              Une fois votre virement effectué, veuillez
              <span class="fw-700">cliquer sur le bouton ci&#8209;dessous</span>
              afin de valider celui-ci :
            </div>
          </div>
          <div class="row mt-4 text-center">
            <div class="col">
              <button class="btn btn-success text-uppercase" (click)="invoicePaid()">J'ai fait le virement</button>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <!-- obligatoirement a la fin pour passer au dessus du conteneur sinon pas cliquable -->
  <div class="row position-absolute" style="right: 0px">
    <div class="col text-right fs-4">
      <button type="button" class="close" aria-label="Close" (click)="paymentStep2.closePopup('Cross click')">
        <i class="fas fa-times modal-cross"></i>
      </button>
    </div>
  </div>
</div>
