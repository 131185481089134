<div class="container-fluid d-flex flex-column flex-nowrap pt-4" style="min-height: 100vh;" (click)="closeCheckboxes()">
  <div class="row">
    <div class="col text-center">
      <h1 class="mb-5">Système d'abonnement Degineo</h1>
    </div>
  </div>

  <span *ngIf="loading"><i class="fas fa-spinner spinning mr-2"></i>Chargement en cours</span>
  <div *ngIf="!loading" class="row" style="flex: 1 0 auto;">
    <!--Colonne gauche-->
    <div class="col-3 border-light border-top border-right">
      <div class="mb-3">
        <h2 class="mb-3 mt-3 fs-4">Abonnements actifs</h2>
        <ng-container *ngFor="let p of plans">
          <div *ngIf="p.plan.active" class="pointer sub-hover p-2" (click)="selectPlan(p.plan.id)" [class.active]="p.plan.id == selectedPlan.plan.id">
            <div class="fs-4">
              {{ p.plan.name }}
            </div>
            <div class="text-medium fs-2">
              {{ p.plan.producerTarget }}
            </div>
          </div>
        </ng-container>
      </div>
      <div class="mb-3">
        <h2 class="mb-3 fs-4">Abonnements désactivés</h2>
        <ng-container *ngFor="let p of plans">
          <div *ngIf="!p.plan.active" class="pointer sub-hover p-2" (click)="selectPlan(p.plan.id)" [class.active]="p.plan.id == selectedPlan.plan.id">
            <div class="fs-4">
              {{ p.plan.name }}
            </div>
            <div class="text-medium fs-2">
              {{ p.plan.producerTarget }}
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <!--Colonne droite-->
    <div class="col border-light border-top pr-4">
      <div class="row mt-3">
        <div class="col d-flex justify-content-end mb-3 mr-3">
          <button *ngIf="!edit" class="btn btn-primary" (click)="newPlan()">Nouveau plan</button>
        </div>
      </div>
      <div *ngIf="edit || view" class="container">
        <!--Nom-->
        <div class="row mt-3 mb-5">
          <div class="col">
            <h3 class="mb-3">Nom du plan</h3>
            <input [disabled]="!edit && !newP" class="form-control dashed-when-disabled" type="text" [(ngModel)]="selectedPlan.plan.name" />
          </div>
          <div class="col">
            <h3 class="mb-3">Bouton plan</h3>
            <select [disabled]="!edit && !newP" class="form-control dashed-when-disabled" [(ngModel)]="selectedPlan.plan.planButton">
              <option [ngValue]="null" disabled="disabled"> -- Choisir type de bouton -- </option>
              <option [ngValue]="false">Choisir ce forfait</option>
              <option [ngValue]="true">Nous contacter</option>
            </select>
          </div>
          <div class="col d-flex flex-column">
            <div class="text-medium">Nombre d'abonnés : {{ selectedPlan.nbSubTotal }}</div>
            <div class="text-medium">Nombre d'abonnés ce mois-ci : {{ selectedPlan.nbSubMonth }}</div>
          </div>
        </div>
        <!--Contenu-->
        <div class="row mb-5">
          <h3 class="mb-3 col-12">Contenu de l'abonnement</h3>
          <div class="col">
            <label>Cible producteur</label>
            <select [disabled]="!edit && !newP" class="form-control dashed-when-disabled" [(ngModel)]="selectedPlan.plan.producerTarget">
              <option [ngValue]="null" disabled="disabled"> -- Choisir cible producteur -- </option>
              <option *ngFor="let target of producerTargets" [ngValue]="target">{{ target.lib | titlecase }} </option>
            </select>
          </div>
          <div class="col">
            <label>Fonctionnalités</label>
            <div class="position-relative" (click)="toggleCheckboxes(); $event.stopPropagation()" (focusout)="closeCheckboxes()">
              <select [disabled]="!edit && !newP" class="form-control dashed-when-disabled">
                <option [ngValue]="null" disabled="disabled">{{ functionalitiesText }}</option>
              </select>
              <div class="overselect"></div>
            </div>
            <div
              *ngIf="checkboxesVisible"
              (click)="$event.stopPropagation()"
              class="position-absolute bg-white border border-light w-100"
              style="z-index: 100;"
            >
              <ng-container *ngFor="let f of selectedPlan.functionalities; let i = index">
                <label class="d-block p-2">
                  <input type="checkbox" [id]="f.functionality.id" (click)="toggleCheck(i)" [checked]="isChecked(i)" /><span class="ml-2">{{
                    f.functionality.name
                  }}</span></label
                >
              </ng-container>
            </div>
          </div>
          <div class="col">
            <label>Type de facturation</label>
            <select [disabled]="!edit && !newP" class="form-control dashed-when-disabled" [(ngModel)]="selectedPlan.plan.invoiceType">
              <option [ngValue]="null" disabled="disabled"> -- Choisir type de facturation -- </option>
              <option [disabled]="invoicing.id != 3" *ngFor="let invoicing of invoicingTypes" [ngValue]="invoicing"> {{ invoicing }}</option>
            </select>
          </div>
        </div>
        <!--Tarification-->
        <div class="row mb-5">
          <h3 class="mb-3 col-12">Tarification</h3>
          <div class="col">
            <label>Prix de base</label>
            <div class="row mx-0 align-items-end">
              <input
                [disabled]="!edit && !newP"
                class="form-control dashed-when-disabled col"
                type="number"
                step="0.01"
                [(ngModel)]="selectedPlan.plan.baseSubscriptionPriceHT"
              />
              <span>&nbsp;€ HT</span>
            </div>
          </div>
          <div class="col mx-0">
            <label>Prix par machine</label>
            <div class="row mx-0 align-items-end">
              <input
                [disabled]="!edit && !newP"
                class="form-control dashed-when-disabled col"
                type="number"
                step="0.01"
                [(ngModel)]="selectedPlan.plan.printerSubscriptionPriceHT"
              />
              <span>&nbsp;€ HT</span>
            </div>
          </div>
          <div class="col">
            <label>Frais de fonctionnement</label>
            <div class="row mx-0 align-items-end">
              <input
                [disabled]="!edit && !newP"
                class="form-control dashed-when-disabled col"
                type="number"
                step="0.01"
                [(ngModel)]="selectedPlan.plan.comissionPercent"
              />
              <span>&nbsp;%</span>
            </div>
          </div>
        </div>
        <!--Limitations-->
        <div class="row mb-5">
          <h3 class="mb-3 col-12">Limitation (Maxi)</h3>
          <div class="col">
            <label>Nombre de machines</label>
            <input [disabled]="!edit && !newP" class="form-control dashed-when-disabled" type="number" step="1" [(ngModel)]="selectedPlan.plan.nbMaxPrinter" />
          </div>
          <div class="col">
            <label>Nombre d'utilisateurs</label>
            <input [disabled]="!edit && !newP" class="form-control dashed-when-disabled" type="number" step="1" [(ngModel)]="selectedPlan.plan.nbMaxUsers" />
          </div>
          <div class="col">
            <label>Nombre de commandes /mois</label>
            <input
              [disabled]="!edit && !newP"
              class="form-control dashed-when-disabled"
              type="number"
              step="1"
              [(ngModel)]="selectedPlan.plan.nbOrderPerMonth"
            />
          </div>
        </div>
        <!--Boutons-->
        <div class="row justify-content-end mr-0">
          <button *ngIf="edit" class="btn btn-secondary" (click)="cancel()">Annuler</button>
          <button [disabled]="empty()" *ngIf="edit" class="btn btn-success ml-4" (click)="validate()">
            <ng-container *ngIf="newP">Créer le plan</ng-container>
            <ng-container *ngIf="!newP">Valider</ng-container>
          </button>
          <button
            *ngIf="!newP && !edit"
            class="btn ml-4"
            (click)="updateActive()"
            [class.btn-success]="!selectedPlan.plan.active"
            [class.btn-danger]="selectedPlan.plan.active"
          >
            <ng-container *ngIf="!selectedPlan.plan.active">Activer le plan </ng-container>
            <ng-container *ngIf="selectedPlan.plan.active">Désactiver le plan </ng-container>
          </button>
          <button *ngIf="view" class="btn btn-primary ml-4" (click)="editMode()">Modifier</button>
        </div>
      </div>
    </div>
  </div>
</div>
