import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { OrderBy } from 'degineo-common';
import { Filter } from 'degineo-common';
import { FilterOrder, GenericFilter, ProducerPageListItemBODTO } from 'degineo-common';
import { environment } from 'environments/environment';
import { ProducerBOService } from 'degineo-common';
import { Router } from '@angular/router';
import { L } from 'ic2-lib';
import { SlugService } from 'degineo-common';

@Component({
  selector: 'app-producer-page-list',
  templateUrl: './producer-page-list.component.html',
  styleUrls: ['./producer-page-list.component.scss'],
})
export class ProducerPageListComponent implements OnInit, AfterViewInit {
  ProducerPageListComponent: typeof ProducerPageListComponent = ProducerPageListComponent;
  producers: ProducerPageListItemBODTO[] = [];
  OrderBy: typeof OrderBy = OrderBy;

  static filtersCache = {};
  filtersDefault = {};

  static OrderCaches = {};
  static page: number = 1;

  nbPerPage: number = 10;
  count = 0;
  loading: boolean = true;

  builtFilters: Filter[] = [];
  builtFiltersOrder: FilterOrder[] = [];

  rpcHost: string;

  constructor(
    @Inject(ProducerBOService) private producerBOService: ProducerBOService,
    @Inject(SlugService) private slugService: SlugService,
    private router: Router
  ) {
    this.rpcHost = environment.rpcHost;
  }
  ngOnInit() {}

  ngAfterViewInit() {
    this.reload();
  }

  reload() {
    let genericFilter = new GenericFilter();
    genericFilter.filters = this.builtFilters;
    genericFilter.orderFilters = this.builtFiltersOrder;
    genericFilter.page = ProducerPageListComponent.page;
    genericFilter.nbPerPage = this.nbPerPage;
    console.log('genericFilter', genericFilter);
    this.loading = true;
    this.producerBOService.getProducerPages(genericFilter).subscribe(
      (data) => {
        console.log(data);
        this.producers = data.data;
        this.count = data.count;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }

  filterChanged(filters: Filter[]) {
    console.log('filterChanged event', filters);
    this.reload();
  }
  orderChange(order) {
    console.log('orderChange', order);
    this.reload();
  }
  tableRowClick(row: any) {
    console.log('tableRowClick', row);
    this.router.navigate(['/producers', 'edit', this.slugService.string_to_slug(row.name), row.id, 'page']);
  }
}
