import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { ClientData } from '../../providers/client.provider';
import { OrderBOService } from 'degineo-common';
import { L } from 'ic2-lib';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-client-info-right',
  templateUrl: './client-info-right.component.html',
  styleUrls: ['./client-info-right.component.scss'],
})
export class ClientInfoRightComponent implements OnInit {
  constructor(public data: ClientData, @Inject(OrderBOService) private orderBOService: OrderBOService) {}
  countCommand: number = null;
  @Output()
  activeChange: EventEmitter<any> = new EventEmitter();
  @Output()
  deleteClient: EventEmitter<any> = new EventEmitter();

  clientUrl = environment.clientUrl;

  ngOnInit() {
    this.orderBOService.getOrderCount(this.data.dto.id).subscribe(
      (data) => {
        console.log('count :', data);
        this.countCommand = data;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  vewInvoiceClicked() {
    console.log('vewInvoiceClicked');
  }
}
