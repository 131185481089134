<ng-container [ngSwitch]="notif.type.id">
  <div
    routerLink="/paiement-differe/facture/{{ notif.data.idInvoice }}"
    [ngClass]="{
      'px-3': mobile,
      'px-4': !mobile,
      'py-2': mobile,
      'fs-2': mobile,
      'fw-400': mobile,
      'text-white': mobile,
      'bg-white-hover': mobile
    }"
    (click)="closeNotif()"
    *ngSwitchCase="NotifType.DELAYED_PAYMENT_WIRETRANSFERT_CLIENT_DONE_WAITING_PRODUCER_APPROVAL.id"
  >
    La facture n°{{ notif.data.refInvoice }} d'un montant de {{ notif.data.montant | number: '.2':'fr-FR' }}&nbsp;€ a été réglée par virement bancaire le
    {{ deserializeDate(notif.data.dateReglement) | date: 'dd/MM/yyyy' }} à {{ notif.data.producerName }}.
    <span class="fw-500">Le paiement est actuellement en attente de validation.</span> La validation peut prendre plusieurs jours
  </div>
  <div
    routerLink="/paiement-differe/facture/{{ notif.data.idInvoice }}"
    [ngClass]="{
      'px-3': mobile,
      'px-4': !mobile,
      'py-2': mobile,
      'fs-2': mobile,
      'fw-400': mobile,
      'text-white': mobile,
      'bg-white-hover': mobile
    }"
    class="pointer"
    (click)="closeNotif()"
    *ngSwitchCase="NotifType.DELAYED_INVOICE_TO_PAY.id"
  >
    Vous avez la facture n°{{ notif.data.refInvoice }} d'un montant de {{ notif.data.montant | number: '.2':'fr-FR' }}&nbsp;€ à régler par virement bancaire à
    {{ notif.data.producerName }} <span class="fw-700">avant le {{ deserializeDate(notif.data.dateMaxReglement) | date: 'dd/MM/yyyy' }}.</span>
  </div>
  <div
    routerLink="/paiement-differe/producteur/{{ notif.data.idProducer }}"
    [ngClass]="{
      'px-3': mobile,
      'px-4': !mobile,
      'py-2': mobile,
      'fs-2': mobile,
      'fw-400': mobile,
      'text-white': mobile,
      'bg-white-hover': mobile
    }"
    class="pointer"
    (click)="closeNotif()"
    *ngSwitchCase="NotifType.DELAYED_INVOICE_INCOMMING.id"
  >
    Vous aurez une facture d'un montant actuel de {{ notif.data.montant | number: '.2':'fr-FR' }}&nbsp;€ à régler par virement à {{ notif.data.producerName }} à
    partir du {{ deserializeDate(notif.data.dateFacturation) | date: 'dd/MM/yyyy' }}.
  </div>
  <div
    routerLink="/mes-imprimeurs-partenaires"
    [ngClass]="{
      'px-3': mobile,
      'px-4': !mobile,
      'py-2': mobile,
      'fs-2': mobile,
      'fw-400': mobile,
      'text-white': mobile,
      'bg-white-hover': mobile
    }"
    class="pointer"
    (click)="closeNotif()"
    *ngSwitchCase="NotifType.CLIENT_PRODUCER_ASSOCIATION_WAITING.id"
  >
    Le producteur <span class="fw-700">{{ notif.data.producerName }}</span> souhaite devenir un de vos producteurs partenaires, cliquez pour accepter ou refuser
    sa demande.
  </div>
  <div *ngSwitchDefault>
    {{ notif | json }}
  </div>
</ng-container>
