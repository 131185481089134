<div class="container">
  <div class="row" *ngIf="loading">
    <div class="col"><i class="fas fa-spinner spinning"></i> Chargement...</div>
  </div>
  <ng-container *ngIf="!loading">
    <div class="row">
      <div class="col text-center">
        <h3>Demande de devis n°{{ dto.numDevisProducteur }}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">à {{ dto.producerName }}</div>
    </div>

    <div class="row mt-3">
      <div class="col"><label>Type de pièce :</label> {{ dto.articleTypeName }}</div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <label>Fichier 3D :</label>
        <a class="ml-2 underline" href="{{ rpcHost }}modele3D/{{ dto.token3DModel }}/{{ dto.filename }}">{{ dto.filename }}</a>
        <span class="ml-3 text-primary pointer" (click)="showPreview = !showPreview"
          ><i class="fas fs-4" [class.fa-eye]="!showPreview" [class.fa-eye-slash]="showPreview"></i
        ></span>
        <div style="width: 400px; height: 400px" *ngIf="showPreview">
          <degineo-preview-modele modelUrl="{{ rpcHost }}modele3D/{{ dto.token3DModel }}/{{ dto.filename }}"></degineo-preview-modele>
        </div>
      </div>
    </div>
    <!--
    <div class="row">
      <div class="col"><label>Fichier MTL:</label> {{ devis }}</div>
    </div>
    -->
    <div class="row mt-3">
      <div class="col">
        <label>Taille de l'impression :</label> {{ dto.sizeInMmX | number: '.2':'fr-FR' }} x {{ dto.sizeInMmY | number: '.2':'fr-FR' }} x
        {{ dto.sizeInMmZ | number: '.2':'fr-FR' }} mm
      </div>
    </div>
    <div class="row mt-3">
      <div class="col"><label>Matière :</label> {{ dto.materialTypeName }}</div>
    </div>
    <div class="row mt-3">
      <div class="col"><label>Couleur :</label> {{ dto.colorName }}</div>
    </div>
    <div class="row mt-3">
      <div class="col"><label>Finition :</label> {{ dto.finishName }}</div>
    </div>
    <div class="row mt-3">
      <div class="col"><label>Nombre d'exemplaires :</label> {{ dto.quantity }}</div>
    </div>
    <div class="row mt-3">
      <div class="col"><label>Date souhaitée :</label> {{ dto.wishedDeliveryDate | date: 'dd/MM/yyyy' }}</div>
    </div>
    <div class="row mt-3" *ngIf="dto.proposedDeliveryDate">
      <div class="col"><label>Date proposée :</label> {{ dto.proposedDeliveryDate | date: 'dd/MM/yyyy' }}</div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <div class="alert-medium rounded p-3">
          <span class="fw-500">Votre commentaire :</span><br />
          <div>{{ dto.clientComment }}</div>
        </div>
      </div>
    </div>
    <div class="row mt-3" *ngIf="dto.clientModificationComment">
      <div class="col">
        <div class="alert-primary rounded p-3">
          <span class="fw-500">Modification demandées :</span>
          <div>{{ dto.clientModificationComment }}</div>
        </div>
      </div>
    </div>
    <div class="row mt-3" *ngIf="dto.clientRefusalComment !== null">
      <div class="col">
        <div class="alert-danger rounded p-3">
          <span class="fw-500">Votre motif de refus :</span>
          <div>{{ dto.clientRefusalComment }}</div>
        </div>
      </div>
    </div>
    <div class="row mt-3" *ngIf="dto.producerRefusalComment !== null">
      <div class="col">
        <div class="alert-danger rounded p-3">
          <span class="fw-500">Motif du refus producteur :</span>
          <div>{{ dto.producerRefusalComment }}</div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col text-right">
        <button class="btn btn-secondary" routerLink="/devis">Retour</button>
      </div>
      <div
        class="col"
        *ngIf="
          dto.status !== DevisStatus.DEMANDE_REVISION_REFUSEE && dto.status !== DevisStatus.DEMANDE_DEVIS_REFUSEE && dto.status !== DevisStatus.DEMANDE_ENVOYEE
        "
      >
        <button class="btn btn-success" routerLink="/devis/{{ dto.idDevis }}/preview">Voir le devis actuel</button>
      </div>
      <div class="col" *ngIf="dto.status === DevisStatus.DEMANDE_REVISION_REFUSEE || dto.status === DevisStatus.DEMANDE_DEVIS_REFUSEE">
        <button class="btn btn-danger" [disabled]="sending" (click)="hideDevis(dto.idDevis)">
          Supprimer cette demande <i class="fa fa-spinner spinning" *ngIf="sending"></i>
        </button>
      </div>
    </div>
  </ng-container>
</div>
