<div class="container pt-4 pb-4">
  <h1 class="mx-auto text-center mb-5">Commandes</h1>

  <degineo-filter class="d-inline-block w-100 mt-4 mb-4" #fliter [builtFilters]="builtFilters"
    (filterChanged)="filterChanged($event)" [cache]="OrderListComponent.filtersCache" [default]="filtersDefault">
    <div class="form-inline">
      <degineo-textassist-filter #f class="input-group mr-2 mt-1" style="width: 13rem;" name="Client"
        filterName="client" [values]="clients" [presenter]="presenterName" (textChanges)="textChanges($event)">
      </degineo-textassist-filter>

      <degineo-select-filter #f class="input-group mr-2 mt-1" style="width: 13rem;" name="Tous les types de paiement"
        filterName="paymentType" [values]="paymentType" [presenter]="presenterPaymentType">
      </degineo-select-filter>

      <degineo-select-filter #f class="input-group mr-2 mt-1" style="width: 13rem;" name="Tous les statuts"
        filterName="status" [values]="status">
      </degineo-select-filter>

      <degineo-date-filter #f class="input-group mr-2 mt-1" style="width: 13rem;" name="Commandé le" filterName="date">
      </degineo-date-filter>

      <degineo-text-filter #f class="input-group mr-2 mt-1" style="width: 13rem;" name="N° de commande"
        filterName="recherche"> </degineo-text-filter>

      <i class="fas fa-undo text-primary resetToDefault ml-2"
        (click)="fliter.resetToDefault(); OrderListComponent.page = 1"></i>

      <degineo-checkbox-group-filter #f class="form-inline float-left mt-4" filterName="checkBox">
        <degineo-checkbox-filter #cb class="mr-4" name="En attente de validation ({{ BOData.nbOrderOrange + 0 }})"
          filterName="warn" color="warning">
        </degineo-checkbox-filter>
        <degineo-checkbox-filter #cb name="Erreur ({{ BOData.nbOrderRed + 0 }})" filterName="error" color="danger">
        </degineo-checkbox-filter>
      </degineo-checkbox-group-filter>
    </div>
  </degineo-filter>

  <degineo-my-table [rows]="orders" [orderCache]="OrderListComponent.OrderCaches"
    [builtFiltersOrder]="builtFiltersOrder" (orderChange)="orderChange($event)" (rowClick)="tableRowClick($event)">
    <degineo-my-column orderName="reference" name="N° de commande">
      <td *cv="let r" class="text-left text-nowrap">
        {{ r.id }} <i class="far fa-comment-dots text-primary ml-1" *ngIf="r.noteDegineo" placement="top"
          [ngbTooltip]="r.noteDegineo"></i>
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="dateCreated" name="Commandé le" [defaultOrder]="OrderBy.DESC">
      <td *cv="let r" class="text-center">
        {{ r.dateCreated | date: 'dd/MM/yyyy' }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="name" name="Client">
      <td *cv="let r" class="text-center">
        {{ r.clientName }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="paymentType" name="Type de paiement">
      <td *cv="let r" class="text-center">
        <ng-container *ngIf="r.paymentType == null">
          Inconnu?
        </ng-container>
        <ng-container *ngIf="r.paymentType != null">
          <ng-container *ngIf="r.paymentType == paymentStatus.WIRETRANSFERT">
            <ng-container *ngIf="r.delayed">
              Virement à 30j
            </ng-container>
            <ng-container *ngIf="!r.delayed">
              Virement direct
            </ng-container>
          </ng-container>
          <ng-container *ngIf="r.paymentType != paymentStatus.WIRETRANSFERT">
            {{ r.paymentType.lib }}
          </ng-container>
        </ng-container>
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="status" name="Statut">
      <td *cv="let r" class="text-center"
        [ngClass]="{ 'text-danger': isRedOrder(r.fileStatus), 'text-success': isOrangeOrder(r.status)  }">
        <ng-container *ngIf="r.fileStatus==fileStatus.FILE_TO_CORRECT">
          <div ngbTooltip="{{ r.status.id }}">{{r.fileStatus.lib}}</div>
        </ng-container>
        <ng-container *ngIf="r.fileStatus!=fileStatus.FILE_TO_CORRECT">
          <div ngbTooltip="{{ r.status.id }}">{{ r.status.lib }}</div>
        </ng-container>
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="dateLastStatusChange" name="Dernier changement de statut">
      <td *cv="let r" class="text-center">
        {{ moment(r.dateLastStatusChange).fromNow() }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="price" name="Chiffre d'affaires">
      <td *cv="let r" class="text-right">{{ r.priceTTC.toFixed(2) | number: '.2':'fr-FR' }}&nbsp;€ TTC</td>
    </degineo-my-column>
  </degineo-my-table>

  <div class="row justify-content-center mt-3" *ngIf="!loading">
    <ngb-pagination [collectionSize]="count" [(page)]="OrderListComponent.page" [pageSize]="nbPerPage" [maxSize]="6"
      [rotate]="true" (pageChange)="reload()" [disabled]="loading"></ngb-pagination>
  </div>
</div>
