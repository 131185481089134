import { Component, OnInit, Input, Inject } from '@angular/core';
import { ConsumableBODTO } from 'degineo-common';
import { ConsumableData } from '../../providers/consumable.provider';
import { SlugService } from 'degineo-common';

@Component({
  selector: 'app-consumable-producers',
  templateUrl: './consumable-producers.component.html',
  styleUrls: ['./consumable-producers.component.scss'],
})
export class ConsumableProducersComponent implements OnInit {
  @Input()
  edit: boolean;

  constructor(public data: ConsumableData, @Inject(SlugService) public slugService: SlugService) {}

  ngOnInit() {}
}
