<div class="row">
  <div class="col-12 col-md-auto">
    <div class="row">
      <div class="col-12 text-center">
        <img
          class="rounded"
          src="{{ data.rpcHost }}companylogo/{{ data.dto.logoToken }}/logo.png?size=large&reload={{ data.numberToForceReload }}"
          alt="Logo producteur"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-center">
        <input type="file" id="file" style="display: none" accept="image/*" #fileInput (change)="handleFileInput($event)" />
        <button class="btn p-0 text-primary" style="font-size: 0.9rem; letter-spacing: normal" (click)="fileInput.click()">Changer l'image</button>
      </div>
      <div class="col-12 text-center text-danger" *ngIf="errorFile">{{ errorFile }}</div>
    </div>

    <div class="row">
      <div class="col-12 text-center">
        <div class="progress" *ngIf="progressPercent">
          <div class="progress-bar" role="progressbar" [style.width]="progressPercent + '%'">{{ progressPercent }}%</div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-auto m-md-0 mt-3">
    <div>
      <div class="fw-500 d-inline-block" style="font-size: 1.5rem">{{ data.dto.producer.name }}</div>
      <div class="text-secondary d-inline-block ml-2">#ID&nbsp;: {{ data.dto.producer.id }}</div>
    </div>
    <div>
      <a class="text-secondary" [routerLink]="null" (click)="toggleEditBusinessType()" *ngIf="!editBusinessType">{{
        data.dto.producer?.businessType === null ? 'Non saisi' : data.dto.producer.businessType.lib
      }}</a>
      <select *ngIf="editBusinessType" class="form-control" (change)="businessTypeChanged()" [(ngModel)]="businessType" [disabled]="loadingEditBusinessType">
        <option [ngValue]="null" disabled>-- Sélectionner --</option>
        <option *ngFor="let bt of businessTypes" [ngValue]="bt">{{ bt.lib }}</option>
      </select>
      <i class="fas fa-spinner spinning" *ngIf="loadingEditBusinessType"></i>
    </div>

    <div *ngIf="data.dto.producer.subscribed">
      <div *ngIf="!editPlan">
        Abonnement&nbsp;:&nbsp;<a [routerLink]="null" (click)="toggleEditPlan()">{{
          data.dto.plan ? data.dto.plan.name + ' - ' + data.dto.plan.producerTarget.lib : 'Aucun abonnement'
        }}</a>
      </div>
      <div *ngIf="editPlan" class="form-inline">
        Abonnement&nbsp;:&nbsp;
        <select class="form-control" [(ngModel)]="idPlan">
          <option [ngValue]="null" disabled="disabled">-- Choisir --</option>
          <option *ngFor="let plan of plans" [ngValue]="plan.id">{{ plan.name }}</option>
        </select>
        <i class="fas fa-check-circle fs-4 text-success pointer no-select ml-2" *ngIf="!loadingSubscribe" (click)="savePlan()"></i>
        <i class="fas fa-times-circle fs-4 text-danger pointer no-select ml-2" *ngIf="!loadingSubscribe" (click)="editPlan = false"></i>
        <i class="fas fa-spinner spinning" *ngIf="loadingSubscribe"></i>
      </div>

      <div *ngIf="!editRemise">
        <a [routerLink]="null" *ngIf="!hasRemise()" (click)="toggleEditRemise()">Aucune remise active</a>
        <a [routerLink]="null" *ngIf="hasRemise()" (click)="toggleEditRemise()"
          >{{ data.dto.producer.discountPercent }}% de remise jusqu'au {{ data.dto.producer.dateDiscountEnd | date: 'dd/MM/yyyy' }}</a
        >
      </div>
      <div *ngIf="editRemise" class="form-inline">
        <ui-switch style="vertical-align: sub" size="small" color="#00c462" defaultBgColor="#D82929" [(ngModel)]="editRemiseActive"> </ui-switch>
        <ng-container *ngIf="editRemiseActive">
          <div class="input-group ml-2" style="width: 6rem">
            <input type="number" class="form-control" [(ngModel)]="percent" />
            <div class="input-group-append">
              <span class="input-group-text">%</span>
            </div>
          </div>
          <span class="mx-2">jusqu'au </span>
          <div class="input-group w-100 w-md-200px">
            <input
              required
              [(ngModel)]="dateDiscountEnd"
              class="form-control"
              style="width: 6rem"
              angular-mydatepicker
              name="mydate"
              [options]="INgxMyDpOptions"
              #dp="angular-mydatepicker"
            />
            <div class="input-group-append">
              <button type="button" class="btn btn-outline-secondary" (click)="dp.toggleCalendar()">
                <i class="far fa-calendar-alt"></i>
              </button>
            </div>
          </div>
        </ng-container>
        <i class="fas fa-check-circle fs-4 text-success pointer no-select ml-2" *ngIf="!loadingSubscribe" (click)="saveRemise()"></i>
        <i class="fas fa-times-circle fs-4 text-danger pointer no-select ml-2" *ngIf="!loadingSubscribe" (click)="editRemise = false"></i>
        <i class="fas fa-spinner spinning" *ngIf="loadingSubscribe"></i>
      </div>

      <div class="fw-500" style="font-size: 30px" *ngIf="!hasRemise() && data.dto.plan !== null">
        {{
          (data.dto.plan.baseSubscriptionPriceHT + data.dto.plan.printerSubscriptionPriceHT * data.dto.nbPrinter) * (1 + tva / 100)
            | currency: 'EUR':'symbol':'1.2-2':'fr'
        }}
        TTC
      </div>
      <div class="fw-500" style="font-size: 25px" *ngIf="hasRemise() && data.dto.plan !== null">
        <span class="text-line-through">
          {{
            (data.dto.plan.baseSubscriptionPriceHT + data.dto.plan.printerSubscriptionPriceHT * data.dto.nbPrinter) * (1 + tva / 100)
              | currency: 'EUR':'symbol':'1.2-2':'fr'
          }}
          TTC
        </span>
        <span class="text-success fw-700 ml-2">
          {{
            (data.dto.plan.baseSubscriptionPriceHT + data.dto.plan.printerSubscriptionPriceHT * data.dto.nbPrinter) *
              (1 + tva / 100) *
              (1 - data.dto.producer.discountPercent / 100) | currency: 'EUR':'symbol':'1.2-2':'fr'
          }}
        </span>
      </div>

      <div *ngIf="data.dto.plan">
        Prix de base&nbsp;:&nbsp;<span class="fw-500">{{ data.dto.plan.baseSubscriptionPriceHT | currency: 'EUR':'symbol':'1.2-2':'fr' }} HT</span>&nbsp;€
      </div>

      <div *ngIf="data.dto.plan">
        Par imprimante&nbsp;:&nbsp;<span class="fw-500">{{ data.dto.plan.printerSubscriptionPriceHT | currency: 'EUR':'symbol':'1.2-2':'fr' }} HT</span>
        <span class="text-secondary fw-500"> x {{ data.dto.nbPrinter }}</span>
      </div>
    </div>
    <div *ngIf="!data.dto.producer.subscribed">
      <div>Abonnement&nbsp;:&nbsp;Aucun abonnement</div>
      <div>Aucune remise active</div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12 col-md-6 mt-3">
    <div class="alert border-secondary border p-4 m-0 h-100">
      <h6 class="text-center mb-4">Titulaire</h6>
      <div style="font-size: 1.2rem">{{ data.dto.adminFirstName }} {{ data.dto.adminLastName | uppercase }}</div>
      <div><span class="fw-500">Tél.</span>&nbsp;:&nbsp;{{ data.dto.adminTel }}</div>
      <div><span class="fw-500">E-mail</span>&nbsp;:&nbsp;{{ data.dto.adminEmail }}</div>
    </div>
  </div>
  <div class="col-12 col-md-6 mt-3">
    <div class="alert border-secondary border p-4 m-0 h-100">
      <h6 class="text-center mb-4">Adresse</h6>
      <ng-container *ngIf="data.dto.address === null">
        <div>Pas d'adresse définie</div>
      </ng-container>
      <ng-container *ngIf="data.dto.address !== null">
        <div>
          {{ data.dto.address.line1 }}<br />
          <ng-container *ngIf="data.dto.address.line2 !== null && data.dto.address.line2 !== ''">{{ data.dto.address.line2 }}<br /></ng-container>
          <ng-container *ngIf="data.dto.address.line3 !== null && data.dto.address.line3 !== ''">{{ data.dto.address.line3 }}</ng-container>
        </div>
        <div>{{ data.dto.address.zipCode }} - {{ data.dto.address.city }}</div>
        <div>{{ data.dto.address.country | uppercase }}</div>
      </ng-container>
    </div>
  </div>
</div>
