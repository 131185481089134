<div class="row">
  <div class="col">
    <span class="fw-500">Client&nbsp;:</span>
    <a [routerLink]="'/clients/' + slugService.string_to_slug(data.dto.clientCompanyName) + '/' + data.dto.order.idClient">
      {{ data.dto.clientFirstName }} {{ data.dto.clientLastName | uppercase }}
    </a>
  </div>
</div>
<div class="row">
  <div class="col">
    <span class="fw-500">Entreprise&nbsp;:</span>
    <span class="ml-1">{{ data.dto.clientCompanyName }}</span>
  </div>
</div>
<div class="row">
  <div class="col">
    <span class="fw-500">Tél.&nbsp;:</span>
    <span class="ml-1">{{ data.dto.clientPhoneNumber }}</span>
  </div>
</div>
<div class="row">
  <div class="col">
    <span class="fw-500">E-mail&nbsp;:</span>
    <span class="ml-1">{{ data.dto.clientEmail }}</span>
  </div>
</div>
