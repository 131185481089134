<div class="container pt-4 pb-4" *ngIf="!loading">
  <div class="row">
    <div class="col">
      <button type="button" class="btn btn-secondary" (click)="backClicked()"><i class="fas fa-chevron-left"></i> Retour</button>
    </div>
  </div>

  <div class="row">
    <div class="col text-center">
      <h1>{{ data.dto.companyName }}</h1>
    </div>
  </div>

  <div class="row">
    <div class="col text-center" *ngIf="!editSiret">
      SIRET : {{ data.dto.siret | mask: '000 000 000 00000' }} <i class="fas fa-pen-square pointer text-primary" (click)="editSiret = true"></i>
    </div>
    <div class="col justify-content-center form-inline" *ngIf="editSiret">
      <form autocomplete="off" #f="ngForm" [formGroup]="form" [class.show-errors]="f.submitted && !form.valid" (ngSubmit)="updateSiret()">
        SIRET :
        <input type="text" class="form-control ml-2" formControlName="siret" mask="000 000 000 00000" />
        <i class="fas fa-check-circle fs-4 text-success pointer no-select ml-2" *ngIf="!loadingSiret" (click)="f.ngSubmit.emit()"></i>
        <i class="fas fa-times-circle fs-4 text-danger pointer no-select ml-2" *ngIf="!loadingSiret" (click)="editSiret = false"></i>
        <i class="fas fa-spinner spinning" *ngIf="loadingSiret"></i>
      </form>
    </div>
  </div>
  <div class="row" *ngIf="error !== null">
    <div class="col">
      <div class="alert alert-danger">
        <ul>
          <li>
            {{ error }}
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-12 col-md-6">
      <app-client-info-left></app-client-info-left>
    </div>
    <div class="col-12 col-md-6">
      <app-client-info-right (activeChange)="activeChange()" (deleteClient)="deleteClient()"></app-client-info-right>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-12">
      <div class="fw-500 d-inline-block">Producteurs associés :</div>
      <a
        [routerLink]="'/producers/edit/' + slugService.string_to_slug(producer.name) + '/' + producer.id + '/infos'"
        class="d-inline-block ml-1"
        [class.text-warning]="producer.status === ClientProducerAssociationStatus.WAITING_CLIENT_ACCEPTATION"
        [ngbTooltip]="
          producer.status === ClientProducerAssociationStatus.WAITING_CLIENT_ACCEPTATION ? 'Demande depuis ' + moment(producer.dateCreated).fromNow(true) : null
        "
        *ngFor="let producer of data.dto.assocProducer"
        >{{ producer.name }}</a
      >
      <div class="d-inline-block ml-1" *ngIf="data.dto.assocProducer.length == 0">Aucun</div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-12">
      <textarea class="form-control" style="resize: none" placeholder="Notes" rows="5" [(ngModel)]="data.dto.degineoComment"></textarea>
    </div>
  </div>
  <div class="row justify-content-end mt-2">
    <div class="col-md-auto col-12 pr-md-1 mt-2 mt-md-1 order-1 order-md-0">
      <button class="btn btn-secondary w-100" style="min-width: 200px" type="button" (click)="cancelNoteClicked()" *ngIf="displayBtNote()">
        Annuler les modifications
      </button>
    </div>
    <div class="col-md-auto col-12 pl-md-1 mt-1 order-0 order-md-1">
      <button class="btn btn-success w-100" style="min-width: 200px" type="button" (click)="saveNoteClicked()" *ngIf="displayBtNote()">
        Enregistrer les modifications
      </button>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-12">
      <div class="p-4 d-block alert border-secondary border" style="padding-bottom: 0 !important">
        <app-client-order-list></app-client-order-list>
      </div>
    </div>
  </div>
</div>
