import { Component, OnInit, Inject } from '@angular/core';
import { ProducerData } from '../../../../providers/producer.provider';
import { ProducerBOService, LegalStatus } from 'degineo-common';
import { L } from 'ic2-lib';
import { TVAIntracom } from 'degineo-common';

@Component({
  selector: 'app-producer-billing',
  templateUrl: './producer-billing.component.html',
  styleUrls: ['./producer-billing.component.scss'],
})
export class ProducerBillingComponent implements OnInit {
  edit: boolean = false;
  legalStatus: LegalStatus;
  denomination: string;
  capital: number;
  RCS: string;
  siret: string;
  RNANumber: string;

  LegalStatus: typeof LegalStatus = LegalStatus;

  getTVAIntracom = TVAIntracom.getTVAIntracom;

  constructor(public data: ProducerData, @Inject(ProducerBOService) public producerBOService: ProducerBOService) {}

  ngOnInit() {
    this.legalStatus = this.data.dto.producer.legalStatus;
    this.capital = this.data.dto.producer.capital;
    this.RCS = this.data.dto.producer.RCS;
    this.siret = this.data.dto.producer.siret;
    this.denomination = this.data.dto.producer.denomination;
    this.RNANumber = this.data.dto.producer.RNANumber;
  }

  cancelClicked() {
    this.legalStatus = this.data.dto.producer.legalStatus;
    this.capital = this.data.dto.producer.capital;
    this.RCS = this.data.dto.producer.RCS;
    this.siret = this.data.dto.producer.siret;
    this.denomination = this.data.dto.producer.denomination;
    this.RNANumber = this.data.dto.producer.RNANumber;
    this.edit = false;
  }

  saveClicked() {
    this.producerBOService
      .updateBilling(this.data.dto.producer.id, this.denomination, this.legalStatus, this.capital, this.RNANumber, this.RCS, this.siret)
      .subscribe(
        (data) => {
          console.log(data);
          this.data.dto = data;
          this.edit = false;
        },
        (error) => {
          L.e(error);
        }
      );
  }
}
