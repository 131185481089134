import { Component, OnInit, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { PrinterData } from '../providers/printer.provider';
import { PrinterBOService, PrinterNEW } from 'degineo-common';
import { L } from 'ic2-lib';
import { PrinterBODTO } from 'degineo-common';
import { BrandConsumableService } from 'degineo-common';
import { BrandPrinterService } from 'degineo-common';
import { SlugService } from 'degineo-common';

@Component({
  selector: 'app-printer-view-edit',
  templateUrl: './printer-view-edit.component.html',
  styleUrls: ['./printer-view-edit.component.scss'],
})
export class PrinterViewEditComponent implements OnInit {
  private sub: Subscription;
  loading: boolean = true;
  edit: boolean;
  new: boolean = false;

  id: number;

  constructor(
    @Inject(ActivatedRoute) public route: ActivatedRoute,
    @Inject(PrinterBOService) public printerBOService: PrinterBOService,
    @Inject(BrandPrinterService) private brandPrinterService: BrandPrinterService,
    @Inject(SlugService) private slugService: SlugService,
    private router: Router,
    public data: PrinterData
  ) {}

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      if (params['view'] == 'new') {
        this.new = true;
        this.edit = true;

        this.data.clear();

        let p = new PrinterBODTO();
        p.printer = new PrinterNEW();
        p.assocConsumable = [];
        p.businessTargets = [];

        this.data.dto = p;
        this.setNullToZero();
      } else {
        this.new = false;

        if ((params['view'] !== 'view' && params['view'] !== 'edit' && params['view'] !== 'new') || params['id'] === undefined)
          this.router.navigate(['/consumables']);

        if (params['view'] == 'edit') this.edit = true;
        else this.edit = false;

        if (this.id !== parseInt(params['id'])) {
          this.id = parseInt(params['id']);
          this.data.clear();
        }
      }
      this.reload();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  reload() {
    this.loading = true;

    if (this.edit) {
      if (!this.data.brandList)
        this.brandPrinterService.getAllPrinterBrand().subscribe(
          (data) => {
            this.data.brandList = data;
          },
          (error) => {
            L.e(error);
          }
        );
    }

    if (!this.data.dto)
      this.printerBOService.getPrinterDetail(this.id).subscribe(
        (data) => {
          this.data.dto = data;
          this.loading = false;
        },
        (error) => {
          L.e(error);
        }
      );
    else {
      this.data.dto = this.data.dto;
      this.loading = false;
    }

    console.log('data', this.data);
  }

  backClicked() {
    this.router.navigate(['/printers']);
  }

  editClicked() {
    this.router.navigate(['/printers', 'edit', this.slugService.string_to_slug(this.data.dto.printer.name), this.id]);
  }

  cancelClicked() {
    if (this.new) {
      this.router.navigate(['/printers']);
    } else {
      let slug = this.slugService.string_to_slug(this.data.dto.printer.name);
      this.data.dto = null;
      this.data.file = null;
      this.router.navigate(['/printers', 'view', slug, this.id]);
    }
  }

  deleteClicked() {
    this.printerBOService.deletePrinter(this.id).subscribe(
      (data) => {
        if (data) this.router.navigate(['/printers']);
      },
      (error) => {
        L.e(error);
      }
    );
  }

  saveEditClicked() {
    this.setNullToZero();
    if (this.data.dto.printer.name && this.data.dto.printer.printerTechnology && this.data.dto.printer.brand) {
      if (this.data.file) {
        this.printerBOService
          .uploadImg(this.id)
          .withFile(this.data.file)
          .subscribeWithProgress(
            (data) => {
              //console.log('SAVE LOGO', data);
              this.data.file = null;
              this.data.numberToForceReload++;
              this.saveEdit();
            },
            (loaded, total) => {
              //console.log('SAVE LOGO progress', loaded, total);
            },
            (error) => {
              L.e('SAVE LOGO err', error);
            }
          );
      } else {
        this.saveEdit();
      }
    }
  }

  saveEdit() {
    this.printerBOService.updatePrinter(this.data.dto, this.id).subscribe(
      (data) => {
        console.log('updatePrinter', data);
        this.data.clear();
        if (data) this.router.navigate(['/printers', 'view', this.slugService.string_to_slug(data.printer.name), data.printer.id]);
      },
      (error) => {
        L.e(error);
      }
    );
  }

  newClicked() {
    this.setNullToZero();
    if (this.data.dto.printer.name && this.data.dto.printer.printerTechnology && this.data.dto.printer.brand) {
      const rpcReq = this.printerBOService.newPrinter(this.data.dto);
      if (this.data.file) {
        rpcReq.withFile(this.data.file);
        console.log('with file', this.data.file);
      }
      rpcReq.subscribeWithProgress(
        (data) => {
          console.log('new', data);
          this.data.clear();
          if (data) this.router.navigate(['/printers', 'view', this.slugService.string_to_slug(data.printer.name), data.printer.id]);
        },
        (loaded, total) => {},
        (error) => {
          L.e(error);
        }
      );
    }
  }

  setNullToZero() {
    if (this.data.dto.printer.sellingPrice == null) this.data.dto.printer.sellingPrice = 0;
    if (this.data.dto.printer.sizeXmm == null) this.data.dto.printer.sizeXmm = 0;
    if (this.data.dto.printer.sizeYmm == null) this.data.dto.printer.sizeYmm = 0;
    if (this.data.dto.printer.sizeZmm == null) this.data.dto.printer.sizeZmm = 0;
    if (this.data.dto.printer.minimalPrintingSize == null) this.data.dto.printer.minimalPrintingSize = 0;
    if (this.data.dto.printer.printerVerticalSpeed == null) this.data.dto.printer.printerVerticalSpeed = 0;
  }
}
