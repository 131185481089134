<div class="container pt-4 pb-4">
  <degineo-filter
    class="d-inline-block w-100 mt-4 mb-4"
    #fliter
    [builtFilters]="builtFilters"
    (filterChanged)="filterChanged($event)"
    [cache]="ProducerPrinterConsumableTabComponent.filtersCache"
    [default]="filtersDefault"
  >
    <div class="form-inline">
      <degineo-textassist-filter
        #f
        [values]="brandFilter"
        class="input-group mr-2 mt-1"
        style="width: 13rem"
        name="Toutes les marques"
        filterName="brand"
        [presenter]="presenterName"
      ></degineo-textassist-filter>

      <degineo-select-filter
        #f
        [values]="typeFilter"
        class="input-group mr-2 mt-1"
        style="width: 13rem"
        name="Toutes les catégories"
        filterName="categ"
      ></degineo-select-filter>

      <degineo-select-filter
        #f
        class="input-group mr-2 mt-1"
        style="width: 13rem"
        name="Toutes les technologies"
        filterName="techno"
        [values]="technologyFilter"
        [presenter]="presenterName"
      >
      </degineo-select-filter>

      <degineo-select-filter
        #f
        [values]="data.articleTypes"
        class="input-group mr-2 mt-1"
        style="width: 13rem"
        name="Tous les types de pièces"
        filterName="type"
        [presenter]="presenterTitle"
      ></degineo-select-filter>

      <i class="fas fa-undo text-primary resetToDefault ml-2" (click)="fliter.resetToDefault()"></i>

      <degineo-checkbox-group-filter #f class="form-inline float-left mt-4" filterName="checkBox">
        <degineo-round-checkbox-filter #cb class="mr-4" name="Imprimante disponible" filterName="disponible" color="success"> </degineo-round-checkbox-filter>
        <degineo-round-checkbox-filter #cb class="mr-4" name="Imprimante indisponible" filterName="indisponible" color="danger">
        </degineo-round-checkbox-filter>
        <degineo-checkbox-filter #cb class="mr-4" name="Validée" filterName="valid" color="success"> </degineo-checkbox-filter>
        <degineo-checkbox-filter #cb name="En attente ({{ data.nbProducerWarning + 0 }})" filterName="wait" color="warning"> </degineo-checkbox-filter>
      </degineo-checkbox-group-filter>
    </div>
  </degineo-filter>

  <app-producer-printer-consumable-info *ngFor="let ppc of printers" class="d-block mt-3" [ppc]="ppc"> </app-producer-printer-consumable-info>
</div>
