import { Component, OnInit, Host, Inject, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { PaymentWorkflowComponent } from '../payment-workflow/payment-workflow.component';
import { PaymentDataService } from '../payment-data.service';
import { ClientInvoiceDTO, AuthService } from 'degineo-common';

@Component({
  selector: 'app-payment-step2',
  templateUrl: './payment-step2.component.html',
  styleUrls: ['./payment-step2.component.scss'],
})
export class PaymentStep2Component implements OnInit {
  PaymentDataService = PaymentDataService;
  @Input()
  modeFacturePaiementA30j: boolean = false;
  @Input()
  producerName: string = null;
  @Input()
  invoice: ClientInvoiceDTO;
  @Output()
  close: EventEmitter<string> = new EventEmitter<string>();

  constructor(@Inject(PaymentDataService) public paymentDataService: PaymentDataService, @Inject(AuthService) private authService: AuthService) {}

  ngOnInit() {
    console.log(this.modeFacturePaiementA30j);
  }

  switchTo(paymentType: number) {
    this.paymentDataService.paymentMethod = paymentType;
  }

  goBack() {
    if (this.modeFacturePaiementA30j) this.closePopup('Retour');
    else this.paymentDataService.step = PaymentDataService.STEP_ADDRESS;
  }

  closePopup(reason) {
    this.close.emit(reason);
  }
}
