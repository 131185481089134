<h6 class="text-center mb-4">Consommables associées</h6>

<div *ngIf="edit" class="row">
  <ic2-textassist
    class="col-5 pl-1 pr-1"
    placeholder="Marque"
    [values]="consumableBrandList"
    [presenter]="presenterName"
    (textChanges)="brandTextChanged($event)"
    (objectChange)="brandChanged($event)"
    [(ngModel)]="brandName"
    required
  >
  </ic2-textassist>
  <ic2-textassist
    [disabled]="brandName === null"
    class="col-6 pl-1 pr-1"
    placeholder="Consommable"
    [values]="consumableList"
    [presenter]="presenterConsumable"
    (textChanges)="consumableTextChanged($event)"
    (objectChange)="consumableChanged($event)"
    [(ngModel)]="consumableName"
    required
  >
  </ic2-textassist>
  <button [disabled]="brandName === null || idConsumableOption == 0" class="col-1 btn btn-primary-outline p-0" (click)="add()">
    <i style="font-size: 30px" class="far fa-plus-square text-success"></i>
  </button>
</div>
<span class="ml-1 mt-1 text-danger" *ngIf="err != ''">{{ err }}</span>

<div class="mt-4">
  <ng-container *ngFor="let consumableOption of data.dto.assocConsumable">
    <a
      *ngIf="consumableOption.idConsumableOption"
      [routerLink]="'/consumables/view/' + consumableOption.idConsumable + '/' + consumableOption.consumableName"
      class="d-block text-left"
    >
      {{ consumableOption.brand }} - <span class="fw-300">{{ consumableOption.consumableName }}</span></a
    >
    <span class="d-block text-left" *ngIf="!consumableOption.idConsumableOption">
      {{ consumableOption.brand }} - <span class="fw-300">{{ consumableOption.consumableName }}</span>
    </span>
  </ng-container>
</div>

<div *ngIf="data.dto.assocConsumable">
  <div class="text-center" *ngIf="data.dto.assocConsumable.length === 0">Aucun consommable associée</div>
</div>
