import { Component, OnInit, Renderer2, OnDestroy, Input, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DegineoRight } from 'degineo-common';
import { AuthService } from 'degineo-common';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  @Input()
  email: string = '';
  @Input()
  password: string = '';
  loginError: string;
  loading: boolean = false;

  constructor(@Inject(AuthService) public authService: AuthService, @Inject(Router) public router: Router, @Inject(Renderer2) private renderer: Renderer2) {}

  ngOnInit() {
    this.renderer.addClass(document.body, 'login-bg');
    this.authService.isLoggedIn().subscribe(
      (data) => {
        let loggedIn = data && this.authService.has(DegineoRight.ADMIN);
        if (loggedIn) {
          this.router.navigate(['/']);
        }
      },
      (error) => {}
    );
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'login-bg');
  }

  login() {
    this.loading = true;
    this.authService.login(this.email, this.password).subscribe(
      (event) => {
        this.loading = false;

        let loggedIn = event.userBundle && AuthService.has(event.userBundle, DegineoRight.ADMIN);
        if (!loggedIn) {
          event.permitLogin = false;
          this.loginError = "Ce compte n'est pas un compte admin";
          return;
        }
        if (event.redirectUrl === null) event.redirectUrl = '/';
      },
      (error) => {
        this.loading = false;
        if (error.isBusinessError()) this.loginError = 'Utilisateur ou mot de passe incorrect';
        else {
          L.e(error);
          this.loginError = 'Une erreur inconnue est survenue';
        }
      }
    );
  }
}
