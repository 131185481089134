<div class="row">
  <div class="col text-center">
    <h6>Matières associées</h6>
  </div>
</div>
<div class="row mt-3" *ngIf="!edit">
  <div class="col-auto">
    <ul>
      <ng-container *ngFor="let p of getParentMaterialChecked()">
        <li>
          <label class="fw-400 pl-0 no-select">
            {{ p.name }}
          </label>
          <ul>
            <li *ngFor="let m of getChildrenChecked(p.id)">
              <label class="fw-400 pl-0 no-select">
                {{ m.name }}
              </label>
            </li>
          </ul>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
<div class="row mt-3" *ngIf="edit">
  <div class="col-auto">
    <ul>
      <ng-container *ngFor="let p of getParentMaterial()">
        <li>
          <label class="fw-400 pl-0 no-select"> <input type="checkbox" [checked]="has(p)" (change)="checkChange($event, p)" /> {{ p.name }} </label>
          <ul>
            <li *ngFor="let m of getChildren(p.id)">
              <label class="fw-400 pl-0 no-select"> <input type="checkbox" [checked]="has(m)" (change)="checkChange($event, m)" /> {{ m.name }} </label>
            </li>
          </ul>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
