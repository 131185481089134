import { Component, OnInit, Inject } from '@angular/core';
import { MaterialTypeCharacteristicData } from '../providers/materialTypeCharacteristic.provider';
import { MaterialTypeCharacteristicBOService } from 'degineo-common';
import { L } from 'ic2-lib';
import { MaterialTypeService } from 'degineo-common';
import { CharacteristicService } from 'degineo-common';
import { MaterialTypeCharacteristicBODTO } from 'degineo-common';

@Component({
  selector: 'app-material-type-characteristic-view-edit',
  templateUrl: './material-type-characteristic-view-edit.component.html',
  styleUrls: ['./material-type-characteristic-view-edit.component.scss'],
})
export class MaterialTypeCharacteristicViewEditComponent implements OnInit {
  loading: boolean = true;

  new: boolean = false;

  constructor(
    @Inject(MaterialTypeCharacteristicBOService) private materialTypeCharacteristicBOService: MaterialTypeCharacteristicBOService,
    @Inject(MaterialTypeService) private materialTypeService: MaterialTypeService,
    @Inject(CharacteristicService) private characteristicService: CharacteristicService,
    public data: MaterialTypeCharacteristicData
  ) {}

  ngOnInit() {
    this.reload();
  }

  reload() {
    this.loading = true;

    this.materialTypeService.getAllParentsMaterialTypes().subscribe(
      (data) => {
        console.log('getAllParentsMaterialTypes', data);
        this.data.materialParentList = data;
      },
      (error) => {
        L.e(error);
      }
    );

    this.characteristicService.getAllCharacteristics().subscribe(
      (data) => {
        console.log('getAllCharacteristics', data);
        this.data.characteristicList = data;
      },
      (error) => {
        L.e(error);
      }
    );

    this.materialTypeCharacteristicBOService.getAll().subscribe(
      (data) => {
        console.log('data', data);
        this.data.dto = data;
        this.loading = false;
      },
      (error) => {
        L.e(error);
      }
    );
  }

  update() {
    this.materialTypeService.getAllParentsMaterialTypes().subscribe(
      (data) => {
        console.log('getAllParentsMaterialTypes', data);
        this.data.materialParentList = data;
      },
      (error) => {
        L.e(error);
      }
    );

    this.characteristicService.getAllCharacteristics().subscribe(
      (data) => {
        console.log('getAllCharacteristics', data);
        this.data.characteristicList = data;
      },
      (error) => {
        L.e(error);
      }
    );
  }

  newClicked() {
    this.new = !this.new;
  }
}
