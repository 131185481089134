import { ModelData } from 'degineo-common';

export class UploadedFile {
  file: File;
  name: string;
  fileHash: string;
  progress: number = 0;
  modelData: ModelData;
  good: boolean;
}
