<div class="container-fluid" style="margin-top: 15vh;">
  <div class="row" style="margin-bottom: 6rem;">
    <div class="col text-center">
      <img src="assets/logo_degineo_bleu_vert.svg" style="height: 6rem;" />
    </div>
  </div>
  <div class="row">
    <div class="col text-center fs-5 fw-300">
      Bienvenue sur Degineo&nbsp;!
    </div>
  </div>
  <div class="row mt-5">
    <div class="col text-center fs-4">
      <span class="fw-500">Votre inscription est maintenant terminée.</span><br />
      Vous pouvez dès à présent imprimer rapidement et simplement vos Fichiers&nbsp;3D en ligne.<br />
      Si vous avez la moindre question, n’hésitez pas à nous contacter via le <a [routerLink]="null" (click)="openChat()">Chat&nbsp;live</a> situé en bas à
      droite.<br />
    </div>
  </div>
  <div class="row mt-5">
    <div class="col text-center">
      <a class="btn btn-success" routerLink="/impression">Continuer</a>
    </div>
  </div>
</div>
