import { Component, OnInit, Inject } from '@angular/core';
import { PrintDataService } from '../print-data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BasketService, ColorNEW, ClientMarqueBlancheService } from 'degineo-common';
import { L } from 'ic2-lib';
import { OrderService } from '../../../MarqueBlanche/order.service';
import { environment } from 'environments/environment';
import { UploadedFile } from '../../../MarqueBlanche/UploadedFile';

@Component({
  selector: 'app-print-step4',
  templateUrl: './print-step4.component.html',
  styleUrls: ['./print-step4.component.scss'],
})
export class PrintStep4Component implements OnInit {
  error: string;
  redirecting: boolean;
  loading: boolean = false;
  rpcHost: string = environment.rpcHost;
  fromSV: boolean = false;

  constructor(
    @Inject(PrintDataService) public printDataService: PrintDataService,
    @Inject(BasketService) public basketService: BasketService,
    @Inject(Router) public router: Router
  ) {}

  ngOnInit() {
    this.fromSV = this.printDataService.fromSV;
  }
  quantityChanged(qty) {
    this.printDataService.quantity = qty;
  }

  canNextQuantityStep() {
    return !this.loading && this.printDataService.wishedDeliveryDate !== undefined && this.printDataService.wishedDeliveryDate !== null;
  }

  deliveryDateChanged(date) {
    console.log(date);
    this.printDataService.wishedDeliveryDate = date;
  }

  next() {
    let idColor = 0;
    let multicolor = false;
    if (this.printDataService.selectedColor === OrderService.MULTICOLOR_STR) {
      multicolor = true;
    } else if (this.printDataService.selectedColor !== null) {
      idColor = (<ColorNEW>this.printDataService.selectedColor).id;
    }
    let idMaterial = 0;
    if (this.printDataService.selectedMaterial !== null) {
      idMaterial = this.printDataService.selectedMaterial.id;
    }
    let idFinish = 0;
    if (this.printDataService.selectedFinish !== null) idFinish = this.printDataService.selectedFinish.id;

    if (this.redirecting) {
      console.error('already clicked'); //maybe handle disabled in future ?
      return;
    }
    this.redirecting = true;
    this.loading = true;
    this.basketService
      .addBasket(
        this.printDataService.files.map((uf) => uf.fileHash),
        this.printDataService.name,
        this.printDataService.comment,
        this.printDataService.idProducer,
        this.printDataService.articleTypeSelected.id,
        idMaterial,
        idColor,
        multicolor,
        idFinish,
        this.printDataService.size,
        this.printDataService.quantity,
        this.printDataService.wishedDeliveryDate,
        this.fromSV
      )
      .subscribe(
        (data) => {
          this.loading = false;
          this.printDataService.reset();
          this.router.navigate(['/panier']);
        },
        (error) => {
          this.loading = false;
          this.redirecting = false;
          L.e(error);
          this.error = error.message;
        }
      );
  }
  previous() {
    this.printDataService.step = PrintDataService.STEP_PRINT_SETTINGS;
    this.router.navigate(['/impression', 'etape-3']);
  }
}
