import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { environment } from 'environments/environment';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { DateStringFr } from 'degineo-common';

@Component({
  selector: 'app-quantity-choice-step',
  templateUrl: './quantity-choice-step.component.html',
  styleUrls: ['./quantity-choice-step.component.scss'],
})
export class QuantityChoiceStepComponent implements OnInit {
  @Output()
  next: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  previous: EventEmitter<void> = new EventEmitter<void>();
  @Input()
  modeDevis: boolean;
  @Input()
  dentaire: boolean;
  @Input()
  disablePrevious: boolean;
  @Input()
  set date(date: Date) {
    this.innerDate = { isRange: false, singleDate: { jsDate: date } };
  }
  innerDate: IMyDateModel = { isRange: false, singleDate: { jsDate: new Date() } };

  @Output()
  quantityChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output()
  dateChanged: EventEmitter<Date> = new EventEmitter<Date>();
  @Input()
  unitPriceHT: number;
  @Input()
  nextAvailable: boolean = false;
  @Input()
  quantity: number = 1;
  @Input()
  loading: boolean = false;
  @Input()
  shouldShowSuivant: boolean = false;

  TVA: number = environment.tva;
  public INgxMyDpOptions: IAngularMyDpOptions = Object.assign(
    { disableUntil: { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() }, disableWeekends: true },
    DateStringFr.INgxMyDpOptions
  );

  constructor() {}

  ngOnInit() {}
}
