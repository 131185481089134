import { Component, OnInit, Input, Host } from '@angular/core';
import { NotifDTO, NotifType } from 'degineo-common';
import { JSONSerializer } from 'ic2-lib';
import { FrontLayoutComponent } from '../front-layout.component';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  @Input()
  notif: NotifDTO;
  @Input()
  mobile: boolean;
  NotifType: typeof NotifType = NotifType;

  constructor(@Host() public frontLayoutComponent: FrontLayoutComponent) {}

  ngOnInit() {}

  deserializeDate(date) {
    if (date === undefined) return null;
    else if (date.e) return JSONSerializer.fromJSON(date, 'java.time.Instant');
    else if (date.y) return JSONSerializer.fromJSON(date, 'java.time.LocalDateTime');
    else console.error('NON GERE notification component !');
  }

  closeNotif() {
    this.frontLayoutComponent.toggleNotification();
  }
}
