import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProducerBOService } from 'degineo-common';
import { L } from 'ic2-lib';
import { ProducerData } from '../../providers/producer.provider';

@Component({
  selector: 'app-producer-infos-tab',
  templateUrl: './producer-infos-tab.component.html',
  styleUrls: ['./producer-infos-tab.component.scss'],
})
export class ProducerInfosTabComponent implements OnInit, OnDestroy {
  public id: number;
  private sub: Subscription;
  loading: boolean = true;
  successMarqueBlanche: string;
  errorMarqueBlanche: string;

  noteDegineo: string;

  constructor(
    public data: ProducerData,
    @Inject(ActivatedRoute) public route: ActivatedRoute,
    @Inject(ProducerBOService) public producerBOService: ProducerBOService
  ) {}

  reload() {
    console.log(this.id);
    this.loading = true;
    this.producerBOService.getProducerDetails(this.id).subscribe(
      (data) => {
        this.loading = false;
        console.log(data);
        this.data.dto = data;
        this.noteDegineo = data.producer.noteDegineo;
      },
      (error) => {
        L.e(error);
      }
    );
  }

  ngOnInit() {
    this.data.numberToForceReload++;
    this.sub = this.route.params.subscribe((params) => {
      if (params['id'] === undefined) return;

      this.id = +params['id'];
      if (!this.data.dto || this.data.dto.producer.id != this.id) this.reload();
      else {
        this.noteDegineo = this.data.dto.producer.noteDegineo;
        this.loading = false;
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  displayBtNote() {
    if (this.noteDegineo == null || this.noteDegineo == '')
      if (this.data.dto.producer.noteDegineo == null || this.data.dto.producer.noteDegineo == '') return false;
      else return true;
    else if (this.noteDegineo != this.data.dto.producer.noteDegineo) return true;
    else return false;
  }
  cancelNoteClicked() {
    this.data.dto.producer.noteDegineo = this.noteDegineo;
  }
  saveNoteClicked() {
    this.producerBOService.saveDegineoNote(this.id, this.data.dto.producer.noteDegineo).subscribe(
      (data) => {
        this.noteDegineo = this.data.dto.producer.noteDegineo;
      },
      (error) => {
        L.e(error);
      }
    );
  }
}
