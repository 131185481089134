import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { UserBOService, DegineoUser, UserWithDUDTO } from 'degineo-common';
import { L } from 'ic2-lib';
import { User } from 'degineo-common';
import { ProducerData } from '../../../providers/producer.provider';
import { ProducerBOService } from 'degineo-common';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
@Component({
  selector: 'app-producer-user-new',
  templateUrl: './producer-user-new.component.html',
  styleUrls: ['./producer-user-new.component.scss'],
})
export class ProducerUserNewComponent implements OnInit {
  constructor(
    private router: Router,
    public data: ProducerData,
    @Inject(UserBOService) private userBOService: UserBOService,
    @Inject(ProducerBOService) private producerBOService: ProducerBOService,
    @Inject(FormBuilder) private fb: FormBuilder
  ) {}

  @Output()
  returnToList = new EventEmitter();

  userEmail: string;
  new: boolean = true;
  user: UserWithDUDTO = new UserWithDUDTO();
  err: string = '';

  clicked: boolean = false;

  randomPass: string;

  registerForm: FormGroup = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
    lastname: [null, [Validators.required]],
    firstname: [null, [Validators.required]],
    phone: [null, [Validators.required, Validators.pattern(/^(?:\+33\s|0)[1-9](?:\d{2}){4}$/)]],
  });

  ngOnInit() {}

  validateEmail() {
    this.err = '';

    this.userEmail = this.registerForm.value.email;

    console.log('this.user', this.user);

    this.userBOService.getUserByEmail(this.registerForm.value.email).subscribe(
      (data) => {
        if (data.user.idUser == 0) {
          this.new = true;
          this.user = data;
        } else {
          this.new = false;
          this.user = data;
          this.registerForm.patchValue({
            firstname: this.user.user.firstName,
            lastname: this.user.user.lastName,
            phone: this.user.du.phoneNumber,
          });
        }
        this.validateStep2();
      },
      (err) => {
        if (err.isBusinessError()) {
          this.err = err.message;
          this.user = new UserWithDUDTO();
          this.user.user = new User();
          this.user.du = new DegineoUser();
          this.registerForm.controls['firstname'].reset();
          this.registerForm.controls['lastname'].reset();
          this.registerForm.controls['phone'].reset();
        } else L.e(err);
      }
    );
  }

  validateStep2() {
    if (this.new) {
      this.randomPass = Math.random().toString(36).slice(-8);
      this.user.user.password = this.randomPass;
    }
    this.user.user.firstName = this.registerForm.value.firstname;
    this.user.user.lastName = this.registerForm.value.lastname;
    if (this.user.du == null) this.user.du = new DegineoUser();
    this.user.du.phoneNumber = this.registerForm.value.phone;

    this.saveUser();
  }

  cancelStep2() {
    this.registerForm.controls['firstname'].reset();
    this.registerForm.controls['lastname'].reset();
    this.registerForm.controls['phone'].reset();
    this.registerForm.controls['email'].reset();
    this.user = new UserWithDUDTO();
    this.user.user = new User();
    this.user.du = new DegineoUser();
    this.randomPass = null;
  }

  cancelRegistration() {
    this.returnToList.emit(null);
  }

  saveUser() {
    this.clicked = true;
    if (this.user.user.firstName && this.user.user.lastName && this.user.du.phoneNumber && this.registerForm.get('email').valid) {
      console.log(this.user.user.firstName, this.user.user.lastName, this.user.user.email);
      this.user.user.email = this.userEmail;

      this.producerBOService.newProducerHolder(this.user, this.data.dto.producer.id).subscribe(
        () => {
          this.producerBOService.getProducerDetails(this.data.dto.producer.id).subscribe(
            (data) => {
              this.data.dto = data;
              this.returnToList.emit(null);
            },
            (error) => {
              L.e(error);
            }
          );
        },
        (err) => {
          L.e(err);
        }
      );
    }
  }
}
