import { Component, OnInit, Host, Inject, Input, AfterViewInit, AfterViewChecked, OnChanges } from '@angular/core';
import { PaymentStep2Component } from '../payment-step2/payment-step2.component';
import { PaymentDataService } from '../payment-data.service';
import { PaymentService, AuthService, IbanBicDTO } from 'degineo-common';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-payment-step2-wiretransfert',
  templateUrl: './payment-step2-wiretransfert.component.html',
  styleUrls: ['./payment-step2-wiretransfert.component.scss'],
})
export class PaymentStep2WiretransfertComponent implements OnInit {
  paiementA30j: boolean = false;
  paiementA30jShowConfirm: boolean = false;
  nextMonth: Date = new Date();
  //Utilisé pour l'affichage du payement d'une facture
  @Input()
  modeFacturePaiementA30j: boolean = false;
  moisApostrophes = [3 /*avril*/, 7 /*aout*/, 9 /*octobre*/];
  degineoBankInfos: IbanBicDTO = null;
  loading: boolean = false;

  constructor(
    @Host() public paymentStep2: PaymentStep2Component,
    @Inject(PaymentDataService) public paymentDataService: PaymentDataService,
    @Inject(PaymentService) public paymentService: PaymentService,
    @Inject(AuthService) private authService: AuthService
  ) {
    if (this.nextMonth.getMonth() === 11) {
      this.nextMonth.setMonth(0);
      this.nextMonth.setFullYear(this.nextMonth.getFullYear() + 1);
    } else this.nextMonth.setMonth(this.nextMonth.getMonth() + 1);
  }

  ngOnInit() {
    if (!this.modeFacturePaiementA30j) {
      this.paymentService.canDelayPayment(this.paymentDataService.selectedArticles.map((a) => a.idBasketArticle)).subscribe(
        (data) => {
          this.paiementA30j = data;
        },
        (error) => {
          L.e(error);
        }
      );
      this.paymentService.getDegineoBankInfos().subscribe(
        (data) => {
          this.degineoBankInfos = data;
        },
        (err) => {
          L.e(err);
        }
      );
    }
  }

  delayPayment() {
    this.loading = true;
    this.paymentService
      .paiementVirement(
        this.paymentDataService.selectedArticles.map((a) => a.idBasketArticle),
        this.paymentDataService.shippingAddress.id,
        this.paymentDataService.billingAddress.id,
        true
      )
      .subscribe(
        (data) => {
          this.loading = false;
          this.paymentDataService.delayed = true;
          this.paymentDataService.step = PaymentDataService.STEP_CONFIRM;
        },
        (error) => {
          this.loading = false;
          L.e(error);
        }
      );
  }

  wireTransfertDone() {
    this.loading = true;
    this.paymentService
      .paiementVirement(
        this.paymentDataService.selectedArticles.map((a) => a.idBasketArticle),
        this.paymentDataService.shippingAddress.id,
        this.paymentDataService.billingAddress.id,
        false
      )
      .subscribe(
        (data) => {
          this.loading = false;
          this.paymentDataService.step = PaymentDataService.STEP_CONFIRM;
        },
        (error) => {
          this.loading = false;
          L.e(error);
        }
      );
  }

  invoicePaid() {
    this.paymentStep2.closePopup('payment_ok');
  }
  formatIban(iban: string) {
    if (!iban || iban.length < 27) return iban;
    return (
      iban.substr(0, 4) +
      ' ' + //
      iban.substr(4, 4) +
      ' ' + //
      iban.substr(8, 4) +
      ' ' + //
      iban.substr(12, 4) +
      ' ' + //
      iban.substr(16, 4) +
      ' ' + //
      iban.substr(20, 4) +
      ' ' + //
      iban.substr(24, 3) +
      ' '
    );
  }
}
