import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { UserEditValidators, ProducerInvitationInfoDTO } from 'degineo-common';
import { RegisterService, AuthService } from 'degineo-common';
import { switchMap, map } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { L } from 'ic2-lib';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  form: FormGroup = this.fb.group(
    {
      email: [null, [Validators.required, Validators.email], this.uniqueEmailValidator.bind(this)],
      password: [null, [Validators.required, Validators.minLength(6)]],
      password2: [null, [Validators.required, Validators.minLength(6)]],
    },
    {
      updateOn: 'blur',
      validator: UserEditValidators.checkPasswords('password', 'password2'),
    }
  );
  form2: FormGroup = this.fb.group({
    prenom: [null, [Validators.required]],
    nom: [null, [Validators.required]],
    companyName: [null, [Validators.required]],
    phoneNumber: [null, [Validators.required, UserEditValidators.phoneNumber]],
    siret: [null, [Validators.required, UserEditValidators.siretValide]],
  });
  step = 1;
  loading: boolean = false;
  token: string = null;
  rpcHost = environment.rpcHost;
  infos: ProducerInvitationInfoDTO = null;
  svClientUrl: string;
  svMentionsLegalesUrl: string;
  svConfidentialitesUrl: string;
  producerUrl;

  constructor(
    @Inject(FormBuilder) private fb: FormBuilder,
    @Inject(Router) public router: Router,
    @Inject(RegisterService) private registerService: RegisterService,
    @Inject(AuthService) private authService: AuthService,
    @Inject(ActivatedRoute) private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.svClientUrl = environment.svUrl + 'espace-client';
    this.svMentionsLegalesUrl = environment.svUrl + 'mentions-legales';
    this.svConfidentialitesUrl = environment.svUrl + 'confidentialite';
    this.producerUrl = environment.producerUrl;

    if (environment.name === 'DEV') {
      this.form.controls.password.setValue('testtest1');
      this.form.controls.password2.setValue('testtest1');

      this.form2.controls.prenom.setValue('test');
      this.form2.controls.nom.setValue('invitation');
      this.form2.controls.phoneNumber.setValue('0615635188');
      this.form2.controls.siret.setValue('82209661600013');
    }
    this.activatedRoute.params.subscribe((params) => {
      if (params['token'] === undefined) {
        this.authService.isLoggedIn().subscribe((data) => {
          if (data) this.router.navigate(['/']);
        });
      } else {
        this.token = params['token'];

        console.log(this.token);
        this.registerService.getInvitationInformation(this.token).subscribe(
          (data) => {
            this.infos = data;
            this.form.controls.email.setValue(this.infos.email);
            this.form2.controls.companyName.setValue(this.infos.name);

            if (environment.name === 'DEV') {
              this.form.controls.password.setValue('testtest1');
              this.form.controls.password2.setValue('testtest1');

              this.form2.controls.prenom.setValue('test');
              this.form2.controls.nom.setValue('invitation');
              this.form2.controls.phoneNumber.setValue('0615635188');
              this.form2.controls.siret.setValue('82209661600013');
            }
          },
          (err) => {
            L.e(err);
          }
        );
        /*
        this.registerService.confirmEmail(this.token).subscribe(
          (data) => {
            this.authService.loginWith(data);
          },
          (error) => {
            if (error.isBusinessError()) {
              this.authService.isLoggedIn().subscribe((data) => {
                if (!data) this.router.navigate(['/connexion']);
              });
            } else {
              L.e(error);
            }
          }
        );*/
      }
    });
  }

  uniqueEmailValidator(input: FormControl): Observable<{}> {
    let obs = Observable.create((obs) => {
      this.registerService.emailAvailable(input.value).subscribe(
        (data) => {
          obs.next(!data ? { alreadyExists: true } : null);
          obs.complete();
        },
        (err) => {
          L.e(err);
          obs.next({ couldNotCheckEmailAvailability: true });
          obs.complete();
        }
      );
    });
    return obs;
  }

  submit() {
    if (!this.form.valid) {
      console.log(this.form);
      return;
    }
    this.step = 2;

    //TODO
  }

  submit2() {
    if (!this.form2.valid) {
      console.log(this.form2);
      return;
    }
    let value = this.form.value;
    let value2 = this.form2.value;
    this.loading = true;
    this.registerService
      .signup(value.email, value.password, value2.prenom, value2.nom, value2.companyName, value2.phoneNumber, value2.siret, this.token)
      .subscribe(
        (data) => {
          this.authService.loginWith(data.userBundle, () => {
            console.log(this.token, data.hasPendingProducerInvitations);
            /* changé du à discussion #375
            if (this.token !== null) this.router.navigate(['/confirmation-inscription']);
            else if (data.hasPendingProducerInvitations) this.router.navigate(['/mes-imprimeurs-partenaires']);
            else this.router.navigate(['/confirmation-inscription']);
            */
            this.router.navigate(['/confirmation-inscription']);
          });
          this.loading = false;
        },
        (err) => {
          L.e(err);
          this.loading = false;
        }
      );
  }

  /*
  submit() {
    console.log(this.form);
    if (!this.form.valid) return;
    let value = this.form.value;
    this.loading = true;
    this.registerService.configAccount(value.prenom, value.nom, value.companyName, value.phoneNumber, value.siret).subscribe(
      (data) => {
        this.loginService.getBundle().subscribe(
          (data) => {
            this.authService.loginWith(data);
            this.router.navigate(['/']);
          },
          (error) => {
            L.e(error);
          }
        );
      },
      (error) => {
        if (error.isBusinessError()) this.router.navigate(['/']);
        else L.e(error);
      }
    );
  }
  */
}
