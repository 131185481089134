<div class="row">
  <div class="col-12 text-md-right">
    <span class="fw-500 mr-2" [ngClass]="data.dto.isRegister ? 'text-success' : 'text-danger'">Inscrit</span>
    <div class="d-inline-block position-relative">
      <ui-switch [disabled]="true" style="vertical-align: sub" size="small" color="#00c462" defaultBgColor="#D82929" [checked]="data.dto.isRegister">
      </ui-switch>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12 text-md-right">
    <span class="fw-500 mr-2" [ngClass]="data.dto.producer.subscribed ? 'text-success' : 'text-danger'">Abonné</span>
    <div class="d-inline-block position-relative">
      <ui-switch style="vertical-align: sub" size="small" color="#00c462" defaultBgColor="#D82929" [checked]="data.dto.producer.subscribed"> </ui-switch>
      <div (click)="subscribedChange(); $event.stopPropagation()" class="pointer position-absolute h-100" style="right: 0; top: 0; width: 100%"></div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12 text-md-right">
    <button
      class="bt-anim fw-500 mr-1 text-primary btn btn-primary-outline p-0"
      (click)="copy('demande-devis')"
      placement="top"
      ngbTooltip="Cliquer pour copier l'URL"
    >
      Devis en marque blanche
    </button>
    <div class="d-inline-block position-relative">
      <ui-switch style="vertical-align: sub" size="small" color="#00c462" defaultBgColor="#D82929" [checked]="data.dto.producer.marqueBlancheDevis">
      </ui-switch>
      <div (click)="marqueBlancheDevisChange(); $event.stopPropagation()" class="pointer position-absolute h-100" style="right: 0; top: 0; width: 100%"></div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12 text-md-right">
    <button
      class="bt-anim fw-500 mr-1 text-primary btn btn-primary-outline p-0"
      (click)="copy('impression-en-ligne')"
      placement="top"
      ngbTooltip="Cliquer pour copier l'URL"
    >
      Commande en marque blanche
    </button>
    <div class="d-inline-block position-relative">
      <ui-switch style="vertical-align: sub" size="small" color="#00c462" defaultBgColor="#D82929" [checked]="data.dto.producer.marqueBlanchePrint">
      </ui-switch>
      <div (click)="marqueBlanchePrintChange(); $event.stopPropagation()" class="pointer position-absolute h-100" style="right: 0; top: 0; width: 100%"></div>
    </div>
  </div>
</div>

<div class="row text-danger pointer mt-2">
  <div class="col-12 text-md-right">
    <div class="d-inline-block" placement="top" ngbTooltip="Comming soon">
      <button disabled class="btn btn-primary-outline text-danger p-0" style="letter-spacing: normal" (click)="removeClicked()">
        <i class="fas fa-user-times mr-1"></i> Supprimer ce producteur
      </button>
    </div>
  </div>
</div>

<div class="row mt-2">
  <div class="col-12 text-md-right">
    Création du compte&nbsp;:&nbsp;<span>{{ data.dto.producer.dateCreated | date: 'dd/MM/yyyy' }}</span>
  </div>
</div>

<div class="row">
  <div class="col-12 text-md-right">
    Abonné depuis&nbsp;:&nbsp;<span>{{ data.dto.producer.dateContractSigned | date: 'dd/MM/yyyy' }}</span>
  </div>
</div>

<div class="row">
  <div class="col-12 text-md-right">
    Dernière connexion&nbsp;:&nbsp;
    <span *ngIf="data.dto.DateLastLog === null">Jamais</span>
    <span *ngIf="data.dto.DateLastLog !== null">{{ data.dto.DateLastLog | date: 'dd/MM/yyyy' }} à {{ data.dto.DateLastLog | date: 'HH:mm:ss' }}</span>
  </div>
  <div *ngIf="data.dto.DateLastLog !== null && data.dto.firstNameLastLog !== null && data.dto.lastNameLastLog !== null" class="col-12 text-md-right">
    de
    <span>{{ data.dto.firstNameLastLog }}&nbsp;{{ data.dto.lastNameLastLog }}</span>
  </div>
</div>

<div class="row mt-2">
  <div class="col-12 text-md-right">
    <div class="d-inline-block" [ngbTooltip]="data.dto.autologToken === null ? 'Pas d\'utilisateur trouvé' : null">
      <a
        class="btn btn-success"
        style="min-width: 235px"
        [attr.href]="data.dto.autologToken !== null ? producerUrl + 'autolog/' + data.dto.autologToken : null"
        target="_blank"
        [class.disabled]="data.dto.autologToken === null"
      >
        Se connecter au compte
      </a>
    </div>
  </div>
</div>

<div class="row mt-2">
  <div class="col-12 text-md-right">
    <a class="btn btn-primary" style="min-width: 235px" [routerLink]="'/invoices/company/' + data.dto.producer.id"> Voir factures </a>
  </div>
</div>
