<!--<ngb-tabset  [activeId]="activeId"></ngb-tabset>-->
<ul ngbNav #nav="ngbNav" [(activeId)]="activeId" (navChange)="onTabChange($event)" class="nav-tabs">
  <li [ngbNavItem]="'back'">
    <a ngbNavLink>
      <div class="text-primary">
        <i style="font-size: 1.3rem" class="fas fa-chevron-left"></i>
      </div>
    </a>
    <ng-template ngbNavContent> </ng-template>
  </li>
  <li [ngbNavItem]="'infos'">
    <a ngbNavLink>
      <span class="fw-700" *ngIf="!data.dto">...</span>
      <span class="fw-700" *ngIf="data.dto">{{ data.dto.producer.name }}</span>
    </a>
    <ng-template ngbNavContent>
      <div class="container-fluid mt-2">
        <app-producer-infos-tab *ngIf="data.dto"></app-producer-infos-tab>
      </div>
    </ng-template>
  </li>
  <li [ngbNavItem]="'article'">
    <a ngbNavLink> Articles en fabrication </a>
    <ng-template ngbNavContent>
      <app-producer-article-list *ngIf="data.dto"></app-producer-article-list>
    </ng-template>
  </li>
  <li [ngbNavItem]="'printers'">
    <a ngbNavLink>
      <degineo-my-badge bgColor="warning" [size]="20" [textSize]="12" class="position-absolute" *ngIf="data.nbProducerWarning > 0">
        {{ data.nbProducerWarning }}
      </degineo-my-badge>
      <span [ngClass]="{ 'ml-4': data.nbProducerWarning > 0 }">Imprimantes & Consommables</span>
    </a>
    <ng-template ngbNavContent>
      <app-producer-printer-consumable-tab *ngIf="data.dto"></app-producer-printer-consumable-tab>
    </ng-template>
  </li>
  <li [ngbNavItem]="'users'">
    <a ngbNavLink> Utilisateurs </a>
    <ng-template ngbNavContent>
      <app-producer-users-tab *ngIf="data.dto"></app-producer-users-tab>
    </ng-template>
  </li>
  <li [ngbNavItem]="'page'">
    <a ngbNavLink> Page Degineo </a>
    <ng-template ngbNavContent>
      <lib-producer-edit [editMode]="true" [rpc]="data.rpcHost" [svUrl]="svUrl" (changePage)="toggleView()" *ngIf="data.dto && !view"> </lib-producer-edit>
      <lib-producer-display
        [editMode]="true"
        [rpc]="data.rpcHost"
        [producerUrl]="producerUrl"
        [svUrl]="svUrl"
        (changePage)="toggleView()"
        *ngIf="data.dto && view"
      >
      </lib-producer-display>
    </ng-template>
  </li>
  <li [ngbNavItem]="'devis'">
    <a ngbNavLink> Devis </a>
    <ng-template ngbNavContent>
      <app-producer-devis-tab *ngIf="data.dto"></app-producer-devis-tab>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
