import { Component, OnInit, Inject, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'environments/environment';
import { ClientInvoiceService, ProducerDelayedOrdersDTO, ClientInvoiceDTO } from 'degineo-common';
import { L } from 'ic2-lib';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentWorkflowComponent } from '../payment/payment-workflow/payment-workflow.component';
import { PaymentStep2Component } from '../payment/payment-step2/payment-step2.component';
import { PaymentStep3Component } from '../payment/payment-step3/payment-step3.component';
import { NotificationService } from '../../front-layout/notification.service';

@Component({
  selector: 'app-delayed-payment',
  templateUrl: './delayed-payment.component.html',
  styleUrls: ['./delayed-payment.component.scss'],
})
export class DelayedPaymentComponent implements OnInit, OnDestroy {
  private sub: Subscription;
  delayedOrders: ProducerDelayedOrdersDTO;
  invoice: ClientInvoiceDTO;
  loading: boolean = true;
  rpcHost: string = environment.rpcHost;

  constructor(
    @Inject(ActivatedRoute) public route: ActivatedRoute,
    @Inject(ClientInvoiceService) public clientInvoiceService: ClientInvoiceService,
    private modalService: NgbModal,
    private router: Router,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      let id = +params['id'];
      this.route.url.subscribe((data) => {
        if (data[1].path === 'producteur') this.loadViaProducer(id);
        else this.loadViaInvoice(id);
      });
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  loadViaProducer(idProducer: number) {
    this.clientInvoiceService.getProducerDelayedOrders(idProducer).subscribe(
      (data) => {
        this.delayedOrders = data;
        this.loading = false;
      },
      (error) => {
        L.e(error);
      }
    );
  }

  loadViaInvoice(idInvoice: number) {
    this.clientInvoiceService.getDelayedInvoice(idInvoice).subscribe(
      (data) => {
        this.invoice = data;
        this.clientInvoiceService.getProducerDelayedOrders(this.invoice.idProducer).subscribe(
          (data) => {
            this.delayedOrders = data;
            this.loading = false;
          },
          (error) => {
            L.e(error);
          }
        );
      },
      (error) => {
        L.e(error);
      }
    );
  }

  openPayment() {
    const modalRef = this.modalService.open(PaymentStep2Component, {
      centered: true,
      //@ts-ignore
      size: 'xl',
      beforeDismiss: () => {
        return false;
      },
    });
    modalRef.componentInstance.modeFacturePaiementA30j = true;
    modalRef.componentInstance.invoice = this.invoice;
    modalRef.componentInstance.producerName = this.delayedOrders.producerName;
    modalRef.componentInstance.close.subscribe((data) => {
      console.log('CLOSE', data);
      modalRef.close(data);
    });

    modalRef.result.then((data) => {
      console.log(data);
      if (data === 'payment_ok') {
        this.clientInvoiceService.markInvoicePaid(this.invoice.idInvoice).subscribe(
          (data) => {
            const modalRef = this.modalService.open(PaymentStep3Component, {
              centered: true,
              //@ts-ignore
              size: 'xl',
              beforeDismiss: () => {
                return false;
              },
            });
            modalRef.componentInstance.modeFacturePaiementA30j = true;
            modalRef.componentInstance.close.subscribe((data) => {
              this.notificationService.reload();
              modalRef.close(data);
              this.router.navigate(['/mes-impressions']);
            });
          },
          (error) => {
            L.e(error);
          }
        );
      }
    });
  }
}
