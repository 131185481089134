<div class="row" *ngIf="!edit">
  <div class="col">
    <div class="fw-500" style="font-size: 1.4rem">
      {{ data.dto.consumable.brand }}
      <span class="fw-300"> - {{ data.dto.consumable.name }}</span>
    </div>
    <div class="row mt-3">
      <div class="col-12 col-md-auto border-right border-secondary mt-2 mt-md-0">
        <div>Technologie&nbsp;: {{ getCurrentPrinterTechnology() }}</div>
        <div>Matière&nbsp;: {{ data.dto.MaterialTypeName }}</div>
      </div>
      <div class="col-12 col-md-auto mt-2 mt-md-0">
        <div>Prix de vente moyen&nbsp;: {{ getAvgPrice() }}&nbsp;€</div>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="edit">
  <div class="col">
    <div class="fw-500 mb-1 d-inline-block">
      <ic2-textassist
        class="d-block"
        style="width: 250px"
        placeholder="Marque"
        [values]="data.brandList"
        [presenter]="presenter"
        [(ngModel)]="data.dto.consumable.brand"
        required
      >
      </ic2-textassist>
    </div>
    <span class="fw-300" style="font-size: 1.4rem"> - </span>
    <div class="mb-1 d-inline-block">
      <input style="width: 310px" [(ngModel)]="data.dto.consumable.name" class="form-control" type="text" placeholder="Référence du consommable" required />
    </div>
    <div class="row mt-3">
      <div class="col-12 col-md-auto border-right border-secondary">
        <div class="mb-1">
          <div class="d-inline-block">Technologie&nbsp;:</div>
          <div class="d-inline-block ml-1">
            {{ getCurrentPrinterTechnology() }}
          </div>
        </div>
        <div class="mb-1">
          <div class="d-inline-block">Matière&nbsp;:</div>
          <select
            style="width: 250px"
            class="form-control d-inline-block ml-1 mr-1"
            [(ngModel)]="data.dto.consumable.idMaterialType"
            (change)="materialChange(material.value)"
            required
            #material
          >
            <option [ngValue]="0">-- Matière --</option>
            <option *ngFor="let material of data.materialList" [ngValue]="material.id">{{ material.name }}</option>
          </select>
        </div>
      </div>
      <div class="col-12 col-md-auto">
        <div class="mb-1">
          <div class="d-inline-block" style="line-height: 38px">Prix de vente moyen&nbsp;: {{ getAvgPrice() }}&nbsp;€</div>
        </div>
      </div>
    </div>
  </div>
</div>
