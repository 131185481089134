import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DegineoCommonModule } from 'degineo-common';

import { AgmCoreModule } from '@agm/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { SignupComponent } from './Auth/signup/signup.component';
import { LoginComponent } from './Auth/login/login.component';
import { FrontLayoutComponent } from './front-layout/front-layout.component';
import { MyFilesComponent } from './Pages/my-files/my-files.component';
import { SignupConfirmComponent } from './Pages/signup-confirm/signup-confirm.component';
import { PrintStep1Component } from './Pages/print/print-step1/print-step1.component';
import { PrintStep2Component } from './Pages/print/print-step2/print-step2.component';
import { UploadStepComponent } from './MarqueBlanche/steps/upload-step/upload-step.component';
import { PrintSettingsComponent } from './MarqueBlanche/steps/print-settings/print-settings.component';
import { PrintSettingsNameComponent } from './MarqueBlanche/steps/print-settings/print-settings-name/print-settings-name.component';
import { PrintSettingsSizeComponent } from './MarqueBlanche/steps/print-settings/print-settings-size/print-settings-size.component';
import { PrintSettingsColorComponent } from './MarqueBlanche/steps/print-settings/print-settings-color/print-settings-color.component';
import { PrintSettingsCommentComponent } from './MarqueBlanche/steps/print-settings/print-settings-comment/print-settings-comment.component';
import { PrintSettingsCharacteristicsComponent } from './MarqueBlanche/steps/print-settings/print-settings-characteristics/print-settings-characteristics.component';
import { PrintSettingsMaterialComponent } from './MarqueBlanche/steps/print-settings/print-settings-material/print-settings-material.component';
import { PrintSettingsDentaireComponent } from './MarqueBlanche/steps/print-settings-dentaire/print-settings-dentaire.component';
import { QuantityChoiceStepComponent } from './MarqueBlanche/steps/quantity-choice-step/quantity-choice-step.component';
import { PrintGuard } from './Pages/print/print-guard.service';
import { PrintStep3Component } from './Pages/print/print-step3/print-step3.component';
import { PrintStep4Component } from './Pages/print/print-step4/print-step4.component';
import { PanierComponent } from './Pages/panier/panier.component';
import { PaymentStep1AddressComponent } from './Pages/payment/payment-step1-address/payment-step1-address.component';
import { PaymentWorkflowComponent } from './Pages/payment/payment-workflow/payment-workflow.component';
import { PaymentStep2Component } from './Pages/payment/payment-step2/payment-step2.component';
import { PaymentStep2CreditcardComponent } from './Pages/payment/payment-step2-creditcard/payment-step2-creditcard.component';
import { PaymentStep2PaypalComponent } from './Pages/payment/payment-step2-paypal/payment-step2-paypal.component';
import { PaymentStep2WiretransfertComponent } from './Pages/payment/payment-step2-wiretransfert/payment-step2-wiretransfert.component';
import { PaymentStep3Component } from './Pages/payment/payment-step3/payment-step3.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { ClientDevisPageComponent } from './Pages/devis/client-devis-page/client-devis-page.component';
import { ClientDevisListComponent } from './Pages/devis/client-devis-list/client-devis-list.component';
import { ClientDevisPreviewComponent } from './Pages/devis/client-devis-preview/client-devis-preview.component';
import { ClientDevisModificationComponent } from './Pages/devis/client-devis-modification/client-devis-modification.component';
import { ClientDevisRefusalComponent } from './Pages/devis/client-devis-refusal/client-devis-refusal.component';
import { MyPrintsComponent } from './Pages/my-prints/my-prints.component';
import { NotificationComponent } from './front-layout/notification/notification.component';
import { DelayedPaymentComponent } from './Pages/delayed-payment/delayed-payment.component';
import { InvoiceListComponent } from './Pages/invoice-list/invoice-list.component';
import { MyProfileComponent } from './Pages/my-profile/my-profile.component';
import { CheckoutDevisPopupComponent } from './Pages/devis/checkout-devis-popup/checkout-devis-popup.component';
import { MyProducersComponent } from './Pages/my-producers/my-producers.component';
import { TunnelMarqueBlancheComponent } from './MarqueBlanche/tunnel-marque-blanche/tunnel-marque-blanche.component';
import { ArticleTypeChoiceStepComponent } from './MarqueBlanche/steps/article-type-choice-step/article-type-choice-step.component';
import { LoginRegisterStepComponent } from './MarqueBlanche/steps/login-register-step/login-register-step.component';
import { RegisterStepComponent } from './MarqueBlanche/steps/register-step/register-step.component';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { NgxMaskModule } from 'ngx-mask';
import { LoginStepComponent } from './MarqueBlanche/steps/login-step/login-step.component';
import { FinishMarqueBlancheOrderComponent } from './Pages/finish-marque-blanche-order/finish-marque-blanche-order.component';
import { ValidationEmailComponent } from './Pages/validation-email/validation-email.component';
import { PrintSettingsFinishComponent } from './MarqueBlanche/steps/print-settings/print-settings-finish/print-settings-finish.component';
import { PrintSettingsPrinterTechnologyComponent } from './MarqueBlanche/steps/print-settings/print-settings-printer-technology/print-settings-printer-technology.component';
import { Ic2Module } from 'ic2-lib';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forChild(),
    NgbModule,
    AgmCoreModule,
    UiSwitchModule,
    DegineoCommonModule,
    NgxPayPalModule,
    GooglePlaceModule,
    AngularMyDatePickerModule,
    Ic2Module,
  ],
  exports: [],
  declarations: [
    SignupComponent,
    LoginComponent,
    FrontLayoutComponent,
    MyFilesComponent,
    SignupConfirmComponent,
    PrintStep1Component,
    PrintStep2Component,
    PrintStep3Component,
    PrintStep4Component,
    PanierComponent,
    PaymentStep1AddressComponent,
    PaymentWorkflowComponent,
    PaymentStep2Component,
    PaymentStep2CreditcardComponent,
    PaymentStep2PaypalComponent,
    PaymentStep2WiretransfertComponent,
    PaymentStep3Component,
    ClientDevisPageComponent,
    ClientDevisListComponent,
    ClientDevisPreviewComponent,
    ClientDevisModificationComponent,
    ClientDevisRefusalComponent,
    MyPrintsComponent,
    NotificationComponent,
    DelayedPaymentComponent,
    InvoiceListComponent,
    MyProfileComponent,
    CheckoutDevisPopupComponent,
    MyProducersComponent,
    TunnelMarqueBlancheComponent,
    ArticleTypeChoiceStepComponent,
    UploadStepComponent,
    PrintSettingsComponent,
    PrintSettingsNameComponent,
    PrintSettingsSizeComponent,
    PrintSettingsColorComponent,
    PrintSettingsCommentComponent,
    PrintSettingsCharacteristicsComponent,
    PrintSettingsMaterialComponent,
    PrintSettingsDentaireComponent,
    LoginRegisterStepComponent,
    QuantityChoiceStepComponent,
    RegisterStepComponent,
    LoginStepComponent,
    FinishMarqueBlancheOrderComponent,
    ValidationEmailComponent,
    PrintSettingsFinishComponent,
    PrintSettingsPrinterTechnologyComponent,
  ],
  providers: [PrintGuard],
  entryComponents: [PaymentWorkflowComponent, PaymentStep2Component, PaymentStep3Component, CheckoutDevisPopupComponent],
})
export class FrontModule {}
