import { Component, OnInit, Inject } from '@angular/core';
import { PrintDataService } from '../print-data.service';
import { PrintService, SizeDTO } from 'degineo-common';
import { Router } from '@angular/router';
import { L } from 'ic2-lib';
import * as moment from 'moment';
import { UploadedFile } from '../../../MarqueBlanche/UploadedFile';
@Component({
  selector: 'app-print-step2',
  templateUrl: './print-step2.component.html',
  styleUrls: ['./print-step2.component.scss'],
})
export class PrintStep2Component implements OnInit {
  error: string;
  constructor(
    @Inject(PrintDataService) public printDataService: PrintDataService,
    @Inject(PrintService) public printService: PrintService,
    @Inject(Router) public router: Router
  ) {}

  ngOnInit() {}

  fileAdded(file: File) {
    if (!this.printDataService.dentaire && this.printDataService.files.length === 1) {
      this.fileDeleted(this.printDataService.files[0]);
    }
    console.log(file);
    let uf = new UploadedFile();
    uf.file = file;
    uf.name = file.name;
    this.printDataService.files.push(uf);
    this.printService
      .uploadFile()
      .withFile(uf.file)
      .subscribeWithProgress(
        (data) => {
          uf.fileHash = data;
          this.printService.getFileDimensions(uf.fileHash).subscribe(
            (data) => {
              uf.modelData = data;
              uf.good = true;
              uf.progress = 100;
              if (this.printDataService.dentaire) this.printDataService.quantity = this.printDataService.files.length;
            },
            (err) => {
              L.e(err);
              this.error = err.message;
            }
          );
        },
        (loaded, total) => {
          uf.progress = ~~(((loaded / total) * 100) / 2);
        },
        (err) => {
          L.e(err);
          this.error = err.message;
        }
      );
  }

  fileDeleted(file: UploadedFile) {
    this.printDataService.files.splice(this.printDataService.files.indexOf(file), 1);
    this.printService.deleteFile(file.fileHash).subscribe(
      (data) => {
        console.log(file.file.name, 'deleted');
        if (this.printDataService.dentaire) this.printDataService.quantity = this.printDataService.files.length;
      },
      (err) => {
        L.e(err);
        this.error = err.message;
      }
    );
  }

  canNextUploadStep(): boolean {
    //console.log('CAN NEXT', (this.files.length > 0), (this.files.find((file) => !file.good) === undefined));
    return this.printDataService.files.length > 0 && this.printDataService.files.find((file) => !file.good) === undefined; //all uploaded
  }

  next() {
    this.printDataService.defaultSizeDTO = new SizeDTO();
    let file = this.printDataService.files[0];
    this.printDataService.defaultSizeDTO.x = Math.round(file.modelData.x * 100) / 100;
    this.printDataService.defaultSizeDTO.y = Math.round(file.modelData.y * 100) / 100;
    this.printDataService.defaultSizeDTO.z = Math.round(file.modelData.z * 100) / 100;
    //this.sizeChanged(this.defaultSizeDTO); removed because the print-setting-size will trigger it
    if (this.printDataService.dentaire) this.printDataService.name = this.printDataService.articleTypeSelected.name + ' ' + moment().format('DD/MM/YYYY');
    else if (!this.printDataService.name) this.printDataService.name = file.file.name.split('.').slice(0, -1).join('.');
    this.printDataService.step = PrintDataService.STEP_PRINT_SETTINGS;
    this.router.navigate(['/impression', 'etape-3']);
  }

  previous() {
    this.printDataService.step = PrintDataService.STEP_CHOOSE_ARTICLE_TYPE;
    this.printDataService.reset();
    this.router.navigate(['/impression']);
  }
}
