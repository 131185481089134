<div class="container pt-4 pb-4">
  <div class="row">
    <div class="col-md-6 offset-md-3">
      <h1 class="text-center">Nouveau producteur</h1>

      <form class="mt-5" [formGroup]="registerForm" #f="ngForm" [class.show-errors]="f.submitted && !registerForm.valid" (submit)="onSubmit()">
        <div class="form-row">
          <div class="form-group col-md-4 text-center">
            <img class="rounded" *ngIf="imgURL" alt="Logo" [src]="imgURL" width="140" style="min-height: 100px;" />
            <div class="row">
              <div class="col text-center">
                <input type="file" id="file" style="display: none;" accept="image/*" #fileInput (change)="handleFileInput($event)" />
                <button
                  class="btn d-block ml-auto mr-auto p-0 mt-1 text-primary"
                  style="font-size: 0.9rem; letter-spacing: normal;"
                  (click)="fileInput.click(); $event.preventDefault()"
                >
                  <span *ngIf="imgURL == null">Ajouter l'image</span>
                  <span *ngIf="imgURL != null">Changer l'image</span>
                </button>
                <div *ngIf="submitted && errorFile" class="invalid-feedback">
                  <div>{{ errorFile }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group col-md-8">
            <label>Nom du producteur</label>
            <input type="text" formControlName="name" class="form-control" />
          </div>
        </div>

        <hr />

        <div class="form-group">
          <textarea type="text" formControlName="noteDegineo" class="form-control" style="resize: none;" placeholder="Notes" rows="3"></textarea>
        </div>

        <hr />

        <div class="form-group">
          <label>Adresse</label>
          <input type="text" formControlName="AddressLine" class="form-control" />
        </div>

        <div class="form-row">
          <div class="form-group col-md-8">
            <label>Ville</label>
            <input type="text" formControlName="City" class="form-control" />
          </div>
          <div class="form-group col-md-4">
            <label>Code postal</label>
            <input type="text" formControlName="ZipCode" class="form-control" />
          </div>
        </div>

        <div class="form-group">
          <label>Pays</label>
          <select class="form-control" formControlName="Country">
            <option [ngValue]="''">-- Pays --</option>
            <option *ngFor="let country of countries" [ngValue]="country">
              {{ country }}
            </option>
          </select>
        </div>

        <div class="row mt-4" *ngIf="f.submitted && !registerForm.valid">
          <div class="col">
            <div class="alert alert-danger pr-3 pl-3 pr-md-5 pl-md-5 pt-3 pb-3">
              <ul>
                <li *ngIf="registerForm.hasError('required', 'name')">Le nom du producteur est obligatoire.</li>
                <li *ngIf="registerForm.hasError('required', 'AddressLine')">L'adresse est obligatoire.</li>
                <li *ngIf="registerForm.hasError('required', 'City')">La ville est obligatoire.</li>
                <li *ngIf="registerForm.hasError('required', 'ZipCode')">Le code postal est obligatoire.</li>
                <li *ngIf="registerForm.hasError('required', 'Country')">Le pays est obligatoire.</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col">
            <button class="btn btn-secondary w-100" type="button" (click)="cancel()">
              Annuler
            </button>
          </div>
          <div class="col">
            <button class="btn btn-success w-100" type="submit">
              Ajouter
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
