<div class="container-fluid">
  <div class="row">
    <div class="col text-right fs-4">
      <button type="button" class="close" aria-label="Close" (click)="close.emit('Cross click')">
        <i class="fas fa-times modal-cross"></i>
      </button>
    </div>
  </div>

  <ng-container *ngIf="success; else error">
    <div class="row mt-4">
      <div class="col text-center">
        <img src="assets/payment/check-circle-regular.svg" style="height: 6rem; width: 6rem" />
      </div>
    </div>
    <div class="row mt-5" *ngIf="!modeFacturePaiementA30j">
      <div class="col text-center">
        <h6>
          <ng-container *ngIf="!paiement30J">Paiement accepté.</ng-container><br />
          <ng-container *ngIf="paymentMethod === PaymentDataService.PAYMENT_WIRETRANSFERT && !paiement30J; else other">
            Paiement en attente de validation. <br />
            Votre commande est prise en compte.
          </ng-container>
          <ng-template #other>Votre commande a été transmise !</ng-template>
        </h6>
      </div>
    </div>
    <div class="row mt-5 mb-5">
      <div class="col text-center">
        <button class="btn btn-success text-uppercase" (click)="continue()">Continuer</button>
      </div>
    </div>
  </ng-container>

  <ng-template #error>
    <div class="row mt-4">
      <div class="col text-center">
        <img src="assets/payment/times-circle-danger.svg" style="height: 6rem; width: 6rem" />
      </div>
    </div>
    <div class="row mt-5">
      <div class="col text-center">
        <h6>Paiement refusé.</h6>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col text-center">
        Votre demande de paiement a été rejetée. Rien n'a été débité de votre compte.<br />
        Votre commande n'a donc pas pu être validée.<br />
        Réessayez avec un autre compte bancaire ou un autre moyen de paiement.<br />
        Si vous ne trouvez pas de solution, <a routerLink="/chat-client">contactez-nous.</a>
      </div>
    </div>
    <div class="row mt-5 mb-5">
      <div class="col text-center">
        <button class="btn btn-success text-uppercase" (click)="continue()">Continuer</button>
      </div>
    </div>
  </ng-template>
</div>
