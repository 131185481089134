import { Component, OnInit, Inject } from '@angular/core';
import { PrinterData } from '../../providers/printer.provider';
import { SlugService } from 'degineo-common';

@Component({
  selector: 'app-printer-company',
  templateUrl: './printer-company.component.html',
  styleUrls: ['./printer-company.component.scss'],
})
export class PrinterCompanyComponent implements OnInit {
  constructor(public data: PrinterData, @Inject(SlugService) public slugService: SlugService) {}

  ngOnInit() {}
}
