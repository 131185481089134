import { Component, OnInit, Inject, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { OrderService } from '../../order.service';
import { PreviewModeleComponent } from 'degineo-common';

@Component({
  selector: 'app-print-settings',
  templateUrl: './print-settings.component.html',
  styleUrls: ['./print-settings.component.scss'],
})
export class PrintSettingsComponent implements OnInit {
  @Input()
  nextAvailable: boolean;
  @Input()
  modelUrl: string;
  @Output()
  next: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  previous: EventEmitter<void> = new EventEmitter<void>();
  @ViewChild(PreviewModeleComponent)
  preview: PreviewModeleComponent;

  constructor(@Inject(OrderService) public orderService: OrderService) {}

  ngOnInit() {
    this.orderService.title = "Paramétrages d'impression";
  }
}
