<div class="container pt-4 pb-4">
  <h1 class="mx-auto text-center mb-5">Facturation</h1>

  <degineo-filter
    class="d-inline-block w-100 mt-2 mb-4"
    #filter
    [builtFilters]="builtFilters"
    (filterChanged)="filterChanged($event)"
    [cache]="InvoiceListComponent.filtersCache"
    [default]="filtersDefault"
  >
    <div class="form-inline">
      <degineo-textassist-filter
        #f
        class="input-group mr-2 mt-1"
        style="width: 17rem;"
        name="Emetteur"
        filterName="transmitter"
        [values]="companies"
        [presenter]="presenterName"
        (textChanges)="loadEmetteurs($event)"
      >
      </degineo-textassist-filter>

      <degineo-date-filter #f class="input-group mr-2 mt-1" style="width: 17rem;" name="Date d'émission" filterName="date"> </degineo-date-filter>

      <degineo-text-filter #f class="input-group mr-2 mt-1" style="width: 17rem;" name="N° de facture ou de commande" filterName="recherche">
      </degineo-text-filter>

      <i class="fas fa-undo text-primary resetToDefault ml-2" (click)="filter.resetToDefault(); InvoiceListComponent.page = 1"></i>
    </div>
  </degineo-filter>

  <degineo-my-table
    [rows]="invoices"
    [orderCache]="InvoiceListComponent.OrderCaches"
    [builtFiltersOrder]="builtFiltersOrder"
    (orderChange)="orderChange($event)"
    (rowClick)="tableRowClick($event)"
    [pointer]="false"
    [hoverable]="false"
  >
    <degineo-my-column orderName="reference" align="left" name="N° de facture">
      <td *cv="let r" class="text-left">
        {{ r.reference }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="DateCreated" name="Date d'émission" [defaultOrder]="OrderBy.DESC">
      <td *cv="let r" class="text-center">
        {{ r.date | date: 'dd/MM/yyyy' }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="transmitter" name="Emetteur">
      <td *cv="let r" class="text-center">
        {{ r.transmitter }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="totalHT" align="right" name="Montant HT">
      <td *cv="let r" class="text-right">{{ r.totalHT | number: '.2':'fr-FR' }}&nbsp;€</td>
    </degineo-my-column>

    <degineo-my-column orderName="totalTTC" align="right" name="Montant TTC">
      <td *cv="let r" class="text-right">{{ r.totalTTC | number: '.2':'fr-FR' }}&nbsp;€</td>
    </degineo-my-column>

    <degineo-my-column [disableOrder]="true">
      <td *cv="let r" class="text-center">
        <a href="{{ rpcHost }}invoice/{{ r.fileToken }}?dl=true">
          <i style="vertical-align: middle;" class="fas fa-file-download"></i>
        </a>
      </td>
    </degineo-my-column>
  </degineo-my-table>

  <div class="row justify-content-center mt-3" *ngIf="!loading">
    <ngb-pagination
      [collectionSize]="count"
      [(page)]="InvoiceListComponent.page"
      [pageSize]="nbPerPage"
      [maxSize]="6"
      [rotate]="true"
      (pageChange)="reload()"
      [disabled]="loading"
    ></ngb-pagination>
  </div>
</div>
