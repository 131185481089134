import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  ArticleTypePrintStep1DTO,
  PrinterSizeDTO,
  SizeDTO,
  MaterialTypeNEW,
  ColorListDTO,
  ColorNEW,
  CharacteristicsDisplayDTO,
  AssociatedProducerDTO,
  PrintService,
  LastPrintConfigDTO,
  ModelData,
  Finish,
} from 'degineo-common';
import { L } from 'ic2-lib';
import { UploadedFile } from '../../MarqueBlanche/UploadedFile';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class PrintDataService {
  idProducer: number = 0;
  producerSelected: AssociatedProducerDTO = null;
  articleTypeSelected: ArticleTypePrintStep1DTO = null;
  dentaire: boolean = false;
  printerSizes: PrinterSizeDTO[] = [];
  files: UploadedFile[] = [];
  quantity: number = 1;
  name: string = null;
  comment: string = null;

  size: SizeDTO = null;
  availableMaterials: MaterialTypeNEW[] = null;
  selectedMaterial: MaterialTypeNEW = null;
  parentMaterial: MaterialTypeNEW = null;
  tempMaterial: MaterialTypeNEW = null;
  availableColors: ColorListDTO = null;
  selectedColor: ColorNEW | string = null;
  tempColor: ColorNEW | string = null;
  availableFinish: Finish[] = null;
  selectedFinish: Finish = null;
  tempFinish: Finish = null;
  defaultSizeDTO: SizeDTO = null;
  characs: CharacteristicsDisplayDTO[] = [];
  wishedDeliveryDate: Date = null;
  price: number = 0;

  fromSV: boolean = false;
  step = PrintDataService.STEP_CHOOSE_ARTICLE_TYPE;

  public static readonly STEP_CHOOSE_ARTICLE_TYPE = 1;
  public static readonly STEP_UPLOAD_MODELS = 2;
  public static readonly STEP_PRINT_SETTINGS = 3;
  public static readonly STEP_QUANTITY = 4;

  constructor(private router: Router, public printService: PrintService) {}

  printAgain(config: LastPrintConfigDTO) {
    this.reset();
    this.printService.getProducers().subscribe(
      (data) => {
        //find producer
        let producer: AssociatedProducerDTO = null;
        if (config.producerSelected) {
          producer = data.producers.find((p) => p.idProducer === config.idProducer);
        } else {
          producer = data.producers.find((p) => p.idProducer === 0);
        }

        this.name = config.name;
        this.comment = config.comment;
        let file = new UploadedFile();
        file.modelData = new ModelData();
        file.modelData.x = config.fileSizeXmm;
        file.modelData.y = config.fileSizeYmm;
        file.modelData.z = config.fileSizeZmm;
        file.good = true;
        file.progress = 100;
        file.fileHash = config.fileHash;
        file.name = config.fileName;
        this.files.push(file);

        if (producer === undefined) {
          console.log('producer is undefined', config);
          this.step = PrintDataService.STEP_CHOOSE_ARTICLE_TYPE;
          this.router.navigate(['/impression']);
        } else {
          this.idProducer = producer.idProducer;
          this.producerSelected = producer;
          //find article type
          let articleType = data.articles.find((at) => at.id === config.idArticleType);
          if (articleType !== undefined && this.producerSelected.idArticlesAvailable.includes(articleType.id)) {
            this.articleTypeSelected = articleType;
            this.dentaire = articleType.dental;

            this.defaultSizeDTO = new SizeDTO();
            this.defaultSizeDTO.x = Math.round(file.modelData.x * 100) / 100;
            this.defaultSizeDTO.y = Math.round(file.modelData.y * 100) / 100;
            this.defaultSizeDTO.z = Math.round(file.modelData.z * 100) / 100;
            //this.sizeChanged(this.defaultSizeDTO); removed because the print-setting-size will trigger it
            if (this.dentaire) this.name = this.articleTypeSelected.name + ' ' + moment().format('DD/MM/YYYY');
            this.size = new SizeDTO();
            this.size.x = config.orderSizeXmm;
            this.size.y = config.orderSizeYmm;
            this.size.z = config.orderSizeZmm;

            this.printService.getPossibleMaterials(this.idProducer, null, this.articleTypeSelected.id, this.size).subscribe(
              (data) => {
                this.availableMaterials = data;
                let material = this.availableMaterials.find((mt) => mt.id === config.idMaterialType);
                if (material !== undefined) {
                  this.selectedMaterial = material;
                  this.printService.getPossibleColors(this.idProducer, null, this.articleTypeSelected.id, this.selectedMaterial.id, this.size).subscribe(
                    (data) => {
                      this.availableColors = data;
                      //TODO restore multicolor function, + je devrait surement ajouter multicolor ici (printdataservice) car actuellement c géré un peu a l'arrache dans print-step3#next et print-step4#next
                      let color = this.availableColors.colors.find((c) => c.id === config.idColor);
                      if (color !== undefined) {
                        this.selectedColor = color;
                        //load characteristics
                        let colorId = 0;
                        if (this.selectedColor !== null && this.selectedColor instanceof ColorNEW) colorId = this.selectedColor.id;
                        let idFinish = 0;
                        if (this.selectedFinish !== null) idFinish = this.selectedFinish.id;
                        this.printService
                          .getPossibleFinish(this.idProducer, null, this.articleTypeSelected.id, this.selectedMaterial.id, colorId, this.size)
                          .subscribe(
                            (data) => {
                              this.availableFinish = data;
                              let finish = data.find((f) => f.id === idFinish);
                              if (finish !== undefined) this.selectedFinish = finish;
                            },
                            (err) => {
                              L.e(err);
                            }
                          );
                        this.printService
                          .getCharacteristics(this.idProducer, null, this.articleTypeSelected.id, this.selectedMaterial.id, colorId, idFinish, this.size)
                          .subscribe(
                            (data) => {
                              this.characs = data;
                            },
                            (err) => {
                              L.e(err);
                            }
                          );
                      }
                    },
                    (err) => {
                      L.e(err);
                    }
                  );
                }
              },
              (err) => {
                L.e(err);
              }
            );

            this.step = PrintDataService.STEP_PRINT_SETTINGS;
            this.router.navigate(['/impression', 'etape-3']);
          } else {
            console.log('articleType', config, articleType, this.producerSelected.idArticlesAvailable);
            this.step = PrintDataService.STEP_CHOOSE_ARTICLE_TYPE;
            this.router.navigate(['/impression']);
          }
        }
      },
      (error) => {
        L.e(error);
      }
    );
  }

  reset() {
    this.step = PrintDataService.STEP_CHOOSE_ARTICLE_TYPE;
    this.idProducer = 0;
    this.articleTypeSelected = null;
    this.dentaire = false;
    this.printerSizes = [];
    this.files = [];
    this.quantity = 1;
    this.name = null;
    this.comment = null;
    this.producerSelected = null;

    this.size = null;
    this.availableMaterials = null;
    this.selectedMaterial = null;
    this.parentMaterial = null;
    this.tempMaterial = null;
    this.availableColors = null;
    this.selectedColor = null;
    this.tempColor = null;
    this.availableFinish = null;
    this.selectedFinish = null;
    this.tempFinish = null;
    this.defaultSizeDTO = null;
    this.characs = [];
    this.wishedDeliveryDate = null;
    this.price = 0;

    this.fromSV = false;
  }
}
