import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FilterOrder, OrderBy, FilterComponent, Filter, InvoiceType, GenericFilter, InvoiceListItemDTO, BillerDTO } from 'degineo-common';
import { ClientInvoiceService } from 'degineo-common';
import { L } from 'ic2-lib';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss'],
})
export class InvoiceListComponent implements OnInit {
  InvoiceListComponent: typeof InvoiceListComponent = InvoiceListComponent;
  invoices: InvoiceListItemDTO[] = [];
  loading: boolean = true;
  count = 0;
  nbPerPage = 15;
  companies: BillerDTO[] = [];

  OrderBy: typeof OrderBy = OrderBy;

  @ViewChild('filter') filter: FilterComponent;
  static filtersCache = {};
  filtersDefault = {};
  static OrderCaches = {};
  static page: number = 1;

  search: string = null;
  builtFilters: Filter[] = [];
  builtFiltersOrder: FilterOrder[] = [];

  rpcHost: string = environment.rpcHost;
  presenterName = (obj) => obj.name;

  constructor(@Inject(ClientInvoiceService) private clientInvoiceService: ClientInvoiceService) {}

  ngOnInit() {
    this.loadEmetteurs('');
    this.reload();
  }

  reload() {
    let genericFilter = new GenericFilter();
    genericFilter.filters = this.builtFilters;
    genericFilter.orderFilters = this.builtFiltersOrder;
    genericFilter.page = InvoiceListComponent.page;
    genericFilter.nbPerPage = this.nbPerPage;
    console.log('genericFilter', genericFilter);
    this.loading = true;
    this.clientInvoiceService.getInvoices(genericFilter).subscribe(
      (data) => {
        console.log('getInvoices', data);
        this.invoices = data.data;
        this.count = data.count;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }

  getInvoiceTypeLib(id) {
    return InvoiceType.mapFromId(id).toString();
  }

  filterChanged(filters: Filter[]) {
    console.log('filterChanged event', filters);
    this.reload();
  }
  orderChange(order) {
    console.log('orderChange', order);
    this.reload();
  }
  tableRowClick(row: any) {
    //console.log('tableRowClick', row);
    //this.router.navigate(['/consumables', 'view', row.name, row.brandId, row.printerTechnologyId, row.materialTypeId]);
  }

  loadEmetteurs(text: string) {
    console.log('load emetteurs', text);
    this.clientInvoiceService.getMyBillers('').subscribe(
      (data) => {
        this.companies = data;
      },
      (error) => {
        L.e(error);
      }
    );
  }
}
