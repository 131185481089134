<div class="container-fluid h-100 d-flex flex-column">
  <div class="row position-absolute" style="right:0px;">
    <div class="col text-right fs-4">
      <button type="button" class="close" aria-label="Close" (click)="paymentStep2.closePopup('Cross click')">
        <i class="fas fa-times modal-cross"></i>
      </button>
    </div>
  </div>
  <div class="row flex-fill">
    <div class="col align-self-center">
      <div class="container-fluid">
        <div class="row mt-2" *ngIf="recette">
          <div class="col">
            <div class="alert alert-info">Le compte paypal de test est : test@degineo.com / testtest</div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col text-center">
            Paypal est une solution de paiement sécurisée qui accepte la majorité des cartes bancaires.<br />
            <br />
            Vous devez d'abord vous connecter à Paypal.
          </div>
        </div>
        <div class="row mt-5 justify-content-center mt-2">
          <div class="col-10" style="height: 50px;">
            <!-- height en dur pour éviter que la page bouge une fois le bouton paypal chargé -->
            <ngx-paypal [config]="payPalConfig"></ngx-paypal>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
