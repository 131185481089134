import { Component, OnInit, HostListener, Inject, PLATFORM_ID } from '@angular/core';
import { AuthService, ClientProducerAssociationService, ClientService } from 'degineo-common';
import { Router } from '@angular/router';
import { NotificationService } from './notification.service';
import { environment } from 'environments/environment';
import { AvatarChangeService } from './avatar-change.service';
import { PanierDataService } from '../Pages/panier/panier-data.service';
import { isPlatformBrowser } from '@angular/common';
import { CpaService } from './cpa.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-front-layout',
  templateUrl: './front-layout.component.html',
  styleUrls: ['./front-layout.component.scss'],
})
export class FrontLayoutComponent implements OnInit {
  mobile: boolean = false;
  show = true;
  showProfile = false;
  showNotification = false;
  loading: boolean = false;
  headerLinks = [
    { name: 'imprimer', routerLink: '/impression', img: { path: 'assets/header', ext: '.svg', name: 'imprimer' } },
    { name: 'Mes impressions', routerLink: '/mes-impressions', img: { path: 'assets/header', ext: '.svg', name: 'mes-impressions' } },
    { name: 'Mes fichiers 3D', routerLink: '/mes-fichiers', img: { path: 'assets/header', ext: '.svg', name: 'mes-fichiers-3d' } },
    { name: 'Devis', routerLink: '/devis', img: { path: 'assets/header', ext: '.svg', name: 'devis' } },
    { name: 'Panier', routerLink: '/panier', img: { path: 'assets/header', ext: '.svg', name: 'panier' }, panier: true },
  ];

  changeEmailForm: FormGroup = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
  });

  public innerWidth: any;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    if (window.innerWidth >= 992) {
      this.mobile = false;
      this.show = true;
    } else {
      this.mobile = true;
      this.show = false;
    }
  }

  constructor(
    @Inject(PLATFORM_ID) private platform: Object,
    public authService: AuthService,
    public router: Router,
    @Inject(PanierDataService) public panierDataService: PanierDataService,
    @Inject(NotificationService) public notificationService: NotificationService,
    @Inject(AvatarChangeService) public avatarChangeService: AvatarChangeService,
    @Inject(ClientProducerAssociationService) public clientProducerAssociationService: ClientProducerAssociationService,
    public cpaService: CpaService,
    public modalService: NgbModal,
    public fb: FormBuilder,
    @Inject(ClientService) public clientService: ClientService
  ) {
    this.avatarChangeService.reload();
  }

  ngOnInit() {
    this.panierDataService.load(false);
    if (isPlatformBrowser(this.platform)) {
      this.innerWidth = window.innerWidth;
      if (window.innerWidth >= 992) {
        this.mobile = false;
        this.show = true;
      } else {
        this.mobile = true;
        this.show = false;
      }
    }
  }

  toggleProfile() {
    this.showProfile = !this.showProfile;
  }
  toggleNotification() {
    this.showNotification = !this.showNotification;
  }

  notificationDropdownOpenChanged(open: boolean) {
    this.showNotification = open;
  }

  profileOpenChanged(open: boolean) {
    this.showProfile = true;
  }

  clicked() {
    if (this.mobile) this.show = false;
    this.showNotification = false;
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/connexion']);
  }

  showPopup(evt, content) {
    evt.preventDefault();
    this.modalService
      .open(content, {
        centered: true,
        backdrop: 'static',
        keyboard: false,
        beforeDismiss: () => {
          return !this.loading;
        },
      })
      .result.then(
        (result) => {
          //`Closed with: ${result}`;
        },
        (reason) => {
          //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  resendEmail(evt, content) {
    this.clientService.resendValidationEmail().subscribe(
      (data) => {
        this.showPopup(evt, content);
      },
      (err) => {
        L.e(err);
      }
    );
  }

  changeEmail(modal: NgbActiveModal) {
    if (!this.changeEmailForm.valid) {
      console.log(this.changeEmailForm);
      return;
    }
    this.loading = true;
    this.changeEmailForm.disable();
    this.clientService.changeEmail(this.changeEmailForm.value.email).subscribe(
      (data) => {
        this.loading = false;
        this.changeEmailForm.enable();
        this.authService.userBundle.user.email = this.changeEmailForm.value.email;
        this.authService.userBundle.user.login = this.changeEmailForm.value.email;
        modal.close();
      },
      (err) => {
        this.changeEmailForm.enable();
        this.loading = false;
        if (err.isBusinessError()) {
          if (err.message.includes('No change')) this.changeEmailForm.get('email').setErrors({ noChange: true });
          else if (err.message.includes('email already exists')) this.changeEmailForm.get('email').setErrors({ alreadyExists: true });
        } else L.e(err);
      }
    );
  }
}
