<div class="row" *ngIf="!edit">
  <div class="col-12 col-md-auto text-center">
    <img
      class="rounded"
      alt="Photo de l'imprimante"
      src="{{ rpcHost }}printerImage/{{ data.dto.printer.id }}/printer.png?size=large&reload={{ data.numberToForceReload }}"
      width="140"
    />
  </div>
  <div class="col-12 col-md-auto">
    <div class="row">
      <div class="col">
        <div class="fw-500" style="font-size: 1.4rem">
          {{ data.dto.printer.brand }}
          <span class="fw-300"> - {{ data.dto.printer.name }}</span>
        </div>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col text-secondary">#ID&nbsp;: {{ data.dto.printer.id }}</div>
    </div>
    <div class="row mt-3">
      <div class="col-12 col-md-auto border-right border-secondary">
        <div>Catégorie&nbsp;: {{ data.dto.printer.category }}</div>
        <div>Technologie d'impression&nbsp;: {{ data.dto.printer.printerTechnology }}</div>
      </div>
      <div class="col-12 col-md-auto">
        <div>Prix de vente&nbsp;: {{ data.dto.printer.sellingPrice | number: '.2':'fr-FR' }}&nbsp;€</div>
        <div [class.text-danger]="!data.dto.printer.stillSold">
          <ng-container *ngIf="data.dto.printer.stillSold"> Toujours commercialisée </ng-container>
          <ng-container *ngIf="!data.dto.printer.stillSold"> N'est plus commercialisée </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="edit">
  <div class="col-12 col-md-auto">
    <div class="row">
      <div class="col text-center">
        <img
          class="rounded"
          *ngIf="!data.file"
          alt="Photo de l'imprimante"
          src="{{ rpcHost }}printerImage/{{ data.dto.printer.id }}/printer.png?size=large&reload={{ data.numberToForceReload }}"
        />
        <img class="rounded" *ngIf="data.file" alt="Photo de l'imprimante" [src]="imgURL" width="140" />
        <span class="text-danger d-block" *ngIf="errorFile">{{ errorFile }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col text-center">
        <input type="file" id="file" style="display: none" accept="image/*" #fileInput (change)="handleFileInput($event)" />
        <button class="btn d-block ml-auto mr-auto p-0 mt-1 text-primary" style="font-size: 0.9rem; letter-spacing: normal" (click)="fileInput.click()">
          Changer l'image
        </button>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-auto">
    <div class="row mt-3">
      <div class="col-6">
        <ic2-textassist
          class="d-block w-100"
          style="width: 250px"
          placeholder="Marque de l'imprimante"
          [values]="data.brandList"
          [presenter]="presenter"
          [(ngModel)]="data.dto.printer.brand"
          required
        >
        </ic2-textassist>
      </div>
      <div class="col-6">
        <div class="fw-500 d-inline-block">
          <input style="width: 282px" [(ngModel)]="data.dto.printer.name" class="form-control" type="text" placeholder="Référence de l'imprimante" required />
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-6">
        <select class="form-control d-inline-block w-100" [(ngModel)]="data.dto.printer.category">
          <option [ngValue]="null" disabled="disabled">-- Choisir la catégorie --</option>
          <option *ngFor="let ls of categoryList" [ngValue]="ls" [selected]="data.dto.printer.category == ls">
            {{ ls }}
          </option>
        </select>
      </div>

      <div class="col-6">
        Prix de vente&nbsp;:
        <input class="price form-control d-inline-block" style="width: 133px" type="number" placeholder="Prix" [(ngModel)]="data.dto.printer.sellingPrice" />
        &nbsp;€
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-6" *ngIf="data.dto.assocConsumable.length === 0">
        <select
          style="width: 250px"
          class="form-control d-inline-block"
          [(ngModel)]="data.dto.printer.printerTechnology"
          (ngModelChange)="reloadConsumableBrands()"
          required
        >
          <option [ngValue]="null">-- Technologie --</option>
          <option *ngFor="let tech of data.technologyList" [ngValue]="tech">{{ tech.lib }}</option>
        </select>
      </div>
      <div class="col-6" *ngIf="data.dto.assocConsumable.length !== 0" ngbTooltip="Ne peut être changé si des consommables sont liés">
        {{ data.dto.printer.printerTechnology }}
      </div>
      <div class="col-6" [class.text-success]="data.dto.printer.stillSold" [class.text-danger]="!data.dto.printer.stillSold">
        Commercialisée&nbsp;:
        <ui-switch style="vertical-align: sub" size="small" color="#00c462" defaultBgColor="#d82929" [(ngModel)]="data.dto.printer.stillSold"> </ui-switch>
      </div>
    </div>
  </div>
</div>
