import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef, OnDestroy, AfterViewInit, Host, Inject } from '@angular/core';
import { environment } from 'environments/environment';
import { NgForm, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentStep2Component } from '../payment-step2/payment-step2.component';
import { PaymentService, CreditCardNEW } from 'degineo-common';
import { PaymentDataService } from '../payment-data.service';
import { L } from 'ic2-lib';
import { fbind } from 'q';

@Component({
  selector: 'app-payment-step2-creditcard',
  templateUrl: './payment-step2-creditcard.component.html',
  styleUrls: ['./payment-step2-creditcard.component.scss'],
})
export class PaymentStep2CreditcardComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('cardNumber') cardNumber: ElementRef;
  @ViewChild('cardExpiry') cardExpiry: ElementRef;
  @ViewChild('cardCvc') cardCvc: ElementRef;
  cardHandler = this.onChange.bind(this);
  cardNumberStripe: any;
  cardExpiryStripe: any;
  cardCvcStripe: any;
  error: string = null;
  stripe: any;
  loading: boolean;
  idCardSelected: number = 0; //0 = new card
  cards: CreditCardNEW[] = [];

  form: FormGroup = this.fb.group({
    name: [null, [Validators.required]],
    saveNewCard: [false, []],
  });

  constructor(
    @Host() public paymentStep2: PaymentStep2Component,
    private cd: ChangeDetectorRef,
    @Inject(PaymentDataService) public paymentDataService: PaymentDataService,
    @Inject(PaymentService) public paymentService: PaymentService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.stripe = Stripe(environment.stripePublishableKey); // use your test publishable key
    this.paymentService.getCustomerCreditCards().subscribe(
      (data) => {
        this.cards = data;
      },
      (error) => {
        L.e(error);
      }
    );
  }

  ngAfterViewInit() {
    const opts = {
      placeholder: '',
      style: {
        base: {
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          fontSize: '16px',
          lineHeight: '1.5rem',
          color: 'rgb(49, 69, 89)',
        },
        invalid: {
          color: 'red',
        },
      },
    };
    const stripeElements = this.stripe.elements();
    this.cardNumberStripe = stripeElements.create('cardNumber', opts);
    this.cardNumberStripe.mount(this.cardNumber.nativeElement);
    this.cardNumberStripe.addEventListener('change', this.cardHandler);

    this.cardExpiryStripe = stripeElements.create('cardExpiry', opts);
    this.cardExpiryStripe.mount(this.cardExpiry.nativeElement);
    this.cardExpiryStripe.addEventListener('change', this.cardHandler);

    this.cardCvcStripe = stripeElements.create('cardCvc', opts);
    this.cardCvcStripe.mount(this.cardCvc.nativeElement);
    this.cardCvcStripe.addEventListener('change', this.cardHandler);
  }

  ngOnDestroy() {
    if (this.cardNumberStripe) {
      this.cardNumberStripe.removeEventListener('change', this.cardHandler);
      this.cardNumberStripe.destroy();
    }
    if (this.cardExpiryStripe) {
      this.cardExpiryStripe.removeEventListener('change', this.cardHandler);
      this.cardExpiryStripe.destroy();
    }
    if (this.cardCvcStripe) {
      this.cardCvcStripe.removeEventListener('change', this.cardHandler);
      this.cardCvcStripe.destroy();
    }
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  updateDisabledStatus(disabled: boolean) {
    const opts = {
      disabled: disabled,
    };
    this.cardNumberStripe.update(opts);
    this.cardExpiryStripe.update(opts);
    this.cardCvcStripe.update(opts);
    if (disabled) this.form.disable();
    else this.form.enable();
  }

  disabled() {
    return (
      (!this.form.valid || this.cardNumberStripe._empty || this.cardExpiryStripe._empty || this.cardCvcStripe._empty || this.error !== null) &&
      this.idCardSelected === 0
    );
  }

  async validerPaiementCB() {
    console.log(this);
    if (this.disabled()) return;

    this.loading = true;
    this.updateDisabledStatus(true);

    if (this.idCardSelected === 0) {
      const { token, error } = await this.stripe.createToken(this.cardNumberStripe);

      if (error) {
        console.log('Something is wrong:', error);
        this.updateDisabledStatus(false);
        this.loading = false;
      } else {
        console.log('Success!', token);
        this.paymentService
          .paiementStripeNewCard(
            this.paymentDataService.selectedArticles.map((a) => a.idBasketArticle),
            this.paymentDataService.shippingAddress.id,
            this.paymentDataService.billingAddress.id,
            token.id,
            this.form.value.name,
            this.form.value.saveNewCard
          )
          .subscribe(
            (data) => {
              this.updateDisabledStatus(false);
              this.loading = false;
              this.paymentDataService.step = PaymentDataService.STEP_CONFIRM;
            },
            (error) => {
              L.e(error);
              this.error = error.message;
              this.updateDisabledStatus(false);
              this.loading = false;
            }
          );
      }
    } else {
      this.paymentService
        .paiementStripeOldCard(
          this.paymentDataService.selectedArticles.map((a) => a.idBasketArticle),
          this.paymentDataService.shippingAddress.id,
          this.paymentDataService.billingAddress.id,
          this.idCardSelected
        )
        .subscribe(
          (data) => {
            this.updateDisabledStatus(false);
            this.loading = false;
            this.paymentDataService.step = PaymentDataService.STEP_CONFIRM;
          },
          (error) => {
            L.e(error);
            this.error = error.message;
            this.updateDisabledStatus(false);
            this.loading = false;
          }
        );
    }
  }

  select(card: CreditCardNEW) {
    //card can be null if it's the new card we select
    if (card === null) {
      this.idCardSelected = 0;
      this.updateDisabledStatus(false);
      return;
    }
    if (this.idCardSelected === card.id) {
      this.idCardSelected = 0;
      this.updateDisabledStatus(false);
    } else {
      this.idCardSelected = card.id;
      this.updateDisabledStatus(true);
    }
  }

  check($event: MouseEvent, card: CreditCardNEW) {
    console.log($event.target);
    if (($event.target as Element).nodeName === 'LABEL') $event.stopPropagation();
    if (($event.target as Element).nodeName === 'I') this.deleteCard(card);
  }

  deleteCard(card: CreditCardNEW) {
    this.paymentService.disableCard(card.id).subscribe(
      (data) => {
        this.cards = this.cards.splice(this.cards.indexOf(card), 1);
      },
      (error) => {
        L.e(error);
      }
    );
    //reset the card to the new card
    this.idCardSelected = 0;
  }
}
