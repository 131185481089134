import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Inject, PLATFORM_ID } from '@angular/core';

import { AppComponent } from './app.component';
import { Ic2Module, IRPC, RpcError } from 'ic2-lib';

import { registerLocaleData, isPlatformBrowser } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InvoiceAdminComponent } from './Admins/Financial/invoice-admin.component';
import { DegineoCommonModule, AuthService } from 'degineo-common';
import { environment } from 'environments/environment';
import { AgmCoreModule } from '@agm/core';
import { FrontModule } from 'projects/client/src/app/Front/front.module';
import { AdminLayoutComponent } from './AdminLayout/admin-layout.component';
import { DashboardComponent } from './Admins/Dashboard/dashboard.component';
import { ProducerListComponent } from './Admins/Producer/List/producer-list.component';
import { ProducerEditComponent } from './Admins/Producer/Edit/producer-edit.component';
import { ProducerInfosTabComponent } from './Admins/Producer/Edit/producer-infos-tab/producer-infos-tab.component';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { ConsumableListComponent } from './Admins/Consumable/consumable-list/consumable-list.component';
import { ConsumableViewEditComponent } from './Admins/Consumable/consumable-view-edit/consumable-view-edit.component';
import { ConsumableCaracteristiquesComponent } from './Admins/Consumable/compo/consumable-caracteristiques/consumable-caracteristiques.component';
import { ConsumableInfoComponent } from './Admins/Consumable/compo/consumable-info/consumable-info.component';
import { ConsumableOptionsComponent } from './Admins/Consumable/compo/consumable-options/consumable-options.component';
import { ConsumablePrintersComponent } from './Admins/Consumable/compo/consumable-printers/consumable-printers.component';
import { ConsumableProducersComponent } from './Admins/Consumable/compo/consumable-producers/consumable-producers.component';
import { ConsumableData } from './Admins/Consumable/providers/consumable.provider';
import { PrinterListComponent } from './Admins/Printer/printer-list/printer-list.component';
import { PrinterViewEditComponent } from './Admins/Printer/printer-view-edit/printer-view-edit.component';
import { PrinterData } from './Admins/Printer/providers/printer.provider';
import { PrinterInfoComponent } from './Admins/Printer/compo/printer-info/printer-info.component';
import { PrinterSizeComponent } from './Admins/Printer/compo/printer-size/printer-size.component';
import { PrinterMetierComponent } from './Admins/Printer/compo/printer-metier/printer-metier.component';
import { PrinterConsumablesComponent } from './Admins/Printer/compo/printer-consumables/printer-consumables.component';
import { PrinterCompanyComponent } from './Admins/Printer/compo/printer-company/printer-company.component';
import { ClientListComponent } from './Admins/Client/client-list/client-list.component';
import { ClientViewComponent } from './Admins/Client/client-view/client-view.component';
import { ClientData } from './Admins/Client/providers/client.provider';
import { ClientInfoLeftComponent } from './Admins/Client/compo/client-info-left/client-info-left.component';
import { ClientInfoRightComponent } from './Admins/Client/compo/client-info-right/client-info-right.component';
import { MaterialTypeCharacteristicViewEditComponent } from './Admins/MaterialTypeCharacteristic/material-type-characteristic-view-edit/material-type-characteristic-view-edit.component';
import { MaterialTypeCharacteristicsInfoComponent } from './Admins/MaterialTypeCharacteristic/compo/material-type-characteristics-info/material-type-characteristics-info.component';
import { MaterialTypeCharacteristicData } from './Admins/MaterialTypeCharacteristic/providers/materialTypeCharacteristic.provider';
import { MaterialTypeCaracteristicNewComponent } from './Admins/MaterialTypeCharacteristic/compo/material-type-caracteristic-new/material-type-caracteristic-new.component';
import { ProducerInfosLeftComponent } from './Admins/Producer/Edit/producer-infos-tab/compo/producer-infos-left/producer-infos-left.component';
import { ProducerInfosRightComponent } from './Admins/Producer/Edit/producer-infos-tab/compo/producer-infos-right/producer-infos-right.component';
import { ProducerData } from './Admins/Producer/providers/producer.provider';
import { ProducerBankAccountComponent } from './Admins/Producer/Edit/producer-infos-tab/compo/producer-bank-account/producer-bank-account.component';
import { ProducerBillingComponent } from './Admins/Producer/Edit/producer-infos-tab/compo/producer-billing/producer-billing.component';
import { ProducerUsersTabComponent } from './Admins/Producer/Edit/producer-users-tab/producer-users-tab.component';
import { ProducerPrinterConsumableTabComponent } from './Admins/Producer/Edit/producer-printer-consumable-tab/producer-printer-consumable-tab.component';
import { ProducerPrinterConsumableInfoComponent } from './Admins/Producer/Edit/producer-printer-consumable-tab/compo/producer-printer-consumable-info/producer-printer-consumable-info.component';
import { ProducerPrinterConsumableOptionInfoComponent } from './Admins/Producer/Edit/producer-printer-consumable-tab/compo/producer-printer-consumable-option-info/producer-printer-consumable-option-info.component';
import { OrderListComponent } from './Admins/Order/order-list/order-list.component';
import { OrderViewComponent } from './Admins/Order/order-view/order-view.component';
import { OrderViewClientComponent } from './Admins/Order/compo/order-view-client/order-view-client.component';
import { OrderViewBillingAddressComponent } from './Admins/Order/compo/order-view-billing-address/order-view-billing-address.component';
import { OrderViewShippingAddressComponent } from './Admins/Order/compo/order-view-shipping-address/order-view-shipping-address.component';
import { OrderViewNoteComponent } from './Admins/Order/compo/order-view-note/order-view-note.component';
import { OrderData } from './Admins/Order/providers/order.provider';
import { ClientOrderListComponent } from './Admins/Client/compo/client-order-list/client-order-list.component';
import { ProducerArticleListComponent } from './Admins/Producer/Edit/producer-article-list/producer-article-list.component';
import { BOData } from './provider/bo.provider';
import { ProducerPageListComponent } from './Admins/ProducerPage/producer-page-list/producer-page-list.component';
import { PaymentToDegineoListComponent } from './Admins/PaymentToDegieno/payment-to-degineo-list/payment-to-degineo-list.component';
import { PaymentToProducerListComponent } from './Admins/PaymentToProducer/payment-to-producer-list/payment-to-producer-list.component';
import { SubscriptionListComponent } from './Admins/Subscription/subscription-list/subscription-list.component';
import { PaymentToDegineoEditComponent } from './Admins/PaymentToDegieno/payment-to-degineo-edit/payment-to-degineo-edit.component';
import { ProducerNewComponent } from './Admins/Producer/New/producer-new/producer-new.component';
import { ProducerDevisTabComponent } from './Admins/Producer/Edit/producer-devis-tab/producer-devis-tab.component';
import { ProducerUserNewComponent } from './Admins/Producer/Edit/producer-users-tab/producer-user-new/producer-user-new.component';
import { ProducerUserListComponent } from './Admins/Producer/Edit/producer-users-tab/producer-user-list/producer-user-list.component';
import { ArticleTypeViewEditComponent } from './Admins/ArticleType/article-type-view-edit/article-type-view-edit.component';
import { ArticleTypeData } from './Admins/ArticleType/providers/articleType.provider';
import { ArticleTypeNewComponent } from './Admins/ArticleType/compo/article-type-new/article-type-new.component';
import { ArticleTypeInfoComponent } from './Admins/ArticleType/compo/article-type-info/article-type-info.component';
import { CodenafListEditComponent } from './Admins/CodeNaf/codenaf-list-edit/codenaf-list-edit.component';
import { BrowserCookiesModule } from 'ngx-utils-cookies-port';
import { Router } from '@angular/router';
import { CookiesService } from 'ngx-utils-cookies-port';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { NgxMaskModule } from 'ngx-mask';
import { FixturesComponent } from './Admins/Fixtures/fixtures.component';
import { FinishListComponent } from './Admins/Finish/finish-list/finish-list.component';
import { FinishViewEditComponent } from './Admins/Finish/finish-view-edit/finish-view-edit.component';
import { FinishInfoComponent } from './Admins/Finish/compo/finish-info/finish-info.component';
import { FinishMaterialAssocComponent } from './Admins/Finish/compo/finish-material-assoc/finish-material-assoc.component';
@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    InvoiceAdminComponent,
    DashboardComponent,
    ProducerListComponent,
    ProducerEditComponent,
    ProducerInfosTabComponent,
    ConsumableListComponent,
    ConsumableViewEditComponent,
    ConsumableCaracteristiquesComponent,
    ConsumableInfoComponent,
    ConsumableOptionsComponent,
    ConsumablePrintersComponent,
    ConsumableProducersComponent,
    PrinterListComponent,
    PrinterViewEditComponent,
    PrinterInfoComponent,
    PrinterSizeComponent,
    PrinterMetierComponent,
    PrinterConsumablesComponent,
    PrinterCompanyComponent,
    ClientListComponent,
    ClientViewComponent,
    ClientInfoLeftComponent,
    ClientInfoRightComponent,
    MaterialTypeCharacteristicViewEditComponent,
    MaterialTypeCharacteristicsInfoComponent,
    MaterialTypeCaracteristicNewComponent,
    ProducerInfosLeftComponent,
    ProducerInfosRightComponent,
    ProducerBankAccountComponent,
    ProducerBillingComponent,
    ProducerUsersTabComponent,
    ProducerPrinterConsumableTabComponent,
    ProducerPrinterConsumableInfoComponent,
    ProducerPrinterConsumableOptionInfoComponent,
    OrderListComponent,
    OrderViewComponent,
    OrderViewClientComponent,
    OrderViewBillingAddressComponent,
    OrderViewShippingAddressComponent,
    OrderViewNoteComponent,
    ClientOrderListComponent,
    ProducerArticleListComponent,
    ProducerPageListComponent,
    PaymentToDegineoListComponent,
    PaymentToProducerListComponent,
    SubscriptionListComponent,
    PaymentToDegineoEditComponent,
    ProducerNewComponent,
    ProducerDevisTabComponent,
    ProducerUserNewComponent,
    ProducerUserListComponent,
    ArticleTypeViewEditComponent,
    ArticleTypeNewComponent,
    ArticleTypeInfoComponent,
    CodenafListEditComponent,
    FixturesComponent,
    FinishListComponent,
    FinishViewEditComponent,
    FinishInfoComponent,
    FinishMaterialAssocComponent,
  ],
  imports: [
    BrowserModule,
    AngularMyDatePickerModule,
    BrowserCookiesModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgbModule,
    Ic2Module,
    FormsModule,
    ReactiveFormsModule,
    DegineoCommonModule,
    UiSwitchModule,
    FrontModule,
    AgmCoreModule.forRoot({
      apiKey: environment.gmapsApiKey,
    }),
    AppRoutingModule, //needs to be last
  ],
  providers: [BOData, ConsumableData, PrinterData, ClientData, MaterialTypeCharacteristicData, ProducerData, OrderData, ArticleTypeData],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    public irpc: IRPC,
    router: Router,
    @Inject(PLATFORM_ID) private platform: Object,
    private cookiesService: CookiesService,
    authService: AuthService
  ) {
    if (isPlatformBrowser(this.platform)) {
      irpc.authToken = (<any>window).jwtToken;
      irpc.url = environment.rpcHost + 'rpc';
      if (this.irpc.authToken === null || this.irpc.authToken === undefined) {
        let token = cookiesService.get('token');
        //console.log(token);
        if (token === null) {
          return;
        }
        this.irpc.authToken = token;
      }
    } else {
      irpc.url = environment.ssrRpcHost + 'rpc';
    }
    //Auto load token from localStorage

    if (irpc.authToken !== null && irpc.authToken !== undefined) {
      const decodedToken = new JwtHelperService().decodeToken(irpc.authToken);
    }
    //console.log(decodedToken);
    irpc.log = environment.name !== 'PRODUCTION';

    irpc.interceptErrorHandlers.push((req, err) => {
      if (err.code === RpcError.NOT_LOGGED) {
        console.log('INTERCEPTED NOT LOGGED ERROR on ' + req.req.serviceDotMethod);
        authService.logout();
        router.navigate(['/connexion']);
        return true;
      }
      return false;
    });
  }
}
