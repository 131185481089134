<div class="container-fluid">
  <div class="row">
    <div class="col">
      <h6 class="text-center">Choisissez la couleur de votre pièce</h6>
    </div>
  </div>
  <div class="row mt-3" *ngIf="availableColors === null">
    <div class="col text-center">
      Veuillez choisir une matière avant.
    </div>
  </div>
  <div *ngIf="availableColors !== null">
    <div class="row mt-3">
      <div class="col-2" [class.mt-4]="i > 5" *ngFor="let color of availableColors.colors; let i = index">
        <div
          class="color-box"
          [class.active]="selectedColor === color"
          [style.backgroundColor]="'#' + color.hexCode"
          [title]="color.name"
          (click)="colorChanged.emit(color)"
        >
          &nbsp;
        </div>
      </div>
    </div>
    <!--
      Commenté en attendant une refonte pour le multicolore, les screens de la maquette sont ici :
      https://gitlab.rockpalm.it/degineo/platform/issues/101
    <div class="row mt-4 box-multicolor" [class.active]="isMulticolor" *ngIf="availableColors.canPrintInMulticolor" (click)="colorChanged.emit('multicolor')">
      <div class="col-2">
        <div class="box-rainbow">&nbsp;</div>
      </div>
      <div class="col-10">
        Impression multicolore<br />
        <span class="text-muted">(Imprime les couleurs contenues dans votre fichier .mtl)</span>
      </div>
    </div>
    <div class="row mt-1" *ngIf="selectedColor === OrderService.MULTICOLOR_STR">
      <div class="col" (drop)="drop($event)" (dragover)="dragover($event)" (dragexit)="dragexit($event)" (dragenter)="dragenter($event)">
        <div class="fileUploadContainer" id="fileUploadContainer">
          <div class="fileuploader fileuploader-theme-dragdrop">
            <input type="file" name="articleModelFile" accept=".mtl" class="hiddeninput" #fileinput (change)="fileUpload($event)" />
            <div class="fileuploader-input">
              <div class="fileuploader-input-inner">
                <img src="assets/fileuploader-dragdrop-icon.png" />
                <h3 *ngIf="!dragging">
                  <span>Glissez votre fichier .mtl ici</span>
                </h3>
                <h3 *ngIf="dragging">
                  <span>Déposez votre fichier .mtl ici</span>
                </h3>
                <p class="mb-1">ou</p>
                <button class="btn btn-primary" (click)="fileinput.click()">Parcourez vos fichiers</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    -->
  </div>
</div>
