import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { environment } from 'environments/environment';
import { OrderService } from '../../order.service';
import { FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ForgotPwdPopupComponent } from 'degineo-common';

@Component({
  selector: 'app-login-register-step',
  templateUrl: './login-register-step.component.html',
  styleUrls: ['./login-register-step.component.scss'],
})
export class LoginRegisterStepComponent implements OnInit {
  environment = environment;
  @Input()
  preRegisterForm: FormGroup;
  @Output()
  login: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  continueRegistration: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  previous: EventEmitter<void> = new EventEmitter<void>();
  @Input()
  registerEmailUnavailable: boolean = false;

  svUrl: string = environment.svUrl;
  constructor(@Inject(OrderService) public orderService: OrderService, private modalService: NgbModal) {}

  ngOnInit() {
    this.orderService.title = 'Imprimer un fichier en 3D';
  }
}
