import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ClientDevisDTO, DevisStatus, ClientDevisService } from 'degineo-common';
import { L } from 'ic2-lib';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../../../../../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CheckoutDevisPopupComponent } from '../checkout-devis-popup/checkout-devis-popup.component';

@Component({
  selector: 'app-client-devis-preview',
  templateUrl: './client-devis-preview.component.html',
  styleUrls: ['./client-devis-preview.component.scss'],
})
export class ClientDevisPreviewComponent implements OnInit, OnDestroy {
  private sub: Subscription;
  public dto: ClientDevisDTO;
  iframeUrl: SafeResourceUrl;
  devisUrl: string;
  rpcHost: string = environment.rpcHost;
  sending: boolean;
  DevisStatus: typeof DevisStatus = DevisStatus;

  constructor(
    @Inject(ClientDevisService) public clientDevisService: ClientDevisService,
    @Inject(ActivatedRoute) public route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    public router: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      this.clientDevisService.getDevis(+params['id']).subscribe(
        (data) => {
          this.dto = data;
          this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.rpcHost + 'previewDevis/' + data.tokenPreview);
          this.devisUrl = this.rpcHost + 'devis/' + data.tokenPreview;
          console.log(this.devisUrl);
        },
        (error) => {
          L.e(error);
        }
      );
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  acceptDevis() {
    this.sending = true;
    //TODO test redir parent window

    this.clientDevisService.acceptDevis(this.dto.idDevis).subscribe(
      (data) => {
        this.sending = false;

        this.openModal();
      },
      (error) => {
        this.sending = false;
        L.e(error);
      }
    );
  }

  openModal() {
    const modalRef = this.modalService.open(CheckoutDevisPopupComponent, {
      centered: true,
      //@ts-ignore
      size: 'md',
      beforeDismiss: () => {
        return true;
      },
    });

    modalRef.result
      .then((info) => {
        console.log(info);
        if (info === 'ajoute') {
          this.checkoutDevis();
        }
      })
      .catch((err) => {
        console.log(err);
        this.router.navigate(['/devis']);
      });
  }

  checkoutDevis() {
    this.sending = true;
    //TODO test redir parent window

    this.clientDevisService.ajouteDevisAuPanier(this.dto.idDevis).subscribe(
      (data) => {
        this.sending = false;
        this.router.navigate(['/panier']);
        /*
        setTimeout(() => {
          this.router.navigate(['/Client', 'Devis']);
        }, 2000);
        */
      },
      (error) => {
        this.sending = false;
        L.e(error);
      }
    );
  }
}
