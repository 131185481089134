<div class="container-small">
  <div class="row">
    <div class="col text-center">
      <h1>Mon profil</h1>
    </div>
  </div>
  <div class="row mt-5 align-items-center">
    <div class="col-3 text-center">
      <img class="rounded-circle mb-2" [src]="avatarChangeService.avatar" style="width:7rem;height: 7rem;" />
      <input type="file" id="file" style="display: none" accept="image/png,image/jpeg,image/gif" #fileInput (change)="handleFileInput($event)" /><br />
      <a [routerLink]="null" class="fs-2 fw-500" (click)="fileInput.click()">Changer l'image</a>
      <div class="progress" *ngIf="progressPercent">
        <div class="progress-bar" role="progressbar" [style.width]="progressPercent + '%'">{{ progressPercent }}%</div>
      </div>
      <span class="text-danger d-block" *ngIf="errorFile">{{ errorFile }}</span>
    </div>
    <div class="col-9 text-right">
      <button class="btn btn-primary text-uppercase" (click)="openResetPasswordPopup()">
        <i class="fas fa-unlock-alt mr-2"></i>
        Modifier mon mot de passe
      </button>
    </div>
  </div>

  <form #f="ngForm" [formGroup]="form" [class.show-errors]="f.submitted && !form.valid">
    <div class="row mt-5">
      <div class="col-md-6">
        <label for="firstname">Prénom</label>
        <input type="text" class="form-control mt-2 dashed-when-disabled" id="firstname" formControlName="firstname" />
      </div>
      <div class="col-md-6">
        <label for="lastname">Nom</label>
        <input type="text" class="form-control mt-2 dashed-when-disabled" id="lastname" formControlName="lastname" />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <label for="companyName">Nom de votre entreprise</label>
        <input type="text" class="form-control mt-2 dashed-when-disabled" id="companyName" formControlName="companyName" />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <label for="phone">Numéro de téléphone</label>
        <input type="text" class="form-control mt-2 dashed-when-disabled" id="phone" formControlName="phone" mask="00 00 00 00 00" />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <label for="email">E-mail</label>
        <input formControlName="email" id="email" class="form-control mt-2 dashed-when-disabled" type="text" />
        <div *ngIf="form.get('email').invalid && !form.get('email').pristine" class="ml-2 mt-1 text-danger">
          <div *ngIf="form.get('email').errors">
            E-mail invalide
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3" *ngIf="error">
      <div class="col">
        <div class="alert alert-danger">
          <ul>
            <li>
              {{ error }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col text-right">
        <button class="btn btn-primary text-uppercase" type="button" (click)="enable()" *ngIf="!showConfirm">
          Modifier
        </button>
        <ng-container *ngIf="showConfirm">
          <button [disabled]="loading" class="btn btn-secondary text-uppercase" type="button" (click)="cancel()">
            Annuler
          </button>
          <button [disabled]="form.invalid || loading" class="btn btn-success text-uppercase ml-2" type="button" (click)="validate()">
            Valider
          </button>
        </ng-container>
      </div>
    </div>
  </form>
</div>
