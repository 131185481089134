<div class="container-fluid my-3 fw-400">
  <div class="row px-5">
    <div class="col text-center">
      <p class="fw-700">Vous avez accepté ce devis.</p>
      <p>Souhaitez-vous ajouter les impression&nbsp;3D de ce devis au panier&nbsp;?</p>
    </div>
  </div>
  <div class="row mt-3 px-5">
    <div class="col text-center">
      <button class="btn btn-secondary text-uppercase" style="width: 140px" (click)="activeModal.dismiss('plus_tard')">Plus tard</button>
      <button class="btn btn-success text-uppercase ml-3" style="width: 140px" (click)="activeModal.close('ajoute')">Oui</button>
    </div>
  </div>
</div>
