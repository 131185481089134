import { Component, OnInit, Output, Input, EventEmitter, Inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from 'environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderService } from '../../order.service';
import { ForgotPwdPopupComponent } from 'degineo-common';

@Component({
  selector: 'app-login-step',
  templateUrl: './login-step.component.html',
  styleUrls: ['./login-step.component.scss'],
})
export class LoginStepComponent implements OnInit {
  environment = environment;
  @Input()
  loginForm: FormGroup;
  @Input()
  preRegisterForm: FormGroup;
  @Input()
  loginError: string;
  @Output()
  login: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  register: EventEmitter<void> = new EventEmitter<void>();

  constructor(@Inject(OrderService) public orderService: OrderService, private modalService: NgbModal) {}

  ngOnInit() {}
  openResetPopup() {
    const modalRef = this.modalService.open(ForgotPwdPopupComponent, {
      centered: true,
      //@ts-ignore
      size: 'xl',
      beforeDismiss: () => {
        return false;
      },
    });
  }
}
