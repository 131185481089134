import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, LoginService, DegineoRight, ForgotPwdPopupComponent } from 'degineo-common';
import { L } from 'ic2-lib';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  form: FormGroup = this.fb.group({
    email: [null, [Validators.required, Validators.email]],
    password: [null, [Validators.required, Validators.minLength(6)]],
  });
  loginError: string;
  loading: boolean = false;
  svClientUrl: string;
  producerUrl: string;

  constructor(
    private modalService: NgbModal,
    @Inject(FormBuilder) private fb: FormBuilder,
    @Inject(Router) private router: Router,
    @Inject(AuthService) private authService: AuthService,
    @Inject(LoginService) private loginService: LoginService
  ) {}

  ngOnInit() {
    this.authService.isLoggedIn().subscribe((data) => {
      if (data) this.router.navigate(['/']);
    });
    this.svClientUrl = environment.svUrl + 'espace-client';
    this.producerUrl = environment.producerUrl;
  }

  submit() {
    console.log(this.form);
    if (!this.form.valid) return;
    if (this.loading) return;
    this.loading = true;

    this.authService.login(this.form.value.email, this.form.value.password).subscribe(
      (event) => {
        console.log(event);
        let loggedIn = event.userBundle && AuthService.has(event.userBundle, DegineoRight.CLIENT);
        this.loading = false;
        if (!loggedIn) {
          event.permitLogin = false;
          this.loginError = "Ce compte n'est pas un compte client";
          return;
        }
        if (event.redirectUrl === null) event.redirectUrl = '/';
      },
      (error) => {
        this.loading = false;
        if (error.isBusinessError()) this.loginError = 'Utilisateur ou mot de passe incorrect';
        else {
          L.e(error);
          this.loginError = 'Une erreur inconnue est survenue';
        }
      }
    );
  }

  openResetPasswordPopup() {
    const modalRef = this.modalService.open(ForgotPwdPopupComponent, {
      centered: true,
      //@ts-ignore
      size: 'xl',
      beforeDismiss: () => {
        return false;
      },
    });
  }
}
