import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ClientBOService, ClientProducerAssociationStatus, UserEditValidators } from 'degineo-common';
import { L } from 'ic2-lib';
import { ClientData } from '../providers/client.provider';
import { SlugService } from 'degineo-common';
import * as moment_ from 'moment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-client-view',
  templateUrl: './client-view.component.html',
  styleUrls: ['./client-view.component.scss'],
})
export class ClientViewComponent implements OnInit {
  private sub: Subscription;
  loading: boolean = true;
  editSiret: boolean = false;
  degineoComment: string;
  loadingSiret: boolean = false;
  error: string = null;
  form: FormGroup = this.fb.group({
    siret: [null, [Validators.required, UserEditValidators.siretValide]],
  });
  id: number;

  moment = moment_;
  ClientProducerAssociationStatus: typeof ClientProducerAssociationStatus = ClientProducerAssociationStatus;

  constructor(
    @Inject(ActivatedRoute) public route: ActivatedRoute,
    @Inject(ClientBOService) public clientBOService: ClientBOService,
    @Inject(SlugService) public slugService: SlugService,
    private router: Router,
    public data: ClientData,
    public fb: FormBuilder
  ) {
    this.moment.locale('fr');
  }

  ngOnInit() {
    console.log('ngOnInit');
    this.sub = this.route.params.subscribe((params) => {
      if (params['id']) {
        this.id = parseInt(params['id']);
      } else {
        this.router.navigate(['/clients']);
      }
      this.reload();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  reload() {
    this.loading = true;

    this.clientBOService.getClientDetail(this.id).subscribe(
      (data) => {
        this.degineoComment = data.degineoComment;
        this.form.patchValue({ siret: data.siret });
        this.data.dto = data;
        this.loading = false;
      },
      (error) => {
        L.e(error);
      }
    );

    console.log('data', this.data);
  }

  updateSiret() {
    this.error = null;
    if (!this.form.valid) {
      console.log(this.form);
      return;
    }
    this.loadingSiret = true;
    this.clientBOService.updateSiret(this.id, this.form.value.siret).subscribe(
      (data) => {
        this.loadingSiret = false;
        this.editSiret = false;
        this.data.dto.siret = this.form.value.siret;
      },
      (err) => {
        this.loadingSiret = false;
        if (err.isBusinessError()) this.error = err.message;
        else L.e(err);
      }
    );
  }

  backClicked() {
    this.router.navigate(['/clients']);
  }

  activeChange() {
    console.log('activeChange');

    this.clientBOService.updateClientActive(this.id, !this.data.dto.inactived).subscribe(
      (data) => {
        this.data.dto.inactived = !this.data.dto.inactived;
      },
      (error) => {
        L.e(error);
      }
    );
  }

  deleteClient() {
    console.log('deleteClient');

    this.clientBOService.deleteClient(this.id).subscribe(
      (data) => {
        this.router.navigate(['/clients']);
      },
      (error) => {
        L.e(error);
      }
    );
  }

  displayBtNote() {
    if (this.degineoComment == null || this.degineoComment == '')
      if (this.data.dto.degineoComment == null || this.data.dto.degineoComment == '') return false;
      else return true;
    else if (this.degineoComment != this.data.dto.degineoComment) return true;
    else return false;
  }
  cancelNoteClicked() {
    this.data.dto.degineoComment = this.degineoComment;
  }
  saveNoteClicked() {
    this.clientBOService.saveDegineoNote(this.id, this.data.dto.degineoComment).subscribe(
      (data) => {
        this.degineoComment = this.data.dto.degineoComment;
      },
      (error) => {
        L.e(error);
      }
    );
  }
}
