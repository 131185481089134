<div class="container-fluid">
  <div class="row">
    <div class="col">
      <h6 class="text-center">Indiquez la matière de votre impression&nbsp;3D</h6>
    </div>
  </div>
  <div class="row mt-3" *ngIf="availableMaterials === null">
    <div class="col text-center">Veuillez choisir une taille d'impression avant.</div>
  </div>
  <div class="row mt-3" *ngIf="availableMaterials !== null && availableMaterials.length === 0">
    <div class="col text-center">Aucune matière n'est disponible pour ces dimensions</div>
  </div>
  <div class="row mt-3" *ngIf="availableMaterials">
    <div class="col">
      <select class="form-control" [ngModel]="selectedMaterial" (ngModelChange)="materialSelected.emit($event)">
        <option [ngValue]="null" disabled="disabled">-- Choisir la matière --</option>
        <ng-container *ngFor="let material of rootMaterials(); let i = index">
          <ng-container *ngIf="hasChildren(material); else nochild">
            <option [ngValue]="childMaterial" *ngFor="let childMaterial of childrenOf(material); let i2 = index">
              {{ material.name }} - {{ childMaterial.name }}
            </option>
          </ng-container>
          <ng-template #nochild>
            <option [ngValue]="material">{{ material.name }}</option>
          </ng-template>
        </ng-container>
      </select>
    </div>
    <!--
    <ng-container *ngIf="availableMaterials">
      <div
        class="box"
        [class.last]="i === rootMaterials().length - 1"
        [class.active]="open[material.id]"
        *ngFor="let material of rootMaterials(); let i = index"
      >
        <div class="title pointer" [class.with-children]="hasChildren(material)" *ngIf="hasChildren(material); else nochild" (click)="toggle(material.id)">
          <i class="fa" style="width: 1.5rem" [class.fa-chevron-right]="!open[material.id]" [class.fa-chevron-down]="open[material.id]"></i> {{ material.name }}
        </div>
        <ng-template #nochild>
          <div class="title">
            <input
              type="radio"
              name="test"
              id="material-{{ material.id }}"
              (change)="materialSelected.emit(material)"
              [checked]="selectedMaterial?.id === material.id"
            />
            <label for="material-{{ material.id }}">{{ material.name }}</label>
          </div>
        </ng-template>
        <div class="consumable" *ngIf="hasChildren(material)">
          <div *ngFor="let childMaterial of childrenOf(material); let i2 = index">
            <input
              type="radio"
              name="test"
              id="material-{{ childMaterial.id }}"
              (change)="materialSelected.emit(childMaterial)"
              [checked]="selectedMaterial?.id === childMaterial.id"
            />
            <label for="material-{{ childMaterial.id }}">{{ childMaterial.name }}</label>
          </div>
        </div>
      </div>
    </ng-container>
    -->
  </div>
</div>
