<div class="container" *ngIf="!panierDataService.articles">
  <div class="row">
    <div class="col"><i class="fas fa-spinner spinning"></i></div>
  </div>
</div>
<ng-container *ngIf="panierDataService.articles">
  <div class="container">
    <div class="row mb-5 align-items-center" *ngIf="panierDataService.articles.articles.length === 0">
      <div class="col text-center"><h3>Votre panier est vide.</h3></div>
    </div>
    <div class="row mb-5 align-items-center" *ngFor="let article of panierDataService.articles.articles">
      <div class="col text-right">
        <input
          type="checkbox"
          class="form-control"
          style="display: inline; width: 1.5rem"
          [checked]="article.checked"
          (change)="checkedChanged(article, $event)"
          *ngIf="!loading"
        />
        <i class="fas fa-spinner spinning" *ngIf="loading"></i>
      </div>
      <div class="col" [class.disabled]="!article.checked">
        <img class="rounded" style="width: 3.75rem; height: 3.75rem" img-preloader="{{ rpcHost }}modele3Dpng/{{ article.tokenModelPng }}/modele.png" />
      </div>
      <div class="col-4 text-center fs-2" [class.disabled]="!article.checked">
        <span class="fw-700" *ngIf="article.producerName !== 'Degineo'">{{ article.producerName }}<br /></span>
        {{ article.name }} <br />
        {{ article.articleTypeName }} <br />
        <ng-container *ngIf="!article.dentaire">
          {{ article.sizeInMm.x | number: '.2':'fr-FR' }}&nbsp;mm x {{ article.sizeInMm.y | number: '.2':'fr-FR' }}&nbsp;mm x
          {{ article.sizeInMm.z | number: '.2':'fr-FR' }}&nbsp;mm<br />
          {{ article.materialName }} - {{ article.colorName }} - {{ article.finishName }}
        </ng-container>
      </div>
      <div class="col text-center fw-700" [class.disabled]="!article.checked">
        <i
          class="fas fa-minus-circle"
          [ngClass]="{
            pointer: article.idDevis === 0 && article.quantity > 1,
            'text-primary': article.idDevis === 0 && article.quantity > 1,
            'text-secondary': article.idDevis !== 0 || article.quantity === 1
          }"
          (click)="removeQuantity(article)"
          *ngIf="!loading"
        ></i>
        <i class="fas fa-spinner spinning" *ngIf="loading"></i>
        <span class="fw-700 ml-2 mr-2">{{ article.quantity }}</span>
        <i
          class="fas fa-plus-circle"
          [ngClass]="{
            pointer: article.idDevis === 0,
            'text-primary': article.idDevis === 0,
            'text-secondary': article.idDevis !== 0
          }"
          (click)="addQuantity(article)"
          *ngIf="!loading"
        ></i>
        <i class="fas fa-spinner spinning" *ngIf="loading"></i>
      </div>
      <div class="col text-right fs-2 fw-700" [class.disabled]="!article.checked">
        {{ article.unitPriceTTC * article.quantity | number: '1.0-2':'fr-FR' }}&nbsp;€<!-- prix en ttc-->
      </div>
      <div class="col fs-2 fw-700" [class.disabled]="!article.checked">
        <i class="far fa-trash-alt hover-danger" (click)="deleteArticle(article)" *ngIf="!loading" placement="right" ngbTooltip="Supprimer"></i>
        <i class="fas fa-spinner spinning" *ngIf="loading"></i>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row bg-light-30p pt-2 pb-2">
      <div class="col-12 col-md-4 order-3 order-md-1 pt-2 pt-md-0">
        <button routerLink="/impression" [disabled]="loading" class="btn btn-secondary w-100 mt-2 mb-2 text-uppercase">Imprimer un autre fichier 3D</button>
      </div>
      <div class="col-12 col-md-4 text-right order-1 order-md-2">
        <span class="fs-2 fw-300">Total TTC</span><span class="fs-4 fw-700"> {{ panierDataService.articles.totalTTC | number: '.2':'fr-FR' }}&nbsp;€</span
        ><br />
        <span class="fs-2 fw-400">Dont TVA : {{ panierDataService.articles.VATAmount | number: '.2':'fr-FR' }}&nbsp;€ - frais de port inclus</span>
      </div>
      <div class="col-12 col-md-4 order-2 order-md-3 pt-2 pt-md-0">
        <button (click)="openPayment()" [disabled]="loading || cannotOrder()" class="btn btn-success w-100 mt-2 mb-2 text-uppercase">Commander</button>
      </div>
    </div>
  </div>
</ng-container>
