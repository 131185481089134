<div class="container-fluid">
  <div class="row">
    <div class="col">
      <h6 class="text-center">Technologie qui sera utilisée&nbsp;:</h6>
    </div>
  </div>
  <div class="row mt-3" *ngIf="selectedMaterial === null">
    <div class="col text-center">Veuillez choisir une matière avant.</div>
  </div>
  <div class="row align-items-center mt-4" *ngIf="selectedMaterial !== null">
    <div class="col-auto">
      <img src="assets/technologies_d_impression/{{ selectedMaterial.printerTechnology.name }}.png" class="rounded" style="width: 5rem; height: 5rem" />
    </div>
    <div class="col-auto">
      <span class="fw-500">{{ selectedMaterial.printerTechnology.lib }}</span>
      <br />
      {{ printerTechnoBlabla[selectedMaterial.printerTechnology.id] }}
    </div>
  </div>
</div>
