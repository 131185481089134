import { Component, OnInit, Inject, EventEmitter, Output, Input } from '@angular/core';
import { OrderService } from '../../order.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-register-step',
  templateUrl: './register-step.component.html',
  styleUrls: ['./register-step.component.scss'],
})
export class RegisterStepComponent implements OnInit {
  @Output()
  next: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  previous: EventEmitter<void> = new EventEmitter<void>();
  @Input()
  registerForm: FormGroup;
  error: string = null;
  showInfo: boolean = false;
  disabled: boolean = false;

  constructor(@Inject(OrderService) public orderService: OrderService) {}

  ngOnInit() {
    this.orderService.title = 'Imprimer un fichier en 3D';
  }
}
