<div class="container pt-4 pb-4">
  <div class="row">
    <div class="col text-center">
      <h1>Fixtures</h1>
    </div>
  </div>

  <div class="row mt-5" *ngIf="loading || loadingDump || loadingRestore">
    <span *ngIf="loading"><i class="fas fa-spinner spinning mr-2"></i>Chargement en cours</span>
    <span *ngIf="loadingDump"><i class="fas fa-spinner spinning mr-2"></i>Sauvegarde en cours</span>
    <span *ngIf="loadingRestore"><i class="fas fa-spinner spinning mr-2"></i>Restauration en cours</span>
  </div>

  <ng-template [ngIf]="!loading && !loadingDump && !loadingRestore">
    <div class="row mt-5">
      <div class="col-5">
        <div class="input-group">
          <input #dumpName class="form-control" placeholder="Nom de la sauvegarde" />
          <div class="input-group-append">
            <span class="input-group-text">.zip</span>
          </div>
        </div>
      </div>
      <button class="btn btn-success" type="button" [disabled]="loading" (click)="dump(dumpName.value); dumpName.value = ''">
        <i class="fas fa-download mr-2"></i>Sauvegarder
      </button>
      <div class="col d-flex justify-content-end">
        <input type="file" id="file" style="display: none;" accept=".zip" #fileInput (change)="handleFileInput($event, fileInput)" />
        <button class="btn btn-primary" (click)="fileInput.click()">
          <i class="fas fa-file-medical mr-2"></i>
          Importer des fixtures
        </button>
      </div>
    </div>

    <div class="row py-4" *ngIf="dumps !== null">
      <div class="col">
        <table class="table table-hover table-striped">
          <thead class="thead-light">
            <tr>
              <th colspan="4">Sauvegardes</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let dump of dumps">
              <td>
                <a [href]="getDownload(dump.name)" [download]="dump.name" class="text-dark text-decoration-none">
                  <span ngbTooltip="{{ dump.createdDate | date: 'dd/MM/yyyy HH:mm' }}">
                    <i class="far fa-file-archive"></i>
                    {{ dump.name }}</span
                  >
                </a>
              </td>
              <td>
                <button
                  class="btn btn-primary"
                  (click)="restore(dump.name)"
                  [ngbTooltip]="!isProd ? 'Tous les mots de passe seront reset à \'testtest1\'' : null"
                >
                  <i class="fas fa-upload mr-2"></i>Restaurer
                </button>
              </td>
              <td class="row">
                <div class="col-6">
                  <div class="input-group">
                    <input #newZipName class="form-control" placeholder="Nouveau nom" />
                    <div class="input-group-append">
                      <span class="input-group-text">.zip</span>
                    </div>
                  </div>
                </div>
                <button class="btn btn-success" (click)="renameZip(dump.name, newZipName.value); newZipName.value = ''">
                  <i class="far fa-edit mr-2"></i>Renommer
                </button>
              </td>
              <td>
                <button class="btn btn-danger" (click)="deleteZip(dump.name)"><i class="fas fa-trash-alt mr-2"></i>Supprimer</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-template>
</div>
