import { Component, OnInit, Inject, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { Filter, PrinterTechnology, SlugService } from 'degineo-common';
import { FilterOrder, OrderBy, ConsumableListItemBODTO, MaterialTypeNEW, ColorNEW, GenericFilter } from 'degineo-common';
import { L } from 'ic2-lib';
import { MaterialTypeService } from 'degineo-common';
import { ColorService } from 'degineo-common';
import { Router } from '@angular/router';
import { ConsumableBOService } from 'degineo-common';
import { BrandConsumableService } from 'degineo-common';

@Component({
  selector: 'app-consumable-list',
  templateUrl: './consumable-list.component.html',
  styleUrls: ['./consumable-list.component.scss'],
})
export class ConsumableListComponent implements OnInit, AfterViewInit {
  constructor(
    @Inject(ConsumableBOService) public consumableBOService: ConsumableBOService,
    @Inject(MaterialTypeService) private materialTypeService: MaterialTypeService,
    @Inject(ColorService) private colorService: ColorService,
    @Inject(BrandConsumableService) private brandConsumableService: BrandConsumableService,
    private router: Router,
    private slugService: SlugService
  ) {}

  ConsumableListComponent: typeof ConsumableListComponent = ConsumableListComponent;
  consumables: ConsumableListItemBODTO[] = [];
  OrderBy: typeof OrderBy = OrderBy;

  static filtersCache = {};
  filtersDefault = {};
  static OrderCaches = {};
  static page: number = 1;

  nbPerPage: number = 10;
  count = 0;
  loading: boolean = true;

  builtFilters: Filter[] = [];
  builtFiltersOrder: FilterOrder[] = [];

  brandFilter: Array<string> = [];
  materialFilter: Array<MaterialTypeNEW> = [];
  colorFilter: Array<ColorNEW> = [];
  technologyFilter: PrinterTechnology[] = PrinterTechnology.values;
  presenterSelect = (obj) => obj.name;

  ngOnInit() {}

  ngAfterViewInit() {
    this.brandConsumableService.getAllConsumableBrand().subscribe(
      (data) => {
        this.brandFilter = data;
      },
      (error) => {
        L.e(error);
      }
    );

    this.colorService.getAllColors().subscribe(
      (data) => {
        this.colorFilter = data;
      },
      (error) => {
        L.e(error);
      }
    );

    this.materialTypeService.getAllChildsMaterialTypes().subscribe(
      (data) => {
        this.materialFilter = data;
      },
      (error) => {
        L.e(error);
      }
    );
    this.reload();
  }

  reload() {
    let genericFilter = new GenericFilter();
    genericFilter.filters = this.builtFilters;
    genericFilter.orderFilters = this.builtFiltersOrder;
    genericFilter.page = ConsumableListComponent.page;
    genericFilter.nbPerPage = this.nbPerPage;
    console.log('genericFilter', genericFilter);
    this.loading = true;
    this.consumableBOService.getConsumables(genericFilter).subscribe(
      (data) => {
        this.loading = false;
        this.consumables = data.data;
        this.count = data.count;
        console.log('data', data);
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }

  newClicked() {
    this.router.navigate(['/consumables', 'new']);
  }

  filterChanged(filters: Filter[]) {
    console.log('filterChanged event', filters);
    this.reload();
  }
  orderChange(order) {
    console.log('orderChange', order);
    this.reload();
  }
  tableRowClick(row: ConsumableListItemBODTO) {
    console.log('tableRowClick', row);
    this.router.navigate(['/consumables', 'view', row.id, this.slugService.string_to_slug(row.name)]);
  }
}
