<div class="container-fluid d-flex flex-column h-100">
  <div class="row">
    <div class="col text-center fw-500">Connectez-vous à votre compte Degineo afin de finaliser votre commande.</div>
  </div>
  <div class="row mt-5 bg-primary justify-content-center flex-grow-1" style="box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1)">
    <div class="col mt-5 px-4">
      <div class="container-small border bg-white rounded shadow-2 pb-4">
        <div class="row" style="padding-top: 2rem">
          <div class="col text-center">
            <h3>J'ai déjà un compte Degineo</h3>
          </div>
        </div>
        <form #f="ngForm" [formGroup]="loginForm" [class.show-errors]="f.submitted && !loginForm.valid">
          <div class="row mt-4">
            <div class="col">
              <i class="fas fa-envelope input-icon"></i>
              <input formControlName="email" autocomplete="username" type="text" class="form-control input-with-icon" placeholder="E-mail" />
            </div>
          </div>
          <div class="row" *ngIf="!loginForm.get('email').pristine || f.submitted">
            <div class="col-12 text-danger" *ngIf="loginForm.get('email').getError('required')">Le champ e-mail est requis</div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <i class="fas fa-lock input-icon"></i>
              <input
                formControlName="password"
                autocomplete="current-password"
                type="password"
                class="form-control input-with-icon"
                placeholder="Mot de passe"
              />
            </div>
          </div>
          <div class="row" *ngIf="!loginForm.get('password').pristine || f.submitted">
            <div class="col-12 text-danger" *ngIf="loginForm.get('password').errors">
              Le mot de passe doit contenir au moins une lettre et au moins un caractère numérique. Au moins 8 caractères sont nécessaires.
            </div>
          </div>
          <div class="row">
            <div class="col">
              <div class="row mt-1 justify-content-end">
                <div class="col-auto">
                  <a class="fw-400 fs-2 text-info pointer" (click)="openResetPopup()">Mot de passe oublié ?</a>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-1" *ngIf="loginError">
            <div class="col text-danger text-center">
              {{ loginError }}
            </div>
          </div>
          <div class="row mt-4">
            <div class="col text-center">
              <button type="button" class="btn btn-success text-uppercase w-100" [disabled]="orderService.loading" (click)="login.emit()">Se connecter</button>
            </div>
          </div>
        </form>
      </div>
      <div class="container-fluid mt-5">
        <div class="row">
          <div class="col-12">
            <div class="container-small bg-white rounded shadow-2 py-4">
              <div class="row">
                <div class="col text-center">
                  <h3>Nouveau sur Degineo ?</h3>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col text-center text-uppercase">
                  <a class="fw-500" style="letter-spacing: 0.025em" [routerLink]="null" (click)="register.emit()">Créer un compte</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 text-center mt-3 mb-3">
            <img src="assets/header/logo_degineo_blanc_vert.svg" style="height: 2rem" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
