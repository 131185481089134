import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProducerBOService } from 'degineo-common';
import { newProducerBODTO } from 'degineo-common';
import { L } from 'ic2-lib';
import { Router } from '@angular/router';
import { SlugService } from 'degineo-common';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-producer-new',
  templateUrl: './producer-new.component.html',
  styleUrls: ['./producer-new.component.scss'],
})
export class ProducerNewComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  imgURL: any;
  errorFile: string;

  file: File = null;

  countries: string[];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    @Inject(ProducerBOService) public producerBOService: ProducerBOService,
    @Inject(SlugService) public slugService: SlugService
  ) {
    this.countries = environment.countries;
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      noteDegineo: [''],
      AddressLine: ['', Validators.required],
      City: ['', Validators.required],
      Country: ['', Validators.required],
      ZipCode: ['', Validators.required],
    });
  }

  handleFileInput(event) {
    let files: FileList = event.target.files;
    var fr = new FileReader();
    fr.onload = (e) => {
      // file is loaded
      var img = new Image();
      img.onload = () => {
        console.log(img.width + ' x ' + img.height);
        let dimensionsMin = { w: 94, h: 140 };
        if (img.width < dimensionsMin.w || img.height < dimensionsMin.h) {
          //dimensions minis
          this.errorFile = 'Votre fichier doit au minimum faire ' + dimensionsMin.w + 'x' + dimensionsMin.h;
        } else {
          this.file = files[0];
        }
      };
      this.imgURL = (e.target as any).result;
      img.src = (e.target as any).result; // is the data URL because called with readAsDataURL
    };
    fr.readAsDataURL(files[0]);
  }

  get f() {
    return this.registerForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (!this.registerForm.valid) {
      console.log(this.registerForm);
      return;
    }

    let prod: newProducerBODTO = new newProducerBODTO();
    prod.producerName = this.registerForm.value.name;
    prod.producerNote = this.registerForm.value.noteDegineo;
    prod.addressLine = this.registerForm.value.AddressLine;
    prod.city = this.registerForm.value.City;
    prod.zipCode = this.registerForm.value.ZipCode;
    prod.country = this.registerForm.value.Country;

    console.log('New');
    this.producerBOService.newProducer(prod).subscribe(
      (data) => {
        console.log('New created id:', data);
        if (data != 0) {
          if (this.file) {
            console.log('file : ', this.file);
            this.uploadLogo(data);
          } else {
            console.log('no file return', this.registerForm);
            this.router.navigate(['/producers/edit/' + this.slugService.string_to_slug(this.registerForm.value.name) + '/' + data + '/infos']);
          }
        }
      },
      (err) => {
        L.e(err);
      }
    );
  }

  uploadLogo(id) {
    console.log('uploadLogo', this.file);
    this.producerBOService
      .uploadLogo(id)
      .withFile(this.file)
      .subscribeWithProgress(
        () => {
          console.log('uploaded', this.registerForm.value.name, id);
          this.router.navigate(['/producers/edit/' + this.slugService.string_to_slug(this.registerForm.value.name) + '/' + id + '/infos']);
        },
        () => {},
        (error) => {
          L.e('SAVE LOGO err', error);
        }
      );
  }

  cancel() {
    this.registerForm.reset();
    this.file = null;
    this.imgURL = null;
    this.router.navigate(['/producers']);
  }
}
