<div class="container">
  <div class="row my-3">
    <div class="col text-center">
      <h1>Nouvel administrateur</h1>
    </div>
  </div>

  <!-- ETAPE 1  -->
  <form #f="ngForm" [formGroup]="registerForm" [class.show-errors]="f.submitted && !registerForm.valid">
    <div class="row mt-3">
      <div class="col">
        <label for="Prénom">Prénom</label>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col">
        <input type="text" class="form-control" formControlName="firstname" />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <label for="Prénom">Nom</label>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col">
        <input type="text" class="form-control" formControlName="lastname" />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <label for="Prénom">Numéro de téléphone</label>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col">
        <input type="text" class="form-control" formControlName="phone" />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <label for="Prénom">Email</label>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col">
        <input type="email" class="form-control" formControlName="email" />
      </div>
    </div>

    <div class="row mt-4">
      <div class="col">
        <button class="btn btn-secondary w-100" type="button" (click)="cancelRegistration()">
          <i class="fas fa-arrow-left"></i>
        </button>
      </div>
      <div class="col">
        <button [disabled]="registerForm.invalid && registerForm.get('email').invalid" class="btn btn-success w-100" type="button" (click)="validateEmail()">
          <i class="fas fa-check"></i>
        </button>
      </div>
    </div>
  </form>
</div>
