import { Component, OnInit, Inject } from '@angular/core';
import { DumpService, DumpDTO } from 'degineo-common';
import { L } from 'ic2-lib';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-fixtures',
  templateUrl: './fixtures.component.html',
  styleUrls: ['./fixtures.component.scss'],
})
export class FixturesComponent implements OnInit {
  dumps: DumpDTO[] = null;
  loading: boolean = false;
  loadingDump: boolean = false;
  loadingRestore: boolean = false;
  rpcHost: string;
  isProd: boolean = false;
  constructor(@Inject(DumpService) private dumpService: DumpService) {}

  ngOnInit() {
    this.getDumps();
    this.rpcHost = environment.rpcHost;
    if (environment.name === 'PRODUCTION') this.isProd = true;
  }

  getDumps() {
    this.loading = true;
    this.dumpService.getDumps().subscribe(
      (data) => {
        this.dumps = data;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }

  getDownload(dumpName: string) {
    let dump: DumpDTO = this.dumps.find((x) => x.name == dumpName);
    return this.rpcHost + 'dump/' + dump.dlToken + '/' + dump.name;
  }

  restore(dump: string) {
    this.loadingRestore = true;
    this.dumpService.restore(dump).subscribe(
      (data) => {
        this.loadingRestore = false;
      },
      (err) => {
        this.loadingRestore = false;
        L.e(err);
      }
    );
  }

  dump(dump: string) {
    dump = dump.trim();
    if (!dump) {
      return;
    }

    this.loadingDump = true;
    dump += '.zip';

    this.dumpService.dump(dump).subscribe(
      (data) => {
        this.loadingDump = false;
        this.getDumps();
      },
      (err) => {
        this.loadingDump = false;
        L.e(err);
      }
    );
  }

  deleteZip(dump: string) {
    if (confirm('Voulez-vous vraiment supprimer la sauvegarder ' + dump + ' ?')) {
      this.loading = true;

      this.dumpService.deleteZip(dump).subscribe(
        (data) => {
          this.getDumps();
        },
        (err) => {
          this.loading = true;
          L.e(err);
        }
      );
    }
  }

  renameZip(dump: string, newName: string) {
    newName = newName.trim();
    if (!newName) {
      return;
    }

    this.loading = true;
    newName += '.zip';

    this.dumpService.renameZip(dump, newName).subscribe(
      (data) => {
        this.getDumps();
      },
      (err) => {
        this.loading = true;
        L.e(err);
      }
    );
  }

  handleFileInput(event, fileInput) {
    const files: FileList = event.target.files;
    const file = files[0];
    this.loading = true;
    this.dumpService
      .importFixtures()
      .withFile(file)
      .subscribeWithProgress(
        (data) => {
          fileInput.value = '';
          this.loading = false;
          this.getDumps();
        },
        (err) => {
          L.e(err);
          this.loading = false;
          fileInput.value = '';
        }
      );
  }
}
