<h6 class="text-center mb-4">
  Informations de facturation

  <div *ngIf="!edit" class="float-right">
    <button class="btn btn-primary-outline text-primary p-0" (click)="edit = true">
      <i class="fas fa-pen-square" style="font-size: 25px"></i>
    </button>
  </div>
  <div *ngIf="edit" class="float-right">
    <button class="btn btn-primary-outline text-danger p-0" (click)="cancelClicked()">
      <i class="fas fa-times-circle" style="font-size: 25px"></i>
    </button>
    <button class="btn btn-primary-outline text-success p-0 ml-2" (click)="saveClicked()">
      <i class="fas fa-check-circle" style="font-size: 25px"></i>
    </button>
  </div>
</h6>

<div class="mb-1">
  <span class="fw-500"> Raison sociale </span>
  &nbsp;:&nbsp;
  <span *ngIf="!edit">
    {{ data.dto.producer.denomination }}
  </span>
  <span *ngIf="edit">
    <input type="text" class="form-control d-inline-block w-200px" [(ngModel)]="denomination" />
  </span>
</div>

<div class="mb-1">
  <span class="fw-500"> Statut juridique </span>
  &nbsp;:&nbsp;
  <span *ngIf="!edit">
    {{ data.dto.producer.legalStatus }}
  </span>
  <span *ngIf="edit">
    <select class="form-control d-inline-block w-200px" [(ngModel)]="legalStatus">
      <option [ngValue]="null" disabled="disabled">-- Choisir le statut --</option>
      <option *ngFor="let ls of LegalStatus.values" [ngValue]="ls" [selected]="legalStatus == ls">{{ ls }}</option>
    </select>
  </span>
</div>

<div class="mb-1" *ngIf="legalStatus !== LegalStatus.STATUS_AE && legalStatus !== LegalStatus.STATUS_EI && legalStatus !== LegalStatus.STATUS_ASSOCIATION">
  <span class="fw-500"> Au capital de </span>
  &nbsp;:&nbsp;
  <span *ngIf="!edit"> {{ data.dto.producer.capital | number: '.2':'fr-FR' }}&nbsp;€ </span>
  <span *ngIf="edit"> <input class="form-control d-inline-block w-200px" type="number" [(ngModel)]="capital" />&nbsp;€ </span>
</div>

<div class="mb-1" *ngIf="legalStatus == LegalStatus.STATUS_ASSOCIATION">
  <span class="fw-500"> Numéro RNA </span>
  &nbsp;:&nbsp;
  <span *ngIf="!edit">{{ data.dto.producer.RNANumber }}</span>
  <span *ngIf="edit"><input class="form-control d-inline-block w-200px" type="text" [(ngModel)]="RNANumber" /></span>
</div>

<div class="mb-1">
  <span class="fw-500"> Au RCS de </span>
  &nbsp;:&nbsp;
  <span *ngIf="!edit">
    {{ data.dto.producer.RCS }}
  </span>
  <span *ngIf="edit">
    <input class="form-control d-inline-block w-200px" type="text" [(ngModel)]="RCS" />
  </span>
</div>

<div class="mb-1">
  <span class="fw-500"> SIRET </span>
  &nbsp;:&nbsp;
  <span *ngIf="!edit">
    {{ data.dto.producer.siret }}
  </span>
  <span *ngIf="edit">
    <input class="form-control d-inline-block w-200px" type="text" [(ngModel)]="siret" />
  </span>
</div>

<div>
  <span class="fw-500"> TVA Intracommunautaire </span>
  &nbsp;:&nbsp;
  <span>
    {{ getTVAIntracom(data.dto.producer.siret) }}
  </span>
</div>
