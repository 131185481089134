<div class="container">
  <div class="row">
    <div class="col-12 col-md-8 mb-4 mb-md-0">
      <div
        class="fileUploadContainer text-center pt-3"
        (drop)="drop($event)"
        (dragover)="dragover($event)"
        (dragexit)="dragexit($event)"
        (dragenter)="dragenter($event)"
        (click)="fileinput.click()"
      >
        <input
          type="file"
          name="articleModelFile"
          [accept]="allowedModelExtensionsService.getExtensions()"
          class="hiddeninput"
          #fileinput
          [multiple]="multi"
          (change)="fileUpload($event)"
        />
        <img src="assets/fileuploader-dragdrop-icon.png" class="mt-5 mb-4" />
        <h6 class="text-primary" *ngIf="!dragging">Glissez {{ multi ? 'votre ou vos fichiers' : 'votre fichier' }} 3D ici</h6>
        <h6 class="text-primary" *ngIf="dragging">Déposez {{ multi ? 'votre ou vos fichiers' : 'votre fichier' }} 3D ici</h6>
        <p class="mb-2 fw-500 text-secondary">ou</p>
        <button class="btn btn-primary">Parcourez vos fichiers</button>
      </div>
    </div>
    <div class="col-12 col-md-4 mb-4 mb-md-0 text-center">
      <div class="alert h-100 shadow-2">
        <h6>Fichiers&nbsp;3D chargés</h6>
        <div class="filelist">
          <div class="d-flex align-items-center mb-1 pl-2" *ngFor="let file of files">
            <div class="" style="flex-grow: 9">
              <degineo-progress-bar [progress]="file.progress" [color-degraded]="{ '0': '#06c', '99': '#00c462' }"> {{ file.name }}</degineo-progress-bar>
            </div>
            <div class="" style="flex-grow: 1">
              <i class="fa fa-times-circle text-danger pointer" [class.d-none]="file.progress < 100" (click)="deleteFile(file)"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center mt-0 mt-md-4">
    <div class="col-12">
      <div class="alert alert-info">
        <p class="mt-3">
          Vous pouvez envoyer n'importe quel type de fichier parmis les extensions suivantes :<br />
          <ng-container *ngFor="let ext of allowedModelExtensionsService.getExtensions(); let last = last">
            <span class="fw-700">{{ ext }}</span>
            <ng-container *ngIf="!last">, </ng-container>
          </ng-container>
        </p>

        <p>Taille maximale du fichier : <b>120 Mo</b></p>
        <p *ngIf="!multi">
          Si vous avez besoin d'envoyer des fichiers <span class="fw-700">.mtl</span>, vous pourrez le faire à l'étape suivante après avoir sélectionné
          "Impression multicolore".
        </p>
      </div>
    </div>
  </div>
  <div class="row mb-5 mt-4">
    <div class="col-12 col-md-6 mt-3 mt-sm-0 text-md-right order-2 order-md-1">
      <button type="button" class="btn btn-secondary btn-allonge w-100 w-sm-200px" (click)="previous.emit()">Annuler</button>
    </div>
    <div class="col-12 col-md-6 order-1 order-md-2">
      <button type="button" class="btn btn-success btn-allonge w-100 w-sm-200px" [disabled]="!nextAvailable" (click)="next.emit()">
        Suivant <i class="fas fa-chevron-right"></i>
      </button>
    </div>
  </div>
</div>
