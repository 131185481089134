import { Component, OnInit, Host, Output, EventEmitter, Input, Inject } from '@angular/core';
import { PaymentWorkflowComponent } from '../payment-workflow/payment-workflow.component';
import { PaymentDataService } from '../payment-data.service';
import { AuthService } from 'degineo-common';

@Component({
  selector: 'app-payment-step3',
  templateUrl: './payment-step3.component.html',
  styleUrls: ['./payment-step3.component.scss'],
})
export class PaymentStep3Component implements OnInit {
  @Input()
  paiement30J: boolean = false;
  @Output()
  close: EventEmitter<string> = new EventEmitter<string>();
  @Input()
  modeFacturePaiementA30j: boolean = false;
  @Input()
  paymentMethod: number = 0;

  PaymentDataService = PaymentDataService;
  success: boolean = true;
  hospital = false;

  constructor(@Inject(AuthService) private authService: AuthService, public paymentDataService: PaymentDataService) {}

  ngOnInit() {
    this.success = this.paymentDataService.step == PaymentDataService.STEP_CONFIRM;
  }

  continue() {
    this.close.emit('payment finished');
  }
}
