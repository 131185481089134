import { Component, OnInit, Inject } from '@angular/core';
import { OrderBy, RoleInCompany } from 'degineo-common';
import { Filter } from 'degineo-common';
import { FilterOrder, GenericFilter, ProducerUserBODTO, DegineoRight, User } from 'degineo-common';
import { L } from 'ic2-lib';
import { ProducerBOService } from 'degineo-common';
import { ProducerData } from '../../../providers/producer.provider';
import { SlugService } from 'degineo-common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-producer-user-list',
  templateUrl: './producer-user-list.component.html',
  styleUrls: ['./producer-user-list.component.scss'],
})
export class ProducerUserListComponent implements OnInit {
  ProducerUserListComponent: typeof ProducerUserListComponent = ProducerUserListComponent;

  newUser = false;

  users: ProducerUserBODTO[] = [];
  loading: boolean = true;
  count = 0;
  nbPerPage = 10;
  page = 1;
  producerUrl;

  OrderBy: typeof OrderBy = OrderBy;

  static filtersCache = {};
  filtersDefault = {};
  static OrderCaches = {};
  static page: number = 1;

  builtFilters: Filter[] = [];
  builtFiltersOrder: FilterOrder[] = [];

  rights: RoleInCompany[] = RoleInCompany.values;

  presenterName = (obj) => obj.name;
  presenterLib = (obj) => (obj === null ? 'null' : obj.lib);
  presenterUser = (obj) => obj.firstName + ' ' + obj.lastName.toUpperCase();

  constructor(
    public data: ProducerData,
    @Inject(ProducerBOService) public producerBOService: ProducerBOService,
    @Inject(SlugService) private slugService: SlugService,
    private router: Router
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.reload();
  }

  reload() {
    let genericFilter = new GenericFilter();
    genericFilter.filters = this.builtFilters;
    genericFilter.orderFilters = this.builtFiltersOrder;
    genericFilter.page = ProducerUserListComponent.page;
    genericFilter.nbPerPage = this.nbPerPage;
    console.log('genericFilter', genericFilter);
    this.loading = true;
    this.producerBOService.getUsersProducer(this.data.dto.producer.id, genericFilter).subscribe(
      (data) => {
        console.log('getUsersProducer', data);
        this.users = data.data;
        this.count = data.count;
        this.loading = false;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  newClicked() {
    this.newUser = true;
  }

  filterChanged(filters: Filter[]) {
    console.log('filterChanged event', filters);
    this.reload();
  }
  orderChange(order) {
    console.log('orderChange', order);
    this.reload();
  }
}
