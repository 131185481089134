<div class="container-fluid d-flex flex-column h-100" [class.justify-content-center]="orderService.printOff || orderService.devisOff">
  <ng-container *ngIf="orderService.printOff || orderService.devisOff">
    <div class="row">
      <div class="col p-3 text-center" *ngIf="orderService.infos">
        <img src="{{ rpcHost }}companylogo/{{ orderService.infos.logoToken }}/logo.png?size=large" alt="{{ orderService.infos.name }}" />
      </div>
    </div>
    <div class="row mt-5">
      <div class="col fs-4 text-center" *ngIf="orderService.printOff">
        Le système de commande en ligne est momentanément indisponible.<br />
        Veuillez réessayer ultérieurement.
      </div>
      <div class="col fs-4 text-center" *ngIf="orderService.devisOff">
        Le système de demande de devis est momentanément indisponible.<br />
        Veuillez réessayer ultérieurement.
      </div>
    </div>
    <div class="row mt-5">
      <div class="col text-center">
        <button class="btn btn-primary" (click)="back()">Retour</button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!orderService.printOff && !orderService.devisOff">
    <div class="row">
      <div class="col p-3 text-center" *ngIf="orderService.infos">
        <img src="{{ rpcHost }}companylogo/{{ orderService.infos.logoToken }}/logo.png?size=large" alt="{{ orderService.infos.name }}" />
      </div>
    </div>
    <div
      class="row bandeau"
      *ngIf="orderService.step !== os.STEP_LOGIN_REGISTER && orderService.step !== os.STEP_LOGIN && orderService.step !== os.STEP_REGISTER"
    >
      <div class="col text-center pt-4 pb-4">
        <h1>{{ orderService.modeDevis ? 'Demande de devis' : 'Imprimer un fichier 3D' }}</h1>
      </div>
    </div>
    <div class="row mt-3" *ngIf="orderService.error">
      <div class="col text-center">
        <div class="alert alert-danger">
          {{ orderService.error }}
        </div>
      </div>
    </div>
    <div class="row mt-5" *ngIf="orderService.step === os.STEP_CHOOSE_ARTICLE_TYPE">
      <div class="col text-center">
        <app-article-type-choice-step [articleTypes]="orderService.articleTypes" (select)="orderService.setArticleType($event)"></app-article-type-choice-step>
      </div>
    </div>

    <div class="row mt-5" *ngIf="orderService.step === os.STEP_UPLOAD_MODELS">
      <div class="col">
        <app-upload-step
          [files]="orderService.files"
          [multi]="orderService.dentaire"
          (fileDeleted)="fileDeleted($event)"
          (fileAdded)="fileAdded($event)"
          [nextAvailable]="orderService.canNextUploadStep()"
          (next)="orderService.next()"
          (previous)="orderService.previous()"
        ></app-upload-step>
      </div>
    </div>

    <div class="row mt-5" *ngIf="orderService.step === os.STEP_PRINT_SETTINGS && !orderService.dentaire">
      <div class="col">
        <app-print-settings
          [nextAvailable]="orderService.canNextPrintSettingStep()"
          [modelUrl]="orderService.modelUrl"
          (next)="orderService.next()"
          (previous)="orderService.previous()"
        ></app-print-settings>
      </div>
    </div>
    <div class="row mt-5" *ngIf="orderService.step === os.STEP_PRINT_SETTINGS && orderService.dentaire">
      <div class="col">
        <app-print-settings-dentaire
          [nextAvailable]="orderService.canNextPrintSettingStep()"
          [modelUrl]="orderService.modelUrl"
          (next)="orderService.next()"
          (previous)="orderService.previous()"
        ></app-print-settings-dentaire>
      </div>
    </div>

    <div class="row flex-grow-1" *ngIf="orderService.step === os.STEP_LOGIN_REGISTER && !orderService.redirecting">
      <app-login-register-step
        class="w-100 h-100"
        [preRegisterForm]="orderService.preRegisterForm"
        [registerEmailUnavailable]="orderService.registerEmailUnavailable"
        (login)="orderService.goLogin()"
        (continueRegistration)="orderService.next()"
        (previous)="orderService.previous()"
      ></app-login-register-step>
    </div>

    <div class="row flex-grow-1" *ngIf="orderService.step === os.STEP_REGISTER && !orderService.redirecting">
      <app-register-step
        class="w-100 h-100"
        [registerForm]="orderService.registerForm"
        (next)="orderService.next()"
        (previous)="orderService.previous()"
      ></app-register-step>
    </div>

    <div class="row flex-grow-1" *ngIf="orderService.step === os.STEP_LOGIN && !orderService.redirecting">
      <app-login-step
        class="w-100 h-100"
        [loginForm]="orderService.loginForm"
        [loginError]="orderService.loginError"
        (login)="orderService.login()"
        (register)="orderService.goRegister()"
        (previous)="orderService.previous()"
      ></app-login-step>
    </div>

    <!-- migrated to a new page inside degineo client template
  <div class="row mt-5" *ngIf="orderService.step === os.STEP_QUANTITY && !orderService.redirecting">
    <div class="col">
      <app-quantity-choice-step
        [dentaire]="orderService.dentaire"
        [quantity]="orderService.quantity"
        [modeDevis]="orderService.modeDevis"
        (quantityChanged)="orderService.quantityChanged($event)"
        [unitPriceHT]="orderService.price"
        [nextAvailable]="orderService.canNextQuantityStep()"
        [date]="orderService.wishedDeliveryDate"
        (dateChanged)="orderService.deliveryDateChanged($event)"
        (next)="orderService.next()"
        (previous)="orderService.previous()"
      >
      </app-quantity-choice-step>
    </div>
  </div>
  -->

    <div class="row mt-5" *ngIf="orderService.redirecting">
      <div class="col text-center fw-500 fs-5">
        <!--<div class="mt-4">
        <i
          class="fa fa-spinner spinning"
          [class.text-primary]="!orderService.modeDevis"
          [class.text-success]="orderService.modeDevis"
          style="font-size:3rem;"
        ></i>
      </div>-->
        <p class="mt-4 text-success" *ngIf="orderService.modeDevis"><i class="fas fa-check"></i> Votre demande de devis a bien été envoyée.</p>
        <p class="mt-4" *ngIf="!orderService.modeDevis">
          Redirection vers votre espace Degineo en cours.<br />
          Vous y retrouverez votre panier afin de finaliser votre commande.
        </p>
      </div>
    </div>
  </ng-container>
</div>
