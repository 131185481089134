import { Component, OnInit, Input, Inject, OnDestroy } from '@angular/core';
import { PrinterData } from '../../providers/printer.provider';
import { ConsumableOptionWithConsumableDTO, PrinterAssociatedConsumableOptionBODTO, BrandConsumableService } from 'degineo-common';
import { L } from 'ic2-lib';
import { ConsumablePrinterService } from 'degineo-common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-printer-consumables',
  templateUrl: './printer-consumables.component.html',
  styleUrls: ['./printer-consumables.component.scss'],
})
export class PrinterConsumablesComponent implements OnInit, OnDestroy {
  @Input()
  edit: boolean;

  @Input()
  new: boolean;

  brandName: string = null;

  consumableName: string;
  idConsumableOption: number = 0;

  consumableList: ConsumableOptionWithConsumableDTO[] = null;
  consumableBrandList: string[];
  err: string = '';
  sub: Subscription;

  presenterName = (obj) => obj;

  constructor(
    public data: PrinterData,
    @Inject(ConsumablePrinterService) private consumablePrinterService: ConsumablePrinterService,
    @Inject(BrandConsumableService) private brandConsumableService: BrandConsumableService
  ) {}

  ngOnInit() {
    this.reload();
    this.sub = this.data.reloadConsumableBrands.subscribe((next) => {
      this.reload();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  reload() {
    this.idConsumableOption = 0;
    this.brandName = null;
    this.consumableName = null;
    if (this.data.dto.printer.printerTechnology !== null)
      this.brandConsumableService.getAllConsumableBrandByPrinterTechnology(this.data.dto.printer.printerTechnology).subscribe(
        (data) => {
          this.consumableBrandList = data;
        },
        (error) => {
          L.e(error);
        }
      );
  }

  presenterConsumable(c: ConsumableOptionWithConsumableDTO) {
    let res = c.consumable.name + ' ' + c.colorName;
    if (c.co.capacity !== null && c.co.unit !== null) res += ' ' + c.co.capacity + ' ' + c.co.unit;
    return res;
  }

  brandTextChanged(text) {}
  brandChanged(brand) {
    console.log('brandChanged', brand);
    if (brand != null) {
      this.brandName = brand;
      this.err = '';
      this.consumablePrinterService.getAllConsumableOfBrand(brand, this.data.dto.printer.printerTechnology).subscribe(
        (data) => {
          this.consumableList = data;
        },
        (error) => {
          L.e(error);
        }
      );
    } else {
      this.err = '';
      this.brandName = null;
      this.consumableList = [];
      this.idConsumableOption = 0;
      this.consumableName = null;
    }
  }

  consumableTextChanged(text) {}
  consumableChanged(consumableOption: ConsumableOptionWithConsumableDTO) {
    if (consumableOption != null) {
      if (this.data.dto.assocConsumable.findIndex((x) => x.idConsumableOption == consumableOption.co.id) == -1) {
        this.consumableName = this.presenterConsumable(consumableOption);
        this.idConsumableOption = consumableOption.co.id;
        this.err = '';
      } else {
        this.err = 'Consommable déjà associé';
      }
    } else {
      this.consumableName = null;
      this.idConsumableOption = 0;
      this.err = '';
    }
  }

  add() {
    console.log('Marque : ', this.brandName, ' ConsumableOption : ', this.idConsumableOption);
    let pa: PrinterAssociatedConsumableOptionBODTO = new PrinterAssociatedConsumableOptionBODTO();
    pa.idConsumableOption = this.idConsumableOption;
    pa.consumableName = this.consumableName;
    pa.brand = this.brandName;
    this.data.dto.assocConsumable.push(pa);
    this.idConsumableOption = 0;
    this.consumableName = null;
  }
}
