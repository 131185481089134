import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { PrintService, ColorNEW, SizeDTO, PriceService, MaterialTypeNEW, PreviewModeleComponent, ClientMarqueBlancheService, Finish } from 'degineo-common';
import { Router, ActivatedRoute } from '@angular/router';
import { PrintDataService } from '../print-data.service';
import { L } from 'ic2-lib';
import { environment } from '../../../../../../../../environments/environment';
import { OrderService } from '../../../MarqueBlanche/order.service';
import { UploadedFile } from '../../../MarqueBlanche/UploadedFile';

@Component({
  selector: 'app-print-step3',
  templateUrl: './print-step3.component.html',
  styleUrls: ['./print-step3.component.scss'],
})
export class PrintStep3Component implements OnInit, AfterViewInit {
  error: string;
  fromSV: boolean = false;
  @ViewChild(PreviewModeleComponent)
  preview: PreviewModeleComponent;
  loading: boolean = true;
  constructor(
    @Inject(PrintDataService) public printDataService: PrintDataService,
    @Inject(PrintService) public printService: PrintService,
    @Inject(ClientMarqueBlancheService) public clientMarqueBlancheService: ClientMarqueBlancheService,
    @Inject(PriceService) public priceService: PriceService,
    @Inject(Router) public router: Router,
    @Inject(ActivatedRoute) public route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if (params['token'] === undefined) {
        this.loading = false;
        return;
      }
      this.clientMarqueBlancheService.getSVPrint(params['token']).subscribe(
        (data) => {
          this.printDataService.idProducer = data.producer.idProducer;
          let uf = new UploadedFile();
          uf.fileHash = data.token;
          this.printService.getFileName(data.token).subscribe(
            (data) => {
              uf.name = data;
              this.printDataService.name = data.split('.').slice(0, -1).join('.');
            },
            (err) => {
              L.e(err);
            }
          );
          this.printService.getFileDimensions(data.token).subscribe(
            (data) => {
              uf.modelData = data;
              uf.good = true;
              uf.progress = 100;
              //call this because we async loaded the file
              this.ngAfterViewInit();
            },
            (err) => {
              L.e(err);
            }
          );
          this.printDataService.files.push(uf);
          this.printDataService.name = '';
          this.printDataService.comment = '';
          this.printDataService.selectedColor = data.color;
          this.printDataService.articleTypeSelected = data.article;
          this.printDataService.selectedMaterial = data.material;
          this.printDataService.producerSelected = data.producer;
          this.printDataService.selectedFinish = data.finish;
          this.printDataService.fromSV = true;
          this.sizeChanged(data.sizeInMm);
        },
        (err) => {
          L.e(err);
        }
      );
    });
  }

  ngAfterViewInit() {
    if (this.printDataService.files.length > 0) {
      let uf = this.printDataService.files[0];
      let url = environment.rpcHost + 'tempTunnelModel/' + uf.fileHash + '/' + uf.name;
      console.log(url);
      this.preview.load3DModel2(url, uf.modelData);
    }
  }

  sizeChanged(size: SizeDTO) {
    console.log('size changed', size);
    this.printDataService.size = size;
    this.printDataService.tempMaterial = this.printDataService.selectedMaterial;
    this.printDataService.selectedMaterial = null;
    this.printDataService.tempColor = this.printDataService.selectedColor;
    this.printDataService.selectedColor = null;
    this.printDataService.tempFinish = this.printDataService.selectedFinish;
    this.printDataService.selectedFinish = null;
    this.printDataService.availableMaterials = null;
    this.printDataService.availableColors = null;
    this.printDataService.availableFinish = null;
    if (this.printDataService.size.x === null || this.printDataService.size.y === null || this.printDataService.size.z === null) {
      //to avoid safari error when input has text in it
      console.error('there is letters in number inputs');
      return;
    }
    this.printService
      .getPossibleMaterials(this.printDataService.idProducer, null, this.printDataService.articleTypeSelected.id, this.printDataService.size)
      .subscribe(
        (data) => {
          this.printDataService.availableMaterials = data;
          if (this.printDataService.tempMaterial !== null) {
            let material = this.printDataService.availableMaterials.find((mt) => mt.id === this.printDataService.tempMaterial.id);
            this.printDataService.tempMaterial = null;
            if (material !== undefined) {
              this.materialChanged(material);
            }
          }
        },
        (err) => {
          L.e(err);
          this.error = err.message;
        }
      );
  }

  mtlFileAdded(file: File) {
    console.log('mtl file added', file);
    console.error('TODO !');
    /*
    let fileHash = 'test';
    if (this.printDataService.files.length > 0) fileHash = this.printDataService.files[0].fileHash;
    this.printService
      .uploadMtlFile(fileHash)
      .withFile(file)
      .subscribeWithProgress(
        (data) => {
          console.log('mtl uploaded');
        },
        (loaded, total) => {
          //uf.progress = ~~(loaded / total * 100);
        },
        (err) => {
          L.e(err);
          this.error = err.message;
        }
      );*/
  }

  nameChanged(name: string) {
    this.printDataService.name = name;
  }

  commentChanged(comment: string) {
    this.printDataService.comment = comment;
  }

  materialChanged(material: MaterialTypeNEW) {
    //console.log('material changed', material);
    this.printDataService.selectedMaterial = material;
    this.printDataService.parentMaterial = this.printDataService.availableMaterials.find((mt) => mt.id === this.printDataService.selectedMaterial.idParent);
    if (this.printDataService.tempColor === null)
      //car peut déjà être set via sizeChanged
      this.printDataService.tempColor = this.printDataService.selectedColor;
    this.printDataService.selectedColor = null;
    this.printDataService.availableColors = null;
    this.printDataService.availableFinish = null;
    this.printService
      .getPossibleColors(
        this.printDataService.idProducer,
        null,
        this.printDataService.articleTypeSelected.id,
        this.printDataService.selectedMaterial.id,
        this.printDataService.size
      )
      .subscribe(
        (data) => {
          this.printDataService.availableColors = data;

          if (this.printDataService.tempColor !== null) {
            let color = null;
            if (this.printDataService.tempColor instanceof String) {
              if (this.printDataService.availableColors.canPrintInMulticolor) color = OrderService.MULTICOLOR_STR;
            } else {
              color = this.printDataService.availableColors.colors.find((c) => c.id === (<ColorNEW>this.printDataService.tempColor).id);
            }
            this.printDataService.tempColor = null;
            if (color !== null && color !== undefined) this.colorChanged(color);
          }
        },
        (err) => {
          L.e(err);
          this.error = err.message;
        }
      );
    if (this.printDataService.tempColor === null) this.loadCharacs();
  }

  colorChanged(color: ColorNEW | string) {
    //string can be 'multicolor'
    //console.log('color changed', color);
    this.printDataService.selectedColor = color;
    if (this.preview !== undefined) {
      //can be used while redirecting directly to step 4
      if (color === OrderService.MULTICOLOR_STR) {
        this.preview.setMultiColor();
      } else this.preview.setColor('#' + (<ColorNEW>color).hexCode);
    }
    let idColor = 0;
    if (this.printDataService.selectedColor !== OrderService.MULTICOLOR_STR && this.printDataService.selectedColor !== null) {
      idColor = (<ColorNEW>this.printDataService.selectedColor).id;
    }

    this.printDataService.selectedFinish = null;
    this.printDataService.availableFinish = null;
    this.printService
      .getPossibleFinish(
        this.printDataService.idProducer,
        null,
        this.printDataService.articleTypeSelected.id,
        this.printDataService.selectedMaterial.id,
        idColor,
        this.printDataService.size
      )
      .subscribe(
        (data) => {
          this.printDataService.availableFinish = data.sort((a, b) => a.name.localeCompare(b.name));
          if (this.printDataService.tempFinish !== null) {
            const finish = this.printDataService.availableFinish.find((f) => f.id === this.printDataService.tempFinish.id);
            this.printDataService.tempFinish = null;
            if (finish !== undefined) this.finishChanged(finish);
          }
        },
        (err) => {
          L.e(err);
        }
      );

    if (this.printDataService.tempFinish === null) this.loadCharacs();
  }

  finishChanged(finish: Finish) {
    console.log('finishChanged', finish);
    this.printDataService.selectedFinish = finish;
    this.loadCharacs();
  }

  isMulticolor() {
    return this.printDataService.selectedColor === OrderService.MULTICOLOR_STR;
  }

  loadCharacs() {
    let idColor = 0;
    if (this.printDataService.selectedColor !== null && this.printDataService.selectedColor instanceof ColorNEW)
      idColor = this.printDataService.selectedColor.id;

    let idFinish = 0;
    if (this.printDataService.selectedFinish !== null) idFinish = this.printDataService.selectedFinish.id;

    this.printService
      .getCharacteristics(
        this.printDataService.idProducer,
        null,
        this.printDataService.articleTypeSelected.id,
        this.printDataService.selectedMaterial.id,
        idColor,
        idFinish,
        this.printDataService.size
      )
      .subscribe(
        (data) => {
          this.printDataService.characs = data;
          if (this.printDataService.fromSV) this.loading = false;
        },
        (err) => {
          L.e(err);
          this.error = err.message;
        }
      );
  }

  canNextPrintSettingStep(): boolean {
    if (this.printDataService.dentaire) {
      return this.printDataService.name !== null && this.printDataService.name.length > 2;
    }
    /*console.log(
      'next print settings',
      this.size !== null,
      this.selectedColor !== null,
      this.selectedMaterial !== null,
      this.name !== null && this.name.length > 2
    );*/
    return (
      this.printDataService.size !== null &&
      this.printDataService.selectedColor !== null &&
      this.printDataService.selectedMaterial !== null &&
      this.printDataService.name !== null &&
      this.printDataService.name.length > 2
    );
  }

  next() {
    let idColor = 0;
    let multicolor = false;
    if (this.printDataService.selectedColor === OrderService.MULTICOLOR_STR) {
      multicolor = true;
    } else if (this.printDataService.selectedColor !== null) {
      idColor = (<ColorNEW>this.printDataService.selectedColor).id;
    }
    let idMaterial = 0;
    if (this.printDataService.selectedMaterial !== null) {
      idMaterial = this.printDataService.selectedMaterial.id;
    }
    let idFinish = 0;
    if (this.printDataService.selectedFinish !== null) idFinish = this.printDataService.selectedFinish.id;

    this.priceService
      .getPriceClient(
        this.printDataService.idProducer,
        this.printDataService.articleTypeSelected.id,
        idMaterial,
        idColor,
        multicolor,
        idFinish,
        this.printDataService.size
      )
      .subscribe(
        (data) => {
          this.printDataService.price = data;
          this.printService.getNextAvailableDeliveryDate(this.printDataService.idProducer).subscribe(
            (data) => {
              this.printDataService.wishedDeliveryDate = data.wishedDate;
              this.printDataService.step = PrintDataService.STEP_QUANTITY;
              this.router.navigate(['/impression', 'etape-4']);
            },
            (error) => {
              L.e(error);
              this.error = error.message;
            }
          );
        },
        (error) => {
          L.e(error);
          this.error = error.message;
        }
      );
  }

  previous() {
    this.printDataService.characs = [];
    this.printDataService.size = null;
    this.printDataService.availableMaterials = null;
    this.printDataService.selectedMaterial = null;
    this.printDataService.parentMaterial = null;
    this.printDataService.tempMaterial = null;
    this.printDataService.availableColors = null;
    this.printDataService.selectedColor = null;
    this.printDataService.tempColor = null;
    this.printDataService.availableFinish = null;
    this.printDataService.selectedFinish = null;
    this.printDataService.tempFinish = null;
    this.printDataService.defaultSizeDTO = null;
    this.printDataService.comment = undefined;
    this.printDataService.step = PrintDataService.STEP_UPLOAD_MODELS;
    this.router.navigate(['/impression', 'etape-2']);
  }
}
