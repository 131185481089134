import { Injectable } from '@angular/core';
import { BasketArticleClientDTO, AddressNEW } from 'degineo-common';

@Injectable({
  providedIn: 'root',
})
export class PaymentDataService {
  selectedArticles: BasketArticleClientDTO[] = [];
  totalPriceTTC: number;
  shippingAddress: AddressNEW;
  billingAddress: AddressNEW;

  canDismiss: boolean = false;

  step: number = PaymentDataService.STEP_ADDRESS;
  paymentMethod: number = PaymentDataService.PAYMENT_STRIPE;
  delayed: boolean = false;

  public static readonly STEP_ADDRESS = 1;
  public static readonly STEP_PAYMENT = 2;
  public static readonly STEP_CONFIRM = 3;
  public static readonly STEP_ERROR = 4;

  public static readonly PAYMENT_STRIPE = 1;
  public static readonly PAYMENT_PAYPAL = 2;
  public static readonly PAYMENT_WIRETRANSFERT = 3;

  constructor() {}

  reset() {
    this.selectedArticles = [];
    this.totalPriceTTC = null;
    this.canDismiss = false;
    this.shippingAddress = null;
    this.billingAddress = null;
    this.step = PaymentDataService.STEP_ADDRESS;
    this.paymentMethod = PaymentDataService.PAYMENT_STRIPE;
    this.delayed = false;
  }
}
