import { Component, OnInit, Input } from '@angular/core';
import { BusinessTarget, BusinessTargetLevel2, BusinessTargets, PrinterBusinessTarget } from 'degineo-common';
import { PrinterData } from '../../providers/printer.provider';

@Component({
  selector: 'app-printer-metier',
  templateUrl: './printer-metier.component.html',
  styleUrls: ['./printer-metier.component.scss'],
})
export class PrinterMetierComponent implements OnInit {
  @Input()
  edit: boolean;
  targets: BusinessTarget[] = BusinessTargets;
  opened: BusinessTarget[] = [];

  constructor(public data: PrinterData) {}

  ngOnInit() {}

  changeParent(target: BusinessTarget) {
    if (this.hasAll(target)) {
      //deselect every child
      for (let t of target.children) {
        this.changeChild(t);
      }
    } else {
      //select any missing child
      for (let t of target.children) {
        if (!this.has(t)) this.changeChild(t);
      }
    }
  }

  changeChild(target: BusinessTargetLevel2) {
    if (this.has(target))
      this.data.dto.businessTargets.splice(
        this.data.dto.businessTargets.findIndex((b) => b.target.id === target.id),
        1
      );
    else {
      const pbt = new PrinterBusinessTarget();
      pbt.target = target;
      this.data.dto.businessTargets.push(pbt);
    }
  }

  toggle(code: BusinessTarget) {
    if (this.isOpen(code)) this.opened.splice(this.opened.indexOf(this.opened.find((c) => c.level1.id === code.level1.id)), 1);
    else this.opened.push(code);
  }

  isOpen(code: BusinessTarget) {
    return this.opened.find((c) => c.level1.id === code.level1.id) !== undefined;
  }

  hasAny(target: BusinessTarget) {
    if (this.data.dto.businessTargets === null) return false;
    return this.data.dto.businessTargets.find((bt) => target.children.find((b) => b.id === bt.target.id) !== undefined) !== undefined;
  }
  hasAll(target: BusinessTarget) {
    if (this.data.dto.businessTargets === null) return false;
    for (const child of target.children) {
      if (this.data.dto.businessTargets.find((bt) => bt.target.id === child.id) === undefined) return false;
    }
    return true;
  }
  has(target: BusinessTargetLevel2) {
    if (this.data.dto.businessTargets === null) return false;
    return this.data.dto.businessTargets.find((bt) => bt.target.id === target.id) !== undefined;
  }
}
