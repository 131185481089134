<div class="text-center fw-500">Adresse de facturation</div>
<div class="row mt-4">
  <div class="col" *ngIf="data.dto.billingAddress">
    {{ data.dto.billingAddress.line1 }}
    <ng-container *ngIf="data.dto.billingAddress.line2"> <br />{{ data.dto.billingAddress.line2 }} </ng-container>
    <ng-container *ngIf="data.dto.billingAddress.line3"> <br />{{ data.dto.billingAddress.line3 }} </ng-container>
  </div>
</div>
<div class="row">
  <div class="col" *ngIf="data.dto.billingAddress">{{ data.dto.billingAddress.zipCode }} {{ data.dto.billingAddress.city }}</div>
</div>
<div class="row">
  <div class="col" *ngIf="data.dto.billingAddress">
    {{ data.dto.billingAddress.country | uppercase }}
  </div>
</div>
