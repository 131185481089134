<div class="container pt-4 pb-4">
  <h1 class="mx-auto text-center mb-5">Caractéristiques matières</h1>

  <button class="btn btn-success fw-500" type="button" (click)="newClicked()">
    <i class="fas fa-plus  mr-1"></i>
    Ajouter une nouvelle matière
  </button>

  <div class="mt-4">
    <app-material-type-caracteristic-new *ngIf="new" class="d-block mt-3" (cancel)="new = false" (reload)="reload()"> </app-material-type-caracteristic-new>
    <app-material-type-characteristics-info *ngFor="let item of data.dto" class="d-block mt-3" (reload)="reload()" (update)="update()" [item]="item">
    </app-material-type-characteristics-info>
  </div>
</div>
