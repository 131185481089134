<div class="container-fluid">
  <div class="row">
    <div class="col">
      <h6 class="text-center">Commentaires</h6>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <textarea
        rows="5"
        class="form-control w-100"
        [(ngModel)]="comment"
        (ngModelChange)="commentChanged.emit($event)"
        placeholder="Si vous avez des commentaires concernant votre impression 3D, vous pouvez les faire ici."
        maxlength="500"
        #c="ngModel"
      ></textarea>
    </div>
  </div>
  <div class="row mt-1" *ngIf="c.invalid && (c.dirty || c.touched)">
    <div class="col text-danger">
      <div *ngIf="c.getError('maxlength')">Le commentaire ne doit pas dépasser 500 caractères.</div>
    </div>
  </div>
</div>
