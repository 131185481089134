import { Component, OnInit } from '@angular/core';
import { BusinessTarget, BusinessTargetLevel2, BusinessTargets, ClientBOService } from 'degineo-common';
import { L } from 'ic2-lib';
import { ClientData } from '../../providers/client.provider';

@Component({
  selector: 'app-client-info-left',
  templateUrl: './client-info-left.component.html',
  styleUrls: ['./client-info-left.component.scss'],
})
export class ClientInfoLeftComponent implements OnInit {
  targets: BusinessTarget[] = BusinessTargets;
  editTarget: boolean = false;
  loadingTarget: boolean = false;
  BusinessTargetLevel2: typeof BusinessTargetLevel2 = BusinessTargetLevel2;

  constructor(public data: ClientData, public clientBOService: ClientBOService) {}

  ngOnInit() {}

  getLevel1(targetLevel2: BusinessTargetLevel2) {
    if (targetLevel2 === null) return null;
    for (const target of this.targets) {
      if (target.children.some((child) => child.id === targetLevel2.id)) return target.level1;
    }
    return null;
  }

  saveTarget() {
    this.loadingTarget = true;
    this.clientBOService.updateClientBusinessTarget(this.data.dto.id, this.data.dto.target).subscribe(
      (data) => {
        this.editTarget = false;
        this.loadingTarget = false;
      },
      (err) => {
        this.loadingTarget = false;
        L.e(err);
      }
    );
  }
}
