import { Component, OnInit, Inject } from '@angular/core';
import { environment } from 'environments/environment';
import { MyPrintsService, GenericFilter, ClientOrderDTO } from 'degineo-common';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-my-prints',
  templateUrl: './my-prints.component.html',
  styleUrls: ['./my-prints.component.scss'],
})
export class MyPrintsComponent implements OnInit {
  rpcHost: string = environment.rpcHost;
  loading: boolean = false;
  page: number = 1;
  count: number = 1;
  nbPerPage: number = 5;
  finishedOrders: ClientOrderDTO[] = [];
  printingOrders: ClientOrderDTO[] = [];

  constructor(@Inject(MyPrintsService) public myPrintsService: MyPrintsService) {}

  ngOnInit() {
    this.loading = true;
    this.myPrintsService.getPrintingOrders().subscribe(
      (data) => {
        this.printingOrders = data;
        //this.printingOrders.push(this.printingOrders[0])
      },
      (error) => {
        L.e(error);
      }
    );
    this.reload();
  }

  reload() {
    this.loading = true;
    let filter = new GenericFilter();
    filter.page = this.page;
    filter.nbPerPage = this.nbPerPage;
    this.myPrintsService.getFinishedOrders(filter).subscribe(
      (data) => {
        this.finishedOrders = data.data;
        this.count = data.count;
        this.loading = false;
        console.log(this);
      },
      (error) => {
        L.e(error);
      }
    );
  }
}
