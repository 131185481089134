<div class="container pt-4 pb-4">
  <h1 class="mx-auto text-center mb-5">Types d'articles</h1>

  <div class="row">
    <div class="col">
      <button class="btn btn-success fw-500" type="button" (click)="newClicked()">
        <i class="fas fa-plus mr-1"></i>
        Ajouter un nouveau type d'article
      </button>
    </div>
  </div>
  <degineo-filter
    class="d-inline-block w-100 mt-4"
    #fliter
    [builtFilters]="builtFilters"
    (filterChanged)="filterChanged($event)"
    [cache]="ArticleTypeViewEditComponent.filtersCache"
    [default]="filtersDefault"
  >
    <div class="form-inline">
      <degineo-checkbox-group-filter #f class="form-inline float-left" filterName="checkBox">
        <degineo-round-checkbox-filter #cb class="mr-4" name="Activé" filterName="activated" color="success"> </degineo-round-checkbox-filter>
        <degineo-round-checkbox-filter #cb class="mr-4" name="Non activé" filterName="unactivated" color="danger"> </degineo-round-checkbox-filter>
      </degineo-checkbox-group-filter>
    </div>
  </degineo-filter>
  <div class="mt-4 row">
    <app-article-type-new *ngIf="new" class="d-block col-12 mb-3 mt-1" (cancel)="new = false" (reload)="reload()"> </app-article-type-new>
    <ng-container *ngFor="let item of data.dto; let index = index">
      <app-article-type-info *ngIf="display(item.at.active)" class="mt-1 mb-3 col-md-4 d-inline-block align-top" (reload)="reload()" [articleTypeDTO]="item">
      </app-article-type-info>
    </ng-container>
  </div>
</div>
