<div class="container pt-4" *ngIf="!loading">
  <h1 class="mx-auto text-center mb-5">{{ data.dto.order.id }}</h1>

  <div class="row">
    <div class="col-md-6 col-12">
      <div class="fw-500">
        Type de paiement&nbsp;: {{ data.dto.order.paymentType.lib }}
        <span *ngIf="data.dto.order.delayed" class="text-primary ml-1">(Virement en fin de mois)</span>
      </div>
      <div class="fw-500 row mt-1">
        <div class="col-auto align-self-center">Prix de la commande&nbsp;: {{ data.dto.priceTTC.toFixed(2) | number: '.2':'fr' }}&nbsp;€ TTC</div>
        <ng-container *ngIf="!data.dto.order.delayed">
          (todo: loader)
          <div class="col-auto pr-1">
            <div
              class="pointer d-inline-block text-secondary"
              style="font-size: 25px"
              [ngClass]="{ 'text-success': data.dto.order.paid }"
              (click)="payOrderClicked()"
            >
              <i class="fas fa-check-circle"></i>
            </div>
          </div>
          <div class="col-auto pl-1">
            <div
              class="pointer d-inline-block text-secondary"
              style="font-size: 25px"
              [ngClass]="{ 'text-danger': !data.dto.order.paid }"
              (click)="unPayOrderClicked()"
              ngbTooltip="Coming soon"
            >
              <i class="fas fa-times-circle"></i>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="row mt-1">
        <div class="col-auto text-secondary">#ID&nbsp;: {{ data.dto.order.id }}</div>
      </div>
      <div class="fw-500 mt-3 text-warning" *ngIf="!data.dto.order.checked">fichier 3D en cours de validation</div>
      <div class="fw-500 mt-3 text-success" *ngIf="data.dto.order.checked">Fichier 3D validé</div>
    </div>
    <div class="col-md-6 col-12 text-md-right mt-3 mt-md-0">
      <div class="d-inline-block" placement="top" ngbTooltip="Coming soon">
        <button disabled class="btn btn-primary-outline text-danger p-0" style="letter-spacing: normal" (click)="removeClicked()">
          <i class="far fa-times-circle mr-1"></i> Supprimer cette commande
        </button>
      </div>
      <div class="mt-2">Commandé le&nbsp;: {{ data.dto.order.dateCreated | date: 'dd/MM/yyyy' }}</div>
      <div class="mt-1">Date limite de livraison&nbsp;: {{ data.dto.order.wishedDeliveryDate | date: 'dd/MM/yyyy' }}</div>
      <div class="mt-1">Livré par&nbsp;: {{ data.dto.producerName }}</div>
      <div class="mt-1">
        <a class="btn btn-primary" style="min-width: 225px" [routerLink]="'/invoices/search/' + data.dto.order.id"> Voir facture </a>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-4 mt-4">
      <app-order-view-client class="h-100 pt-4 pl-4 pr-4 d-block alert border-secondary border"></app-order-view-client>
    </div>
    <div class="col-12 col-md-4 mt-4">
      <app-order-view-billing-address class="h-100 pt-4 pl-4 pr-4 d-block alert border-secondary border"> </app-order-view-billing-address>
    </div>
    <div class="col-12 col-md-4 mt-4">
      <app-order-view-shipping-address class="h-100 pt-4 pl-4 pr-4 d-block alert border-secondary border"> </app-order-view-shipping-address>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mt-4">
      <app-order-view-note></app-order-view-note>
    </div>
  </div>
</div>
<div class="container-fluid pb-4 mt-4" *ngIf="!loading">
  <div>Nom du producteur : TODO</div>
  <div>Choix : TODO (client / producteur)</div>
  <div>Prix ttc : TODO {{ 0 | number: '.2':'fr-FR' }}&nbsp;€</div>
  <div>
    Fichier 3D Client : <a href="{{ rpcHost }}orderModele3D/{{ data.dto.clientFileToken }}/{{ data.dto.clientFileName }}"> {{ data.dto.clientFileName }} </a>
  </div>
  <div>
    Fichier 3D Degineo :
    <a href="{{ rpcHost }}orderModele3D/{{ data.dto.degineoFileToken }}/{{ data.dto.degineoFileName }}"> {{ data.dto.degineoFileName }} </a>
    <input type="file" [accept]="allowedModelExtensionsService.getExtensions()" id="file" style="display: none" #fileInput (change)="handleFileInput($event)" />
    <button class="btn p-0 text-primary" style="font-size: 0.9rem; letter-spacing: normal" (click)="fileInput.click()">
      <i class="fas fa-upload text-primary pointer" style="font-size: 18px"></i> Envoyer un fichier corrigé
    </button>
    <div class="progress" *ngIf="progressPercent">
      <div class="progress-bar" role="progressbar" [style.width]="progressPercent + '%'">{{ progressPercent }}%</div>
    </div>
    <span class="text-danger d-block" *ngIf="errorFile">{{ errorFile }}</span>
  </div>
  <div>
    Fichier 3D Producteur :
    <a href="{{ rpcHost }}orderModele3D/{{ data.dto.producerFileToken }}/{{ data.dto.producerFileName }}"> {{ data.dto.producerFileName }} </a>
  </div>

  <div>Validation fichier</div>
  <div
    class="pointer d-inline-block text-secondary m-1"
    style="font-size: 25px"
    [ngClass]="{ 'text-success': data.dto.order.checked }"
    (click)="checkClicked()"
  >
    <i class="fas fa-check-circle"></i>
  </div>
  <div
    class="pointer d-inline-block text-secondary m-1"
    style="font-size: 25px"
    [ngClass]="{ 'text-danger': !data.dto.order.checked }"
    (click)="unCheckClicked()"
  >
    <i class="fas fa-times-circle"></i>
  </div>

  <div>Statut {{ data.dto.order.status.lib }}</div>

  <div>Dernier changement de statut : {{ moment(data.dto.order.dateLastStatusChange).fromNow() }}</div>
</div>
