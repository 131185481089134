import { Component, OnInit, Inject, Input } from '@angular/core';
import { OrderService } from '../../../order.service';
import { CharacteristicsDisplayDTO, MaterialTypeNEW } from 'degineo-common';

@Component({
  selector: 'app-print-settings-characteristics',
  templateUrl: './print-settings-characteristics.component.html',
  styleUrls: ['./print-settings-characteristics.component.scss'],
})
export class PrintSettingsCharacteristicsComponent implements OnInit {
  @Input()
  characs: CharacteristicsDisplayDTO[] = [];
  @Input()
  selectedMaterial: MaterialTypeNEW = null;
  @Input()
  parentMaterial: MaterialTypeNEW = null;

  constructor() {}

  ngOnInit() {}
}
