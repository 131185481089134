<div class="container-fluid">
  <div class="row" *ngIf="printDataService.idProducer !== 0">
    <div class="col text-center">
      <img
        src="{{ rpcHost }}companylogo/{{ printDataService.producerSelected.logoToken }}/logo.png?size=medium"
        alt="{{ printDataService.producerSelected.name }}"
      />
    </div>
  </div>
  <div class="row" [class.mt-5]="printDataService.idProducer !== 0">
    <div class="col">
      <app-quantity-choice-step
        [dentaire]="printDataService.dentaire"
        [quantity]="printDataService.quantity"
        [modeDevis]="false"
        (quantityChanged)="quantityChanged($event)"
        [unitPriceHT]="printDataService.price"
        [nextAvailable]="canNextQuantityStep()"
        [date]="printDataService.wishedDeliveryDate"
        (dateChanged)="deliveryDateChanged($event)"
        [loading]="loading"
        (next)="next()"
        (previous)="previous()"
      >
      </app-quantity-choice-step>
    </div>
  </div>
  <div class="row" *ngIf="error">
    <div class="col">
      <div class="alert alert-danger">
        <ul>
          <li>Une erreur est survenue : {{ error }}</li>
        </ul>
      </div>
    </div>
  </div>
</div>
