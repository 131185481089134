import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SizeDTO } from 'degineo-common';
import { PrinterSizeDTO } from 'degineo-common';
import { OrderService } from '../../../order.service';

@Component({
  selector: 'app-print-settings-size',
  templateUrl: './print-settings-size.component.html',
  styleUrls: ['./print-settings-size.component.scss'],
})
export class PrintSettingsSizeComponent implements OnInit, OnChanges {
  sizeX: number = 0;
  sizeY: number = 0;
  sizeZ: number = 0;
  unit: string;
  @Output()
  sizeChanged: EventEmitter<SizeDTO> = new EventEmitter<SizeDTO>();
  @Input()
  defaultSizeDTO: SizeDTO = null;
  @Input()
  alreadySelectedSizeDTO: SizeDTO = null;
  @Input()
  printerSizes: PrinterSizeDTO[];
  restoreToDefault: boolean = false;
  showPrinter: string = null;
  constructor() {}

  ngOnInit() {}

  //afficher celle avec le plus gros volume

  ngOnChanges(changes: SimpleChanges) {
    console.log('ON CHANGES p-s-z', changes, this.defaultSizeDTO);
    if (
      changes.alreadySelectedSizeDTO &&
      changes.alreadySelectedSizeDTO.firstChange &&
      changes.alreadySelectedSizeDTO.currentValue !== undefined &&
      changes.alreadySelectedSizeDTO.currentValue !== null
    ) {
      this.sizeX = this.alreadySelectedSizeDTO.x;
      this.sizeY = this.alreadySelectedSizeDTO.y;
      this.sizeZ = this.alreadySelectedSizeDTO.z;
      this.unit = 'mm';
    } else if (changes.defaultSizeDTO && changes.defaultSizeDTO.firstChange) {
      this.setToDefaultSize(true);
    }
  }

  setToDefaultSize(fireEvent: boolean) {
    this.sizeX = this.defaultSizeDTO.x;
    this.sizeY = this.defaultSizeDTO.y;
    this.sizeZ = this.defaultSizeDTO.z;
    this.unit = 'mm';
    this.changed('osef', fireEvent);
  }

  recalculate(changeName: string, size: SizeDTO) {
    if (changeName === 'x') {
      let ratio = size.x / this.defaultSizeDTO.x;
      size.y = this.round2Decimals(this.defaultSizeDTO.y * ratio);
      this.sizeY = this.unit === 'cm' ? size.y / 10 : size.y;
      size.z = this.round2Decimals(this.defaultSizeDTO.z * ratio);
      this.sizeZ = this.unit === 'cm' ? size.z / 10 : size.z;
    } else if (changeName === 'y') {
      let ratio = size.y / this.defaultSizeDTO.y;
      size.x = this.round2Decimals(this.defaultSizeDTO.x * ratio);
      this.sizeX = this.unit === 'cm' ? size.x / 10 : size.x;
      size.z = this.round2Decimals(this.defaultSizeDTO.z * ratio);
      this.sizeZ = this.unit === 'cm' ? size.z / 10 : size.z;
    } else if (changeName === 'z') {
      let ratio = size.z / this.defaultSizeDTO.z;
      size.y = this.round2Decimals(this.defaultSizeDTO.y * ratio);
      this.sizeY = this.unit === 'cm' ? size.y / 10 : size.y;
      size.x = this.round2Decimals(this.defaultSizeDTO.x * ratio);
      this.sizeX = this.unit === 'cm' ? size.x / 10 : size.x;
    }
  }

  round2Decimals(n: number) {
    return Math.round(n * 100) / 100;
  }

  changed(changeName: string, fireEvent: boolean = true) {
    if (this.sizeX === undefined || this.sizeY === undefined || this.sizeZ === undefined || this.unit === undefined) return;

    if (changeName === 'unit') {
      if (this.unit === 'cm') {
        this.sizeX /= 10;
        this.sizeY /= 10;
        this.sizeZ /= 10;
      } else {
        this.sizeX *= 10;
        this.sizeY *= 10;
        this.sizeZ *= 10;
      }
    }
    let size = new SizeDTO();
    if (this.unit === 'cm') {
      size.x = this.sizeX * 10;
      size.y = this.sizeY * 10;
      size.z = this.sizeZ * 10;
    } else {
      size.x = this.sizeX;
      size.y = this.sizeY;
      size.z = this.sizeZ;
    }

    this.recalculate(changeName, size);

    if (this.defaultSizeDTO != null && (size.x !== this.defaultSizeDTO.x || size.y !== this.defaultSizeDTO.y || size.z !== this.defaultSizeDTO.z)) {
      this.restoreToDefault = true;
    } else {
      this.restoreToDefault = false;
    }
    let tropGrand = false;
    let tropPetit = false;
    let ok = false;
    for (let ps of this.printerSizes) {
      console.log(size, ps);
      if (ps.minimalPrintingSize > size.x || ps.minimalPrintingSize > size.y || ps.minimalPrintingSize > size.z) tropPetit = true;
      if (
        ps.sizeXmm * OrderService.PRINTER_MARGIN < size.x ||
        ps.sizeYmm * OrderService.PRINTER_MARGIN < size.y ||
        ps.sizeZmm * OrderService.PRINTER_MARGIN < size.z
      )
        tropGrand = true;
      if (
        ps.minimalPrintingSize <= size.x &&
        ps.minimalPrintingSize <= size.y &&
        ps.minimalPrintingSize <= size.z &&
        ps.sizeXmm * OrderService.PRINTER_MARGIN >= size.x &&
        ps.sizeYmm * OrderService.PRINTER_MARGIN >= size.y &&
        ps.sizeZmm * OrderService.PRINTER_MARGIN >= size.z
      )
        ok = true;
    }
    this.showPrinter = null;
    if (!ok) {
      if (tropPetit) {
        let minPrinter = this.printerSizes.reduce((prev, curr) => (prev.minimalPrintingSize < curr.minimalPrintingSize ? prev : curr), this.printerSizes[0]);
        if (this.unit === 'cm')
          this.showPrinter =
            "La taille minimale d'impression est :&nbsp;" +
            (minPrinter.minimalPrintingSize / 10).toFixed(2).replace('.', ',') +
            ' x ' +
            (minPrinter.minimalPrintingSize / 10).toFixed(2).replace('.', ',') +
            ' x ' +
            (minPrinter.minimalPrintingSize / 10).toFixed(2).replace('.', ',') +
            ' cm';
        else
          this.showPrinter =
            "La taille minimale d'impression est :&nbsp;" +
            minPrinter.minimalPrintingSize.toFixed(2).replace('.', ',') +
            ' x ' +
            minPrinter.minimalPrintingSize.toFixed(2).replace('.', ',') +
            ' x ' +
            minPrinter.minimalPrintingSize.toFixed(2).replace('.', ',') +
            ' mm';
      }
      if (tropGrand) {
        let maxPrinter = this.printerSizes.reduce((prev, curr) => (prev.volume > curr.volume ? prev : curr), this.printerSizes[0]);
        let size = '';
        if (this.unit === 'cm')
          size =
            ((maxPrinter.sizeXmm * OrderService.PRINTER_MARGIN) / 10).toFixed(2).replace('.', ',') +
            ' x ' +
            ((maxPrinter.sizeYmm * OrderService.PRINTER_MARGIN) / 10).toFixed(2).replace('.', ',') +
            ' x ' +
            ((maxPrinter.sizeZmm * OrderService.PRINTER_MARGIN) / 10).toFixed(2).replace('.', ',') +
            ' cm';
        else
          size =
            (maxPrinter.sizeXmm * OrderService.PRINTER_MARGIN).toFixed(2).replace('.', ',') +
            ' x ' +
            (maxPrinter.sizeYmm * OrderService.PRINTER_MARGIN).toFixed(2).replace('.', ',') +
            ' x ' +
            (maxPrinter.sizeZmm * OrderService.PRINTER_MARGIN).toFixed(2).replace('.', ',') +
            ' mm';
        this.showPrinter = "La taille maximale d'impression est :&nbsp;" + size;
      }
    }
    console.log(
      'size is',
      ok,
      tropPetit,
      tropGrand,
      this.printerSizes.reduce((prev, curr) => (prev.volume > curr.volume ? prev : curr), this.printerSizes[0]),
      this.sizeX,
      this.sizeY,
      this.sizeZ,
      size,
      ' will fire event :',
      fireEvent
    );
    if (fireEvent) this.sizeChanged.emit(size);
  }
}
