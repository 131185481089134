import { Component, OnInit, Input } from '@angular/core';
import { PrinterData } from '../../providers/printer.provider';
import { environment } from 'environments/environment';
import { ProducerIntendedType } from 'degineo-common';

@Component({
  selector: 'app-printer-info',
  templateUrl: './printer-info.component.html',
  styleUrls: ['./printer-info.component.scss'],
})
export class PrinterInfoComponent implements OnInit {
  @Input()
  edit: boolean;

  rpcHost: string;
  imgURL: any;
  errorFile: string;
  categoryList: ProducerIntendedType[] = ProducerIntendedType.values;

  presenter = (obj) => obj;

  constructor(public data: PrinterData) {
    this.rpcHost = environment.rpcHost;
  }

  ngOnInit() {}

  handleFileInput(event) {
    let files: FileList = event.target.files;
    var fr = new FileReader();
    fr.onload = (e) => {
      // file is loaded
      var img = new Image();
      img.onload = () => {
        console.log(img.width + ' x ' + img.height);
        let dimensionsMin = { w: 94, h: 140 };
        if (img.width < dimensionsMin.w || img.height < dimensionsMin.h) {
          //dimensions minis
          this.errorFile = 'Votre fichier doit au minimum faire ' + dimensionsMin.w + 'x' + dimensionsMin.h;
        } else {
          this.data.file = files[0];
          console.log(this.data.file, this.data.file.size / 1024 / 1024 + 'MB', this.data.file.name.split('.').pop());
        }
      };
      this.imgURL = (e.target as any).result;
      img.src = (e.target as any).result; // is the data URL because called with readAsDataURL
    };
    fr.readAsDataURL(files[0]);
  }

  reloadConsumableBrands() {
    this.data.reloadConsumableBrands.next();
  }
}
