<div class="container-small">
  <div class="row mb-5">
    <div class="col text-center">
      <h1>Mes imprimeurs partenaires</h1>
    </div>
  </div>
  <ng-container *ngIf="producers === null">
    <div class="row mb-5">
      <div class="col text-center">
        <div class="col"><i class="fas fa-spinner spinning"></i> Chargement...</div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="producers !== null">
    <div class="row mb-5" *ngIf="producers.length === 0">
      <div class="col text-center">
        <h3>Vous n'avez pas d'imprimeur partenaire.</h3>
      </div>
    </div>
    <div
      class="row align-items-center mb-3 py-2 rounded-pill"
      [class.bg-light-30p]="!producer.needToAcceptOrRefuse"
      [class.bg-light]="producer.needToAcceptOrRefuse"
      *ngFor="let producer of producers"
    >
      <div class="col-2">
        <img src="{{ rpcHost }}companylogo/{{ producer.logoToken }}/logo.png?size=small" class="rounded-circle" style="width: 3rem" />
      </div>
      <div class="col-5 fw-500">
        {{ producer.producerName }}
      </div>
      <div class="col-5 text-right pr-4" *ngIf="producer.needToAcceptOrRefuse">
        <div class="d-inline-block text-center fw-500">
          Demande en attente&nbsp;:<br />
          <a class="fw-500" [routerLink]="null" (click)="accept(producer)">Accepter</a>
          <a [routerLink]="null" class="ml-2 fw-500" (click)="refuse(producer)">Refuser</a>
        </div>
      </div>
      <div class="col-5 text-right pr-4" *ngIf="!producer.needToAcceptOrRefuse">
        <i
          class="far fa-times-circle text-secondary hover-danger pointer"
          ngb
          style="font-size: 1.5rem"
          placement="bottom"
          ngbTooltip="Retirer cet imprimeur de vos imprimeurs partenaires"
          (click)="remove(producer)"
        ></i>
      </div>
    </div>
  </ng-container>
</div>
