import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { FilterOrder, Filter, OrderBy, GenericFilter, ProducerLiteBODTO } from 'degineo-common';
import { Router } from '@angular/router';
import { InvoiceBOService } from 'degineo-common';
import { L } from 'ic2-lib';
import { SlugService } from 'degineo-common';

@Component({
  selector: 'app-payment-to-degineo-list',
  templateUrl: './payment-to-degineo-list.component.html',
  styleUrls: ['./payment-to-degineo-list.component.scss'],
})
export class PaymentToDegineoListComponent implements OnInit, AfterViewInit {
  PaymentToDegineoListComponent: typeof PaymentToDegineoListComponent = PaymentToDegineoListComponent;
  payments = [];
  OrderBy: typeof OrderBy = OrderBy;

  static filtersCache = {};
  filtersDefault = {};

  static OrderCaches = {};
  static page: number = 1;

  nbPerPage: number = 10;
  count = 0;
  loading: boolean = true;

  builtFilters: Filter[] = [];
  builtFiltersOrder: FilterOrder[] = [];

  producers: ProducerLiteBODTO[] = [];

  presenterName = (obj) => obj.name;

  constructor(
    private router: Router,
    @Inject(InvoiceBOService) public invoiceService: InvoiceBOService,
    @Inject(SlugService) public slugService: SlugService
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.invoiceService.getProducersWhereDegineoWaitsForPayment().subscribe(
      (data) => {
        this.producers = data;
      },
      (err) => {
        L.e(err);
      }
    );
    this.reload();
  }

  numberWithSpaces(x) {
    return x
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      .replace('.', ',');
  }

  reload() {
    let genericFilter = new GenericFilter();
    genericFilter.filters = this.builtFilters;
    genericFilter.orderFilters = this.builtFiltersOrder;
    genericFilter.page = PaymentToDegineoListComponent.page;
    genericFilter.nbPerPage = this.nbPerPage;
    console.log('genericFilter', genericFilter);
    this.loading = true;
    this.invoiceService.getBillToDegineo(genericFilter).subscribe(
      (data) => {
        console.log(data);
        this.payments = data.data;
        this.count = data.count;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }

  oneMonthsAgo = new Date().setMonth(new Date().getMonth() - 1);
  olderThan1Month(date) {
    const d = new Date(date);
    return +this.oneMonthsAgo > +date;
  }

  edit(id) {
    this.router.navigate(['/paymentToD', 'edit', id]);
  }

  filterChanged(filters: Filter[]) {
    console.log('filterChanged event', filters);
    this.reload();
  }
  orderChange(order) {
    console.log('orderChange', order);
    this.reload();
  }
  tableRowClick(row: any) {
    console.log('tableRowClick', row);
    //this.router.navigate(['/producers', 'edit', row.id]);
  }
}
