<div class="container pt-4 pb-4" *ngIf="!loading">
  <!-- Boutton top - View -->
  <div class="overflow-auto" *ngIf="!edit!; new">
    <button type="button" class="btn btn-secondary m-1 float-left" (click)="backClicked()"><i class="fas fa-chevron-left"></i> Retour</button>
    <button type="button" class="btn btn-primary m-1 float-right" style="min-width: 200px" (click)="editClicked()">Modifier</button>
  </div>

  <!-- Boutton top - Edit -->
  <div class="overflow-auto" *ngIf="edit && !new">
    <button class="btn btn-success m-1 float-right" style="min-width: 200px" type="button" (click)="saveEditClicked()" [disabled]="!data.dto.finish.name">
      Enregistrer les modifications
    </button>
    <button class="btn btn-secondary m-1 float-right" style="min-width: 200px" type="button" (click)="cancelClicked()">Annuler les modifications</button>
    <button class="btn btn-danger m-1 float-right" style="min-width: 200px" type="button" (click)="deleteClicked()">Supprimer cette finition</button>
  </div>

  <!-- Boutton top - New -->
  <div class="overflow-auto" *ngIf="new">
    <button class="btn btn-success m-1 float-right" style="min-width: 200px" type="button" (click)="newClicked()" [disabled]="!data.dto.finish.name">
      Ajouter
    </button>
    <button class="btn btn-secondary m-1 float-right" style="min-width: 200px" type="button" (click)="cancelClicked()">Annuler</button>
  </div>

  <div class="row mt-5">
    <div class="col-12">
      <app-finish-info [edit]="edit"> </app-finish-info>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-12 mt-4">
      <app-finish-material-assoc class="h-100 p-4 d-block alert border-secondary border" [edit]="edit"> </app-finish-material-assoc>
    </div>
  </div>
</div>
