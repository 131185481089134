import { Component, OnInit, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentDataService } from '../payment-data.service';

@Component({
  selector: 'app-payment-workflow',
  templateUrl: './payment-workflow.component.html',
  styleUrls: ['./payment-workflow.component.scss'],
})
export class PaymentWorkflowComponent implements OnInit {
  PaymentDataService = PaymentDataService;
  constructor(public activeModal: NgbActiveModal, @Inject(PaymentDataService) public paymentDataService: PaymentDataService) {}

  ngOnInit() {}

  close(reason) {
    this.activeModal.close(reason);
  }
}
