import { Component, OnInit, Inject } from '@angular/core';
import { OrderData } from '../../providers/order.provider';
import { L } from 'ic2-lib';
import { OrderBOService } from 'degineo-common';

@Component({
  selector: 'app-order-view-note',
  templateUrl: './order-view-note.component.html',
  styleUrls: ['./order-view-note.component.scss'],
})
export class OrderViewNoteComponent implements OnInit {
  noteDegineo;

  constructor(public data: OrderData, @Inject(OrderBOService) public orderBOService: OrderBOService) {}

  ngOnInit() {
    this.noteDegineo = this.data.dto.order.noteDegineo;
  }

  displayBtNote() {
    if (this.noteDegineo == null || this.noteDegineo == '')
      if (this.data.dto.order.noteDegineo == null || this.data.dto.order.noteDegineo == '') return false;
      else return true;
    else if (this.noteDegineo != this.data.dto.order.noteDegineo) return true;
    else return false;
  }
  cancelNoteClicked() {
    this.data.dto.order.noteDegineo = this.noteDegineo;
  }
  saveNoteClicked() {
    this.orderBOService.saveDegineoNote(this.data.dto.order.id, this.data.dto.order.noteDegineo).subscribe(
      (data) => {
        this.noteDegineo = this.data.dto.order.noteDegineo;
      },
      (error) => {
        L.e(error);
      }
    );
  }
}
