import { Component, OnInit, Input } from '@angular/core';
import { PrinterData } from '../../providers/printer.provider';

@Component({
  selector: 'app-printer-size',
  templateUrl: './printer-size.component.html',
  styleUrls: ['./printer-size.component.scss'],
})
export class PrinterSizeComponent implements OnInit {
  @Input()
  edit: boolean;

  constructor(public data: PrinterData) {}

  ngOnInit() {}
}
