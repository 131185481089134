import { Component, OnInit, Inject, AfterViewInit, ViewChild, ElementRef, ContentChildren, QueryList } from '@angular/core';
import { InvoiceBOService } from 'degineo-common';
import { L } from 'ic2-lib';
import { environment } from 'environments/environment';
import { OrderBy } from 'degineo-common';
import { Filter } from 'degineo-common';
import { FilterOrder, GenericFilter, InvoiceBODTO, InvoiceType } from 'degineo-common';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FilterComponent } from 'degineo-common';

@Component({
  selector: 'invoice-admin',
  templateUrl: './invoice-admin.component.html',
  styleUrls: ['./invoice-admin.component.scss'],
})
export class InvoiceAdminComponent implements OnInit, AfterViewInit {
  InvoiceAdminComponent: typeof InvoiceAdminComponent = InvoiceAdminComponent;
  private sub: Subscription;
  invoices: InvoiceBODTO[] = [];
  loading: boolean = true;
  count = 0;
  nbPerPage = 15;

  OrderBy: typeof OrderBy = OrderBy;

  @ViewChild('filter') filter: FilterComponent;
  static filtersCache = {};
  filtersDefault = {};
  static OrderCaches = {};
  static page: number = 1;

  search: string = null;
  idCompany: number = null;
  builtFilters: Filter[] = [];
  builtFiltersOrder: FilterOrder[] = [];

  company1: string[] = [];
  company2: string[] = [];

  type = InvoiceType.values;

  rpcHost: string;

  generationLoading = false;
  nbInvoicesInserted: number = null;
  notProd = environment.name !== 'PRODUCTION';

  presenterName = (obj) => obj.name;

  constructor(private router: Router, @Inject(ActivatedRoute) public route: ActivatedRoute, @Inject(InvoiceBOService) public invoiceService: InvoiceBOService) {
    this.rpcHost = environment.rpcHost;
  }

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      if (params['search']) {
        this.search = params['search'];
        this.filtersDefault = {
          recherche: params['search'],
        };
      } else if (params['company']) {
        this.idCompany = parseInt(params['company']);
      } else {
        this.router.navigate(['/invoices']);
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.search || this.idCompany) {
        this.filter.resetToDefault();
      }

      this.reload();
    });
  }

  reload() {
    let genericFilter = new GenericFilter();
    genericFilter.filters = this.builtFilters;
    if (this.idCompany) {
      let f: Filter = new Filter();
      f.name = 'company';
      f.intValues = [this.idCompany];
      genericFilter.filters.push(f);
    }
    genericFilter.orderFilters = this.builtFiltersOrder;
    genericFilter.page = InvoiceAdminComponent.page;
    genericFilter.nbPerPage = this.nbPerPage;
    console.log('genericFilter', genericFilter);
    this.loading = true;
    this.invoiceService.getBills(genericFilter).subscribe(
      (data) => {
        console.log('getBills', data);
        this.invoices = data.data;
        this.count = data.count;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }

  company1NameChange(txt) {
    this.invoiceService.getClientOrProducerAssist(txt).subscribe(
      (data) => {
        this.company1 = data;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  company2NameChange(txt) {
    this.invoiceService.getClientOrProducerAssist(txt).subscribe(
      (data) => {
        this.company2 = data;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  getInvoiceTypeLib(id) {
    return InvoiceType.mapFromId(id).toString();
  }

  filterChanged(filters: Filter[]) {
    console.log('filterChanged event', filters);
    this.reload();
  }
  orderChange(order) {
    console.log('orderChange', order);
    this.reload();
  }
  tableRowClick(row: InvoiceBODTO) {
    console.log('tableRowClick', row);
    //this.router.navigate(['/consumables', 'view', row.name, row.brandId, row.printerTechnologyId, row.materialTypeId]);
  }

  generateDelayedInvoices() {
    this.generationLoading = true;
    this.invoiceService.forceCreateDelayedInvoices().subscribe(
      (data) => {
        this.nbInvoicesInserted = data;
        this.generationLoading = false;
        this.reload();
      },
      (err) => {
        this.generationLoading = false;
        L.e(err);
      }
    );
  }
}
