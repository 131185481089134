<div class="container fs-2 fw-400">
  <div class="row mb-4">
    <div class="col">
      <h3>Pièces en impression</h3>
    </div>
  </div>

  <div class="row mb-5 p-4 bg-light-30p border border-light" [class.overflow-auto]="printingOrders.length >= 3">
    <div class="col">
      <div class="container-fluid" style="max-height: 18rem; min-height: 18rem">
        <div class="row align-items-center" [class.mb-5]="!last" *ngFor="let order of printingOrders; let last = last">
          <div class="col-2">
            <img class="rounded" style="width: 3.75rem; height: 3.75rem" img-preloader="{{ rpcHost }}orderModele3Dpng/{{ order.tokenModelPng }}/modele.png" />
          </div>
          <div class="col-3 text-center">
            {{ order.name }} <br />
            {{ order.articleTypeName }} <br />
            <ng-container *ngIf="!order.dentaire">
              {{ order.sizeInMm.x | number: '.2':'fr-FR' }}&nbsp;mm x {{ order.sizeInMm.y | number: '.2':'fr-FR' }}&nbsp;mm x
              {{ order.sizeInMm.z | number: '.2':'fr-FR' }}&nbsp;mm<br />
              {{ order.materialName }} - {{ order.colorName }} - {{ order.finishName }}
            </ng-container>
          </div>
          <div class="col-2 text-center fw-500">Qté&nbsp;:&nbsp;{{ order.quantity }}</div>
          <div class="col-2 text-center fw-500">{{ order.unitPriceTTC * order.quantity | number: '2.0-2':'fr-FR' }}&nbsp;€ <span>TTC</span></div>
          <div class="col-3 text-right">
            <span class="fw-500 text-success">{{ order.status.lib }}</span
            ><br />
            Imprimé par <span class="fw-500">{{ order.producerName }}</span
            ><br />
            Impression n°{{ order.idOrder }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mb-4">
    <div class="col">
      <h3>Pièces imprimées et livrées</h3>
    </div>
  </div>

  <div class="row mb-5 p-4">
    <div class="col">
      <div class="container-fluid">
        <div class="row align-items-center" [class.mb-5]="!last" *ngFor="let order of finishedOrders; let last = last">
          <div class="col-2">
            <img class="rounded" style="width: 3.75rem; height: 3.75rem" img-preloader="{{ rpcHost }}orderModele3Dpng/{{ order.tokenModelPng }}/modele.png" />
          </div>
          <div class="col-3 text-center">
            {{ order.name }} <br />
            {{ order.articleTypeName }} <br />
            {{ order.sizeInMm.x | number: '.2':'fr-FR' }}&nbsp;mm x {{ order.sizeInMm.y | number: '.2':'fr-FR' }}&nbsp;mm x
            {{ order.sizeInMm.z | number: '.2':'fr-FR' }}&nbsp;mm<br />
            {{ order.materialName }} - {{ order.colorName }} - {{ order.finishName }}
          </div>
          <div class="col-2 text-center fw-500">Qté&nbsp;:&nbsp;{{ order.quantity }}</div>
          <div class="col-2 text-center fw-500">{{ order.unitPriceTTC * order.quantity | number: '2.0-2':'fr-FR' }}&nbsp;€ <span>TTC</span></div>
          <div class="col-3 text-right">
            <a class="fw-500 text-primary" href="{{ rpcHost }}invoice/{{ order.tokenInvoice }}/facture.pdf" target="_blank" *ngIf="!order.delayedPayment"
              >Voir la facture</a
            >
            <span class="fw-500 text-warning" *ngIf="order.delayedPayment">Paiement à 30 jours</span>
            <br />
            Imprimé par <span class="fw-500">{{ order.producerName }}</span
            ><br />
            Impression n°{{ order.idOrder }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-center mt-3" *ngIf="!loading">
    <ngb-pagination
      [collectionSize]="count"
      [(page)]="page"
      [pageSize]="nbPerPage"
      [maxSize]="5"
      [rotate]="true"
      (pageChange)="reload()"
      [disabled]="loading"
    ></ngb-pagination>
  </div>
</div>
