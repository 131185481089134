<div class="container-fluid">
  <div class="row">
    <div class="col">
      <h6 class="text-center">Indiquez les dimensions de votre impression&nbsp;3D</h6>
    </div>
  </div>
  <div class="row mt-3">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="container-fluid p-0">
            <div class="row">
              <div class="col-6">
                <span class="pl-2">Largeur</span>
              </div>
              <div class="col-6">
                <span class="pl-2">Profondeur</span>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-6 pr-1">
                <input
                  type="number"
                  inputmode="decimal"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  class="box form-control"
                  step="0.01"
                  min="0"
                  name="sizeX"
                  [(ngModel)]="sizeX"
                  (ngModelChange)="changed('x')"
                />
              </div>
              <div class="col-6 pr-1">
                <input
                  type="number"
                  inputmode="decimal"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  class="box form-control"
                  step="0.01"
                  min="0"
                  name="sizeY"
                  [(ngModel)]="sizeY"
                  (ngModelChange)="changed('y')"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 mt-2 mt-md-0">
          <div class="container-fluid p-0">
            <div class="row">
              <div class="col-6">
                <span class="pl-2">Hauteur</span>
              </div>
              <div class="col-6">
                <span class="pl-2">Unité</span>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-6 pr-1">
                <input
                  type="number"
                  inputmode="decimal"
                  pattern="[0-9]+([\.,][0-9]+)?"
                  class="box form-control"
                  name="sizeZ"
                  [(ngModel)]="sizeZ"
                  (ngModelChange)="changed('z')"
                />
              </div>
              <div class="col-6 pr-1">
                <select class="form-control" name="unit" [(ngModel)]="unit" (ngModelChange)="changed('unit')">
                  <option value="cm">cm</option>
                  <option value="mm">mm</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-2" *ngIf="restoreToDefault && !showPrinter">
    <div class="col text-primary no-select">
      <span class="pointer" (click)="setToDefaultSize(true)"><i class="fas fa-sync-alt mr-1"></i> Remettre les dimensions du fichier&nbsp;3D.</span>
    </div>
  </div>
  <div class="row mt-2" *ngIf="!restoreToDefault">
    <div class="col"><i class="fas fa-level-up-alt r90 mr-2"></i>Les dimensions actuelles sont celles du fichier&nbsp;3D.</div>
  </div>
  <div class="row mt-2" *ngIf="showPrinter">
    <div class="col text-danger" [innerHTML]="showPrinter"></div>
  </div>
</div>
