<h6 class="text-center mb-4">Cible métier</h6>

<div class="container-fluid overflow-auto" style="max-height: 190px" *ngIf="edit">
  <ng-container *ngFor="let t of targets">
    <div class="row">
      <div class="col align-items-center d-flex">
        <i class="fas pr-2 pointer" style="width: 1.5rem" (click)="toggle(t)" [class.fa-chevron-down]="isOpen(t)" [class.fa-chevron-right]="!isOpen(t)"></i>
        <label class="fw-400 pointer">
          <input type="checkbox" class="mr-2" [checked]="hasAll(t)" [indeterminate]="hasAny(t) && !hasAll(t)" (change)="changeParent(t)" />
          {{ t.level1.lib }}
        </label>
      </div>
    </div>
    <ng-container *ngIf="isOpen(t)">
      <div class="row" *ngFor="let child of t.children">
        <div class="col align-items-center ml-5">
          <label class="fw-400 pointer">
            <input type="checkbox" class="mr-2" [checked]="has(child)" (change)="changeChild(child)" />
            {{ child.lib }}
          </label>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
<div class="container-fluid" *ngIf="!edit">
  <ng-container *ngIf="data.dto.businessTargets.length === 0">
    <div class="row">
      <div class="col text-center text-secondary">Aucune cible métier paramétrée</div>
    </div>
  </ng-container>
  <ul>
    <ng-container *ngFor="let t of targets">
      <ng-container *ngIf="hasAny(t)">
        <li>{{ t.level1.lib }}</li>
        <li style="list-style-type: none">
          <ul>
            <ng-container *ngFor="let child of t.children">
              <li *ngIf="has(child)">{{ child.lib }}</li>
            </ng-container>
          </ul>
        </li>
      </ng-container>
    </ng-container>
  </ul>
</div>
