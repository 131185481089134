import { Component, OnInit, Inject, AfterViewInit, ViewChild } from '@angular/core';
import { ProducerBOService, ImportProducerResultDTO } from 'degineo-common';
import { ProducerCompanyListItemBODTO, OrderBy, Filter, FilterOrder, GenericFilter } from 'degineo-common';
import { L } from 'ic2-lib';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { BOData } from '../../../provider/bo.provider';
import { SlugService } from 'degineo-common';

@Component({
  selector: 'app-producer-list',
  templateUrl: './producer-list.component.html',
  styleUrls: ['./producer-list.component.scss'],
})
export class ProducerListComponent implements OnInit, AfterViewInit {
  ProducerListComponent: typeof ProducerListComponent = ProducerListComponent;
  producers: ProducerCompanyListItemBODTO[] = [];
  OrderBy: typeof OrderBy = OrderBy;

  static filtersCache = {};
  filtersDefault = {};

  static OrderCaches = {};
  static page: number = 1;

  nbPerPage: number = 10;
  count = 0;
  loading: boolean = true;

  builtFilters: Filter[] = [];
  builtFiltersOrder: FilterOrder[] = [];

  rpcHost: string;
  errorFile: string;
  import: ImportProducerResultDTO = null;
  importing: boolean = false;

  constructor(
    public BOData: BOData,
    @Inject(ProducerBOService) private producerBOService: ProducerBOService,
    @Inject(SlugService) private slugService: SlugService,
    private router: Router
  ) {
    this.rpcHost = environment.rpcHost;
  }

  ngOnInit() {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.reload();
    });
  }

  reload() {
    const genericFilter = new GenericFilter();
    genericFilter.filters = this.builtFilters;
    genericFilter.orderFilters = this.builtFiltersOrder;
    genericFilter.page = ProducerListComponent.page;
    genericFilter.nbPerPage = this.nbPerPage;
    console.log('genericFilter', genericFilter);
    this.loading = true;
    this.producerBOService.getProducers(genericFilter).subscribe(
      (data) => {
        console.log(data);
        this.producers = data.data;
        this.count = data.count;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }

  newClicked() {
    this.router.navigate(['/producers', 'new']);
  }

  filterChanged(filters: Filter[]) {
    console.log('filterChanged event', filters);
    this.reload();
  }
  orderChange(order) {
    console.log('orderChange', order);
    this.reload();
  }
  tableRowClick(row: ProducerCompanyListItemBODTO) {
    console.log('tableRowClick', row);
    this.router.navigate(['/producers', 'edit', this.slugService.string_to_slug(row.name), row.id, 'infos']);
  }

  handleFileInput(event, fileInput) {
    const files: FileList = event.target.files;
    const file = files[0];
    this.importing = true;
    this.errorFile = null;
    this.producerBOService
      .importProducers()
      .withFile(file)
      .subscribeWithProgress(
        (data) => {
          //this.progressPercent = null;
          this.import = data;
          fileInput.value = '';
          this.importing = false;
          this.reload();
        },
        (loaded, total) => {
          //this.progressPercent = ((loaded / total) * 100).toFixed(0);
        },
        (err) => {
          L.e(err);
          this.importing = false;
          //this.progressPercent = null;
          this.errorFile = err.message;
          fileInput.value = '';
        }
      );
  }
}
