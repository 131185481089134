import { Injectable } from '@angular/core';
import { ArticleTypeBODTO, ArticleTypeNEW } from 'degineo-common';

@Injectable()
export class ArticleTypeData {
  public dto: ArticleTypeBODTO[] = null;

  public constructor() {}

  clear() {
    this.dto = null;
  }
}
