import { Component, OnInit, Inject } from '@angular/core';
import { L } from 'ic2-lib';
import { environment } from '../../../../../../../../environments/environment';
import { DevisStatus, ClientDevisListItemDTO, ClientDevisService } from 'degineo-common';

@Component({
  selector: 'app-client-devis-list',
  templateUrl: './client-devis-list.component.html',
  styleUrls: ['./client-devis-list.component.scss'],
})
export class ClientDevisListComponent implements OnInit {
  devis: ClientDevisListItemDTO[] = [];
  loaded: boolean = false;
  loading: boolean = false;
  DevisStatus: typeof DevisStatus = DevisStatus;
  rpcHost: string = environment.rpcHost;

  constructor(@Inject(ClientDevisService) public clientDevisService: ClientDevisService) {}

  ngOnInit() {
    this.load();
  }

  load() {
    this.loading = true;
    this.clientDevisService.getListDevis().subscribe(
      (data) => {
        this.devis = data;
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }
}
