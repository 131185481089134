<div class="container-fluid">
  <div class="row" *ngIf="!dto">
    <div class="col"><i class="fas fa-spinner spinning"></i> Chargement...</div>
  </div>
  <ng-container *ngIf="dto">
    <div class="row mt-3 align-items-center">
      <div class="col-10">
        <button class="btn btn-secondary" routerLink="/devis">Retour</button>

        <ng-container *ngIf="dto.status === DevisStatus.NOUVEAU_DEVIS || dto.status === DevisStatus.DEVIS_REVISE">
          <button class="btn btn-success ml-2" (click)="acceptDevis()" [disabled]="sending">
            Accepter le devis <i class="fa fa-spinner spinning" *ngIf="sending"></i>
          </button>
          <button class="btn btn-primary ml-2" routerLink="/devis/{{ dto.idDevis }}/modifications">
            Demander des modifications
          </button>
          <button class="btn btn-danger ml-2" routerLink="/devis/{{ dto.idDevis }}/refusal">
            Refuser le devis
          </button>
        </ng-container>
        <button
          class="btn btn-success ml-2"
          (click)="checkoutDevis()"
          [disabled]="sending"
          *ngIf="dto.status === DevisStatus.DEVIS_ACCEPTE && !dto.alreadyInBasket"
        >
          Ajouter au panier <i class="fa fa-spinner spinning" *ngIf="sending"></i>
        </button>
      </div>
      <div class="col-2 text-right fs-5">
        <a [href]="devisUrl"><i class="fas fa-download"></i></a>
      </div>
    </div>
    <div class="row mt-3 p-3" style="height: 900px">
      <div class="col border rounded">
        <iframe [src]="iframeUrl" class="h-100 w-100 border-0"></iframe>
      </div>
    </div>
  </ng-container>
</div>
