<div *ngIf="!edit" class="card">
  <div class="card-img-top pointer bg-dark text-white overflow-hidden text-center" style="max-height: 250px">
    <img style="max-width: 100%; max-height: 100%" [src]="imgUrl" alt="Image type d'article" (click)="toggle()" />
  </div>
  <div (click)="toggle()" class="card-header pointer">
    <i *ngIf="!show" class="fas fa-chevron-right mr-2" style="width: 25px"></i>
    <i *ngIf="show" class="fas fa-chevron-down mr-2" style="width: 25px"></i>
    <span class="fw-500">{{ articleTypeDTO.at.name }}</span>
    <span class="round float-right" [ngClass]="articleTypeDTO.at.active ? 'bg-success' : 'bg-danger'"></span>
  </div>
  <div *ngIf="show" class="card-body">
    <div class="row">
      <div class="col-auto">
        <span class="fw-500 mr-2 pl-2" [ngClass]="articleTypeDTO.at.active ? 'text-success' : 'text-danger'">Activé</span>
        <div class="d-inline-block position-relative">
          <ui-switch [disabled]="true" style="vertical-align: sub" size="small" color="#00c462" defaultBgColor="#d82929" [checked]="articleTypeDTO.at.active">
          </ui-switch>
          <div (click)="$event.stopPropagation()" class="pointer position-absolute" style="right: 0; top: 0; left: 0; bottom: 0"></div>
        </div>
      </div>
    </div>
    <div class="row mt-3 align-items-center">
      <div class="col-auto pr-1">
        <label class="pr-0">Ordre&nbsp;:</label>
      </div>

      <div class="col-auto pl-0">
        <div class="col-auto">
          <span>{{ articleTypeDTO.at.order }}</span>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-auto pr-1">
        <label class="pr-0">Coefficient de matière moy. au cm³&nbsp;:</label>
      </div>

      <div class="col-auto pl-0">
        <div class="col-auto">
          <span *ngIf="articleTypeDTO.at.averageMatterQuantity">{{ articleTypeDTO.at.averageMatterQuantity }}</span>
          <span *ngIf="!articleTypeDTO.at.averageMatterQuantity">~</span>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-auto">
        <label>Dentaire : </label>
        <ui-switch
          [disabled]="true"
          style="vertical-align: sub"
          size="small"
          color="#00c462"
          defaultBgColor="#d82929"
          [(ngModel)]="articleTypeDTO.at.dental"
        ></ui-switch>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-auto">
        <label>Prix unitaire : {{ articleTypeDTO.at.defaultPrice }}</label>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-auto">
        <label>Cible métier&nbsp;:</label>
      </div>
    </div>
    <ng-container *ngIf="articleTypeDTO.targets.length === 0">
      <div class="row">
        <div class="col text-secondary text-center">Aucune cible métier paramétrée</div>
      </div>
    </ng-container>
    <ul>
      <ng-container *ngFor="let t of targets">
        <ng-container *ngIf="hasAny(t)">
          <li>{{ t.level1.lib }}</li>
          <li style="list-style-type: none">
            <ul>
              <ng-container *ngFor="let child of t.children">
                <li *ngIf="has(child)">{{ child.lib }}</li>
              </ng-container>
            </ul>
          </li>
        </ng-container>
      </ng-container>
    </ul>
  </div>
  <div *ngIf="show" class="card-footer">
    <div class="float-left text-secondary">#ID&nbsp;: {{ articleTypeDTO.at.id }}</div>
    <div class="text-primary pointer float-right" (click)="editClicked()">Modifier</div>
  </div>
</div>

<div *ngIf="edit" class="card">
  <div class="card-img-top text-white overflow-hidden bg-dark position-relative text-center" style="height: 250px">
    <img style="opacity: 0.3; max-width: 100%; max-height: 100%" [src]="imgUrl" alt="Image type d'article" />
    <div class="position-absolute w-100 h-100 pointer" style="top: 0" (click)="fileInput.click()">
      <button class="d-block btn ml-auto mr-auto p-0 text-light v-center">Changer l'image (420px * 330px)</button>
      <input type="file" accept="image/png" id="file" style="display: none" #fileInput (change)="handleFileInput($event)" />
      <button class="btn p-0 text-primary" style="font-size: 0.9rem; letter-spacing: normal">
        <i class="fas fa-upload text-primary pointer" style="font-size: 18px"></i>
      </button>
      <div class="progress" *ngIf="progressPercent">
        <div class="progress-bar" role="progressbar" [style.width]="progressPercent + '%'">{{ progressPercent }}%</div>
      </div>
      <span class="text-danger d-block" *ngIf="errorFile">{{ errorFile }}</span>
    </div>
  </div>
  <div class="card-header pointer">
    <span class="fw-500">{{ tempArticleType.name }}</span>
    <span class="round float-right" [ngClass]="tempArticleType.active ? 'bg-success' : 'bg-danger'"></span>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-auto">
        <span class="fw-500 mr-2 pl-2" [ngClass]="tempArticleType.active ? 'text-success' : 'text-danger'"> Activé</span>
        <div class="d-inline-block position-relative">
          <ui-switch style="vertical-align: sub" size="small" color="#00c462" defaultBgColor="#d82929" [checked]="tempArticleType.active"> </ui-switch>
          <div
            (click)="activated(!tempArticleType.active); $event.stopPropagation()"
            class="pointer position-absolute"
            style="right: 0; top: 0; left: 0; bottom: 0"
          ></div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <label>Nom&nbsp;:</label>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <input class="form-control" type="text" [(ngModel)]="tempArticleType.name" />
      </div>
    </div>
    <div class="row mt-3 align-items-center">
      <div class="col-auto pr-1">
        <label class="pr-0">Ordre&nbsp;:</label>
      </div>

      <div class="col-auto pl-0">
        <div class="col-auto">
          <input class="col form-control d-inline-block ml-3 mt-1" style="width: 100px" type="number" [(ngModel)]="tempArticleType.order" />
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-auto">
        <label>Quantité de matière moyenne&nbsp;:</label>
      </div>
    </div>
    <div class="row">
      <div class="col-auto">
        <input class="col form-control d-inline-block ml-3 mt-1" style="width: 100px" type="number" [(ngModel)]="tempArticleType.averageMatterQuantity" /><span
          class="align-self-center"
          >&nbsp;cm³</span
        >
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-auto">
        <label>Dentaire : </label>
        <ui-switch style="vertical-align: sub" size="small" color="#00c462" defaultBgColor="#d82929" [(ngModel)]="tempArticleType.dental"></ui-switch>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-auto">
        <label>Prix unitaire : (si dentaire)</label>
        <input class="form-control d-inline-block" style="width: 100px" type="number" [(ngModel)]="tempArticleType.defaultPrice" />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-auto">
        <label>Cible métier&nbsp;:</label>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-auto">
        <ng-container *ngFor="let t of targets">
          <div class="row">
            <div class="col align-items-center d-flex">
              <i
                class="fas pr-2 pointer"
                style="width: 1.5rem"
                (click)="toggleBT(t)"
                [class.fa-chevron-down]="isOpen(t)"
                [class.fa-chevron-right]="!isOpen(t)"
              ></i>
              <label class="fw-400 pointer">
                <input type="checkbox" class="mr-2" [checked]="hasAll(t)" [indeterminate]="hasAny(t) && !hasAll(t)" (change)="changeParent(t)" />
                {{ t.level1.lib }}
              </label>
            </div>
          </div>
          <ng-container *ngIf="isOpen(t)">
            <div class="row" *ngFor="let child of t.children">
              <div class="col align-items-center ml-5">
                <label class="fw-400 pointer">
                  <input type="checkbox" class="mr-2" [checked]="has(child)" (change)="changeChild(child)" />
                  {{ child.lib }}
                </label>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="card-footer">
    <div class="row">
      <button class="btn btn-secondary ml-auto" style="min-width: 100px" type="button" (click)="cancelClicked()">Annuler</button>
      <button class="btn btn-success ml-2 mr-2" style="min-width: 100px" type="button" (click)="saveClicked()">Valider</button>
    </div>
  </div>
</div>
