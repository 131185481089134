<div class="container pt-4 pb-4">
  <h1 class="mx-auto text-center mb-5">Producteurs 3D</h1>

  <div class="row">
    <div class="col">
      <button class="btn btn-success" type="button" (click)="newClicked()"><i class="fas fa-plus mr-1"></i> Nouveau producteur</button>
      <input type="file" id="file" style="display: none" accept=".csv,.xls" #fileInput (change)="handleFileInput($event, fileInput)" />
      <button class="btn btn-primary ml-2" [disabled]="importing" (click)="fileInput.click()">
        <i class="fas fa-file-medical mr-2"></i>Importer des producteurs <i class="fas fa-spinner spinning" *ngIf="importing"></i>
      </button>
      <span class="ml-2 fw-500" *ngIf="import !== null">{{ import.success }}/{{ import.total }} producteurs importés</span>
      <span class="ml-2 fw-500" *ngIf="errorFile">{{ errorFile }}</span>
    </div>
  </div>

  <degineo-filter
    class="d-inline-block w-100 mt-4 mb-4"
    #fliter
    [builtFilters]="builtFilters"
    (filterChanged)="filterChanged($event)"
    [cache]="ProducerListComponent.filtersCache"
    [default]="filtersDefault"
  >
    <div class="form-inline">
      <degineo-date-filter #f class="input-group mr-2 mt-1" style="width: 13rem" name="Création du compte" filterName="dateCreated"> </degineo-date-filter>

      <degineo-date-filter #f class="input-group mr-2 mt-1" style="width: 13rem" name="Abonné depuis" filterName="dateSub"> </degineo-date-filter>

      <degineo-date-filter #f class="input-group mr-2 mt-1" style="width: 13rem" name="Dernière connexion" filterName="dateLogin"> </degineo-date-filter>

      <degineo-text-filter #f class="input-group mr-2 mt-1" style="width: 13rem" name="Producer, CP ou Ville" filterName="recherche"> </degineo-text-filter>

      <i class="fas fa-undo text-primary resetToDefault ml-2" (click)="fliter.resetToDefault(); ProducerListComponent.page = 1"></i>
    </div>

    <degineo-checkbox-group-filter #f class="form-inline float-left mt-4" filterName="checkBox">
      <degineo-checkbox-filter #cb class="mr-4" name="Abonné" filterName="abonne" color="success"> </degineo-checkbox-filter>
      <degineo-checkbox-filter #cb class="mr-4" name="Inscrit" filterName="inscrit" color="primary"> </degineo-checkbox-filter>
      <degineo-checkbox-filter disabled #cb class="mr-4" name="Désabonné" filterName="desabonne" color="warning" ngbTooltip="dans le futur">
      </degineo-checkbox-filter>
      <degineo-checkbox-filter disabled #cb class="mr-4" name="Désinscrit" filterName="desinscrit" color="danger" ngbTooltip="dans le futur">
      </degineo-checkbox-filter>
      <degineo-checkbox-filter #cb class="mr-4" name="Non inscrit" filterName="nonInscrit" color="secondary"> </degineo-checkbox-filter>
      <degineo-checkbox-filter #cb name="En attente ({{ 0 + BOData.nbProducerWarning }})" filterName="warn" color="warning"> </degineo-checkbox-filter>
    </degineo-checkbox-group-filter>
  </degineo-filter>

  <div class="float-right">{{ count }} résultats</div>
  <degineo-my-table
    [rows]="producers"
    [orderCache]="ProducerListComponent.OrderCaches"
    [builtFiltersOrder]="builtFiltersOrder"
    (orderChange)="orderChange($event)"
    (rowClick)="tableRowClick($event)"
  >
    <degineo-my-column orderName="name" name="Producteur" [defaultOrder]="OrderBy.ASC">
      <td *cv="let r" class="align-middle text-center position-relative">
        <img class="logo rounded mb-2" style="width: 80px" src="{{ rpcHost }}companylogo/{{ r.logoToken }}/logo.png?size=small" />
        <div style="line-height: 1">
          {{ r.name }}
        </div>
        <degineo-my-badge
          bgColor="warning"
          [size]="20"
          [textSize]="12"
          class="mt-1 d-inline-block position-absolute"
          style="left: 15px; top: 0px"
          *ngIf="r.nbPrinterConsumableWarn > 0"
        >
          {{ r.nbPrinterConsumableWarn }}
        </degineo-my-badge>
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="city" name="CP - Ville">
      <td *cv="let r" class="align-middle text-center">{{ r.address.zipCode }} - {{ r.address.city }}</td>
    </degineo-my-column>

    <degineo-my-column name="Statut" [disableOrder]="true">
      <td *cv="let r" class="align-middle text-center">
        <div class="text-success fw-500" *ngIf="r.subscribed">Abonné</div>
        <div class="text-warning fw-500" *ngIf="r.unsub">Désabonné</div>
        <div class="text-primary fw-500" *ngIf="!r.subscribed && r.register">Inscrit</div>
        <div class="text-secondary fw-500" *ngIf="!r.register">Non inscrit</div>
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="dateCreacted" name="Création du compte">
      <td *cv="let r" class="align-middle text-center">
        {{ r.dateCreacted | date: 'dd/MM/yyyy' }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="dateSub" name="Abonné depuis">
      <td *cv="let r" class="align-middle text-center">
        {{ r.dateSub | date: 'dd/MM/yyyy' }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="lastLogin" name="Dernière connexion">
      <td *cv="let r" class="align-middle text-center">
        {{ r.dateLastConnexion | date: 'dd/MM/yyyy' }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="nbPrinter" name="Nbr. d'imprimantes">
      <td *cv="let r" class="align-middle text-center">
        {{ r.nbPrinter }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="nbClient" name="Clients dans le portefeuille">
      <td *cv="let r" class="align-middle text-center">
        {{ r.nbCli }}
      </td>
    </degineo-my-column>

    <degineo-my-column name="CA total">
      <td *cv="let r" class="align-middle text-center"></td>
    </degineo-my-column>
  </degineo-my-table>

  <div class="row justify-content-center mt-3" *ngIf="!loading">
    <ngb-pagination
      [collectionSize]="count"
      [(page)]="ProducerListComponent.page"
      [pageSize]="nbPerPage"
      [maxSize]="6"
      [rotate]="true"
      (pageChange)="reload()"
      [disabled]="loading"
    ></ngb-pagination>
  </div>
</div>
