<div class="container pt-4 pb-4">
  <h1 class="mx-auto text-center mb-5">Paiements vers Degineo</h1>

  <degineo-filter
    class="d-inline-block w-100 mt-4 mb-4"
    #fliter
    [builtFilters]="builtFilters"
    (filterChanged)="filterChanged($event)"
    [cache]="PaymentToDegineoListComponent.filtersCache"
    [default]="filtersDefault"
  >
    <div class="form-inline">
      <degineo-textassist-filter
        #f
        class="input-group mr-2 mt-1"
        style="width :13rem"
        name="Producteur débiteur"
        filterName="producer"
        [values]="producers"
        [presenter]="presenterName"
      >
      </degineo-textassist-filter>

      <degineo-date-filter #f class="input-group mr-2 mt-1" style="width :13rem" name="Facturé le" filterName="date"> </degineo-date-filter>

      <degineo-text-filter #f class="input-group mr-2 mt-1" style="width :13rem" name="N° de facture" filterName="recherche"> </degineo-text-filter>

      <i class="fas fa-undo text-primary resetToDefault ml-2" (click)="fliter.resetToDefault(); PaymentToDegineoListComponent.page = 1"></i>
    </div>
  </degineo-filter>

  <degineo-my-table
    [rows]="payments"
    [orderCache]="PaymentToDegineoListComponent.OrderCaches"
    [builtFiltersOrder]="builtFiltersOrder"
    (orderChange)="orderChange($event)"
    (rowClick)="tableRowClick($event)"
    [pointer]="false"
  >
    <degineo-my-column name="#ID" [disableOrder]="true">
      <td *cv="let r" class="text-right align-middle text-secondary">
        {{ r.invoiceId }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="reference" name="N° de facture">
      <td *cv="let r" class="text-left align-middle" [ngClass]="{ 'text-danger': olderThan1Month(r.date) }">
        {{ r.reference }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="date" name="Facturé le" [defaultOrder]="OrderBy.ASC">
      <td *cv="let r" class="text-center align-middle" [ngClass]="{ 'text-danger': olderThan1Month(r.date) }">
        {{ r.date | date: 'dd/MM/yyyy' }}
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="producer" name="Producteur débiteur">
      <td *cv="let r" class="text-center align-middle" [ngClass]="{ 'text-danger': olderThan1Month(r.date) }">
        <a [routerLink]="'/producers/edit/' + slugService.string_to_slug(r.producerName) + '/' + r.idProducer + '/infos'" *ngIf="r.producerName">
          {{ r.producerName }}
        </a>
      </td>
    </degineo-my-column>

    <degineo-my-column orderName="price" name="Le producteur nous doit">
      <td *cv="let r" class="text-right align-middle" [ngClass]="{ 'text-danger': olderThan1Month(r.date) }">
        {{ r.price | number: '.2':'fr-FR' }}&nbsp;€ TTC
      </td>
    </degineo-my-column>

    <degineo-my-column name="Articles payés" [disableOrder]="true">
      <td *cv="let r" class="text-center align-middle" [ngClass]="{ 'text-danger': olderThan1Month(r.date) }">{{ r.nbPaid }} / {{ r.nbToPay }}</td>
    </degineo-my-column>

    <degineo-my-column [disableOrder]="true">
      <td *cv="let r" class="text-center align-middle">
        <button class="btn btn-primary" type="button" (click)="edit(r.invoiceId)">
          Editer
        </button>
      </td>
    </degineo-my-column>
  </degineo-my-table>

  <div class="row justify-content-center mt-3" *ngIf="!loading">
    <ngb-pagination
      [collectionSize]="count"
      [(page)]="PaymentToDegineoListComponent.page"
      [pageSize]="nbPerPage"
      [maxSize]="6"
      [rotate]="true"
      (pageChange)="reload()"
      [disabled]="loading"
    ></ngb-pagination>
  </div>

  <div class="row mt-3">
    <div class="col">
      <div class="row">
        <div class="col text-danger">Facture de plus de 1 mois</div>
      </div>
    </div>
  </div>
</div>
