import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { ProducerData } from '../providers/producer.provider';
import { Router, ActivatedRoute } from '@angular/router';
import { BOData } from '../../../provider/bo.provider';
import { ProducerBOService } from 'degineo-common';
import { L } from 'ic2-lib';
import { environment } from '../../../../../../../environments/environment';

@Component({
  selector: 'app-producer-edit',
  templateUrl: './producer-edit.component.html',
  styleUrls: ['./producer-edit.component.scss'],
})
export class ProducerEditComponent implements OnInit, OnDestroy {
  constructor(
    public data: ProducerData,
    private router: Router,
    public BOData: BOData,
    @Inject(ActivatedRoute) public route: ActivatedRoute,
    @Inject(ProducerBOService) public producerBOService: ProducerBOService
  ) {}

  activeId: String = 'infos';
  id: number;
  name: string;
  interval;
  svUrl: string = environment.svUrl;
  producerUrl: string = environment.producerUrl;
  view: boolean = true;

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = parseInt(params['id']);
      this.name = params['name'];
      this.activeId = params['page'];
    });

    this.data.fetchNbWarn(this.id);
    this.interval = setInterval(() => {
      this.data.fetchNbWarn(this.id);
    }, 60000);

    this.producerBOService.getProducerDetails(this.id).subscribe(
      (data) => {
        this.data.dto = data;
      },
      (error) => {
        L.e(error);
      }
    );
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }

  onTabChange(event: NgbNavChangeEvent) {
    //console.log(event);
    if (event.nextId == 'back') {
      this.router.navigate(['/producers']);
    } else this.router.navigate(['/producers/edit/' + this.name + '/' + this.id + '/' + event.nextId]);
    event.preventDefault();
  }

  toggleView() {
    this.view = !this.view;
  }
}
