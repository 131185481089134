import { Component, OnInit, Inject, Output, EventEmitter, Input } from '@angular/core';
import { OrderService } from '../../order.service';
import { ArticleTypeNEW } from 'degineo-common';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-article-type-choice-step',
  templateUrl: './article-type-choice-step.component.html',
  styleUrls: ['./article-type-choice-step.component.scss'],
})
export class ArticleTypeChoiceStepComponent implements OnInit {
  @Output()
  select: EventEmitter<ArticleTypeNEW> = new EventEmitter();
  @Input()
  articleTypes: ArticleTypeNEW[] = [];
  rpcHost: string = environment.rpcHost;

  constructor() {}

  ngOnInit() {}
}
