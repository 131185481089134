import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { BasketService, NotFoundComponent } from 'degineo-common';
import { L } from 'ic2-lib';
import { BasketDTO, BasketArticleClientDTO } from 'degineo-common';
import { environment } from 'environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentWorkflowComponent } from '../payment/payment-workflow/payment-workflow.component';
import { PaymentDataService } from '../payment/payment-data.service';
import { PanierDataService } from './panier-data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-panier',
  templateUrl: './panier.component.html',
  styleUrls: ['./panier.component.scss'],
})
export class PanierComponent implements OnInit {
  loading: boolean = false;
  rpcHost: string = environment.rpcHost;
  constructor(
    @Inject(BasketService) public basketService: BasketService,
    private modalService: NgbModal,
    @Inject(PaymentDataService) public paymentDataService: PaymentDataService,
    @Inject(PanierDataService) public panierDataService: PanierDataService,
    private router: Router
  ) {}

  ngOnInit() {
    this.panierDataService.load();
  }

  checkedChanged(article: BasketArticleClientDTO, event) {
    console.log(article, event.target.checked);
    this.loading = true;
    this.basketService.toggleBasketArticle(article.idBasketArticle, event.target.checked).subscribe(
      (data) => {
        this.loading = false;
        this.panierDataService.articles = data;
      },
      (error) => {
        this.loading = false;
        L.e(error);
      }
    );
  }

  addQuantity(article: BasketArticleClientDTO) {
    if (article.idDevis !== 0) return;
    console.log(article);
    this.loading = true;
    this.basketService.changeQuantity(article.idBasketArticle, article.quantity + 1).subscribe(
      (data) => {
        this.loading = false;
        this.panierDataService.articles = data;
      },
      (error) => {
        this.loading = false;
        L.e(error);
      }
    );
  }

  removeQuantity(article: BasketArticleClientDTO) {
    if (article.quantity - 1 === 0) return;
    if (article.idDevis !== 0) return;
    console.log(article);
    this.loading = true;
    this.basketService.changeQuantity(article.idBasketArticle, article.quantity - 1).subscribe(
      (data) => {
        this.loading = false;
        this.panierDataService.articles = data;
      },
      (error) => {
        this.loading = false;
        L.e(error);
      }
    );
  }

  deleteArticle(article: BasketArticleClientDTO) {
    console.log(article);
    this.loading = true;
    this.basketService.deleteArticle(article.idBasketArticle).subscribe(
      (data) => {
        this.loading = false;
        this.panierDataService.articles = data;
      },
      (error) => {
        this.loading = false;
        L.e(error);
      }
    );
  }

  openPayment() {
    this.paymentDataService.reset();

    this.paymentDataService.totalPriceTTC = this.panierDataService.articles.totalTTC;
    this.paymentDataService.selectedArticles = this.panierDataService.articles.articles.filter((a) => a.checked);

    if (this.paymentDataService.selectedArticles.length === 0) return;

    const modalRef = this.modalService.open(PaymentWorkflowComponent, {
      centered: true,
      //@ts-ignore
      size: 'xl',
      beforeDismiss: () => {
        return this.paymentDataService.canDismiss;
      },
    });

    modalRef.result.then((info) => {
      this.panierDataService.load();
      if (info === 'payment finished') this.router.navigate(['/mes-impressions']);
    });
    //console.log(modalRef);
    //modalRef.componentInstance.name = 'World';
  }

  cannotOrder() {
    return this.panierDataService.articles.articles.filter((a) => a.checked).length === 0;
  }
}
