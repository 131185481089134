import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { OrderBy, PaymentType, ClientBOService, ClientListItemBODTO, OrderFileStatus } from 'degineo-common';
import { Filter } from 'degineo-common';
import { FilterOrder, GenericFilter, OrderListItemBODTO, OrderNEWStatus } from 'degineo-common';
import { Router } from '@angular/router';
import { OrderBOService } from 'degineo-common';
import { L } from 'ic2-lib';
import { OrderData } from '../providers/order.provider';
import { BOData } from '../../../provider/bo.provider';
import * as moment_ from 'moment';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss'],
})
export class OrderListComponent implements OnInit, AfterViewInit {
  OrderListComponent: typeof OrderListComponent = OrderListComponent;
  orders: OrderListItemBODTO[] = [];

  moment = moment_;

  OrderBy: typeof OrderBy = OrderBy;
  fileStatus: typeof OrderFileStatus = OrderFileStatus;
  paymentStatus: typeof PaymentType = PaymentType;

  static filtersCache = {};
  filtersDefault = {};

  static OrderCaches = {};
  static page: number = 1;

  nbPerPage: number = 10;
  count = 0;
  loading: boolean = true;

  builtFilters: Filter[] = [];
  builtFiltersOrder: FilterOrder[] = [];

  paymentType: PaymentType[] = PaymentType.values;
  clients: ClientListItemBODTO[] = [];
  status = OrderNEWStatus.values;

  presenterName = (obj: ClientListItemBODTO) => obj.companyName;
  presenterPaymentType = (obj: PaymentType) => obj.lib;

  constructor(
    @Inject(OrderBOService) public orderBOService: OrderBOService,
    @Inject(ClientBOService) public clientBOService: ClientBOService,
    public BOData: BOData,
    public data: OrderData,
    private router: Router
  ) {
    this.moment.locale('fr');
  }

  ngOnInit() {}
  ngAfterViewInit() {
    this.getClientName('');

    this.reload();
  }

  isRedOrder(status) {
    if (status == OrderFileStatus.FILE_TO_CORRECT) return true; //TODO ?
  }

  isOrangeOrder(idStatus) {
    if (idStatus == OrderNEWStatus.WAITING_MODEL_CHECK || idStatus == OrderNEWStatus.WAITING_PAYMENT) {
      return true;
    }
  }

  textChanges(text) {
    this.getClientName(text);
  }

  getClientName(text) {
    let filter = new GenericFilter();
    filter.nbPerPage = 10;
    filter.page = 1;
    let search = new Filter();
    search.name = 'recherche';
    search.stringValues = [];
    search.stringValues.push(text);
    filter.filters = [];
    filter.filters.push(search);
    this.clientBOService.getClients(filter).subscribe(
      (data) => {
        this.clients = data.data;
      },
      (error) => {
        L.e(error);
      }
    );
  }

  reload() {
    let genericFilter = new GenericFilter();
    genericFilter.filters = this.builtFilters;
    genericFilter.orderFilters = this.builtFiltersOrder;
    genericFilter.page = OrderListComponent.page;
    genericFilter.nbPerPage = this.nbPerPage;
    console.log('genericFilter', genericFilter);
    this.loading = true;
    this.orderBOService.getOrders(genericFilter).subscribe(
      (data) => {
        this.loading = false;
        this.orders = data.data;
        this.count = data.count;
        console.log('data', data);
      },
      (err) => {
        this.loading = false;
        L.e(err);
      }
    );
  }

  filterChanged(filters: Filter[]) {
    console.log('filterChanged event', filters);
    this.reload();
  }
  orderChange(order) {
    console.log('orderChange', order);
    this.reload();
  }
  tableRowClick(row: any) {
    console.log('tableRowClick', row);
    this.router.navigate(['/orders', row.id]);
  }
}
