import { Component, OnInit, Input, Inject, Output, EventEmitter } from '@angular/core';
import { ProducerPrinterConsumableOptionListItemBODTO, SlugService } from 'degineo-common';
import { ProducerData } from '../../../../providers/producer.provider';
import { ProducerBOService } from 'degineo-common';
import { L } from 'ic2-lib';
import { environment } from 'environments/environment';
import { BOData } from '../../../../../../provider/bo.provider';

@Component({
  selector: 'app-producer-printer-consumable-option-info',
  templateUrl: './producer-printer-consumable-option-info.component.html',
  styleUrls: ['./producer-printer-consumable-option-info.component.scss'],
})
export class ProducerPrinterConsumableOptionInfoComponent implements OnInit {
  show: boolean = false;
  edit: boolean = false;
  newPrice: number;

  disabled: boolean = true;

  @Input()
  consumable: ProducerPrinterConsumableOptionListItemBODTO;
  @Input()
  idCompanyPrinter: number;

  @Output()
  checkPrinterDisabled = new EventEmitter();

  constructor(
    public data: ProducerData,
    public BOData: BOData,
    @Inject(ProducerBOService) public producerBOService: ProducerBOService,
    public slugService: SlugService
  ) {}

  ngOnInit() {
    this.newPrice = this.consumable.defaultProductionCost;

    this.checkDisabled();
  }

  checkDisabled() {
    if (this.consumable.checked) {
      this.disabled = false;
    } else {
      //Check si au moin 1 type dispo et un prix
      if (this.consumable.currentProductionCost == 0 || this.consumable.producerTypes.length == 0) this.disabled = true;
      else this.disabled = false;
    }
    this.checkPrinterDisabled.emit(null);
  }

  isAdvised(idType) {
    return this.consumable.idArticleTypeAdvised.includes(idType);
  }

  onClick(idType, checked) {
    let idProducerPrinterConsumableOptionArticleType = 0;
    const companyPrinterConsumableOptionArticleType = this.consumable.producerTypes.find((obj) => obj.idArticleType === idType);
    if (companyPrinterConsumableOptionArticleType) idProducerPrinterConsumableOptionArticleType = companyPrinterConsumableOptionArticleType.id;
    this.producerBOService
      .updateCompanyPrinterConsumableType(idType, this.consumable.idProducerPrinterConsumableOption, idProducerPrinterConsumableOptionArticleType)
      .subscribe(
        (data) => {
          console.log('data', data);
          if (data.id == 0) {
            let index = this.consumable.producerTypes.findIndex((obj) => obj.id === idProducerPrinterConsumableOptionArticleType);
            this.consumable.producerTypes.splice(index, 1);
          } else {
            this.consumable.producerTypes.push(data);
          }

          this.checkDisabled();
        },
        (err) => {
          L.e(err);
        }
      );
  }

  isDental(type) {
    return type.dental;
  }

  isChecked(id) {
    return this.consumable.producerTypes.find((obj) => obj.idArticleType === id);
  }

  cancelClicked() {
    this.newPrice = this.consumable.defaultProductionCost;
    this.edit = false;
  }

  saveClicked() {
    this.producerBOService.updateProducerPrinterConsumablePrice(this.consumable.idProducerPrinterConsumableOption, this.newPrice).subscribe(
      (data) => {
        this.consumable.defaultProductionCost = this.newPrice;
        console.log(this.consumable.currentProductionCost);
        console.log(this.consumable.currentProductionCost == 0.0);
        if (this.consumable.currentProductionCost == 0.0) this.consumable.currentProductionCost = this.newPrice;
        console.log(this.consumable.currentProductionCost);
        this.edit = false;
        this.checkDisabled();
      },
      (err) => {
        L.e(err);
      }
    );
  }

  checked(value) {
    //if (!this.disabled)
    this.producerBOService.updateProducerPrinterConsumableChecked(this.consumable.idProducerPrinterConsumableOption, value).subscribe(
      (data) => {
        this.consumable.checked = data;
        this.data.fetchNbWarn(this.data.dto.producer.id);
        this.BOData.fetchNbProducerWarn();
        this.checkDisabled();
      },
      (err) => {
        L.e(err);
      }
    );
  }

  toggle() {
    this.show = !this.show;
  }
}
