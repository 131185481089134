import { Component, OnInit, Inject } from '@angular/core';
import { ArticleTypeData } from '../providers/articleType.provider';
import { ArticleTypeBOService } from 'degineo-common';
import { L } from 'ic2-lib';
import { Filter } from 'degineo-common';

@Component({
  selector: 'app-article-type-view-edit',
  templateUrl: './article-type-view-edit.component.html',
  styleUrls: ['./article-type-view-edit.component.scss'],
})
export class ArticleTypeViewEditComponent implements OnInit {
  ArticleTypeViewEditComponent: typeof ArticleTypeViewEditComponent = ArticleTypeViewEditComponent;
  loading: boolean = true;

  new: boolean = false;

  builtFilters: Filter[] = [];
  static filtersCache = {};
  filtersDefault = {
    activated: true,
  };

  constructor(@Inject(ArticleTypeBOService) private articleTypeBOService: ArticleTypeBOService, public data: ArticleTypeData) {}

  ngOnInit() {
    this.reload();
  }

  reload() {
    console.log('filter', this.builtFilters);
    this.loading = true;
    this.articleTypeBOService.getAll().subscribe(
      (data) => {
        console.log('data', data);
        this.data.dto = data;
        this.loading = false;
      },
      (error) => {
        L.e(error);
      }
    );
  }

  display(activated) {
    //console.log(this.builtFilters);
    let show = false;
    let filter = this.builtFilters.find((x) => x.name === 'checkBox');
    if (!filter) return true;
    let values = this.builtFilters.find((x) => x.name === 'checkBox').stringValues;
    //console.log(values);
    if (values.length === 2) return true;

    if (values.findIndex((x) => x === 'activated') !== -1) if (activated) show = true;
    if (values.findIndex((x) => x === 'unactivated') !== -1) if (!activated) show = true;
    return show;
  }

  filterChanged(filters: Filter[]) {
    console.log('filterChanged event', filters);
    this.reload();
  }

  newClicked() {
    this.new = !this.new;
  }
}
