import { Component, OnInit, Input } from '@angular/core';
import { ConsumableData } from '../../providers/consumable.provider';
import { ConsumableOptionBODTO, ConsumableOption, ColorNEW } from 'degineo-common';

@Component({
  selector: 'app-consumable-options',
  templateUrl: './consumable-options.component.html',
  styleUrls: ['./consumable-options.component.scss'],
})
export class ConsumableOptionsComponent implements OnInit {
  @Input()
  edit: boolean;

  id: number = null;
  name: string;
  hexa: string;
  idColor: number = 0;
  capacity: number = 0;
  unit: string = null;
  price: number = 0;
  stillSold: boolean = true;
  supplierReference: string;

  presenter = (obj) => obj.name;

  constructor(public data: ConsumableData) {}

  ngOnInit() {}

  nomChanged(value: ColorNEW) {
    if (value) {
      this.hexa = value.hexCode;
      this.idColor = value.id;
    } else {
      this.hexa = '';
      this.idColor = 0;
    }
  }

  hexChanged() {
    if (this.hexa) {
      let obj = this.data.dto.options.find((obj) => obj.hexCode.toLocaleLowerCase() == this.hexa.toLocaleLowerCase());
      if (obj) this.name = obj.colorName;
    }
  }

  add() {
    if (!this.hexa && !this.name) return;

    let obj = this.data.colorList.find((obj) => obj.hexCode.toLocaleLowerCase() == this.hexa.toLocaleLowerCase());
    if (obj) this.id = obj.id;
    else this.id = 0;

    let newOption = new ConsumableOptionBODTO();
    newOption.co = new ConsumableOption();
    newOption.colorName = this.name;
    newOption.hexCode = this.hexa;
    if (this.idColor !== 0) newOption.co.idColor = this.idColor;
    newOption.co.stillSold = this.stillSold;
    if (this.price === null) this.price = 0;
    newOption.co.price = this.price;
    newOption.co.supplierReference = this.supplierReference;

    newOption.co.capacity = this.capacity;
    newOption.co.unit = this.unit;

    this.data.dto.options.push(newOption);
  }

  remove(option: ConsumableOptionBODTO) {
    this.data.dto.options.splice(
      this.data.dto.options.findIndex((obj) => obj.co.id == option.co.id),
      1
    );
  }
}
