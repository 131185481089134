import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { ProducerData } from '../../providers/producer.provider';
import { Router } from '@angular/router';
import { OrderBOService, OrderNEWStatus } from 'degineo-common';
import { FilterOrder, Filter, OrderListItemBODTO, OrderBy, GenericFilter } from 'degineo-common';
import { L } from 'ic2-lib';
import { environment } from 'environments/environment';
import { SlugService } from 'degineo-common';
import * as moment_ from 'moment';

@Component({
  selector: 'app-producer-article-list',
  templateUrl: './producer-article-list.component.html',
  styleUrls: ['./producer-article-list.component.scss'],
})
export class ProducerArticleListComponent implements OnInit, AfterViewInit {
  ProducerArticleListComponent: typeof ProducerArticleListComponent = ProducerArticleListComponent;
  OrderBy: typeof OrderBy = OrderBy;

  articles: OrderListItemBODTO[] = [];

  moment = moment_;

  static filtersCache = {};
  filtersDefault = {};

  status: OrderNEWStatus[] = OrderNEWStatus.values;

  static OrderCaches = {};
  static page: number = 1;

  nbPerPage: number = 10;
  count = 0;

  builtFilters: Filter[] = [];
  builtFiltersOrder: FilterOrder[] = [];

  choix: { id: number; name: String }[] = [
    { id: 1, name: 'Producteur' },
    { id: 0, name: 'Degineo' },
  ];

  rpcHost: string;

  presenterName = (obj) => obj.name;

  constructor(
    @Inject(OrderBOService) public orderBOService: OrderBOService,
    @Inject(SlugService) public slugService: SlugService,
    private router: Router,
    public data: ProducerData
  ) {
    this.rpcHost = environment.rpcHost;
  }

  ngOnInit() {}
  ngAfterViewInit() {
    this.reload();
  }

  reload() {
    let genericFilter = new GenericFilter();
    genericFilter.filters = this.builtFilters;

    let f: Filter = new Filter();
    f.name = 'producer';
    f.intValues = [this.data.dto.producer.id];
    genericFilter.filters.push(f);

    genericFilter.orderFilters = this.builtFiltersOrder;
    genericFilter.page = ProducerArticleListComponent.page;
    genericFilter.nbPerPage = this.nbPerPage;
    console.log('genericFilter', genericFilter);
    this.orderBOService.getOrders(genericFilter).subscribe(
      (data) => {
        console.log('getOrderArticles', data);
        this.articles = data.data;
        this.count = data.count;
      },
      (error) => {
        L.e(error);
      }
    );
  }

  filterChanged(filters: Filter[]) {
    console.log('filterChanged event', filters);
    this.reload();
  }
  orderChange(order) {
    console.log('orderChange', order);
    this.reload();
  }
  tableRowClick(row: any) {
    this.router.navigate(['/orders', row.id]);
  }
}
