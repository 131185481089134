<h6 class="text-center mb-4">Caractéristiques techniques</h6>

<div *ngIf="!data.dto.characs" class="text-center">Sélectionner un matériau</div>
<div *ngIf="data.dto.characs && data.dto.characs.length == 0" class="text-center">Pas de caractéristique pour ce
  matériau</div>

<div *ngIf="data.dto.characs && data.dto.characs.length > 0">

  <div *ngIf="!edit">
    <div class="row align-items-center">
      <div class="col-12 col-md-6 pr-md-5 pl-md-5 align-items-center mt-2" *ngFor="let charac of data.dto.characs">
        <div class="row align-items-center">
          <div class="col-6 ">{{charac.title}}&nbsp;:</div>
          <div class="col-auto pr-1">{{charac.prefix}}</div>
          <div class="col-auto p-0 text-right">{{data.consumableCaracteristique[charac.id]}}</div>
          <div class="col-auto col-md-1 pl-1">{{charac.suffix}}</div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="edit">
    <div class="row align-items-center">
      <div class="col-12 col-md-6 pr-md-5 pl-md-5 align-items-center" *ngFor="let charac of data.dto.characs">
        <div class="row align-items-center">
          <div class="col-6">{{charac.title}}&nbsp;:</div>
          <div class="col-auto pr-1">{{charac.prefix}}</div>
          <div class="col-2 p-0">
            <input class="form-control p-1" [type]="charac.characType.id == 1 ? 'number' : 'text'"
              [(ngModel)]="data.consumableCaracteristique[charac.id]">
          </div>
          <div class="col-auto col-md-1 pl-1">{{charac.suffix}}</div>
        </div>
      </div>
    </div>
  </div>

</div>
