<div class="container">
  <div class="row justify-content-center" *ngIf="!dentaire">
    <div class="col-8 alert shadow-2 p-4">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 text-center">
            <h6>Combien d'exemplaires souhaitez vous faire imprimer ?</h6>
          </div>
          <div class="col-12 text-center mt-2">
            Je souhaite faire imprimer
            <input
              type="number"
              inputmode="numeric"
              pattern="[0-9]*"
              class="form-control d-inline ml-1 mr-1"
              style="width: 70px"
              [(ngModel)]="quantity"
              (ngModelChange)="quantityChanged.emit($event)"
              min="1"
            />
            exemplaire(s) de cette pièce.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-5 mt-5 fs-4" *ngIf="!modeDevis">
    <div class="col-6 text-right text-dark">Prix unitaire :</div>
    <div class="col-6 text-dark">{{ unitPriceHT | number: '1.0-2':'fr' }}&nbsp;€ x {{ quantity }}</div>
    <div class="col-6 text-right text-primary fw-500 mt-2">Prix total HT :</div>
    <div class="col-6 text-primary mt-2">
      <span class="fw-500">{{ unitPriceHT * quantity | number: '1.0-2':'fr' }}&nbsp;€</span> <br class="d-md-none" />
      <span class="fs-2 pl-0 pl-md-2">(Frais de port inclus)</span>
    </div>
    <div class="col-6 text-right text-muted mt-2">TVA :</div>
    <div class="col-6 text-muted mt-2">{{ unitPriceHT * quantity * (TVA / 100) | number: '1.0-2':'fr' }}&nbsp;€</div>
    <div class="col-6 text-right text-muted fw-500 mt-2">Sous total TTC :</div>
    <div class="col-6 text-muted fw-500 mt-2">{{ unitPriceHT * quantity * (1 + TVA / 100) | number: '1.0-2':'fr' }}&nbsp;€</div>
  </div>
  <div class="row mb-5 align-items-baseline mt-5">
    <div class="col-sm-12 col-md-6 text-center text-md-right text-muted fw-500">Date de livraison souhaitée :</div>
    <div class="col-sm-12 col-md-6 text-center mt-2 mt-md-0">
      <div class="input-group w-100 w-md-200px">
        <input
          required
          [ngModel]="innerDate"
          (dateChanged)="dateChanged.emit($event.singleDate.jsDate)"
          class="form-control"
          angular-mydatepicker
          name="mydate"
          [options]="INgxMyDpOptions"
          #date
          #dp="angular-mydatepicker"
        />
        <div class="input-group-append">
          <button type="button" class="btn btn-outline-secondary" (click)="dp.toggleCalendar()">
            <i class="far fa-calendar-alt"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-5 mb-5">
    <div class="col-12 col-md-6 mt-3 mt-sm-0 text-md-right order-2 order-md-1">
      <button type="button" class="btn btn-secondary btn-allonge w-100 w-sm-200px" [disabled]="loading || disablePrevious" (click)="previous.emit()">
        <i class="fas fa-chevron-left"></i> Précédent
      </button>
    </div>
    <div class="col-12 col-md-6 order-1 order-md-2">
      <button type="submit" class="btn btn-success w-100 w-sm-200px pl-1 pr-1" [disabled]="!nextAvailable || loading" (click)="next.emit()">
        <ng-container *ngIf="modeDevis && shouldShowSuivant">Suivant <i class="fas fa-chevron-right"></i></ng-container>
        <ng-container *ngIf="modeDevis && !shouldShowSuivant"><i class="fas fa-paper-plane"></i> Envoyer la demande</ng-container>
        <ng-container *ngIf="!modeDevis"><i class="fas fa-shopping-cart"></i> Ajouter au panier </ng-container>
        <i class="fas fa-spinner spinning ml-2" *ngIf="loading"></i>
      </button>
    </div>
  </div>
</div>
