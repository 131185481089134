<div class="container-fluid" style="max-height: 656px; min-height: 656px; height: 656px;">
  <div class="row">
    <div class="col-4 bg-light" style="max-height: 656px; min-height: 656px; height: 656px;">
      <ng-container *ngIf="!modeFacturePaiementA30j">
        <div style="margin-top: 8rem;" class="text-center">
          {{ paymentDataService.selectedArticles.length }} impression{{ paymentDataService.selectedArticles.length > 1 ? 's' : '' }} selectionnée{{
            paymentDataService.selectedArticles.length > 1 ? 's' : ''
          }}
        </div>
        <div class="mt-3 text-center fw-500">
          <span class="fs-5">{{ paymentDataService.totalPriceTTC | number: '.2':'fr-FR' }}&nbsp;€</span> TTC
        </div>
      </ng-container>
      <ng-container *ngIf="modeFacturePaiementA30j">
        <div style="margin-top: 8rem;" class="text-center">{{ invoice.articles.length }} impression{{ invoice.articles.length > 1 ? 's' : '' }} à régler</div>
        <div class="mt-3 text-center fw-500">
          <span class="fs-5">{{ invoice.totalTTC | number: '.2':'fr-FR' }}&nbsp;€</span> TTC
        </div>
      </ng-container>

      <div class="mt-5">
        <div
          class="bulle"
          [class.pointer]="!modeFacturePaiementA30j"
          [class.active]="paymentDataService.paymentMethod === PaymentDataService.PAYMENT_STRIPE && !modeFacturePaiementA30j"
          (click)="switchTo(PaymentDataService.PAYMENT_STRIPE)"
        >
          <img style="height: 1.5rem;" src="assets/payment/cb.svg" />
          <img style="height: 1.5rem;" src="assets/payment/visa.svg" />
          <img style="height: 1.5rem;" src="assets/payment/mastercard.svg" />
          <img style="height: 1.5rem;" src="assets/payment/amexp.svg" />
        </div>
        <div
          class="bulle"
          [class.pointer]="!modeFacturePaiementA30j"
          [class.active]="paymentDataService.paymentMethod === PaymentDataService.PAYMENT_PAYPAL && !modeFacturePaiementA30j"
          (click)="switchTo(PaymentDataService.PAYMENT_PAYPAL)"
        >
          <img style="height: 1.5rem;" src="assets/payment/paypal.svg" />
        </div>
        <div
          class="bulle"
          [class.pointer]="!modeFacturePaiementA30j"
          [class.active]="paymentDataService.paymentMethod === PaymentDataService.PAYMENT_WIRETRANSFERT || modeFacturePaiementA30j"
          (click)="switchTo(PaymentDataService.PAYMENT_WIRETRANSFERT)"
        >
          <img style="height: 1.5rem;" src="assets/payment/logo-sepa.svg" />
        </div>
        <div class="mb-2 fw-500 fs-4 position-absolute" style="bottom: 0px;">
          <a [routerLink]="" (click)="goBack()"><i class="fas fa-chevron-left"></i> Retour</a>
        </div>
      </div>
    </div>

    <div class="col-8 arrow_box p-0 arrow_box_wiretransfert" *ngIf="modeFacturePaiementA30j">
      <app-payment-step2-wiretransfert [modeFacturePaiementA30j]="modeFacturePaiementA30j"></app-payment-step2-wiretransfert>
    </div>
    <div
      *ngIf="!modeFacturePaiementA30j"
      class="col-8 arrow_box p-0"
      [class.arrow_box_cb]="paymentDataService.paymentMethod === PaymentDataService.PAYMENT_STRIPE"
      [class.arrow_box_paypal]="paymentDataService.paymentMethod === PaymentDataService.PAYMENT_PAYPAL"
      [class.arrow_box_wiretransfert]="paymentDataService.paymentMethod === PaymentDataService.PAYMENT_WIRETRANSFERT"
    >
      <app-payment-step2-creditcard *ngIf="paymentDataService.paymentMethod === PaymentDataService.PAYMENT_STRIPE"></app-payment-step2-creditcard>
      <app-payment-step2-paypal *ngIf="paymentDataService.paymentMethod === PaymentDataService.PAYMENT_PAYPAL"></app-payment-step2-paypal>
      <app-payment-step2-wiretransfert *ngIf="paymentDataService.paymentMethod === PaymentDataService.PAYMENT_WIRETRANSFERT"></app-payment-step2-wiretransfert>
    </div>
  </div>
</div>
