<ng-template #changeEmailModal let-modal>
  <div class="container shadow-5 rounded py-4 px-5">
    <i class="close-icon fas fa-times" (click)="modal.dismiss()"></i>
    <div class="row mt-2">
      <div class="col text-center">
        <h6>Indiquez ici l’adresse e-mail qui vous servira <br />d’identifiant Degineo :</h6>
      </div>
    </div>
    <form #f="ngForm" [formGroup]="changeEmailForm" [class.show-errors]="f.submitted && !changeEmailForm.valid" (submit)="changeEmail(modal)">
      <div class="row mt-3">
        <div class="col">
          <input type="text" class="form-control" placeholder="exemple@e-mail.com" autocomplete="email" formControlName="email" />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col text-center">
          <button type="submit" class="btn btn-success" [disabled]="loading">Valider<i class="fas fa-spinner spinning ml-2" *ngIf="loading"></i></button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #resentEmailModal let-modal>
  <div class="container shadow-5 rounded py-4 px-5">
    <i class="close-icon fas fa-times" (click)="modal.dismiss()"></i>
    <div class="row mt-4">
      <div class="col text-center">
        <p>
          Nous venons de vous renvoyer un e-mail de validation à l'adresse suivante&nbsp;: <span class="fw-700">{{ authService.userBundle.user.email }}</span
          >. Pensez à vérifier dans vos courriers indésirables.
        </p>
      </div>
    </div>
  </div>
</ng-template>

<div id="content" [ngStyle]="{ 'padding-left': show ? '17rem' : '0px', 'padding-top': show && mobile ? '0px' : '3.75rem' }">
  <div *ngIf="mobile">
    <div class="w-100 bg-warning text-center fs-2 py-2" *ngIf="!authService.userBundle.du.emailConfirmed">
      Afin de vérifier l’authenticité de votre adresse e-mail, nous avons envoyé un e-mail de validation à l’adresse :
      <span class="fw-700">{{ authService.userBundle.user.email }}</span
      >.<br />
      Si l’adresse e-mail ci-dessus n’est pas correcte, <a [routerLink]="null" (click)="showPopup($event, changeEmailModal)">cliquez ici</a>. Si vous avez perdu
      l'e-mail que nous vous avons envoyé, <a [routerLink]="null" (click)="resendEmail($event, resentEmailModal)">cliquez ici</a> afin d'en recevoir un nouveau.
    </div>
    <div id="nav-icon3" [ngClass]="{ open: show }" (click)="show = !show">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>

    <img class="logo" src="assets/header/logo-degineo.svg" width="110px" style="width: 7rem" />

    <nav class="overflow-hidden topnav w-100 fixed-top bg-primary" [ngStyle]="{ height: show ? '0px' : '3.75rem' }" id="navBar">
      <div class="col">
        <div class="row bg-primary" style="height: 3.75rem">
          <div class="col-auto align-self-center"></div>
        </div>
      </div>
    </nav>

    <nav [ngStyle]="{ left: show ? '0px' : '-17rem' }" class="sidenav fixed-top shadow-1">
      <div class="position-relative w-100 navListContainer">
        <ul class="list-unstyled components navList">
          <div [ngClass]="showProfile ? 'showDropdownLeft' : ''" (click)="clicked()">
            <li (click)="toggleProfile(); $event.stopPropagation()">
              <a class="text-uppercase header-nav-btn">
                <span class="badge badge-pill badge-danger badge-hover2" *ngIf="cpaService.hasPendingClientProducerAssociations">&nbsp;</span>
                <img
                  [src]="avatarChangeService.avatar"
                  height="35px"
                  width="35px"
                  style="width: 2.2rem; height: 2.2rem; border-radius: 50%"
                  class="mr-2"
                  (error)="avatarChangeService.errorLoading()"
                />
                <div class="name d-inline-block align-middle fw-500" style="font-size: 1rem; letter-spacing: 0.025em">
                  {{ authService.userBundle?.user.firstName }}
                </div>
              </a>
            </li>
            <div *ngIf="showProfile">
              <li>
                <a routerLinkActive="active" routerLink="/mon-profil" class="pl-4 fw-500 header-nav-btn" style="letter-spacing: 0.025em"> Mon profil </a>
              </li>
              <li>
                <a routerLinkActive="active" routerLink="/mes-factures" class="pl-4 fw-500 header-nav-btn" style="letter-spacing: 0.025em"> Mes factures </a>
              </li>
              <li>
                <a routerLinkActive="active" routerLink="/mes-imprimeurs-partenaires" class="pl-4 fw-500 header-nav-btn" style="letter-spacing: 0.025em">
                  <span class="badge badge-pill badge-danger badge-marge" style="margin-top: 7px" *ngIf="cpaService.hasPendingClientProducerAssociations">
                    &nbsp;
                  </span>
                  Mes imprimeurs partenaires
                </a>
              </li>
              <li>
                <a (click)="logout()" class="pl-4 fw-500 header-nav-btn" style="letter-spacing: 0.025em">
                  <i class="fas fa-power-off" style="margin-right: 0.3rem; font-size: 1.2rem"></i>Se déconnecter
                </a>
              </li>
            </div>
          </div>
          <li (click)="clicked()" *ngFor="let link of headerLinks">
            <a
              [routerLink]="link.routerLink"
              routerLinkActive="active"
              #rla="routerLinkActive"
              class="text-uppercase fw-500 header-nav-btn"
              style="letter-spacing: 0.025em"
              ><img
                [src]="link.img.path + '/' + link.img.name + (rla.isActive ? '-active' : '') + link.img.ext"
                style="margin-right: 0.3rem; height: 1.2rem"
              />{{ link.name }}</a
            >
          </li>
          <div (click)="clicked()" [ngClass]="showNotification ? 'showDropdownLeft' : ''">
            <li (click)="toggleNotification(); $event.stopPropagation()">
              <a class="text-uppercase fs-2 fw-500 header-nav-btn" style="letter-spacing: 0.025em">
                <img [src]="'assets/header/cloche' + (showNotification ? '-active' : '') + '.svg'" style="margin-right: 0.3rem; height: 1.2rem" />
                notifications
                <span class="badge badge-pill badge-warning" *ngIf="notificationService.notifications.length > 0">{{
                  notificationService.notifications.length
                }}</span>
              </a>
            </li>
            <div *ngIf="showNotification">
              <div class="hr mr-4 ml-4"></div>
              <li *ngFor="let notif of notificationService.notifications">
                <app-notification [notif]="notif" [mobile]="mobile"></app-notification>
                <div class="hr mr-4 ml-4"></div>
              </li>
            </div>
          </div>
        </ul>
      </div>
    </nav>
  </div>

  <div *ngIf="!mobile">
    <nav class="topnav w-100 fixed-top" style="height: 3.75rem" [ngStyle]="{ height: authService.userBundle.du.emailConfirmed ? '3.75rem' : '7.35rem' }">
      <div class="w-100 bg-warning text-center fs-2 py-2" *ngIf="!authService.userBundle.du.emailConfirmed">
        Afin de vérifier l’authenticité de votre adresse e-mail, nous avons envoyé un e-mail de validation à l’adresse :
        <span class="fw-700">{{ authService.userBundle.user.email }}</span
        >.<br />
        Si l’adresse e-mail ci-dessus n’est pas correcte, <a [routerLink]="null" (click)="showPopup($event, changeEmailModal)">cliquez ici</a>. Si vous avez
        perdu l'e-mail que nous vous avons envoyé, <a [routerLink]="null" (click)="resendEmail($event, resentEmailModal)">cliquez ici</a> afin d'en recevoir un
        nouveau.
      </div>
      <div class="col bg-primary">
        <div class="container container-md-fluid">
          <div class="row flex-nowrap bg-primary justify-content-between" style="height: 3.75rem">
            <div class="col-auto">
              <div class="row flex-nowrap">
                <div class="col-auto align-self-center">
                  <a routerLink="/">
                    <img src="assets/header/logo-degineo.svg" width="110px" style="width: 7rem" />
                  </a>
                </div>
                <div class="col-auto text-center p-0" *ngFor="let link of headerLinks">
                  <div routerLinkActive="active" #rla="routerLinkActive" [routerLink]="link.routerLink" class="header-btn no-select pl-2 pr-2">
                    <a class="text-uppercase fs-2 fw-500 no-hover" style="letter-spacing: 0.025em"
                      ><img
                        [src]="link.img.path + '/' + link.img.name + (rla.isActive ? '-active' : '') + link.img.ext"
                        style="margin-right: 0.3rem; height: 1.2rem"
                      />{{ link.name }}
                      <ng-container *ngIf="link.panier && panierDataService.articles !== null && panierDataService.articles.articles.length > 0">
                        ({{ panierDataService.articles.articles.length }})
                      </ng-container>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-auto">
              <div class="row flex-nowrap">
                <div class="col-auto text-center p-0 notification-dw">
                  <div ngbDropdown placement="bottom-right" class="w-100" (openChange)="notificationDropdownOpenChanged($event)" [open]="showNotification">
                    <div class="header-btn pl-3 pr-3" id="dropdownBasic1" ngbDropdownToggle>
                      <img [src]="'assets/header/cloche.svg'" style="margin-right: 0.3rem; height: 1.2rem" />
                      <span class="badge badge-pill badge-warning badge-hover" *ngIf="notificationService.notifications.length > 0">{{
                        notificationService.notifications.length
                      }}</span>
                    </div>
                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                      <ng-container *ngIf="notificationService.notifications.length == 0">
                        <div class="pr-5 pl-5 pt-4 pb-4 text-white text-nowrap">Aucune notification</div>
                      </ng-container>
                      <ng-container *ngIf="notificationService.notifications.length > 0">
                        <div class="hr mr-4 ml-4"></div>
                        <ng-container *ngFor="let notif of notificationService.notifications">
                          <app-notification [notif]="notif" [mobile]="mobile"></app-notification>
                          <div class="hr mr-4 ml-4"></div>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <div class="col-auto text-center p-0 profile-dw">
                  <div ngbDropdown placement="bottom-right" class="w-100" (openChange)="profileOpenChanged($event)" [open]="showProfile">
                    <div class="text-uppercase fs-2 fw-500 header-btn pl-2 pr-2" style="margin-right: 14px" id="dropdownBasic1" ngbDropdownToggle>
                      <span class="badge badge-pill badge-danger badge-hover2" *ngIf="cpaService.hasPendingClientProducerAssociations">&nbsp;</span>
                      <img
                        [src]="avatarChangeService.avatar"
                        height="35px"
                        width="35px"
                        style="width: 2.2rem; height: 2.2rem; border-radius: 50%"
                        class="mr-2"
                        (error)="avatarChangeService.errorLoading()"
                      />
                      <span class="d-none d-xl-block name">{{ authService.userBundle?.user.firstName }}</span>
                    </div>
                    <div class="pl-4 pr-4 pt-3 pb-3" ngbDropdownMenu aria-labelledby="dropdownBasic1">
                      <div routerLink="/mon-profil" (click)="toggleProfile()">Mon profil</div>
                      <div routerLink="/mes-factures" (click)="toggleProfile()">Mes factures</div>
                      <div routerLink="/mes-imprimeurs-partenaires" (click)="toggleProfile()">
                        <span class="badge badge-pill badge-danger badge-marge" *ngIf="cpaService.hasPendingClientProducerAssociations">&nbsp;</span>
                        Mes imprimeurs partenaires
                      </div>
                      <div (click)="logout()"><i class="fas fa-power-off mr-2" style="font-size: 1rem"></i>Se déconnecter</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</div>
<div (click)="clicked()" [ngStyle]="{ paddingTop: authService.userBundle.du.emailConfirmed ? 'unset' : '3.75rem' }">
  <div class="pt-5 pb-5">
    <router-outlet></router-outlet>
  </div>
</div>
