import { Component, OnInit, Host, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentWorkflowComponent } from '../payment-workflow/payment-workflow.component';
import { PaymentDataService } from '../payment-data.service';
import { AddressService, AddressNEW } from 'degineo-common';
import { L } from 'ic2-lib';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';

@Component({
  selector: 'app-payment-step1-address',
  templateUrl: './payment-step1-address.component.html',
  styleUrls: ['./payment-step1-address.component.scss'],
})
export class PaymentStep1AddressComponent implements OnInit {
  facturationSame: boolean = false;
  shippingAddresses: AddressNEW[] = [];
  billingAddresses: AddressNEW[] = [];
  shippingPersonOrCompany: string = null;
  shippingFormattedAddress: string = null;
  billingPersonOrCompany: string = null;
  billingFormattedAddress: string = null;
  shippingAddress: AddressNEW = null;
  billingAddress: AddressNEW = null;
  options: Options = new Options({
    componentRestrictions: { country: 'fr' }, //['fr', 'be', 'ch'] },//TODO fix this when https://github.com/skynet2/ngx-google-places-autocomplete/pull/81 is done
    types: ['address'],
  });

  constructor(
    @Host() public paymentWorkflow: PaymentWorkflowComponent,
    @Inject(PaymentDataService) public paymentDataService: PaymentDataService,
    @Inject(AddressService) public addressService: AddressService
  ) {}

  ngOnInit() {
    this.addressService.getMyAddresses().subscribe(
      (data) => {
        this.shippingAddresses = data.filter((a) => a.livraison);
        this.billingAddresses = data.filter((a) => a.facturation);
      },
      (error) => {
        L.e(error);
      }
    );
    if (this.paymentDataService.shippingAddress !== null && this.paymentDataService.billingAddress !== null) {
      this.shippingAddressSelected(this.paymentDataService.shippingAddress);
      this.shippingPersonOrCompany = this.shippingAddress.societeOrPerson;
      this.billingAddressSelected(this.paymentDataService.billingAddress);
      this.billingPersonOrCompany = this.billingAddress.societeOrPerson;
    }
  }

  shippingNameChanged(text) {
    console.log('shippingNameChanged', text);
    if (this.shippingAddress) {
      this.shippingAddress.societeOrPerson = text;
      this.shippingAddress.id = 0;
    }
  }

  shippingAddressSelected(address: AddressNEW) {
    console.log('shippingAddressSelected', address);
    if (address == null) {
      this.shippingAddress = null;
      this.shippingFormattedAddress = null;
    } else {
      this.shippingAddress = Object.assign(new AddressNEW(), address);
      this.shippingFormattedAddress = this.format(address);
      if (this.facturationSame) this.billingAddress = this.shippingAddress;
    }
  }

  handleShippingAddressChange(event) {
    console.log('adress', event);
    this.shippingAddress = this.createAddressFromGooglePlace(event, true);
    if (this.shippingAddress) {
      this.shippingFormattedAddress = event.formatted_address;
      if (this.facturationSame) this.billingAddress = this.shippingAddress;
    }
  }

  billingNameChanged(text) {
    console.log('billingNameChanged', text);
    if (this.billingAddress) {
      this.billingAddress.societeOrPerson = text;
      this.billingAddress.id = 0;
    }
  }

  billingAddressSelected(address: AddressNEW) {
    console.log('billingAddressSelected', address);
    if (address == null) {
      this.billingAddress = null;
      this.billingFormattedAddress = null;
    } else {
      this.billingAddress = Object.assign(new AddressNEW(), address);
      this.billingFormattedAddress = this.format(address);
    }
  }

  handleBillingAddressChange(event) {
    console.log('adress', event);
    this.billingAddress = this.createAddressFromGooglePlace(event, false);
    if (this.billingAddress) this.billingFormattedAddress = event.formatted_address;
  }

  facturationSameChanged() {
    if (this.facturationSame) {
      this.billingAddress = this.shippingAddress;
      if (this.shippingAddress && this.shippingAddress.id === 0) this.shippingAddress.facturation = true;
    } else {
      if (this.shippingAddress && this.shippingAddress.id === 0) this.shippingAddress.facturation = false;
      this.billingAddress = null;
      this.billingPersonOrCompany = null;
    }
  }

  presenter(item: AddressNEW) {
    return item.societeOrPerson;
  }

  createAddressFromGooglePlace(place, shipping: boolean): AddressNEW {
    const address = new AddressNEW();
    address.societeOrPerson = shipping ? this.shippingPersonOrCompany : this.billingPersonOrCompany;
    address.livraison = shipping;
    address.facturation = this.facturationSame ? true : !shipping;
    address.latitude = place.geometry.location.lat();
    address.longitude = place.geometry.location.lng();
    for (let component of place.address_components) {
      console.log(component);
      if (component.types.includes('street_number')) address.line1 = component.long_name;
      if (component.types.includes('route')) address.line1 += ' ' + component.long_name;
      if (component.types.includes('locality')) address.city = component.long_name;
      if (component.types.includes('country')) address.country = component.long_name;
      if (component.types.includes('postal_code')) address.zipCode = component.long_name;
    }

    if (!this.isFilled(address.line1)) return null;
    return address;
  }

  canValid() {
    //console.log('canValid', this);
    return (
      this.billingAddress !== null &&
      this.shippingAddress !== null &&
      this.isFilled(this.billingAddress.societeOrPerson) &&
      this.isFilled(this.shippingAddress.societeOrPerson)
    );
  }

  isFilled(txt: string) {
    return txt && txt.trim().length !== 0;
  }

  format(address: AddressNEW) {
    let txt = '';
    txt = address.line1;
    if (address.line2) txt += ' ' + address.line2;
    if (address.line3) txt += ' ' + address.line3;
    txt += ', ' + address.zipCode;
    txt += ' ' + address.city;
    txt += ', ' + address.country;
    return txt;
  }

  valider() {
    if (this.facturationSame) {
      if (this.shippingAddress.id === 0) {
        this.addressService.registerAddress(this.shippingAddress).subscribe(
          (data) => {
            this.paymentDataService.shippingAddress = data;
            this.paymentDataService.billingAddress = data;
            this.paymentDataService.step = PaymentDataService.STEP_PAYMENT;
          },
          (error) => {
            L.e(error);
          }
        );
      } else {
        this.paymentDataService.shippingAddress = this.shippingAddress;
        this.paymentDataService.billingAddress = this.shippingAddress;
        this.paymentDataService.step = PaymentDataService.STEP_PAYMENT;
      }
    } else {
      //c'est deux addresses distinctes
      if (this.shippingAddress.id === 0) {
        this.addressService.registerAddress(this.shippingAddress).subscribe(
          (data) => {
            this.paymentDataService.shippingAddress = data;
            this.checkBillingAddress();
          },
          (error) => {
            L.e(error);
          }
        );
      } else {
        this.paymentDataService.shippingAddress = this.shippingAddress;
        this.checkBillingAddress();
      }
    }
  }

  checkBillingAddress() {
    if (this.billingAddress.id === 0) {
      this.addressService.registerAddress(this.billingAddress).subscribe(
        (data) => {
          this.paymentDataService.billingAddress = data;
          this.paymentDataService.step = PaymentDataService.STEP_PAYMENT;
        },
        (error) => {
          L.e(error);
        }
      );
    } else {
      this.paymentDataService.billingAddress = this.billingAddress;
      this.paymentDataService.step = PaymentDataService.STEP_PAYMENT;
    }
  }
}
