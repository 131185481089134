<div class="container pt-4 pb-4">
  <div *ngIf="!data.dto.isRegister">
    <button *ngIf="!newUser" style="min-width: 200px;" class="btn btn-success fw-500" type="button" (click)="newClicked()">
      <i class="fas fa-plus mr-1"></i> Nouvel administrateur
    </button>
  </div>

  <app-producer-user-new (returnToList)="cancelClicked()" *ngIf="newUser"></app-producer-user-new>
  <app-producer-user-list *ngIf="!newUser"></app-producer-user-list>
</div>
