import { Component, OnInit, Inject } from '@angular/core';
import { ProducerTarget, Plan, PlanDTO, FunctionalityDTO, InvoiceTypePlan, Functionality, SubscriptionPlanBOService } from 'degineo-common';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-subscription-list',
  templateUrl: './subscription-list.component.html',
  styleUrls: ['./subscription-list.component.scss'],
})
export class SubscriptionListComponent implements OnInit {
  newP: boolean = false;
  edit: boolean = false;
  view: boolean = false;
  loading = false;

  producerTargets: ProducerTarget[] = ProducerTarget.values;
  functionalities: FunctionalityDTO[] = [];
  invoicingTypes: InvoiceTypePlan[] = InvoiceTypePlan.values;
  plans: PlanDTO[] = [];

  selectedPlan: PlanDTO;
  newSubscriptionPlan: Plan = new Plan();
  checkboxesVisible: boolean = false;

  functionalitiesText: string = null;
  constructor(@Inject(SubscriptionPlanBOService) private subscriptionPlanBOService: SubscriptionPlanBOService) {}

  ngOnInit() {
    this.loading = true;
    this.getFunctionalities();
    this.getPlans(0);
  }

  getFunctionalities() {
    this.subscriptionPlanBOService.getFunctionalities().subscribe(
      (data) => {
        this.functionalities = data;
      },
      (error) => {
        L.e(error);
      }
    );
  }

  getPlans(id: number) {
    this.loading = true;
    this.subscriptionPlanBOService.getPlans().subscribe(
      (data) => {
        this.plans = data;
        console.log(data);
        if (id != 0) this.selectPlan(id);
        else if (!this.selectedPlan) this.selectedPlan = this.plans[0];

        if (this.plans.length > 0) {
          this.getFunctionalitiesText();
          this.view = true;
        }
        this.loading = false;
      },
      (err) => {
        L.e(err);
      }
    );
  }

  selectPlan(id: number) {
    this.selectedPlan = this.plans.find((x) => x.plan.id == id);
    this.view = true;
    this.edit = false;
    this.newP = false;
    this.getFunctionalitiesText();
  }

  newPlan() {
    this.edit = true;
    this.newP = true;
    this.view = false;
    let p = new PlanDTO();
    p.plan = new Plan();
    p.functionalities = [];
    this.functionalities.forEach((x) => {
      let fDTO = new FunctionalityDTO();
      fDTO.functionality = new Functionality();
      fDTO.functionality.id = x.functionality.id;
      fDTO.functionality.name = x.functionality.name;
      fDTO.value = false;

      p.functionalities.push(fDTO);
    });
    /* this.subscriptionPlanBOService.getFunctionalities().subscribe(
      (data) => {
        p.functionalities = data;
        this.getFunctionalitiesText();
      },
      (error) => {
        L.e(error);
      }
    );*/
    this.selectedPlan = p;
    this.getFunctionalitiesText();
  }

  getFunctionalitiesText() {
    this.functionalitiesText = '';

    this.selectedPlan.functionalities.map((x) => {
      if (x.value) {
        if (this.functionalitiesText.length > 0) this.functionalitiesText += ', ';
        this.functionalitiesText += x.functionality.name;
      }
    });
  }

  toggleCheckboxes() {
    if (this.edit || this.newP) this.checkboxesVisible = !this.checkboxesVisible;
  }

  closeCheckboxes() {
    if (this.checkboxesVisible) this.checkboxesVisible = false;
  }

  toggleCheck(index: number) {
    if (!this.selectedPlan.functionalities[index].value) this.selectedPlan.functionalities[index].value = true;
    else this.selectedPlan.functionalities[index].value = false;
    this.getFunctionalitiesText();
  }

  isChecked(index: number) {
    if (this.selectedPlan.functionalities[index].value) return true;
  }

  empty() {
    if (
      !this.selectedPlan.plan.name ||
      !this.selectedPlan.plan.producerTarget ||
      !this.selectedPlan.plan.invoiceType ||
      this.selectedPlan.plan.planButton == null ||
      this.selectedPlan.plan.baseSubscriptionPriceHT == null ||
      this.selectedPlan.plan.printerSubscriptionPriceHT == null ||
      this.selectedPlan.plan.comissionPercent == null ||
      this.selectedPlan.plan.nbMaxPrinter == null ||
      this.selectedPlan.plan.nbMaxUsers == null ||
      this.selectedPlan.plan.nbOrderPerMonth == null
    )
      return true;
  }

  editMode() {
    this.edit = true;
    this.view = false;
  }

  cancel() {
    if (!this.newP) this.view = true;
    this.edit = false;
    this.newP = false;
  }

  validate() {
    this.subscriptionPlanBOService.createPlan(this.selectedPlan).subscribe(
      (data) => {
        this.view = true;
        this.edit = false;
        this.newP = false;
        this.checkboxesVisible = false;
        this.getPlans(data);
      },
      (err) => {
        L.e(err);
      }
    );
  }

  updateActive() {
    this.subscriptionPlanBOService.updateActive(this.selectedPlan.plan.id, !this.selectedPlan.plan.active).subscribe(
      (data) => {
        this.getPlans(0);
        this.selectedPlan.plan.active = !this.selectedPlan.plan.active;
      },
      (err) => {
        L.e(err);
      }
    );
  }
}
