<div class="container-fluid d-flex flex-column h-100">
  <div class="row">
    <div class="col text-center fw-500">
      Nous utilisons le service Degineo pour sécuriser nos prises de commande en ligne.<br />
      Si vous souhaitez nous passer commande et que vous ne possédez pas encore de compte Degineo,<br />
      vous pouvez le créer gratuitement ci&#8209;dessous. Cela prend 2 min.
    </div>
  </div>
  <div class="row mt-5 bg-primary justify-content-center flex-grow-1" style="box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1)">
    <div class="col mt-5 px-4">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="container-small bg-white rounded shadow-2 py-4">
              <div class="row">
                <div class="col text-center">
                  <h3>J'ai déjà un compte Degineo</h3>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col text-center text-uppercase">
                  <a class="fw-500" [routerLink]="null" style="letter-spacing: 0.025em" (click)="login.emit()">Se connecter</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-small border bg-white rounded shadow-2 pb-4 mt-5">
        <div class="row" style="padding-top: 2rem">
          <div class="col text-center">
            <h3>Nouveau sur Degineo ?</h3>
          </div>
        </div>
        <form #f="ngForm" [formGroup]="preRegisterForm" [class.show-errors]="f.submitted && !preRegisterForm.valid">
          <div class="row mt-4">
            <div class="col">
              <i class="fas fa-envelope input-icon"></i>
              <input formControlName="email" autocomplete="email" type="text" class="form-control input-with-icon" placeholder="E-mail" />
            </div>
          </div>
          <div class="row" *ngIf="!preRegisterForm.get('email').pristine || f.submitted">
            <div class="col text-danger" *ngIf="preRegisterForm.get('email').errors">E-mail invalide</div>
          </div>
          <div class="row" *ngIf="registerEmailUnavailable">
            <div class="col text-danger">Cet e-mail est déjà utilisé.</div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <i class="fas fa-lock input-icon"></i>
              <input formControlName="password" autocomplete="new-password" type="password" class="form-control input-with-icon" placeholder="Mot de passe" />
            </div>
          </div>
          <div class="row" *ngIf="!preRegisterForm.get('password').pristine || f.submitted">
            <div class="col-12 text-danger" *ngIf="preRegisterForm.get('password').errors">
              Le mot de passe doit contenir au moins une lettre et au moins un caractère numérique. Au moins 8 caractères sont nécessaires.
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <i class="fas fa-lock input-icon"></i>
              <input
                formControlName="password2"
                autocomplete="new-password"
                type="password"
                class="form-control input-with-icon"
                placeholder="Confirmez votre mot de passe"
              />
            </div>
          </div>
          <div class="row" *ngIf="!preRegisterForm.get('password2').pristine || f.submitted">
            <div class="col-12 text-danger" *ngIf="preRegisterForm.get('password2').getError('notSame')">Les mots de passe ne correspondent pas.</div>
          </div>
          <div class="row mt-4">
            <div class="col text-center">
              <button type="submit" class="btn btn-success text-uppercase w-100" [disabled]="orderService.loading" (click)="continueRegistration.emit()">
                Créer mon compte Degineo
              </button>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col text-center fs-1">
              En créant un compte, vous acceptez nos <a href="{{ svUrl }}mentions-legales" target="_blank">conditions générales de vente</a
              ><br class="d-none d-xl-inline" />
              et notre <a href="{{ svUrl }}confidentialite" target="_blank">politique de confidentialité</a>.
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <a class="pt-2 pb-2" [routerLink]="null" (click)="previous.emit()"> <i class="fas fa-chevron-left"></i> Retour </a>
            </div>
          </div>
        </form>
      </div>

      <div class="container-fluid">
        <div class="row">
          <div class="col-12 text-center mt-3 mb-3">
            <img src="assets/header/logo_degineo_blanc_vert.svg" style="height: 2rem" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
