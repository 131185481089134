import { Component, OnInit, Host, Inject, NgZone } from '@angular/core';
import { environment } from 'environments/environment';
import { NgxPayPalModule, ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import { PaymentStep2Component } from '../payment-step2/payment-step2.component';
import { PaymentDataService } from '../payment-data.service';
import { PaymentService } from 'degineo-common';
import { L } from 'ic2-lib';

@Component({
  selector: 'app-payment-step2-paypal',
  templateUrl: './payment-step2-paypal.component.html',
  styleUrls: ['./payment-step2-paypal.component.scss'],
})
export class PaymentStep2PaypalComponent implements OnInit {
  payPalConfig?: any; //IPayPalConfig
  recette: boolean = environment.name === 'RECETTE' || environment.name === 'DEV';

  constructor(
    @Host() public paymentStep2: PaymentStep2Component,
    @Inject(PaymentDataService) public paymentDataService: PaymentDataService,
    @Inject(PaymentService) public paymentService: PaymentService,
    @Inject(NgZone) public zone: NgZone
  ) {}

  ngOnInit() {
    let purchase_units = [
      {
        amount: {
          currency_code: 'EUR',
          value: this.paymentDataService.totalPriceTTC + '',
          breakdown: {
            item_total: {
              currency_code: 'EUR',
              value: this.paymentDataService.totalPriceTTC + '',
            },
          },
        },
        items: [],
        shipping: {
          name: {
            full_name: this.paymentDataService.shippingAddress.societeOrPerson,
          },
          address: {
            address_line_1: this.paymentDataService.shippingAddress.line1,
            address_line_2: this.paymentDataService.shippingAddress.line2,
            admin_area_2: this.paymentDataService.shippingAddress.city,
            postal_code: this.paymentDataService.shippingAddress.zipCode,
            country_code: this.paymentDataService.shippingAddress.country.toLocaleLowerCase() === 'france' ? 'FR' : null,
          },
        },
      },
    ];
    for (let article of this.paymentDataService.selectedArticles)
      purchase_units[0].items.push({
        name: article.name,
        quantity: article.quantity,
        category: 'PHYSICAL_GOODS',
        unit_amount: {
          currency_code: 'EUR',
          value: +article.unitPriceTTC.toFixed(2) + '', //correctif #658 car le montant total dans breakdown (plus haut) n'était pas = a la somme, j'ai du ré-arrondir ici
        },
      });
    console.log(this.paymentDataService);
    this.payPalConfig = {
      currency: 'EUR',
      clientId: environment.paypalClientId,
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: purchase_units,
          application_context: {
            shipping_preference: 'NO_SHIPPING', //pour éviter de permettre a l'utilisateur de changer l'adresse de livraison dans le popup paypal
            brand_name: 'Degineo',
          },
        },
      advanced: {
        commit: 'true',
      },
      style: {
        label: 'paypal',
        layout: 'horizontal',
        tagline: false,
        fundingicons: false,
      },
      onApprove: (data, actions) => {
        /*console.log('onApprove - transaction was approved, but not authorized', data, actions);
        actions.order.get().then((details) => {
          console.log('onApprove - you can get full order details inside onApprove: ', details);
        });*/
      },
      onClientAuthorization: (data) => {
        console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        this.paymentService
          .paiementPaypal(
            this.paymentDataService.selectedArticles.map((a) => a.idBasketArticle),
            this.paymentDataService.shippingAddress.id,
            this.paymentDataService.billingAddress.id,
            data
          )
          .subscribe(
            (data) => {
              this.zone.run(() => {
                this.paymentDataService.step = PaymentDataService.STEP_CONFIRM;
              });
            },
            (error) => {
              this.zone.run(() => {
                if (error.isBusinessError()) this.paymentDataService.step = PaymentDataService.STEP_ERROR;
              });
              L.e(error);
            }
          );
      },
      onCancel: (data, actions) => {
        console.log('OnCancel', data, actions);
        //this.showCancel = true;
      },
      onError: (err) => {
        console.error('OnError', err);
        this.zone.run(() => {
          this.paymentDataService.step = PaymentDataService.STEP_ERROR;
        });
      },
      onClick: (data, actions) => {
        console.log('onClick', data, actions);
        //this.resetStatus();
      },
    };
    console.log(purchase_units);
  }
}
