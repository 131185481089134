import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, Inject } from '@angular/core';
import { MaterialTypeNEW } from 'degineo-common';

@Component({
  selector: 'app-print-settings-material',
  templateUrl: './print-settings-material.component.html',
  styleUrls: ['./print-settings-material.component.scss'],
})
export class PrintSettingsMaterialComponent implements OnInit, OnChanges {
  @Input()
  availableMaterials: MaterialTypeNEW[];
  @Input()
  selectedMaterial: MaterialTypeNEW;
  @Output()
  materialSelected: EventEmitter<MaterialTypeNEW> = new EventEmitter();

  open: boolean[] = [];

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.open = [];
    if (this.availableMaterials)
      for (let material of this.availableMaterials) {
        if (this.hasChildren(material)) this.open[material.id] = true;
      }
  }
  toggle(id: number) {
    if (this.open[id] === undefined) this.open[id] = true;
    else this.open[id] = !this.open[id];
  }

  hasChildren(mt: MaterialTypeNEW) {
    return this.availableMaterials.find((m) => m.idParent === mt.id) !== undefined;
  }
  rootMaterials() {
    return this.availableMaterials.filter((mt) => mt.idParent === 0);
  }
  childrenOf(mt: MaterialTypeNEW) {
    return this.availableMaterials.filter((m) => m.idParent === mt.id).sort((a, b) => a.name.localeCompare(b.name));
  }
}
