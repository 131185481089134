import { Injectable, Inject } from '@angular/core';
import { ProducerInfoBODTO, ArticleTypeNEW } from 'degineo-common';
import { environment } from 'environments/environment';
import { CommonBOService } from 'degineo-common';
import { L } from 'ic2-lib';

@Injectable()
export class ProducerData {
  public dto: ProducerInfoBODTO = null;

  public rpcHost: string;
  public numberToForceReload: number = 0;

  public articleTypes: ArticleTypeNEW[];

  public nbProducerWarning: number;

  public constructor(@Inject(CommonBOService) public commonBOService: CommonBOService) {
    this.rpcHost = environment.rpcHost;
  }

  fetchNbWarn(id) {
    this.commonBOService.getNbCompanyPrinterAndConsomableWarn(id).subscribe(
      (data) => {
        console.log('fetchNbWarn res', data);
        this.nbProducerWarning = data;
      },
      (error) => {
        L.e(error);
      }
    );
  }
}
